import React from 'react';
import BuyingRules from './BuyingRules/BuyingRules';
import Budget from './Budget/Budget';
import Naming from './Naming/Naming';
import Channels from './Channels/Channels';
import DailyCap from './DailyCap/DailyCap';
import Timing from './Timing/Timing';
import PropTypes from 'prop-types';

function WizardBasics({
    isActive,
    name,
    brand,
    isResizeMapOn,
    selectedCurrency,
    currencies,
    budget,
    campaign,
    dailyCap,
    service,
    timing,
    totalSpent,
    dispatch,
    isTest,
    currencyDefault,
}) {
    return (
        <>
            {!isActive ? (
                ''
            ) : (
                <div
                    className={`basics mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-300 ${
                        isResizeMapOn
                            ? 'transform scale-75 -translate-y-20'
                            : ''
                    }`}>
                    <Naming
                        name={name}
                        brand={brand}
                        dispatch={dispatch}
                        isTest={isTest}
                    />
                    {/* Cuando se pueda implementar channels en el backend descomentar
                <Channels/> */}
                    <Budget
                        selectedCurrency={selectedCurrency}
                        currencies={currencies}
                        budget={budget}
                        campaign={campaign}
                        dispatch={dispatch}
                        currencyDefault={currencyDefault}
                    />
                    <DailyCap
                        selectedCurrency={selectedCurrency}
                        dailyCap={dailyCap}
                        dispatch={dispatch}
                    />
                    <Timing
                        service={service}
                        timing={timing}
                        totalSpent={totalSpent}
                        dispatch={dispatch}
                    />
                    {/* TODO
                <BuyingRules/> */}
                </div>
            )}
        </>
    );
}

WizardBasics.proptyTypes = {
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.string,
    brand: PropTypes.string,
    isResizeMapOn: PropTypes.bool,
    selectedCurrency: PropTypes.object,
    currencies: PropTypes.object,
    budget: PropTypes.object,
    campaign: PropTypes.object,
    dailyCap: PropTypes.object,
    service: PropTypes.func,
    timing: PropTypes.object,
    totalSpent: PropTypes.number,
    dispatch: PropTypes.func,
};

export default WizardBasics;

import React, { useCallback } from 'react';
import { useService } from '../../../containers/UseService';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

const UserSelector = (props) => {
    const { value, onChange, idCustom = 'user' } = props;
    const service = useService();
    const { t } = useTranslation();

    function debounce(fn, delay = 250) {
        let timeout;

        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    }

    const loadOptionsUsers = useCallback(
        debounce(async (inputValue, callback) => {
            const data = await service.getUsers(inputValue);
            const usersWithLabel = data.map((user) => ({
                ...user,
                label: `#${user.id} ${user.username}`,
                value: user.id,
            }));
            callback(usersWithLabel);
        }, 300)
    );

    return (
        <AsyncSelect
            autoFocus
            inputId={idCustom}
            cacheOptions
            loadOptions={loadOptionsUsers}
            value={value ? { label: value.username } : null}
            onChange={onChange}
            placeholder={t('common.components.UserSelector.placeholder')}
        />
    );
};

export default UserSelector;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import InputSelect from '../InputSelect/InputSelect';
import { isEmpty, isValidInput } from '../../../../../../../utils/functions';

const Company = (props) => {
    const { translations, user, setUser, countries } = props;
    const { t } = useTranslation();
    const noValidation = () => true;

    const validateTax = (input) => {
        if (input) {
            if (
                isValidInput(input, 'alphanumeric') &&
                input.length >= 5 &&
                input.length <= 15
            ) {
                return true;
            }
        }
    };

    const validateString = (value) => {
        // this makes sure our beautiful bussiness name is a string
        return typeof value == 'string' && value != null;
    };

    const change = (key, value) => {
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy[key] = value;
        setUser(userCopy);
    };

    return (
        <>
            <div className="w-11/12 grid-cols-1 gap-x-14 sm:gap-y-4  sm:grid sm:grid-cols-2 ">
                <Input
                    label={t(translations + 'company')}
                    id="company-name"
                    value={user.companyName}
                    onChange={(companyName) =>
                        change('companyName', companyName)
                    }
                    onValidate={noValidation}
                    placeholder={t(translations + 'companyPlaceholder')}
                />
                <Input
                    type="text"
                    label={t(translations + 'address')}
                    id="address"
                    value={user.address}
                    onChange={(address) => change('address', address)}
                    onValidate={(e) => !isEmpty(e)}
                    placeholder={t(translations + 'addressPlaceholder')}
                    errorMessage={t(translations + 'invalidAddress')}
                />
                <Input
                    type="text"
                    label={t(translations + 'taxId')}
                    id="taxId"
                    value={user.taxId}
                    onChange={(taxId) => change('taxId', taxId)}
                    onValidate={(tax) => validateTax(tax)}
                    placeholder={t(translations + 'taxIdPlaceholder')}
                    errorMessage={t(translations + 'invalidTaxId')}
                />
                <Input
                    type="text"
                    label={t(translations + 'businessName')}
                    id="business-name"
                    value={user.businessName}
                    onChange={(businessName) =>
                        change('businessName', businessName)
                    }
                    onValidate={() => validateString(user.businessName)}
                    placeholder={t(translations + 'businessNamePlaceholder')}
                    errorMessage={t(translations + 'cannotBeBlank')}
                />
                <InputSelect
                    label={t(translations + 'countryRegistration')}
                    id="countries"
                    options={countries}
                    value={user.countrySelected}
                    onChange={(countrySelected) =>
                        change('countrySelected', countrySelected)
                    }
                    placeholder={t(translations + 'country')}
                />
            </div>
        </>
    );
};

export default Company;

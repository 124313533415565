import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const SwitcherButton2 = ({ value, handleClick, labelOff, labelOn }) => {
    return (
        <>
            <div className="flex justify-between">
                <div
                    onClick={() => handleClick(!value)}
                    className="w-8 relative my-1 cursor-pointer">
                    <div
                        className={`${
                            value ? 'bg-blue-300' : 'bg-gray-300'
                        } h-5 w-8 rounded-full`}>
                        <div
                            className={`${
                                value ? 'left-4' : 'right-4'
                            } mt-1 w-3 h-3 absolute transition-all transform ease-linear duration-150 flex items-center justify-center rounded-full bg-white shadow-toggle border-gray-300 top-0`}></div>
                    </div>
                </div>
                <div
                    onClick={() => handleClick(!value)}
                    className={`${
                        labelOff ? 'block' : 'hidden'
                    } break-normal text-xs font-light mt-1.5 px-2 cursor-pointer capitalize`}>
                    {value ? labelOn : labelOff}
                </div>
            </div>
        </>
    );
};

SwitcherButton2.proptyTypes = {
    value: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    labelOff: PropTypes.string,
    labelOn: PropTypes.string,
};

export default SwitcherButton2;

import React from 'react';

export default function ProgressBar({
    progress,
    isActive,
    campaignPercentage,
    isTopModule,
}) {
    const percentage = progress >= 100 ? 100 : progress <= 0 ? 0 : progress;

    return (
        <div
            className={`${
                isActive ? 'animate-pulse' : ''
            } w-full opacity-75 group-hover:opacity-100 duration-300 transition-opacity bg-gray-100 h-1 rounded-full`}>
            <div
                style={{ width: `${percentage}%` }}
                className={`${
                    isActive
                        ? percentage >= 101 || campaignPercentage >= 101
                            ? 'bg-red-800'
                            : isTopModule
                            ? 'bg-blue-400'
                            : 'bg-green-400'
                        : 'bg-gray-400'
                } mt-2 h-0.5 py-0.5 rounded-full`}></div>
        </div>
    );
}

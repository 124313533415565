import React, { useState, useEffect, useMemo } from 'react';
import { newCampaignActions, screensActions } from '../../store';
import { useSelector, useDispatch } from 'react-redux';

const Checkbox = (props) => {
    const { selectedScreensLocal } = useSelector((state) => state.screens);
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    //it handle the checkbox status
    useEffect(() => {
        if (props) {
            let check =
                selectedScreensLocal.length &&
                selectedScreensLocal.some(
                    (screen) => screen?.id === props?.row?.id
                );
            setIsChecked(check);
        }
    }, [selectedScreensLocal]);

    const addRow = () => {
        if (props) {
            let newScreens;
            if (isChecked) {
                newScreens = selectedScreensLocal.filter(
                    (screen) => screen.id !== props.row.id
                );
            } else {
                newScreens = [props.row, ...selectedScreensLocal];
            }
            dispatch(screensActions.setSelectedScreensLocal(newScreens));
            dispatch(newCampaignActions.setSelectedScreens(newScreens));
        }
    };

    return (
        <div style={{ marginLeft: '-8px' }}>
            <input
                type="checkbox"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
                key={props?.row?.name}
                checked={isChecked}
                onChange={addRow}
            />
        </div>
    );
};
export default Checkbox;

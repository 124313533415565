import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import './styles.css';

const Hint = (props) => {
    const { title, text, type, className, dataFor = 'noValid', icon } = props;

    let types = 'text-gray-700 text-sm';
    switch (type) {
        case 'warning':
            types = 'text-yellow-500 text-sm';
            break;
        case 'error':
            types = 'text-red-500 text-sm';
            break;
        default:
            types = 'text-gray-700 text-sm';
    }

    return (
        <div className={className}>
            {text && text !== '' && (
                <span>
                    <ReactTooltip
                        effect="solid"
                        className="w-48 tooltip"
                        id={dataFor}
                        aria-haspopup="true">
                        <div className="text-white">
                            {title && (
                                <p className="text-lg">
                                    {title}
                                    <br />
                                </p>
                            )}
                            <p>{text}</p>
                        </div>
                    </ReactTooltip>
                    <label data-tip data-for={dataFor} role="alert">
                        <FontAwesomeIcon
                            icon={['fad', `${icon ? icon : 'info-circle'}`]}
                            className={`${types}`}
                        />
                    </label>
                </span>
            )}
        </div>
    );
};

Hint.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    dataFor: PropTypes.string,
    icon: PropTypes.string,
};

export default Hint;

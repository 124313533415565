import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { notificationsActions } from '../../store';

const DropCsv = ({ handleSubmit, setCsvFile, csvFile, dispatch }) => {
    const { t } = useTranslation();

    const handleInput = (e) => {
        if (e.target.files[0]?.name.endsWith('.csv')) {
            setCsvFile({
                file: e.target.files[0],
                name: e.target.files[0].name,
            });
        } else {
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.tooltips.screens.import-type-error'),
                    message: t('common.tooltips.screens.import-error-message'),
                })
            );
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (
            e.dataTransfer.files.length === 1 &&
            e.dataTransfer.files[0].type === 'text/csv'
        ) {
            setCsvFile({
                file: e.dataTransfer.files[0],
                name: e.dataTransfer.files[0].name,
            });
        } else {
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.tooltips.screens.import-type-error'),
                    message: t('common.tooltips.screens.import-error-message'),
                })
            );
        }
        console.log(e.dataTransfer.files);
    };

    return (
        <form
            className="block items-center justify-center w-full space-y-3"
            onSubmit={handleSubmit}>
            <span className="text-xs text-gray-800">
                <p>
                    {t(
                        'sections.campaigns.new-campaign.wizard-screens.import-screens.dropCsv.label-1'
                    )}
                </p>
                <p>
                    {t(
                        'sections.campaigns.new-campaign.wizard-screens.import-screens.dropCsv.label-2'
                    )}
                </p>
            </span>
            <label
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                onDrop={handleDrop}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <FontAwesomeIcon
                        className="w-7 h-7 mb-4"
                        icon={['fad', 'upload']}
                        size="3x"
                        fixedWidth
                    />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.import-screens.dropCsv.title'
                            )}
                        </span>{' '}
                        {t(
                            'sections.campaigns.new-campaign.wizard-screens.import-screens.dropCsv.subtitle'
                        )}
                    </p>
                    {csvFile?.name && (
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            {csvFile.name}
                        </p>
                    )}
                </div>
                <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    name="file"
                    accept="text/csv"
                    onChange={handleInput}
                />
            </label>
            <button
                type="submit"
                className="absolute -bottom-12 right-24 md:right-28 bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                {t('common.buttons.submit')}
            </button>
        </form>
    );
};

export default DropCsv;

import React, { useEffect, useState } from 'react';
import CommonButton from '../../../../../Buttons/CommonButton';
import Modal from '../../../../../Modal/Modal';
import ModalBody from '../../../../../Modal/ModalBody/ModalBody';
import ModalFooter from '../../../../../Modal/ModalFooter/ModalFooter';
import ModalHeader from '../../../../../Modal/ModalHeader/ModalHeader';
import { useTranslation } from 'react-i18next';
import Input from '../../../../Users/Submenu/UserList/UserModal/Input/Input';
import { useService } from '../../../../../../containers/UseService';
import { isValidInput } from '../../../../../../utils/functions';

const CommercialPersonModal = (props) => {
    const { isActive, toggle, commercialPerson, edit, onSubmit } = props;
    const { t } = useTranslation();
    const service = useService();
    const translations =
        'sections.management.section.company.companyCommercial.modal.';
    const userModalTranslations =
        'sections.management.section.users.users.createUserModal.';
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        if (commercialPerson) {
            setEmail(commercialPerson.email);
            setRole(commercialPerson.role);
            setPhoneNumber(commercialPerson.phoneNumber);
            setName(commercialPerson.name);
        } else {
            resetFields();
        }
    }, [commercialPerson]);

    const noValidation = () => true;

    const isAllFieldsCompleted =
        name !== '' &&
        isValidInput(email, 'email') === true &&
        role !== '' &&
        isValidInput(phoneNumber, 'phone') === true;

    const resetFields = () => {
        setName('');
        setEmail('');
        setRole('');
        setPhoneNumber('');
    };

    const handleOnChangePhoneNumber = (phoneNumber) => {
        // remove characters non-numeric
        setPhoneNumber(phoneNumber.replace(/\D/g, ''));
    };

    const handleOnSubmit = async () => {
        const props = {
            name,
            phoneNumber,
            role,
            email,
        };
        if (edit) {
            props.id = commercialPerson.id;
            service.editCommercialPerson(props);
        } else {
            service.addCommercialPersonToUser(props);
        }
        toggle();
        resetFields();
        onSubmit(props);
    };

    useEffect(() => {
        if (!isActive) {
            resetFields();
        }
    }, [isActive]);

    return (
        <Modal size="sm" active={isActive} toggler={toggle}>
            <ModalHeader toggler={toggle}>
                {t(translations + (edit ? 'edit-title' : 'add-title'))}
            </ModalHeader>
            <ModalBody>
                <div className="flex justify-between">
                    <Input
                        label={t(userModalTranslations + 'name')}
                        id="name"
                        value={name}
                        onChange={setName}
                        onValidate={noValidation}
                        placeholder={t(
                            userModalTranslations + 'namePlaceholder'
                        )}
                    />
                    <Input
                        label={t(userModalTranslations + 'email')}
                        id="email"
                        value={email}
                        onChange={setEmail}
                        onValidate={() => isValidInput(email, 'email')}
                        placeholder={t(
                            userModalTranslations + 'emailPlaceholder'
                        )}
                        errorMessage={t(userModalTranslations + 'invalidEmail')}
                    />
                </div>

                <div className="flex justify-between">
                    <Input
                        label={t(userModalTranslations + 'roleTxt')}
                        id="role"
                        value={role}
                        onChange={setRole}
                        onValidate={noValidation}
                        placeholder={t(userModalTranslations + 'roleTxt')}
                    />
                    <Input
                        label={t(userModalTranslations + 'phoneNumber')}
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={handleOnChangePhoneNumber}
                        onValidate={() => isValidInput(phoneNumber, 'phone')}
                        placeholder={t(
                            userModalTranslations + 'phoneNumberPlaceholder'
                        )}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <CommonButton
                    disabled={!isAllFieldsCompleted}
                    onClick={handleOnSubmit}
                    label={t(`common.buttons.${edit ? 'edit' : 'add'}`)}
                    styleType={'primary'}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CommercialPersonModal;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Funding from './Submenu/Funding/Funding';
import Budget from './Submenu/Budget/Budget';
import { useAuth } from '../../../containers/UseAuth';
import InvoicesTable from '../Invoices/Submenus/InvoicesTable';
import { useService } from '../../../containers/UseService';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../store';
import Movements from './Submenu/Movements/Movements';

const Billing = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const [isActiveBillingTab, setIsActiveBillingTab] = useState('budget');
    const handleBillingTab = (section) => setIsActiveBillingTab(section);
    const auth = useAuth();
    const translate = (key) => t(`sections.management.section.invoices.${key}`);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataSaved, setDataSaved] = useState([]);
    const [period, setPeriod] = useState([]);
    const [value, setValue] = useState('thisMonth');
    const [userId, setUserId] = useState(auth.session.user.id);
    const service = useService();
    const dispatch = useDispatch();

    useEffect(() => {
        if (period[0] == null && period[1] == null) return;
        setIsLoading(true);
        let from = period[0] ? period[0] : '';
        let to = period[1] ? period[1] : '';
        service
            .getInvoicesForUser(from, to)
            .then((res) => {
                setData(res);
                setDataSaved(res);
            })
            .catch((err) => {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: translate('tab'),
                        message: translate('invoices.invoice.error-load'),
                    })
                );
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [period]);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-20 md:w-44">
                    <nav className="flex flex-col">
                        <button
                            id="billingBudget"
                            onClick={() => handleBillingTab('budget')}
                            className={`${
                                isActiveBillingTab === 'budget'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right truncate`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.billing.budget.tab'
                                )}
                            </span>{' '}
                            <FontAwesomeIcon
                                icon={['fal', 'chart-pie']}
                                className="text-blue-500"
                                fixedWidth
                            />
                        </button>
                        {auth.isAdmin() && (
                            <button
                                id="billingFunds"
                                onClick={() => handleBillingTab('funds')}
                                className={`${
                                    isActiveBillingTab === 'funds'
                                        ? 'tabActiveV font-semibold truncate'
                                        : ''
                                } tabGeneral uppercase text-xs text-right truncate`}>
                                <span className="hidden md:inline-block">
                                    {t(
                                        'sections.management.section.billing.funding.tab'
                                    )}
                                </span>{' '}
                                <FontAwesomeIcon
                                    icon={['fal', 'coins']}
                                    className="text-blue-500"
                                    fixedWidth
                                />
                            </button>
                        )}
                        <button
                            id="invoicesTableFromBilling"
                            onClick={() => handleBillingTab('invoicesTable')}
                            className={`${
                                isActiveBillingTab === 'invoicesTable'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right truncate`}>
                            <span className="hidden md:inline-block">
                                {t('sections.management.section.invoices.tab')}
                            </span>{' '}
                            <FontAwesomeIcon
                                icon={['fal', 'file-pdf']}
                                className="text-blue-500"
                                fixedWidth
                            />
                        </button>
                        <button
                            id="movementsTableFromBilling"
                            onClick={() => handleBillingTab('movementsTable')}
                            className={`${
                                isActiveBillingTab === 'movementsTable'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right truncate`}>
                            <span className="hidden md:inline-block">
                                {t('sections.management.section.movements.tab')}
                            </span>{' '}
                            <FontAwesomeIcon
                                icon={faExchangeAlt}
                                className="text-blue-500"
                                fixedWidth
                            />
                        </button>
                    </nav>
                </div>
                {/* Funds */}
                {auth.isAdmin() && (
                    <Funding isActive={isActiveBillingTab === 'funds'} />
                )}
                <Budget isActive={isActiveBillingTab === 'budget'} />
                {isActiveBillingTab === 'invoicesTable' && (
                    <div className="w-full px-2">
                        <InvoicesTable
                            data={data}
                            setData={setData}
                            dataSaved={dataSaved}
                            isLoading={isLoading}
                            period={period}
                            setPeriod={setPeriod}
                            userId={userId}
                            setUserId={setUserId}
                            value={value}
                            setValue={setValue}
                        />
                    </div>
                )}
                {isActiveBillingTab === 'movementsTable' && (
                    <div className="w-full px-2">
                        <Movements />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Billing;

/* <div className="my-2 md:w-2/6">
<input type="number" value={transactionType} id="transaction-type" onChange={(e) => setTransactionType(e.target.value)} className="focus:ring-0 focus:outline-none block w-full sm:text-sm border-gray-300 rounded-md" placeholder={t("sections.management.section.billing.transactionTypePlaceholder")} />
</div>*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useClickOuside from '../../../hooks/utils/useClickOuside';
import useKeyEsc from '../../../hooks/utils/useKeyEsc';

const MultiSelect = ({
    label = '',
    options = [],
    handleClick,
    children,
    onClickClose,
    setSearch,
    left = 'left-0',
    isLoadingOptions,
}) => {
    const [isShowOptions, setIsShowOptions] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { t } = useTranslation();
    const [containerRef] = useClickOuside((e) => {
        setIsShowOptions(false);
        if (setSearch) setSearch('');
    });

    useKeyEsc(() => {
        setIsShowOptions(false);
    });

    const handleInputChange = (input, option) => {
        if (input.checked) {
            setSelectedOptions([...selectedOptions, option]);
            handleClick([...selectedOptions, option]);
        } else {
            let newSelectedOptions = selectedOptions.filter(
                (optionLop) => optionLop.id != option.id
            );
            if (newSelectedOptions) {
                setSelectedOptions(newSelectedOptions);
                handleClick(newSelectedOptions);
            }
        }
    };

    return (
        <div className="relative my-auto" ref={containerRef}>
            <div
                className="flex bg-blue-500  justify-between gap-1 focus:ring-0 focus:outline-none border-none bg-transparent text-gray-500 sm:text-sm border border-gray-200 bg-white shadow rounded-sm cursor-pointer"
                onClick={() => setIsShowOptions(!isShowOptions)}>
                <div className="ml-2 mr-1 flex items-center text-white gap-1">
                    <p className="text-s">{label}</p>
                    <div className="h-4 w-4 text-xs flex items-center justify-center bg-gray-300 rounded-full">
                        <span className="text-black flex items-center justify-center text-xs">
                            {selectedOptions.length}
                        </span>
                    </div>
                    <FontAwesomeIcon
                        onClick={onClickClose}
                        className="my-auto mr-1 h-3  hover:text-red-600"
                        icon={['far', 'times']}
                    />
                </div>
            </div>
            <div
                className={`z-20  absolute  transform transition-all ease-in-out duration-300 overflow-y-auto ${left}`}
                style={
                    isShowOptions
                        ? { height: '50vh ' }
                        : {
                              height: '0',
                          }
                }>
                <div
                    className={`bg-white shadow rounded-sm flex flex-col border border-gray-200 shadow rounded-sm  mt-2 py-2 `}>
                    {isShowOptions && children}
                    {isLoadingOptions ? (
                        <label
                            className={` text-gray-500 flex items-center text-center gap-2 min-w-max pl-2 pr-3 bg-white py-1 mx-auto `}>
                            {t('common.words.loading')}...
                        </label>
                    ) : (
                        options.map((option, index) => {
                            return (
                                <label
                                    className={`flex items-center gap-2 min-w-max pl-2 pr-3 bg-white py-1 border-b-2 border-gray-100 hover:bg-gray-200  cursor-pointer
                                ${
                                    index === options.length - 1
                                        ? 'border-none'
                                        : ''
                                }`}
                                    key={`${label}_filter_${option.name}`}>
                                    <input
                                        onChange={(e) =>
                                            handleInputChange(e.target, option)
                                        }
                                        name={'individual-creative-input'}
                                        id={option.id}
                                        type="checkbox"
                                        className="h-4 w-4 text-blue-600 focus:ring-0  rounded"
                                    />
                                    {option.name}
                                </label>
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default MultiSelect;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import './locale/i18n';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';

import store from './store';
import { BrowserRouter } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import ProvideAuth from './containers/UseAuth';
import ProvideService from './containers/UseService';
import { HelmetProvider } from 'react-helmet-async';
import { isInMaintenance } from './utils/functions';
import Maintenance from './containers/Maintenance';

library.add(fab, fal, fad, fas, far);

async function render() {
    ReactDOM.render(
        (await isInMaintenance()) ? (
            <Maintenance />
        ) : (
            <React.StrictMode>
                <HelmetProvider>
                    <Provider store={store}>
                        <ProvideAuth>
                            <BrowserRouter>
                                <ProvideService>
                                    <App />
                                </ProvideService>
                            </BrowserRouter>
                        </ProvideAuth>
                    </Provider>
                </HelmetProvider>
            </React.StrictMode>
        ),
        document.getElementById('root')
    );
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

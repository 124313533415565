import React from 'react';

const CopyText = ({ text }) => {
    const copyText = () => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="cursor-pointer">
            <button onClick={copyText}>{text}</button>
        </div>
    );
};

export default CopyText;

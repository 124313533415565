const ScreensArea = ({ setSelectedScreens, handleSubmit }) => {
    return (
        <form className="w-full" onSubmit={handleSubmit}>
            <textarea
                name="screens"
                className="focus:ring-0 max-h-32 focus:outline-none block w-full pr-12 h-10 sm:text-sm border-gray-300 rounded-sm"
                placeholder="tgx_1234, tgx_4567"></textarea>
            <button
                type="submit"
                className="absolute -bottom-12 right-24 md:right-28 bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Submit
            </button>
        </form>
    );
};

export default ScreensArea;

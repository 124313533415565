import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import ModalBody from '../Modal/ModalBody/ModalBody';
import ModalFooter from '../Modal/ModalFooter/ModalFooter';
import CommonButton from '../Buttons/CommonButton';
import DropCsv from '../DropCsv/DropCsv';
import ScreensArea from '../ScreensArea/ScreensArea';
import Papa from 'papaparse';
import { notificationsActions } from '../../store';

const ImportScreens = ({
    availableScreens,
    selectedScreensLocal,
    updateSelectedScreens,
    dispatch,
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState('uploadCsv');
    const [csvFile, setCsvFile] = useState(null);

    const handleClick = () => {
        setShowModal(!showModal);
        setCsvFile(null);
    };

    const handleCancel = () => {
        setShowModal(false);
        setCsvFile(null);
    };

    const getUniquesScreens = (screens) => {
        const filteredScreens = availableScreens.filter((screen) => {
            return screens.includes(screen.id);
        });

        const screensToUpdate = [...selectedScreensLocal, ...filteredScreens];

        const setObject = new Set();
        const uniquesScreens = screensToUpdate.reduce((accumulator, screen) => {
            if (!setObject.has(screen.id)) {
                setObject.add(screen.id);
                accumulator.push(screen);
            }
            return accumulator;
        }, []);

        return uniquesScreens;
    };

    const formatScreensCsv = async (screens) => {
        return new Promise((resolve, reject) => {
            if (activeTab === 'pasteFromList') {
                const parsedScreens = screens.screens
                    .replace(/\s/g, '')
                    .split(',')
                    .map((item) => {
                        return item.toLowerCase();
                    });

                const isValidFormat = parsedScreens.every((screen) =>
                    /^(tgx|bbt)_\d+$/.test(screen)
                );

                if (!isValidFormat) {
                    dispatch(
                        notificationsActions.setNotification({
                            type: 'warning',
                            title: t(
                                'common.tooltips.screens.import-field-error'
                            ),
                            message: t(
                                'common.tooltips.screens.wrong-list-format-message'
                            ),
                        })
                    );
                    return;
                }

                resolve(getUniquesScreens(parsedScreens));
            } else {
                const fileReader = new FileReader();
                fileReader.readAsText(csvFile.file);
                fileReader.onload = async (e) => {
                    const csvData = e.target.result;
                    const parsedData = Papa.parse(csvData, { header: true });

                    const isValidCsv = parsedData.data.some((row) => row.Id);
                    if (!isValidCsv) {
                        dispatch(
                            notificationsActions.setNotification({
                                type: 'warning',
                                title: t(
                                    'common.tooltips.screens.import-type-error'
                                ),
                                message: t(
                                    'common.tooltips.screens.wrong-csv-format-message'
                                ),
                            })
                        );
                        return;
                    }

                    const parsedScreens = parsedData.data.map((row) => {
                        return row.Id.toLowerCase();
                    });

                    const isValidFormat = parsedScreens.every((screen) =>
                        /^(tgx|bbt)_\d+$/.test(screen)
                    );
                    if (!isValidFormat) {
                        dispatch(
                            notificationsActions.setNotification({
                                type: 'warning',
                                title: t(
                                    'common.tooltips.screens.import-field-error'
                                ),
                                message: t(
                                    'common.tooltips.screens.wrong-list-format-message'
                                ),
                            })
                        );
                        return;
                    } else {
                        resolve(getUniquesScreens(parsedScreens));
                    }
                };
                fileReader.onerror = (error) => {
                    reject(error);
                };
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = Object.fromEntries(new FormData(e.target));
        if (values.file) {
            if (!values.file?.name.includes('.csv')) {
                return;
            }
        }
        const formated = await formatScreensCsv(values);
        formated && updateSelectedScreens(formated);
        setShowModal(false);
    };

    return (
        <div className="py-2 space-y-1 text-sm">
            <div
                className="flex items-center gap-1 cursor-pointer"
                onClick={handleClick}>
                <FontAwesomeIcon icon={['fad', 'file-import']} />
                <span className="title font-light text-gray-700">
                    {t(
                        'sections.campaigns.new-campaign.wizard-screens.import-screens.title'
                    )}
                </span>
            </div>
            <Modal size="sm" active={showModal} toggler={handleClick}>
                <ModalHeader toggler={handleClick}>
                    <div className="block space-y-2 px-10 pt-4">
                        <h1>
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.import-screens.modal-title'
                            )}
                        </h1>
                        <p className="text-sm font-normal">
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.import-screens.modal-description'
                            )}
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className=" flex flex-row  justify-start mb-4 ml-10 relative">
                        <button
                            className={`${
                                activeTab === 'uploadCsv'
                                    ? 'tabActive font-semibold'
                                    : ''
                            } tabGeneral uppercase text-xs text-gray-600 py-2 px-4 focus:shadow rounded-t-xl`}
                            onClick={() => setActiveTab('uploadCsv')}>
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.import-screens.modal-button'
                            )}
                        </button>
                        <button
                            className={`${
                                activeTab === 'pasteFromList'
                                    ? 'tabActive font-semibold'
                                    : ''
                            } tabGeneral uppercase text-xs text-gray-600 py-2 px-4 focus:shadow rounded-t-xl`}
                            onClick={() => setActiveTab('pasteFromList')}>
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.import-screens.modal-textarea'
                            )}
                        </button>
                    </div>
                    <div className="pl-10 pr-10 flex flex-col">
                        <div
                            className={`${
                                activeTab === 'uploadCsv' ? 'block' : 'hidden'
                            }  flex justify-center`}>
                            <DropCsv
                                handleSubmit={handleSubmit}
                                setCsvFile={setCsvFile}
                                csvFile={csvFile}
                                dispatch={dispatch}
                            />
                        </div>
                        <div
                            className={`${
                                activeTab === 'pasteFromList'
                                    ? 'block'
                                    : 'hidden'
                            } flex justify-start pl-6`}>
                            <ScreensArea handleSubmit={handleSubmit} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CommonButton
                        onClick={handleCancel}
                        label={t('common.buttons.cancel')}
                        styleType={'danger'}
                        className="mx-2 capitalize "
                    />
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ImportScreens;

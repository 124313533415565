import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody/ModalBody';
import ModalFooter from '../../components/Modal/ModalFooter/ModalFooter';
import ModalHeader from '../../components/Modal/ModalHeader/ModalHeader';
import CommonButton from '../Buttons/CommonButton';
import { useTranslation } from 'react-i18next';
import { useService } from '../../containers/UseService';

const ContinueCampaignModal = (props) => {
    const { isActive, toggler, goToNewCampaign } = props;
    const { t } = useTranslation();
    const service = useService();
    const [campaignInLocalStorage, setCampaignInLocalStorage] = useState(null);

    useEffect(() => {
        if (isActive) {
            const campaignInLocalStorage = service.getCampaignInLocalStorage();
            setCampaignInLocalStorage(campaignInLocalStorage);
        }
    }, [isActive]);

    const onSaveAndCreate = () => {
        service.clearNewCampaignData();
        goToNewCampaign();
        toggler();
    };

    const onDeleteAndCreate = () => {
        const campaignInLocalStorage = service.getCampaignInLocalStorage();

        if (campaignInLocalStorage) {
            try {
                service.deleteCampaign(campaignInLocalStorage);
            } catch (error) {
                console.error(error);
            }
        }

        service.clearNewCampaignData();
        goToNewCampaign();
        toggler();
    };

    const getDestinationStatus = () => {
        return campaignInLocalStorage?.status === 'DRAFT'
            ? t('common.campaign.statuses.DRAFT').toLowerCase()
            : t('common.campaign.statuses.INACTIVE').toLowerCase();
    };

    return (
        <Modal size="regular" active={isActive} toggler={toggler}>
            <ModalHeader toggler={toggler}>
                {t(
                    'common.menu.continue-campaign-modal.campaign-not-finished-title'
                )}
            </ModalHeader>
            <ModalBody>
                <span>
                    {t('common.menu.continue-campaign-modal.the-campaign')}:{' '}
                    <span className="font-bold">
                        {campaignInLocalStorage?.name !== ''
                            ? campaignInLocalStorage?.name
                            : t('sections.campaign-list.without-name')}
                    </span>{' '}
                </span>
                {t(
                    'common.menu.continue-campaign-modal.campaign-not-finished-message'
                )}
                .
            </ModalBody>
            <ModalFooter>
                <CommonButton
                    label={t(
                        'common.menu.continue-campaign-modal.save-as-status-button',
                        { status: getDestinationStatus() }
                    )}
                    styleType={'primary'}
                    onClick={onSaveAndCreate}
                />
                <CommonButton
                    className="ml-2"
                    label={t(
                        'common.menu.continue-campaign-modal.delete-and-create-button'
                    )}
                    styleType={'danger'}
                    onClick={onDeleteAndCreate}
                />
            </ModalFooter>
        </Modal>
    );
};

export default ContinueCampaignModal;

import React, { useEffect } from 'react';
import ScreenSizes from './ScreenSizes/ScreenSizes';
import Creatives from './Creatives/Creatives';
import Tracking from './Tracking/Tracking';
import UploadedCreatives from './UploadedCreatives/UploadedCreatives';
import CategorySelect from '../../Select/CategorySelect';
import { newCampaignActions } from '../../../store';
import PropTypes from 'prop-types';

function WizardCreatives({
    service,
    isActive,
    creatives,
    sizesNeeded,
    selectedScreens,
    campaignId,
    isResizeMapOn,
    baseName,
    selectedCreativeCategories,
    tracking,
    dispatch,
}) {
    useEffect(() => {
        const fetchCreatives = async () => {
            const creatives = await service.getCreatives(campaignId);
            dispatch(newCampaignActions.setCreatives(creatives));
        };
        if (isActive) {
            fetchCreatives();
        }
    }, [isActive]);

    return (
        <>
            {!isActive ? (
                ''
            ) : (
                <div
                    className={`creatives flex justify-between content-center mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-299 ${
                        isResizeMapOn
                            ? 'transform scale-75 -translate-y-20 -mr-44 -ml-16 flex-col'
                            : 'flex-row'
                    }`}>
                    <div className="w-4/6">
                        <Creatives
                            isResizeMapOn={isResizeMapOn}
                            selectedScreens={selectedScreens}
                            creatives={creatives}
                            baseName={baseName}
                            dispatch={dispatch}
                        />
                        <CategorySelect
                            selectedCreativeCategories={
                                selectedCreativeCategories
                            }
                            dispatch={dispatch}
                            service={service}
                        />
                        <Tracking
                            isResizeMapOn={isResizeMapOn}
                            tracking={tracking}
                            dispatch={dispatch}
                        />
                        <UploadedCreatives
                            creatives={creatives}
                            sizesNeeded={sizesNeeded}
                            service={service}
                            dispatch={dispatch}
                        />
                    </div>
                    <ScreenSizes
                        sizesNeeded={sizesNeeded}
                        creatives={creatives}
                        selectedScreens={selectedScreens}
                        dispatch={dispatch}
                    />
                </div>
            )}
        </>
    );
}

WizardCreatives.proptyTypes = {
    service: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    creatives: PropTypes.array,
    sizesNeeded: PropTypes.array,
    selectedScreens: PropTypes.array,
    campaignId: PropTypes.number,
    isResizeMapOn: PropTypes.bool,
    baseName: PropTypes.string,
    selectedCreativeCategories: PropTypes.string,
    tracking: PropTypes.object,
    dispatch: PropTypes.func,
};

export default WizardCreatives;

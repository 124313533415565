import React from 'react';

const ModalFooter = (props) => {
    const { children, className = '' } = props;
    return (
        <div
            className={`flex items-center justify-end p-3 rounded-b ${className}`}>
            {children}
        </div>
    );
};

export default ModalFooter;

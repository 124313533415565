import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { lastTwoDecimal, formatNumber } from '../../utils/functions';

const hardcodedData = [
    {
        id: 'elixir',
        label: 'women',
        value: 351,
        color: 'hsl(29, 70%, 50%)',
    },
    {
        id: 'go',
        label: 'man',
        value: 245,
        color: 'hsl(220, 70%, 50%)',
    },
    {
        id: 'ruby',
        label: 'ruby',
        value: 549,
        color: 'hsl(152, 70%, 50%)',
    },
    {
        id: 'python',
        label: 'python',
        value: 86,
        color: 'hsl(292, 70%, 50%)',
    },
    {
        id: 'lisp',
        label: 'lisp',
        value: 410,
        color: 'hsl(15, 70%, 50%)',
    },
];

const MyResponsivePie = ({
    symbol = '',
    data,
    enableArcLinkLabels = true,
    isByUserReports /* see data tab */,
    isInteractive = true,
}) => {
    return (
        <ResponsivePie
            valueFormat={(value) =>
                symbol + formatNumber(lastTwoDecimal(value), 'rounded')
            }
            data={data || hardcodedData}
            margin={
                isByUserReports
                    ? { top: 0, right: 0, bottom: 0, left: 0 }
                    : { top: 10, right: 10, bottom: 10, left: 10 }
            }
            sortByValue={true}
            innerRadius={0.5}
            padAngle={0.7}
            enableArcLabels={false}
            cornerRadius={0}
            colors={{ scheme: 'nivo' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableRadialLabels={false}
            radialLabelsSkipAngle={9}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            enableSlicesLabels={false}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            enableArcLinkLabels={enableArcLinkLabels}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={isInteractive}
        />
    );
};

export default MyResponsivePie;

import React from 'react';
import { useSelector } from 'react-redux';

const Bar = (props) => {
    const { children, isActive, title } = props;
    const newCampaign = useSelector((state) => state.newCampaign);
    const isResizeMapOn = newCampaign.isResizeMapOn;

    return (
        <>
            {!isActive ? (
                ''
            ) : (
                <div
                    className={`tabCampaignContainer backdrop bottom fixed ${
                        isResizeMapOn ? 'transform scale-75 translate-y-20' : ''
                    }`}>
                    <div className="absolute">
                        <div className="text-gray-700 text-xs antialiased uppercase font-semibold">
                            {title ? title + ':' : ''}
                        </div>
                        <div className="flex justify-start divide-x text-sm text-gray-500">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Bar;

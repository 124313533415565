import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';

const CustomSearch = ({
    options,
    handleSearch,
    handleSearchType,
    placeholder,
}) => {
    return (
        <div className="md:w-6/6 md:mr-4">
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                        <FontAwesomeIcon
                            icon={['far', 'search']}
                            fixedWidth
                            className="mr-1"
                        />
                    </span>
                </div>
                <input
                    onChange={(e) => handleSearch(e.target.value)}
                    autoComplete="off"
                    type="search"
                    name="budget"
                    id="search-table"
                    className={`focus:ring-0 focus:outline-none block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md ml-1`}
                    placeholder={placeholder}
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    <label htmlFor="column" className="sr-only">
                        {placeholder}
                    </label>
                    <select
                        id="column"
                        onChange={(e) => handleSearchType(e.target.value)}
                        name="column"
                        defaultValue="screen-name"
                        className="focus:ring-0 focus:outline-none h-full py-0 pl-2 pr-7 border-none bg-transparent text-gray-500 sm:text-sm rounded-md cursor-pointer">
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

CustomSearch.propType = {
    options: PropTypes.array,
    handleSearch: PropTypes.func,
    handleSearchType: PropTypes.func,
};

export default CustomSearch;

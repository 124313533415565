import React, { useEffect } from 'react';
import {
    setDefaultHourFormat,
    checkHourRange,
    isNowPM,
    isNowPMAbsolute,
} from '../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function HoursList({
    hoursFormatted,
    hoursEnabled,
    isCampaignCard,
    campaignDays = [],
    campaignStart,
    campaignEnd,
}) {
    useEffect(() => {
        setDefaultHourFormat();
    }, []);

    const { t } = useTranslation();

    const now = Date.now();
    const today = new Date();
    const currentDay = today.getDay();
    const currentHour = today.getHours();

    function compareHours(a, b) {
        if (a < b) {
            return -1;
        } else if (a === b) {
            return 0;
        } else {
            return 1;
        }
    }

    const isCurrentlyActive = () => {
        const isNowCampaignPeriod = campaignStart <= now && now <= campaignEnd;
        const campaignShouldPlayToday = campaignDays.includes(currentDay);
        return campaignShouldPlayToday && isNowCampaignPeriod;
    };

    const isPlayingTime = () => {
        const campaignIsPlaying = hoursEnabled.includes(currentHour);
        return campaignIsPlaying;
    };

    const buyingTime = isNowPMAbsolute() ? 'pm' : 'am';

    const isAMPMformat = localStorage.getItem('hourFormat') === 'ampm';

    const hoursKeys = Object.keys(hoursFormatted);

    const renderHours = (hourType, index) => {
        if (hoursFormatted[hourType].length === 0) return null;
        return (
            <div key={hourType} className={`block `}>
                <span
                    className={` ${
                        buyingTime === hourType &&
                        isCurrentlyActive() &&
                        'text-green-400'
                    } font-semibold`}>
                    {hourType.toUpperCase()}:
                </span>
                {hoursFormatted[hourType].map((label, index) => {
                    let hour =
                        hourType === 'am' ? currentHour : currentHour - 12;

                    const labelValue = label.includes('-')
                        ? label.split('-')
                        : [label];

                    const labelIsInRange =
                        labelValue[0] <= hour &&
                        hour <= labelValue[labelValue.length - 1];

                    return (
                        <span
                            key={label}
                            className={` ${
                                isCurrentlyActive() &&
                                isPlayingTime() &&
                                labelIsInRange &&
                                'font-semibold text-green-400'
                            }`}>
                            {index !== hoursFormatted[hourType].length - 1
                                ? ' ' + label + ','
                                : ' ' + label + ' '}
                        </span>
                    );
                })}
            </div>
        );
    };

    const render24hs = (hoursArray) => {
        let arr = [...hoursArray];

        if (arr.includes(0)) {
            arr.filter((item) => item !== 0);
        }

        arr.sort(compareHours);

        const formattedHours = [];
        let currentRangeStart = null;

        for (let i = 0; i < arr.length; i++) {
            const currentHour = arr[i];
            const nextHour = arr[i + 1];

            if (nextHour === currentHour + 1) {
                if (currentRangeStart === null) {
                    currentRangeStart = currentHour;
                }
            } else {
                if (currentRangeStart === null) {
                    formattedHours.push(currentHour.toString());
                } else {
                    formattedHours.push(
                        `${currentRangeStart} - ${currentHour}`
                    );
                    currentRangeStart = null;
                }
            }
        }

        return (
            <div className={`block `}>
                <span
                    className={`font-semibold ${
                        isCurrentlyActive() &&
                        isPlayingTime() &&
                        'text-green-400'
                    }`}>
                    24hs:
                </span>
                {formattedHours.map((label, index) => {
                    const labelValue = label.includes('-')
                        ? label.split('-')
                        : [label];

                    const labelIsInRange =
                        labelValue[0] <= currentHour &&
                        currentHour <= labelValue[labelValue.length - 1];

                    return (
                        <span
                            key={label}
                            className={` ${
                                isCurrentlyActive() &&
                                isPlayingTime() &&
                                labelIsInRange &&
                                'font-semibold text-green-400'
                            }`}>
                            {index !== formattedHours.length - 1
                                ? ' ' + label + ','
                                : ' ' + label + ' '}
                        </span>
                    );
                })}
            </div>
        );
    };

    return (
        <ul
            className={`w-1/4 md:w-3/6 lg:w-1/4 text-gray-400 text-xs font-light pl-2 ${
                isCampaignCard || 'space-y-1'
            }`}>
            {isCampaignCard ? (
                <li className="text-xs text-gray-400 font-semibold">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.timing.hours'
                    )}
                    :
                </li>
            ) : (
                <li className="mt-4 text-xs uppercase font-medium text-black">
                    <FontAwesomeIcon
                        icon={['fal', `clock`]}
                        fixedWidth
                        className="text-blue-300"
                    />{' '}
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.timing.hours'
                    )}
                </li>
            )}
            {isAMPMformat ? (
                <li>{hoursKeys.map(renderHours)}</li>
            ) : (
                <li>{render24hs(hoursEnabled)}</li>
            )}
        </ul>
    );
}

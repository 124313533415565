import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { newCampaignActions } from '../../../../store/newCampaign';
import TabUploadFiles from './TabUploadFiles/TabUploadFiles';
import TabUploadTag from './TabUploadTag/TabUploadTag';
import TabUploadVast from './TabUploadVast/TabUploadVast';
import { isEmpty } from '../../../../utils/functions';
import PropTypes from 'prop-types';

const Creatives = ({
    isResizeMapOn,
    selectedScreens,
    creatives,
    baseName,
    dispatch,
}) => {
    const { t } = useTranslation();
    const [isActiveAdType, setIsActiveAdType] = useState(1);

    function handleAdType(value) {
        setIsActiveAdType(value);
    }

    const handleChangeBaseName = (baseName) => {
        dispatch(newCampaignActions.setBaseName(baseName));
    };

    let vastCounter = 0;
    let scriptCounter = 0;
    let mediaCounter = 0;

    creatives.forEach((creative) => {
        let isMedia = creative.type === 1;
        let isVast = creative.type === 8;
        let isScript = creative.type === 3;
        if (isVast) {
            vastCounter++;
        }
        if (isScript) {
            scriptCounter++;
        }
        if (isMedia) {
            mediaCounter++;
        }
    });

    return (
        <div className="module rounded m-1">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.title'
                    )}
                </div>
                <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                    {t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.info'
                    )}
                </div>
            </div>
            <div className="body rounded-sm p-1">
                <div className={`${isResizeMapOn ? 'w-full' : 'md:w-3/6'}`}>
                    <span className="block text-sm font-medium text-gray-700 mb-1">
                        {t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.baseName-label'
                        )}
                    </span>
                    <input
                        type="text"
                        value={baseName}
                        onChange={(e) => handleChangeBaseName(e.target.value)}
                        name="campaign-brand"
                        id="campaign-brand"
                        className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.baseName-placeholder'
                        )}
                    />
                </div>
                <div
                    className={`w-full mt-4 ${
                        isEmpty(baseName) || selectedScreens.length === 0
                            ? 'hidden'
                            : ''
                    }`}>
                    <nav className="flex">
                        <button
                            id="adType-1"
                            onClick={() => handleAdType(1)}
                            className={`${
                                isActiveAdType === 1
                                    ? 'active text-blue-500 border-b-2 font-medium border-blue-500'
                                    : ''
                            } text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none`}>
                            <FontAwesomeIcon
                                icon={['fad', 'file']}
                                fixedWidth
                                className="mr-1 text-blue-300"
                            />{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.uploadFiles'
                            )}{' '}
                            <span className="bg-gray-100 rounded-full px-2 text-xs text-gray-500">
                                {mediaCounter}
                            </span>
                        </button>
                        <button
                            id="adType-2"
                            onClick={() => handleAdType(2)}
                            className={`${
                                isActiveAdType === 2
                                    ? 'active text-blue-500 border-b-2 font-medium border-blue-500'
                                    : ''
                            } text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none`}>
                            <FontAwesomeIcon
                                icon={['fad', 'code']}
                                fixedWidth
                                className="mr-1 text-blue-300"
                            />{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.uploadTags'
                            )}{' '}
                            <span className="bg-gray-100 rounded-full px-2 text-xs text-gray-500">
                                {scriptCounter}
                            </span>
                        </button>
                        <button
                            id="adType-3"
                            onClick={() => handleAdType(3)}
                            className={`${
                                isActiveAdType === 3
                                    ? 'active text-blue-500 border-b-2 font-medium border-blue-500'
                                    : ''
                            } text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none`}>
                            <FontAwesomeIcon
                                icon={['fad', 'video']}
                                fixedWidth
                                className="mr-1 text-blue-300"
                            />{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.uploadVast'
                            )}{' '}
                            <span className="bg-gray-100 rounded-full px-2 text-xs text-gray-500">
                                {vastCounter}
                            </span>
                        </button>
                    </nav>

                    <div
                        id="addFiles"
                        className={`${
                            isActiveAdType === 1 ? 'block' : 'hidden'
                        }`}>
                        <TabUploadFiles />
                    </div>
                    <div
                        id="addTags"
                        className={`${
                            isActiveAdType === 2 ? 'block' : 'hidden'
                        } mt-4 bg-gray-50 rounded-md`}>
                        <TabUploadTag />
                    </div>
                    <div
                        id="addTags"
                        className={`${
                            isActiveAdType === 3 ? 'block' : 'hidden'
                        } mt-4 bg-gray-50 rounded-md`}>
                        <TabUploadVast />
                    </div>
                    <div className="block mt-4">
                        <ul className="list-none font-light text-gray-600 text-xs lg:text-sm leading-relaxed">
                            <li className="my-1">
                                <FontAwesomeIcon
                                    icon={['fad', 'info-circle']}
                                    fixedWidth
                                    className="mr-2 text-blue-300"
                                />
                                <span className="font-medium text-black">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.supportedFiles-helper-title'
                                    )}{' '}
                                </span>{' '}
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.supportedFiles-helper-text'
                                )}
                            </li>
                            <li className="my-1">
                                <FontAwesomeIcon
                                    icon={['fad', 'balance-scale']}
                                    fixedWidth
                                    className="mr-2 text-blue-300"
                                />
                                <span className="font-medium text-black">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.recommendedWeight-helper-title'
                                    )}{' '}
                                </span>{' '}
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.recommendedWeight-helper-text'
                                )}
                            </li>
                            <li className="my-1">
                                <FontAwesomeIcon
                                    icon={['fad', 'lightbulb']}
                                    fixedWidth
                                    className="mr-2 text-blue-300"
                                />
                                <span className="font-medium text-black">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.considerations-helper-title'
                                    )}{' '}
                                </span>{' '}
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.considerations-helper-text'
                                )}
                            </li>
                            <li className="my-1">
                                <FontAwesomeIcon
                                    icon={['fad', 'info-circle']}
                                    fixedWidth
                                    className="mr-2 text-blue-300"
                                />
                                <span className="font-medium text-black">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.supportedFiles-zip-title'
                                    )}{' '}
                                </span>{' '}
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.supportedFiles-zip-helper'
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

Creatives.proptyTypes = {
    isResizeMapOn: PropTypes.bool,
    selectedScreens: PropTypes.array,
    creatives: PropTypes.array,
    baseName: PropTypes.string,
    dispatch: PropTypes.func,
};

export default Creatives;

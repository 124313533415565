import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Settings from './Submenus/Settings';

export default function Preferences({ isActive }) {
    const { t } = useTranslation();
    const [submenu, setSubmenu] = useState('settings');
    const handleSubMenu = (section) => setSubmenu(section);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-50">
                    <nav className="flex flex-col">
                        <button
                            id="settings"
                            onClick={() => handleSubMenu('settings')}
                            className={`${
                                submenu === 'settings'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.preferences.settings.button'
                                )}
                            </span>
                        </button>
                    </nav>
                </div>
                <div className={`px-2 w-full`}>
                    {submenu === 'settings' && <Settings />}
                </div>
            </div>
        </div>
    );
}

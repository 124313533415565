import React from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../../../containers/UseService';
import Modal from '../../../../Modal/Modal';
import ModalBody from '../../../../Modal/ModalBody/ModalBody';
import ModalHeader from '../../../../Modal/ModalHeader/ModalHeader';

const ModalViewCreative = (props) => {
    const { isActive, toggler, creative, size, isHandle = true } = props;
    let adTag = creative?.thumbnail_url;
    const isVast = creative?.extension === 'vast';
    const isScript = creative?.script !== null && creative?.script !== '';
    const isHtml = creative?.url?.endsWith('.html');
    const service = useService();
    const { t } = useTranslation();
    const extension =
        creative !== null && 'original_filename' in creative
            ? creative.original_filename.split('.').pop()
            : '';
    const { isVideo, isImage } = service.getCreativeType(extension);
    const stylesCreative = `max-h-40 max-w-screen rounded-md ${size}`;
    return (
        creative !== null && (
            <Modal active={isActive} toggler={toggler}>
                <ModalHeader
                    className="font-semibold"
                    toggler={toggler}
                    children={creative.name}
                    isHandle={isHandle}
                />
                <ModalBody>
                    <div className="flex flex-col align-middle items-center">
                        {isImage && (
                            <img
                                className={stylesCreative}
                                src={creative.url}
                                alt="preview"
                            />
                        )}
                        {isVideo && (
                            <video className={stylesCreative} controls>
                                <source src={creative.url} type="video/mp4" />
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.modal-view-creative.incompatible-browser'
                                )}
                            </video>
                        )}
                        {isScript && <code>{creative.script}</code>}
                        {isHtml && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '300px',
                                    display: 'block',
                                }}>
                                <iframe
                                    src={creative.url}
                                    title={creative.name}
                                    style={{
                                        aspectRatio:
                                            creative.width / creative.height,
                                        width: '100%',
                                        height: '100%',
                                        display: 'block',
                                    }}
                                />
                            </div>
                        )}
                        {isVast && (
                            <iframe
                                src={`/vast/vastdisplay.html?target=${adTag}`}
                                width={600}
                                height={500}
                            />
                        )}
                    </div>
                    <div className="mt-2 self-start">
                        <span className="mt-4 text-xs">
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.resolution'
                            )}
                        </span>
                        <span className="text-xs font-semibold">
                            {creative.width && creative.height
                                ? `${creative.width}x${creative.height}`
                                : t(
                                      'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.modal-view-creative.no-resolution'
                                  )}
                        </span>
                    </div>
                    {!isScript && (
                        <div className="mt-2 self-start">
                            <p className="text-gray-700 text-xs w-full text-left">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.view-new-tab'
                                )}
                                <br />
                                <a
                                    href={creative.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline text-gray-500 break-words overflow-clip">
                                    {creative.url}
                                </a>
                            </p>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        )
    );
};

export default ModalViewCreative;

/**
 *
 * Timeline dashboard general.
 *
 */

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    formatNumber,
    isByHourCurrentPeriod,
    numberWithoutCommas,
} from '../../../utils/functions';
import { useAuth } from '../../../containers/UseAuth';

const TimeLine = ({ currentPeriod }) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const dashboard = useSelector((state) => state.dashboard);
    const reportsTableGeneralData =
        dashboard.reportsTableGeneralData.reportsForTable;
    const [dataTimeLine, setDataTimeLine] = useState({
        labels: [],
        datasets: [],
    });
    const [hasImpacts, setHasImpacts] = useState(false);

    useEffect(() => {
        if (reportsTableGeneralData.length > 0) {
            const byHours = isByHourCurrentPeriod(currentPeriod);
            const getDataBy = (key) =>
                reportsTableGeneralData.find(
                    (tab) =>
                        tab.tabName.toLowerCase() ===
                        t('common.words.' + key).toLowerCase()
                ).data;
            let data = [];
            let subfijo = 'h';

            if (byHours) {
                data = getDataBy('hours');
            } else {
                data = getDataBy('days');
            }
            const timelineLabels = [];
            const timelineImpressions = [];
            const timelineImpacts = [];
            const timelineSpents = [];
            data.forEach((stat) => {
                timelineImpacts.push(
                    parseInt(numberWithoutCommas(stat.impacts))
                );
                timelineImpressions.push(
                    parseInt(numberWithoutCommas(stat.impressions))
                );
                timelineSpents.push(parseInt(numberWithoutCommas(stat.spent)));
                if (byHours) {
                    timelineLabels.push(`${stat.name}${subfijo}`);
                } else {
                    timelineLabels.push(`${stat.dateWithMonth}`);
                }
            });

            setData(
                timelineLabels,
                timelineImpressions,
                timelineImpacts,
                timelineSpents
            );
        }
    }, [reportsTableGeneralData]);

    const setData = (labels, impressions, impacts, spents) => {
        const data = {
            labels: labels,
            datasets:
                auth.isAdmin() || auth.isSupervising()
                    ? [
                          {
                              label: t('common.words.impressions'),
                              data: impressions,
                              fill: false,
                              backgroundColor: 'rgb(255, 99, 132)',
                              borderColor: 'rgba(255, 99, 132, 0.7)',
                              yAxisID: 'y-axis-1',
                              ticks: {
                                  beginAtZero: true,
                              },
                          },
                          {
                              label: t('sections.dashboard.impacts'),
                              data: impacts,
                              fill: true,
                              backgroundColor: 'rgba(54, 162, 235, 0.3)',
                              borderColor: 'rgba(54, 162, 235, 0.2)',
                              yAxisID: 'y-axis-2',
                              ticks: {
                                  beginAtZero: true,
                              },
                          },
                      ]
                    : [
                          {
                              label: t('common.words.spent').toLowerCase(),
                              data: spents,
                              fill: false,
                              backgroundColor: 'rgb(255, 99, 132)',
                              borderColor: 'rgba(255, 99, 132, 0.7)',
                              yAxisID: 'y-axis-1',
                              ticks: {
                                  beginAtZero: true,
                              },
                          },
                          {
                              label: t('sections.dashboard.impacts'),
                              data: impacts,
                              fill: true,
                              backgroundColor: 'rgba(54, 162, 235, 0.3)',
                              borderColor: 'rgba(54, 162, 235, 0.2)',
                              yAxisID: 'y-axis-2',
                              ticks: {
                                  beginAtZero: true,
                              },
                          },
                      ],
        };
        setDataTimeLine(data);
    };

    const timelineOptions = {
        tooltips: {
            // add format to labels timeline
            callbacks: {
                label: function (tooltipItems, data) {
                    const index = tooltipItems.datasetIndex;
                    const label = data.datasets[index].label;
                    let value = data.datasets[index].data[tooltipItems.index];
                    value = formatNumber(value, 'rounded');
                    return `${label}: ${value}`;
                },
            },
        },
        maintainAspectRatio: true,
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: true,
                    },
                    ticks:
                        auth.isAdmin() || auth.isSupervising()
                            ? {
                                  beginAtZero: true,
                              }
                            : {
                                  beginAtZero: true,
                                  callback: function (value) {
                                      if (
                                          dashboard?.selectedCurrency
                                              ?.short_name
                                      ) {
                                          value = formatNumber(
                                              value,
                                              'properCurrency',
                                              null,
                                              {
                                                  currency:
                                                      dashboard.selectedCurrency
                                                          ?.short_name,
                                              }
                                          );
                                          value = value.split(',')[0];
                                      }
                                      return value;
                                  },
                              },
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (
        <div className="module w-full">
            <div className="font-light text-xs uppercase">
                {t('sections.dashboard.timeline')}
            </div>
            <Line
                width={100}
                height={20}
                fill={true}
                data={dataTimeLine}
                options={timelineOptions}
            />
        </div>
    );
};

export default TimeLine;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumber } from '../../utils/functions';
import { walletActions } from '../../store';

function TopWallet() {
    const currencies = useSelector((state) => state.wallet.currencies);
    const dispatch = useDispatch();
    const budgetsInLocalStorage = JSON.parse(localStorage.getItem('budgets'));
    const selector = useSelector((state) => state.wallet.budgets);
    const budgets = budgetsInLocalStorage ? budgetsInLocalStorage : selector;
    const amountBudgets = budgets ? Object.keys(budgets).length : 0;
    const isInLocalStorage = JSON.parse(
        localStorage.getItem('currencyDefault')
    );
    const [currency, setCurrency] = useState(isInLocalStorage);

    const handleChangeCurrency = (currency) => {
        let budgetsSorted = {};
        currencies.forEach((element) => {
            if (currency.includes(element.short_name)) {
                setCurrency(element);
                localStorage.setItem(
                    'currencyDefault',
                    JSON.stringify(element)
                );
                budgetsSorted[element.short_name] = budgets[element.short_name];
            }
        });
        budgetsSorted = { ...budgetsSorted, ...budgets };
        localStorage.setItem('budgets', JSON.stringify(budgetsSorted));
        dispatch(walletActions.setBudgets(budgetsSorted));
    };

    const formatBudget = (budgetShortName) => {
        const currency = currencies.find(
            (currency) => currency.short_name === budgetShortName
        );
        if (currency) {
            return `${currency.short_name} ${formatNumber(
                budgets[budgetShortName],
                'properCurrency',
                null,
                { currency: currency.short_name }
            )}`;
        } else {
            return '';
        }
    };

    if (currencies.length > 0 && !currency && budgets) {
        const strBudget = formatBudget(Object.keys(budgets)[0]);
        currencies.forEach((element) => {
            if (strBudget.includes(element.short_name)) {
                setCurrency(element);
                localStorage.setItem(
                    'currencyDefault',
                    JSON.stringify(element)
                );
            }
        });
        setCurrency(currencies[0]);
    }

    return (
        <div className="mx-1 md:mx-4">
            <FontAwesomeIcon
                icon={['fal', 'wallet']}
                className="hover:text-blue-700"
            />
            <span className="ml-2 hidden md:inline-block">
                {amountBudgets !== 0 && currencies.length > 0 ? (
                    amountBudgets === 1 ? (
                        formatBudget(Object.keys(budgets)[0])
                    ) : (
                        <select
                            onChange={(e) =>
                                handleChangeCurrency(e.target.value)
                            }
                            className="focus:ring-0 focus:outline-none h-full py-0 pl-2 pr-7 border-none bg-transparent text-gray-500 sm:text-sm rounded-md">
                            {Object.keys(budgets).map((key) => (
                                <option key={key}>{formatBudget(key)}</option>
                            ))}
                        </select>
                    )
                ) : (
                    '0.0'
                )}
            </span>
        </div>
    );
}

export default TopWallet;

import React from 'react';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../store';

const useDispatchNotification = () => {
    const dispatch = useDispatch();
    const dispatchNotification = (title, message, type = 'warning') => {
        dispatch(
            notificationsActions.setNotification({
                title: title,
                type: type,
                message: message,
            })
        );
    };

    /**
     * A function that takes in a title and message and then calls the dispatchNotification function with
     * the title, message and the type of notification.
     * @param title - The title of the notification
     * @param message - The message you want to display
     */
    const successNotification = (title, message) => {
        dispatchNotification(title, message, 'success');
    };

    /**
     * Dispatch a notification with the title and message provided, and a warning type.
     * @param title - The title of the notification
     * @param message - The message you want to display
     */
    const warningNotification = (title, message) => {
        dispatchNotification(title, message, 'warning');
    };

    /**
     * It takes a title and a message and dispatches a notification with the given title and message and
     * the type "error"
     * @param title - The title of the notification
     * @param message - The message you want to display
     */
    const errorNotification = (title, message) => {
        dispatchNotification(title, message, 'error');
    };
    return {
        successNotification,
        warningNotification,
        errorNotification,
    };
};

export default useDispatchNotification;

import axios from 'axios';
import {
    createTierPrice,
    updateTierPrice,
    deleteTierPrice,
    getCurrenciesPrices,
    getTierTypes,
    getTierList,
    getAvgCpms,
} from '../constants/endpoints';

/**
 * Get a list of tier types.
 * @async
 * @function
 * @returns {Promise<any>} A promise that resolves with the list of tier types or an error.
 */
export const getTierTypesList = async () => {
    const response = await axios.get(getTierTypes);
    return response.data;
};

/**
 * Get a list of tiers.
 * @async
 * @function
 * @returns {Promise<any>} A promise that resolves with the list of tiers or an error.
 */
export const getTierLists = async () => {
    const response = await axios.get(getTierList);
    return response.data;
};

/**
 * Get a list of currency prices.
 * @async
 * @function
 * @returns {Promise<any>} A promise that resolves with the list of currency prices or an error.
 */
export const getCurrenciesList = async () => {
    const response = await axios.get(getCurrenciesPrices);
    return response.data;
};

/**
 * Creates a tier pricing.
 *
 * @param {Object} data - The data to be sent in the request.
 * @return {Object} An object containing the message and status code of the response.
 */
export const createTierPricing = async (data) => {
    try {
        const response = await axios.post(createTierPrice, data);
        return {
            message: response.data.message,
            statusCode: response.status,
        };
    } catch (error) {
        return {
            message: error.response.data.message,
            statusCode: error.response.status,
        };
    }
};

/**
 * Updates the pricing for a specific item.
 *
 * @param {string} id - The ID of the item to update.
 * @param {object} data - The pricing data to update.
 * @return {object} An object containing the response message and status code.
 */
export const updatePricing = async (id, data) => {
    const response = await axios.patch(`${updateTierPrice}/${id}`, data);
    return {
        message: response.data.message,
        statusCode: response.status,
    };
};

/**
 * Deletes a tier pricing by its ID.
 *
 * @param {number} id - The ID of the tier pricing to delete.
 * @return {Object} An object containing the response message and status code.
 */
export const deleteTierPricing = async (id) => {
    const response = await axios.delete(`${deleteTierPrice}/${id}`);
    return {
        message: response.data.message,
        statusCode: response.status,
    };
};

/**
 * Retrieves the median CPM (Cost Per Mille) by tiers for a given user and selected currency.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} country - The country code.
 * @param {string} provider - The audience provider.
 * @return {Promise<any>} A promise that resolves to the median CPM data.
 */
export const getAvgCpm = async (userId, country, provider, currency) => {
    const response = await axios.get(
        getAvgCpms +
            `?userId=${userId}&country=${country}&provider=${provider}&currency=${currency}`
    );
    return response.data;
};

import React, { useRef, useState } from 'react';
import Dropzone from '../../../../Input/Dropzone/Dropzone';
import { useService } from '../../../../../containers/UseService';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../../../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../../../components/Input/Dropzone/Dropzone.module.css';

const TabUploadFiles = () => {
    const { t } = useTranslation();
    const service = useService();
    const dispatch = useDispatch();
    const newCampaign = useSelector((state) => state.newCampaign);
    const [isLoading, setIsLoading] = useState(false);
    const [invalidFiles, setInvalidFiles] = useState([]);
    const [inputName, setInputName] = useState('');
    const inputFileRef = useRef();

    const fileListToArray = (list) => {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    };

    const uploadFile = async (evt) => {
        const isEventDrop = evt.dataTransfer?.files;
        const files = isEventDrop ? evt.dataTransfer.files : evt.target.files;
        const arrayFiles = fileListToArray(files);
        setInputName(Math.random().toString(36));
        if (isEmpty(newCampaign.baseName)) {
            basenameAlert();
        } else {
            setInvalidFiles([]);
            setIsLoading(true);
            const invalidFiles = await service.uploadFiles(arrayFiles);
            setInvalidFiles(invalidFiles);
            setIsLoading(false);
        }
    };

    const basenameAlert = () => {
        dispatch(
            notificationsActions.setNotification({
                type: 'error',
                title: t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.baseName-label'
                ),
                message: t(
                    'sections.campaigns.new-campaign.wizard-review.validation-issues.errors.base-name'
                ),
            })
        );
    };

    return (
        <>
            <Dropzone
                inputFileRef={inputFileRef}
                onDrop={uploadFile}
                isLoading={isLoading}
                disabled={isLoading}>
                <input
                    ref={inputFileRef}
                    key={inputName}
                    className={`${styles.fileInput}`}
                    type="file"
                    accept=".gif, .jpg, .png, .jpeg, .mp4, .webm, .zip"
                    multiple
                    disabled={isLoading}
                    onChange={uploadFile}
                />
            </Dropzone>
            <div
                className={`${
                    invalidFiles.length === 0 ? 'hidden' : ''
                } flex flex-col`}>
                {invalidFiles.map((file, index) => (
                    <span key={index} className="text-red-600">
                        <FontAwesomeIcon
                            icon={['fas', 'exclamation-triangle']}
                            fixedWidth
                            className="mr-2 text-red-300"
                        />
                        {file.file.name}:{' '}
                        <span className="text-red-500">{file.error}</span>
                    </span>
                ))}
            </div>
        </>
    );
};

export default TabUploadFiles;

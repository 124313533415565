import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    commercialsPersons: [],
};

const { reducer, actions } = createSlice({
    name: 'commercialsPersons',
    initialState: initialState,
    reducers: {
        setCommercialsPersons(state, action) {
            state.commercialsPersons = action.payload;
        },
        addCommercialPerson(state, action) {
            const copyCommercialPersons = JSON.parse(
                JSON.stringify(state.commercialsPersons)
            );
            state.commercialsPersons = [
                ...copyCommercialPersons,
                action.payload,
            ];
        },
        removeCommercialPerson(state, action) {
            const copyCommercialPersons = JSON.parse(
                JSON.stringify(state.commercialsPersons)
            );
            const commercialPersonId = action.payload;
            state.commercialsPersons = copyCommercialPersons.filter((cp) => {
                return cp.id !== commercialPersonId;
            });
        },
        editCommercialPerson(state, action) {
            const copyCommercialPersons = JSON.parse(
                JSON.stringify(state.commercialsPersons)
            );
            const commercialPersonEdited = action.payload;
            state.commercialsPersons = copyCommercialPersons.map((cp) => {
                if (cp.id === commercialPersonEdited.id) {
                    return commercialPersonEdited;
                } else {
                    return cp;
                }
            });
        },
    },
});

export { actions as commercialsPersonsActions };
export { reducer as commercialsPersonsReducer };

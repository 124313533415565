import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions } from '../../../../../store';

const AccuracySegment = ({ calculatingForecast }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const segments = useSelector((state) => state.newCampaign.segments);
    const accuracy = segments.accuracy;
    const [accuracyLayerHeight, setAccuracyLayerHeight] = useState('h-24');
    const [accuracyLabel, setAccuracyLabel] = useState(
        t(
            'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.accuracy-low-title'
        )
    );
    const [accuracyDescription, setAccuracyDescription] = useState(
        t(
            'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.accuracy-low-description'
        )
    );

    function handleAccuracySlider(value) {
        if (calculatingForecast === false) {
            const accuracyValues = new Array(3);
            accuracyValues[0] = 'low';
            accuracyValues[1] = 'mid';
            accuracyValues[2] = 'high';
            const newSegments = Object.assign({}, segments);
            newSegments.accuracy = {
                value: accuracyValues[value],
                sliderValue: value,
            };
            dispatch(newCampaignActions.setSegments(newSegments));
        }
    }

    useEffect(() => {
        if (calculatingForecast === false) {
            switch (accuracy.value) {
                default:
                case 'low':
                    setAccuracyLayerHeight('h-24');
                    break;
                case 'mid':
                    setAccuracyLayerHeight('h-16');
                    break;
                case 'high':
                    setAccuracyLayerHeight('h-8');
                    break;
            }
            setAccuracyLabel(
                t(
                    `sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.accuracy-${accuracy.value}-title`
                )
            );
            setAccuracyDescription(
                t(
                    `sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.accuracy-${accuracy.value}-description`
                )
            );
        }
    }, [accuracy]);

    return (
        <>
            {t(
                'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.accuracy-title'
            )}
            <div className="flex justify-start">
                <div className="my-2 mt-3 h-20 flex justify-center mr-10">
                    <Slider
                        vertical={true}
                        min={0}
                        max={2}
                        step={1}
                        value={accuracy.sliderValue}
                        dots={true}
                        marks={{
                            0: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.low'
                            ),
                            1: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.mid'
                            ),
                            2: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.high'
                            ),
                        }}
                        onChange={handleAccuracySlider}
                    />
                </div>
                <div className="w-full relative flex content-end justify-evenly mt-2 group">
                    <div
                        className={`${accuracyLayerHeight} absolute top-0 -mt-1 left-0 z-30 w-full transform transition-all duration-300 bg-opacity-40 bg-gradient-to-b from-purple-300 via-purple-300 to-transparent rounded-t border-b-2 border-purple-300 border-opacity-10`}></div>
                    <div className="absolute z-40 w-5/6 h-full hidden group-hover:flex flex-col items-center text-center bottom-0 mt-2 rounded bg-white bg-opacity-70 p-2 text-xs text-gray-500">
                        <span className="font-semibold text-right pr-2 text-gray-700">
                            {accuracyLabel}
                        </span>{' '}
                        {accuracyDescription}
                    </div>
                    <div className="rounded bg-gray-200 w-5 h-12 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-6 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-12 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-20 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-20 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-12 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-12 mx-1 self-end"></div>
                    <div className="rounded bg-gray-200 w-5 h-6 mx-1 self-end"></div>
                </div>
            </div>
        </>
    );
};

export default AccuracySegment;

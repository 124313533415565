import { createSlice } from '@reduxjs/toolkit';
import { numberWithCommasInArrayObjectsRecursive } from '../utils/functions';

const campaignStatsInitialState = {
    spent: '-',
    impressions: '-',
    impacts: '-',
    uniqueImpacts: '-',
    ecpm: '-',
    ecpmAverage: '-',
    spot: '-',
    campaigns: [],
    days: [],
    hours: [],
    screen: [],
    stats: null,
    currenciesOfStats: [],
};

const initialState = {
    campaign: {},
    isLoading: false,
    isSilentLoading: false,
    campaignStats: campaignStatsInitialState,
    individualCampaignStats: campaignStatsInitialState,
    dashboardGeneralReports: [],
    campaignPrediction: {},
    currencies: [],
    selectedCurrency: null,
    reportsTableGeneralData: {
        currentCurrency: {},
        reportsForTable: [],
    },
    reportsForActiveCampaigns: [],
};

const setStats = (state, stats, targetState) => {
    // FIXME: This is a temporary fix for the issue of the stats not being
    // showed in the dashboard.
    const formatNumber = (number) => {
        return number !== 0 ? number : 0;
    };
    Object.keys(stats).forEach((stat) => {
        if (
            !isNaN(stats[stat]) &&
            stats[stat] !== '' &&
            !Array.isArray(stats[stat])
        ) {
            if (stat === 'impacts') {
                stats[stat] = Math.round(stats[stat]);
            }
            stats[stat] = stats[stat];
        } else if (Array.isArray(stats[stat])) {
            // FIXME: Change this to use the new format function
            stats[stat] = numberWithCommasInArrayObjectsRecursive(
                stats[stat],
                [
                    'spot',
                    'ecpm',
                    'impacts',
                    'impressions',
                    'spent',
                    'uniqueImpacts',
                ],
                formatNumber
            );
        }
    });
    state[targetState] = stats;
};

const { reducer, actions } = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setCampaign(state, action) {
            state.campaign = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setIsSilentLoading(state, action) {
            state.isSilentLoading = action.payload;
        },
        removeCampaignStats(state, action) {
            state.campaignStats = campaignStatsInitialState;
        },
        removeIndividualCampaignStats(state, action) {
            state.individualCampaignStats = campaignStatsInitialState;
        },
        setCampaignStats(state, action) {
            state.campaignStats = action.payload;
        },
        setIndividualCampaignStats(state, action) {
            const stats = JSON.parse(JSON.stringify(action.payload));
            setStats(state, stats, 'individualCampaignStats');
        },
        setDashboardGeneralReports(state, action) {
            state.dashboardGeneralReports = action.payload;
        },
        setCampaignPrediction(state, action) {
            state.campaignPrediction = action.payload;
        },
        setCurrencies(state, action) {
            state.currencies = action.payload;
        },
        setSelectedCurrency(state, action) {
            state.selectedCurrency = action.payload;
        },
        setReportsTableGeneralData(state, action) {
            state.reportsTableGeneralData.reportsForTable =
                action.payload.reportsForTable;
            state.reportsTableGeneralData.currentCurrency =
                action.payload.currentCurrency;
        },
        setReportsForActiveCampaigns(state, action) {
            state.reportsForActiveCampaigns = action.payload;
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as dashboardActions };
export { reducer as dashboardReducer };

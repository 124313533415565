import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalPdf = ({
    pdfUrl,
    titleIframe,
    isShowPdf,
    setIsShowPdf,
    classNameStyles,
}) => {
    if (!isShowPdf) {
        return null;
    }
    return (
        { isShowPdf } && (
            <>
                <div
                    className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${classNameStyles}`}
                    onClick={() => setIsShowPdf(false)}>
                    <div
                        className={`w-4/6 h-2/3 flex flex-col items-end justify-center overflow-y-auto ${classNameStyles}`}>
                        <div className="">
                            <FontAwesomeIcon
                                icon={['fal', 'times']}
                                fixedWidth
                                className="text-3xl text-gray-500 hover:text-gray-700 cursor-pointer"
                                onClick={() => setIsShowPdf(false)}
                            />
                        </div>
                        <iframe
                            src={pdfUrl}
                            title={titleIframe}
                            className=" w-full h-full"
                            frameBorder="0"
                        />
                    </div>
                </div>
                <div className="opacity-70 fixed inset-0 z-40 bg-gray-200 " />
            </>
        )
    );
};

export default ModalPdf;

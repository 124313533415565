import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Item = (props) => {
    const { children, title, isLoading = false } = props;
    return (
        <div className="p-2 font-light text-gray-400">
            {title}
            <br />
            {isLoading ? (
                <FontAwesomeIcon
                    icon={['fal', 'circle-notch']}
                    size="2x"
                    className="absolute-right-0 bottom-2 h-4 mx-1 animate-spin text-blue-400"
                />
            ) : (
                <span className="font-normal text-gray-700 text-lg">
                    {children}
                </span>
            )}
        </div>
    );
};

export default Item;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../store';

const CurrencySelector = () => {
    const dashboard = useSelector((state) => state.dashboard);
    const dashboardGeneralReports = dashboard.dashboardGeneralReports;
    const currenciesOfStats = dashboard.campaignStats.currenciesOfStats.map(
        (currencyData) => currencyData.currency
    );
    const currencies = useSelector((state) => state.wallet.currencies);
    const dispatch = useDispatch();
    const selectedCurrency = dashboard.selectedCurrency;

    const changeCurrency = (currencyId) => {
        const currency = currencies.find((c) => c.id === parseInt(currencyId));
        dispatch(dashboardActions.setSelectedCurrency(currency));
    };

    useEffect(() => {
        const currenciesInStats = [];
        if (dashboardGeneralReports[4]) {
            dashboardGeneralReports[4].currencies.forEach((shortName) => {
                currenciesInStats.push(
                    currencies.find(
                        (currency) => currency.short_name === shortName
                    )
                );
            });
            dispatch(dashboardActions.setCurrencies(currenciesInStats));
        }
    }, [dashboardGeneralReports]);

    useEffect(() => {
        const setFirstCurrency = () =>
            dispatch(
                dashboardActions.setSelectedCurrency(currenciesOfStats[0])
            );
        if (!selectedCurrency && currenciesOfStats.length > 0) {
            setFirstCurrency();
        } else if (
            currenciesOfStats.length > 0 &&
            !currenciesOfStats.some(
                (currency) => currency.id === selectedCurrency.id
            )
        ) {
            setFirstCurrency();
        }
    }, [currenciesOfStats]);

    useEffect(() => {
        if (
            selectedCurrency &&
            dashboardGeneralReports.length > 0 &&
            dashboardGeneralReports[0]?.data[selectedCurrency?.short_name]
        ) {
            // data control showing on dashboard gral
            const copyReportsByCampaings = [
                ...dashboardGeneralReports[0].data[selectedCurrency.short_name],
            ];
            const copyReportsByDays = [
                ...dashboardGeneralReports[1].data[selectedCurrency.short_name],
            ];
            const copyReportsHours = [
                ...dashboardGeneralReports[2].data[selectedCurrency.short_name],
            ];
            const copyReportsBrands = [
                ...dashboardGeneralReports[3].data[selectedCurrency.short_name],
            ];
            const copyReports = [
                {
                    tabName: dashboardGeneralReports[0].tabName,
                    columnName: dashboardGeneralReports[0].columnName,
                    data: copyReportsByCampaings,
                },
                {
                    tabName: dashboardGeneralReports[1].tabName,
                    columnName: dashboardGeneralReports[1].columnName,
                    data: copyReportsByDays,
                },
                {
                    tabName: dashboardGeneralReports[2].tabName,
                    columnName: dashboardGeneralReports[2].columnName,
                    data: copyReportsHours,
                },
                {
                    tabName: dashboardGeneralReports[3].tabName,
                    columnName: dashboardGeneralReports[3].columnName,
                    data: copyReportsBrands,
                },
                {
                    ...dashboardGeneralReports[4],
                },
            ];
            dispatch(
                dashboardActions.setReportsTableGeneralData({
                    reportsForTable: copyReports,
                    currentCurrency: selectedCurrency,
                })
            );
        }
    }, [selectedCurrency, dashboardGeneralReports]);

    return currenciesOfStats.length > 1 ? (
        <select
            id="currency-selector"
            name="currency-selector"
            value={selectedCurrency ? selectedCurrency.id : ''}
            onChange={(e) => changeCurrency(e.target.value)}
            className="focus:ring-0 focus:outline-none h-full py-0 pl-2 pr-7 border-none bg-transparent text-gray-500 sm:text-sm rounded-md">
            {currenciesOfStats.map((currency, index) => (
                <option value={currency.id} key={index}>
                    {currency.short_name}
                </option>
            ))}
        </select>
    ) : (
        <span>
            {currenciesOfStats.length === 1 && currenciesOfStats[0].short_name}{' '}
        </span>
    );
};

export default CurrencySelector;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';

const InputWithLabel = ({
    title,
    label,
    icon,
    children,
    idHtmlFor,
    className,
}) => {
    return (
        <div className={className}>
            <label
                htmlFor={idHtmlFor}
                className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                <FontAwesomeIcon
                    icon={['fal', icon]}
                    fixedWidth
                    className="text-blue-500"
                />{' '}
                {title}
                <p className="font-light">{label}</p>
            </label>
            <div className="my-2 w-full ">
                <div>
                    <div className="relative flex-grow mr-5">{children}</div>
                </div>
            </div>
        </div>
    );
};

InputWithLabel.prototype = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    idHtmlFor: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default InputWithLabel;

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createInvoiceAPI } from '../../../../services/adminService';
import InvoiceForm from '../Form/InvoiceForm';
import useInvoiceOptions from '../../../../hooks/invoices/useInvoiceOptions';
import useDispatchNotification from '../../../../hooks/notifications/useDispatchNotification';

const NewInvoice = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { successNotification, warningNotification } =
        useDispatchNotification();
    const { initialInvoice } = useInvoiceOptions();
    const inputFileRef = useRef();
    const [formInvoice, setFormInvoice] = useState(initialInvoice);
    const { t } = useTranslation();
    const translate = (key) => t(`sections.management.section.invoices.${key}`);

    const createInvoice = async () => {
        setIsLoading(true);
        const response = await createInvoiceAPI(formInvoice);
        setIsSuccess(true);
        if (response.error) {
            warningNotification(translate('invoices.invoice.error-create'));
            return { error: true };
        } else {
            document.getElementById('file-to-upload').value = '';
            successNotification(
                translate('invoices.invoice.new-invoice'),
                translate('invoices.invoice.success-create')
            );
            return { error: false };
        }
    };

    return (
        <>
            <InvoiceForm
                onSubmit={createInvoice}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                setFormInvoice={setFormInvoice}
                formInvoice={formInvoice}
                inputFileRef={inputFileRef}
            />
        </>
    );
};

export default NewInvoice;

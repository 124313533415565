import { useTranslation } from 'react-i18next';

const useInvoiceOptions = () => {
    const { t } = useTranslation();
    const translate = (key) => {
        return t(`sections.management.section.invoices.${key}`);
    };

    const initialPeriod = [null, null];
    const initialInvoice = {
        userName: null,
        userId: null,
        userLabel: null,
        paymentMethod: null,
        invoiceType: {
            value: 'campaigns',
            label: translate('invoices.invoice.invoice-type-2'),
            id: 2,
        },
        invoicePeriod: initialPeriod,
        invoicePeriodLabel: initialPeriod,
        campaigns: [],
        campaignsLabel: [],
        comment: null,
        filePDFUrl: null,
        createdAt: new Date(),
    };

    const methodOptions = [
        {
            value: 'pre-paid',
            label: translate('invoices.invoice.pre-paid'),
            id: 1,
        },
        {
            value: 'post-paid',
            label: translate('invoices.invoice.post-paid'),
            id: 2,
        },
    ];

    const invoiceTypesOptions = [
        {
            value: 'period',
            label: translate('invoices.invoice.invoice-type-1'),
            id: 1,
        },
        {
            value: 'campaigns',
            label: translate('invoices.invoice.invoice-type-2'),
            id: 2,
        },
    ];
    return {
        methodOptions,
        invoiceTypesOptions,
        initialInvoice,
        initialPeriod,
    };
};

export default useInvoiceOptions;

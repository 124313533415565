import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../containers/UseAuth';
import LinkButton from '../../../Buttons/LinkButton';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    cpmFromPrice,
    formatNumber,
    lastTwoDecimal,
} from '../../../../utils/functions';

const DownloadForecastToCsv = ({
    forecastData,
    tierList,
    selectedScreensLocal,
}) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const newCampaign = useSelector((state) => state.newCampaign);
    const selectedCurrencyShortName = newCampaign?.selectedCurrency?.short_name;
    const campaignId = newCampaign.id;
    const brand = newCampaign.brand;
    const campaignName = newCampaign.name;
    const selectedScreens = newCampaign.selectedScreens;
    const metrics = newCampaign.forecast;
    const user = auth.session.user;
    const nameCSV =
        `Forecast_${user.id}_${user.username}_${brand}_${campaignName}_${campaignId}`.replace(
            / /g,
            ''
        );

    const headers = [
        { label: 'id', key: 'poiId' },
        { label: 'Screen Name', key: 'screenName' },
        { label: 'Screen Type', key: 'screenType' },
        { label: 'Date', key: 'date' },
        { label: 'Day', key: 'weekDay' },
        { label: 'Hour', key: 'hour' },
        { label: 'Impressions', key: 'impressions' },
        { label: 'Impacts', key: 'totalImpacts' },
        { label: 'Targeted Universe', key: 'targetUniverse' },
        { label: 'Coverage Abs', key: 'totalCoverageAbs' },
        { label: 'Coverage Rel', key: 'totalCoverageReg' },
        { label: 'Costs', key: 'costs' },
        { label: 'CPM', key: 'cpm' },
        { label: 'CPI', key: 'cpi' },
        { label: 'Frequency', key: 'totalFrequency' },
        { label: 'GRP', key: 'grp' },
        { label: 'CPS', key: 'cps' },
        { label: 'SPM', key: 'spm' },
    ];

    const getScreenName = (row) => {
        const screenUsed = metrics.screensUsed.find(
            (screen) => screen.id === row.poiId
        );
        return screenUsed ? screenUsed.name : '';
    };

    const formatedCsv = forecastData.map((row) => {
        let screenTypeRow = '';
        if (selectedScreensLocal.length) {
            let screenForRow = selectedScreensLocal.find(
                (screen) => screen.id == row.poiId
            );

            screenTypeRow = row.poiId
                ? tierList[screenForRow?.tierId && screenForRow.tierId]
                : '';
        }
        return {
            ...row,
            screenName: getScreenName(row),
            screenType: screenTypeRow,
            costs: `${newCampaign.selectedCurrency.symbol}${formatNumber(
                row.costs,
                'currency'
            )}`,
            targetUniverse: row.targetUniverse
                ? formatNumber(row.targetUniverse, 'withcommas')
                : 0,
            totalCoverageAbs: row.totalCoverageAbs ? row.totalCoverageAbs : 0,
            cpm: row.cpm
                ? `${newCampaign.selectedCurrency.symbol}${formatNumber(
                      Math.round(row.cpm),
                      'withcommas'
                  )}`
                : `${newCampaign.selectedCurrency.symbol}0`,
            cpi: row.cpi
                ? `${newCampaign.selectedCurrency.symbol}${formatNumber(
                      lastTwoDecimal(row.cpi)
                  )}`
                : `${newCampaign.selectedCurrency.symbol}0`,
            totalFrequency: row.totalFrequency
                ? formatNumber(row.totalFrequency, 'currency')
                : 0,

            grp: 'grp' in row ? formatNumber(lastTwoDecimal(row.grp)) : 0,
            cps: 'cps' in row ? formatNumber(lastTwoDecimal(row.cps)) : 0,
            spm: 'spm' in row ? formatNumber(lastTwoDecimal(row.spm)) : 0,
        };
    });

    return (
        <CSVLink
            data={formatedCsv}
            headers={headers}
            filename={`${nameCSV}.csv`}>
            <FontAwesomeIcon
                icon={['fad', 'file-excel']}
                size="lg"
                className="text-green-500 cursor-pointer mr-2"
            />
            <LinkButton>
                {t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.csv'
                )}
            </LinkButton>
        </CSVLink>
    );
};

export default DownloadForecastToCsv;

import moment from 'moment';
import * as endpoints from '../constants/endpoints';
import axios from '../utils/axios/axios';
import { formatDate, getData } from '../utils/functions';

import { useService } from '../containers/UseService';

// campañas por id de usuario con axios
export const getCampaignsByUserID = async (id) => {
    try {
        const response = await axios.get(endpoints.getCampaignsByUserIdV2 + id);
        return response.data;
    } catch (error) {
        useService.ErrorMessage(error);
    }
};

export const createInvoiceAPI = async (formInvoice) => {
    const formData = new FormData();
    try {
        formData.append('file', formInvoice.file);
        formData.append('userId', formInvoice.userId);
        formData.append(
            'pathName',
            btoa(formInvoice.userId + '_' + Date.now())
        );
        formData.append('invoicePaymentMethod', formInvoice.paymentMethod.id);
        formData.append('invoiceType', formInvoice.invoiceType.id);
        formData.append('invoicePeriod', formInvoice.invoicePeriod);
        formData.append('createdAt', moment().format('YYYY-MM-DD HH:mm:ss'));
        formData.append('campaignIds', JSON.stringify(formInvoice.campaigns));
        formData.append('invoiceComment', formInvoice.comment);
        const response = await axios.post(endpoints.invoices, formData);
        return response.data;
    } catch (err) {
        console.log('err: ', err);
        return { error: true };
    }
};

export const deleteInvoiceApi = async (invoiceId) => {
    try {
        const response = await axios.delete(
            `${endpoints.invoices}/${invoiceId}`
        );
        return response.data;
    } catch (err) {
        console.log('err: ', err);
    }
};

export const updateInvoiceApi = async (formInvoice) => {
    const formData = new FormData();
    try {
        formData.append('invoiceId', formInvoice.id);
        formData.append('file', formInvoice.file);
        formData.append('userId', formInvoice.userId);
        formData.append(
            'pathName',
            btoa(formInvoice.userId + '_' + Date.now())
        );
        formData.append('invoicePaymentMethod', formInvoice.paymentMethod.id);
        formData.append('invoiceType', formInvoice.invoiceType.id);
        formData.append('invoicePeriod', formInvoice.invoicePeriod);
        formData.append('campaignIds', JSON.stringify(formInvoice.campaigns));
        formData.append('invoiceComment', formInvoice.comment);
        const response = await axios.put(endpoints.invoices, formData);
        return response.data;
    } catch (error) {
        console.log(error);
        return { error: true };
    }
};

/**
 * It gets the invoices for a user, from a date, to a date
 * @param [userId] - The userId of the user you want to get invoices for. If you want to get invoices
 * for all users, leave this blank.
 * @param [from] - The start date of the invoice.
 * @param [to] - The end date of the invoice period.
 * @returns An array of objects.
 */
export const getInvoices = async (from = '', to = '') => {
    const url = `${endpoints.getAdminInvoices}?from=${from}&to=${to}`;
    const response = await axios.get(url);
    return response.data;
};

/**
 * It takes a userId and a status, and then it makes a request to the server to update the status of
 * the user
 * @param userId - The id of the user to be updated
 * @param status - true/false
 * @returns The response from the server.
 */
export const updateStatusUser = async (userId, status) => {
    const url = status
        ? endpoints.urlDeactivateUser(userId)
        : endpoints.urlActivateUser(userId);
    const response = await axios.post(url);
    return response;
};

/**
 * return reports by advertisers from the API
 * @param date - an array of two dates, the first one is the start date and the second one is the end
 * date.
 */
export const getReportsByUserTpye = async (date, userType, filters) => {
    let [from, to] = date;
    from = new Date(from);
    from = formatDate(from);
    to = new Date(to);
    to = formatDate(to);
    try {
        let response = getData(
            await axios.get(
                `${endpoints.reportsByUserType}/${userType}?from=${from}&to=${to}&${filters}`
            )
        );
        return response;
    } catch (e) {
        console.error(e);
        return {
            error: e,
        };
    }
};

export const getDataInReports = async (dataToGet = ['users', 'brands']) => {
    try {
        let response = getData(
            await axios.get(
                `${endpoints.reportsByUserType}?get=${dataToGet.join(',')}`
            )
        );
        return response;
    } catch (e) {
        console.error(e);
        return {
            error: true,
        };
    }
};

import React, { useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import ModalBody from '../../../components/Modal/ModalBody/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter/ModalFooter';
import ModalHeader from '../../../components/Modal/ModalHeader/ModalHeader';
import CommonButton from '../../Buttons/CommonButton';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { extraActions } from '../../../store';

const CustomPeriodModal = (props) => {
    const {
        active,
        toggler,
        UTC = false,
        setCurrentPeriod,
        minDate,
        maxDate,
    } = props;
    const { t } = useTranslation();
    const [periodValue, setPeriodValue] = useState(null);
    const handleChangePeriodValue = (period) => setPeriodValue(period);

    const onConfirm = () => {
        toggler();
        if (periodValue) {
            let formatDate = (date) => date;

            if (UTC) {
                formatDate = (date) =>
                    date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
            } else {
                formatDate = (date) =>
                    moment(date).format('YYYY-MM-DD HH:mm:ss');
            }

            setCurrentPeriod([
                formatDate(periodValue[0]),
                formatDate(periodValue[1]),
            ]);
        }
    };

    return (
        <Modal
            size="regular"
            active={active}
            toggler={toggler}
            className="mb-96">
            <ModalHeader toggler={toggler} className="w-auto text-left ">
                {t('sections.dashboard.select-custom-period')}
            </ModalHeader>
            <ModalFooter>
                <DateRangePicker
                    rangeDivider={t('common.components.DateRangePicker.until')}
                    onChange={(periodValue) =>
                        handleChangePeriodValue(periodValue)
                    }
                    value={periodValue}
                    calendarClassName="absolute top-10 right-4"
                />
                <CommonButton
                    className="w-20 ml-2"
                    label={t('common.buttons.cancel')}
                    styleType={'secondary'}
                    onClick={toggler}
                />
                <CommonButton
                    className="w-20 ml-2"
                    label={t('common.buttons.ok')}
                    disabled={periodValue === null}
                    onClick={onConfirm}
                    styleType={'primary'}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CustomPeriodModal;

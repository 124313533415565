import React, { useEffect, useState } from 'react';
import SwitcherButton from '../../Buttons/CommonSwitcher';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../containers/UseService';
import { dashboardActions } from '../../../store';
import CommonButton from '../../Buttons/CommonButton';
import { Link } from 'react-router-dom';
import { campaignStatus, formatNumber } from '../../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyText from '../../Formats/CurrencyText';
import Slider from 'rc-slider';
import styles from './QuickControls.module.css';
import { useHistory } from 'react-router-dom';
import { screensActions } from '../../../store';

const QuickControls = () => {
    const { t } = useTranslation();
    const service = useService();
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => {
        return state.dashboard;
    });
    const campaign = dashboard.campaign;
    const campaignPrediction = dashboard.campaignPrediction;
    const isValidPrediction = JSON.stringify(campaignPrediction) !== '{}';
    const isActive = campaign.status === 5;
    const hasPredictions = isActive && isValidPrediction;
    const id = campaign.id;
    const isActiveOrInactive =
        campaign.status === campaignStatus('ACTIVE') ||
        campaign.status === campaignStatus('INACTIVE');
    const isCpmOptimized = campaign['cpm_optimize'] === 1;
    const campaignBudget =
        campaign['budget'] !== null && campaign['budget'] !== undefined
            ? parseFloat(campaign['budget'])
            : 0;
    const isDailyCapByBudget = !!campaign?.limit_type;
    const isDailyCapEnabled = !(
        campaign['auto_distribution'] === 0 &&
        parseFloat(campaign['daily_limit']) === 0
    );
    const isDailyCapAutoDistributed =
        parseFloat(campaign['auto_distribution']) === 1;
    const [cpmSliderValue, setCpmSliderValue] = useState(0);
    const [dailyCapLimitSliderValue, setDailyCapLimitSliderValue] = useState(0);
    const history = useHistory();

    const toggleActiveCampaign = async () => {
        try {
            const campaignCopy = JSON.parse(JSON.stringify(campaign));
            dispatch(dashboardActions.setIsLoading(true));
            if (isActive) {
                campaignCopy.status = 'ACTIVE';
                await service.deactivateCampaign(campaignCopy);
            } else {
                campaignCopy.status = 'INACTIVE';
                await service.activateCampaign(campaignCopy);
            }
            await service.getCampaignPreview(campaign.id);
            dispatch(dashboardActions.setIsLoading(false));
        } catch (error) {
            console.log(error);
        }
    };

    const editCampaign = () => {
        dispatch(screensActions.resetByKey('maxCpm'));
        dispatch(screensActions.resetByKey('selectedScreensLocal'));
        dispatch(screensActions.resetByKey('isFilterByAudiences'));
        dispatch(screensActions.resetByKey('showSelectedScreens'));
        dispatch(screensActions.resetByKey('selectedScreenTypes'));
        dispatch(screensActions.resetByKey('selectedPublishers'));
        dispatch(screensActions.resetByKey('selectedTiers'));
        history.push(`/campaign/edit/${id}`);
    };

    const handleChangeDailyCapLimit = async (newDailyCapLimit) => {
        await service.changeDailyCapLimit(campaign.id, newDailyCapLimit);
        service.getCampaignPreview(campaign.id);
    };

    const handleChangeCpm = async (newCpm) => {
        await service.changeCpm(campaign.id, newCpm);
        service.getCampaignPreview(campaign.id);
    };

    useEffect(() => {
        const campaignCpm =
            campaign['max_cpm'] !== null && campaign['max_cpm'] !== undefined
                ? parseFloat(campaign['max_cpm'])
                : 0;
        const campaignDailyLimit =
            campaign['daily_limit'] !== null &&
            campaign['daily_limit'] !== undefined
                ? campaign.budget - campaign.daily_limit > 0
                    ? parseFloat(campaign['daily_limit'])
                    : parseFloat(campaign['budget'])
                : 0;
        setCpmSliderValue(campaignCpm);
        setDailyCapLimitSliderValue(campaignDailyLimit);
    }, [campaign]);

    return (
        <>
            {/* if campaign is active or inactive we show the quick controls */}
            <div className={`module items-center relative overflow-x-auto`}>
                <div className="h-5/6 w-8 pointer-events-none absolute top-1 left-1 ml-2 mt-1 z-10 bg-gradient-to-r from-white"></div>
                <div className="h-5/6 w-8 pointer-events-none absolute top-1 right-1 mt-1 z-10 bg-gradient-to-l from-white"></div>
                <div className="w-full flex justify-between items-center overflow-x-auto">
                    <div className="ml-2 font-light text-xs uppercase">
                        {t(
                            'sections.dashboard-individual.quick-controls.title'
                        )}
                    </div>
                    {/* Edit button */}
                    <div className="mr-2 self-center">
                        <CommonButton
                            type="primaryButton"
                            styleType={'smallprimary'}
                            onClick={editCampaign}>
                            <span>
                                {t(
                                    'sections.campaign-list.card-campaign.edit-dashboard'
                                )}
                            </span>
                        </CommonButton>
                    </div>
                </div>
                <div className="flex h-3/5 justify-between self-center content-center items-center flex-nowrap divide-x divide-dotted mt-2 px-2 overflow-x-auto overflow-y-hidden">
                    {/* Switch active/inactive */}
                    <div
                        className={`mr-2 self-center ${
                            isActiveOrInactive ? 'block' : 'hidden'
                        }`}>
                        <div className="w-24 mt-2 ml-2">
                            <SwitcherButton
                                onToggle={toggleActiveCampaign}
                                status={isActive}
                                labelOn={t(
                                    'sections.dashboard-individual.quick-controls.campaign-active'
                                )}
                                labelOff={t(
                                    'sections.dashboard-individual.quick-controls.campaign-paused'
                                )}
                                revertLabel={true}
                            />
                        </div>
                    </div>

                    {/* CPM
                Disabled if
                - is optimized
                - budget of campaing is 0 */}
                    <div
                        className={`mx-2 flex-1 w-40 px-2 ${
                            (isCpmOptimized || campaignBudget === 0) && 'hidden'
                        }`}>
                        <div className="flex">
                            <div>
                                <span className="text-gray-400 text-xs font-light">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.budget.cpm-label'
                                    )}
                                </span>
                                <div className="flex">
                                    <div style={{ minWidth: '9ch' }}>
                                        <CurrencyText
                                            currency={
                                                campaign?.currency?.short_name
                                            }
                                            value={cpmSliderValue}
                                            textSize="text-md"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="ml-2 mt-2">
                                <div className={styles.sliders}>
                                    <Slider
                                        vertical={true}
                                        min={1}
                                        max={
                                            parseFloat(campaign.max_cpm) > 0
                                                ? parseInt(campaign.max_cpm) ===
                                                  1
                                                    ? 100
                                                    : parseInt(
                                                          campaign.max_cpm
                                                      ) * 1.5
                                                : parseFloat(
                                                      campaign['max_cpm']
                                                  )
                                        } //Obtenemos el CPM mas Alto para el usuario + 1000
                                        value={cpmSliderValue}
                                        onChange={setCpmSliderValue}
                                        onAfterChange={handleChangeCpm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Daily Cap
                Disabled if
                - daily cap of campaign is disabled
                - daily cap of campaign is enabled but auto distributed
                - budget of campaign is 0 */}
                    <div
                        className={`mx-2  flex-1 w-40 px-2 ${
                            (!isDailyCapEnabled ||
                                isDailyCapAutoDistributed ||
                                campaignBudget === 0) &&
                            'hidden'
                        }`}>
                        <div className="flex">
                            <div>
                                <span className="text-gray-400 text-xs font-light">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.dailyCap.title'
                                    )}
                                </span>
                                <div className="flex">
                                    {isDailyCapByBudget ? (
                                        <div style={{ minWidth: '9ch' }}>
                                            <CurrencyText
                                                currency={
                                                    campaign?.currency
                                                        ?.short_name
                                                }
                                                value={dailyCapLimitSliderValue}
                                                textSize="text-md"
                                            />
                                        </div>
                                    ) : (
                                        <div className="font-semibold text-md">
                                            <span className="text-xs font-thin text-gray-500">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-briefBar.basics.abbreviated-impressions'
                                                )}
                                            </span>{' '}
                                            {formatNumber(
                                                campaign.daily_limit,
                                                'rounded'
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="ml-2 mt-2">
                                <div className={styles.sliders}>
                                    <Slider
                                        vertical={true}
                                        min={1}
                                        max={
                                            campaign.budget -
                                                campaign.daily_limit >
                                            0
                                                ? parseInt(
                                                      campaign.daily_limit
                                                  ) === 1
                                                    ? 100
                                                    : parseInt(
                                                          campaign.daily_limit
                                                      ) * 1.5
                                                : parseFloat(campaign['budget'])
                                        } //Max of current dailycap + 50%
                                        value={dailyCapLimitSliderValue}
                                        defaultValue={dailyCapLimitSliderValue}
                                        onAfterChange={
                                            handleChangeDailyCapLimit
                                        }
                                        onChange={setDailyCapLimitSliderValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuickControls;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useService } from '../../../../../containers/UseService';
import {
    isNumber,
    formatNumber,
    numberWithoutCommas,
    getData,
} from '../../../../../utils/functions';
import CommonButton from '../../../../Buttons/CommonButton';
import UserSelector from '../../../../Input/UserSelector/UserSelector';
import { walletActions } from '../../../../../store';
import axios from '../../../../../utils/axios/axios';
import * as endpoints from '../../../../../constants/endpoints';

const Funding = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currencies = useSelector((state) => state.wallet.currencies);
    const [currenciesWithLabel, setCurrenciesWithLabel] = useState([]);
    const [optionsTransactionType, setOptionsTransactionType] = useState([]);
    const service = useService();
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [billing, setBilling] = useState('');
    const [currencySpace, setCurrencySpace] = useState('pl-7');
    const [transactionType, setTransactionType] = useState([]);

    const handleOnChangeCurrency = (currency) => setSelectedCurrency(currency);
    const handleChangeBilling = (billing) =>
        setBilling(isNumber(billing) ? numberWithoutCommas(billing) : 0);
    const handleOnChangeTransactionType = (tType) => setTransactionType(tType);

    useEffect(() => {
        setCurrenciesWithLabel(
            currencies.map((currency) => ({
                ...currency,
                label: `${currency.symbol} ${currency.short_name} - ${currency.name}`,
                value: currency.short_name,
            }))
        );
    }, [currencies]);

    useEffect(() => {
        setOptionsTransactionType([
            {
                label: 'Credit',
                value: 1,
            },
            {
                label: 'Gift',
                value: 2,
            },
            {
                label: 'Pre-payment',
                value: 3,
            },
            {
                label: 'Penalty',
                value: 4,
            },
            {
                label: 'Wire-transfer',
                value: 5,
            },
            {
                label: 'Refound',
                value: 6,
            },
            {
                label: 'Compensation',
                value: 7,
            },
        ]);
    }, [setOptionsTransactionType]);

    useEffect(() => {
        if (selectedCurrency) {
            const amountCharacters = selectedCurrency.symbol.length;
            switch (amountCharacters) {
                case 1:
                    setCurrencySpace('pl-7');
                    break;
                case 2:
                    setCurrencySpace('pl-8');
                    break;
                case 3:
                    setCurrencySpace('pl-11');
                    break;
                case 4:
                    setCurrencySpace('pl-14');
                    break;
                default:
                    setCurrencySpace('pl-7');
            }
        }
    }, [selectedCurrency]);

    const addBilling = async () => {
        await service.addBilling(
            selectedUser,
            selectedCurrency,
            parseInt(numberWithoutCommas(billing)),
            transactionType.value
        );
        setSelectedCurrency(null);
        setSelectedUser(null);
        setBilling('');
        setTransactionType(null);
        await service.getBudgets();
    };

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12}`}>
            <span className="text-gray-600 text-xl font-light">
                {t('sections.management.section.billing.funding.title')}
            </span>
            <div
                className={`${
                    isActive ? 'block' : 'hidden'
                } w-full bg-white bg-opacity-90 p-4`}>
                <label
                    htmlFor="user"
                    className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                    <FontAwesomeIcon
                        icon={['fal', 'user-friends']}
                        fixedWidth
                        className="text-blue-500"
                    />{' '}
                    {t('sections.management.section.billing.funding.userTitle')}
                    <p className="font-light">
                        {t(
                            'sections.management.section.billing.funding.userTxt'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <UserSelector
                                value={selectedUser}
                                onChange={setSelectedUser}
                            />
                        </div>
                    </div>
                </div>
                <label
                    htmlFor="currency"
                    className="block text-gray-700 text-sm font-bold mb-2">
                    <FontAwesomeIcon
                        icon={['fal', 'coin']}
                        fixedWidth
                        className="text-blue-500"
                    />{' '}
                    {t(
                        'sections.management.section.billing.funding.currencyTitle'
                    )}
                    <p className="font-light">
                        {t(
                            'sections.management.section.billing.funding.currencyTxt'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <Select
                                inputId="currency"
                                options={currenciesWithLabel}
                                value={selectedCurrency}
                                onChange={handleOnChangeCurrency}
                                placeholder={t(
                                    'sections.management.section.billing.funding.currencySelect'
                                )}
                            />
                        </div>
                    </div>
                </div>

                <label
                    htmlFor="billing"
                    className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                    <FontAwesomeIcon
                        icon={['fal', 'coin']}
                        fixedWidth
                        className="text-blue-500"
                    />{' '}
                    {t(
                        'sections.management.section.billing.funding.billingTitle'
                    )}
                    <p className="font-light">
                        {t(
                            'sections.management.section.billing.funding.billingTxt'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">
                                    {selectedCurrency &&
                                        selectedCurrency.symbol}
                                </span>
                            </div>
                            <input
                                type="text"
                                name="billing"
                                id="billing"
                                value={
                                    formatNumber(billing, 'withcommas') === '0'
                                        ? ''
                                        : formatNumber(billing, 'withcommas')
                                }
                                min="0"
                                autoComplete="off"
                                onChange={(e) =>
                                    handleChangeBilling(e.target.value)
                                }
                                className={`focus:ring-0 focus:outline-none block w-full pr-12  sm:text-sm border-gray-300 rounded-md " ${currencySpace}`}
                                placeholder="0.00"
                            />
                        </div>
                    </div>
                </div>
                <label
                    htmlFor="transaction-type"
                    className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                    {t(
                        'sections.management.section.billing.funding.transactionTypeTitle'
                    )}
                    <p className="font-light">
                        {t(
                            'sections.management.section.billing.funding.transactionTypeDescription'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <Select
                                inputId="transaction-type"
                                options={optionsTransactionType}
                                value={transactionType}
                                onChange={handleOnChangeTransactionType}
                                placeholder={t(
                                    'sections.management.section.billing.funding.transactionTypePlaceholder'
                                )}
                            />
                        </div>
                    </div>
                </div>

                <CommonButton
                    className="mt-4"
                    disabled={
                        selectedUser === null ||
                        selectedCurrency === null ||
                        billing === '' ||
                        transactionType === ''
                    }
                    onClick={addBilling}
                    label={t(
                        'sections.management.section.billing.funding.addBilling'
                    )}
                    styleType={'primary'}
                />
            </div>
        </div>
    );
};

export default Funding;

import React from 'react';
import { useSelector } from 'react-redux';
import Basics from './Basics/Basics';
import Audiences from './Audiences/Audiences';
import Screens from './Screens/Screens';
import Location from './Location/Location';
import Creatives from './Creatives/Creatives';

function WizardBriefBar(params) {
    const stepper = useSelector((state) => state.newCampaign.stepper);
    const isActive = params.isActive;
    const selectedScreensLocal = params.selectedScreensLocal;

    return (
        <>
            {isActive && (
                <>
                    <Basics isActive={stepper.wizard === 'basics'} />
                    <Location isActive={stepper.wizard === 'location'} />
                    <Screens
                        isActive={stepper.wizard === 'screens'}
                        selectedScreensLocal={selectedScreensLocal}
                    />
                    <Audiences isActive={stepper.wizard === 'audiences'} />
                    <Creatives isActive={stepper.wizard === 'creatives'} />
                </>
            )}
        </>
    );
}

export default WizardBriefBar;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { formatNumber } from '../../../../utils/functions';
import { useTranslation } from 'react-i18next';

const BarChart = ({ data, type }) => {
    const { t } = useTranslation();
    const hours = data.map((day) => day.hour);
    const allHours = hours.sort((a, b) => a - b);

    const impactValues = allHours.map((hour) => {
        const dataItem = data.find((item) => parseInt(item.hour) === hour);
        return dataItem ? parseInt(dataItem.impacts) : 0;
    });

    let avgRequestsDay = 0;

    const reqValues = allHours.map((hour) => {
        const dataItem = data.find((item) => parseInt(item.hour) === hour);
        avgRequestsDay += dataItem.avg_req;

        return dataItem ? dataItem.avg_req : 0;
    });

    avgRequestsDay = avgRequestsDay / allHours.length;

    let maxImpacts = Math.max(...impactValues);
    let minImpacts = Math.min(...impactValues);

    if (type == 'requests') {
        maxImpacts = Math.max(...reqValues);
        minImpacts = Math.min(...reqValues);
    }

    const gradient = impactValues.map((value) => {
        const normalizedValue =
            (value - minImpacts) / (maxImpacts - minImpacts);
        let alpha = 0.3 + normalizedValue * 0.7;
        return `rgba(54, 162, 235, ${alpha})`;
    });

    const gradientReq = reqValues.map((value) => {
        let alpha = value <= 20 ? 0.45 : 1;
        return `rgba(245, 159, 138, ${alpha})`;
    });

    const chartData = {
        labels: allHours,
        datasets: [
            {
                type: 'bar',
                label: type,
                data: type === 'requests' ? reqValues : impactValues,
                backgroundColor: type === 'requests' ? gradientReq : gradient,
            },
        ],
    };

    if (type === 'requests') {
        const data = [];
        for (let i = 0; i < 24; i++) {
            data.push(avgRequestsDay);
        }
        chartData.datasets.push({
            type: 'line',
            data: data,
            backgroundColor: 'rgba(142, 142, 142, 0.0)',
        });
    }

    const chartOptions = {
        elements: {
            point: {
                pointStyle: 'line',
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                            return formatNumber(value, 'short');
                        },
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                            return `${value} hs`;
                        },
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItem) {
                    const hour = tooltipItem[0].xLabel;
                    return `${hour} hs`;
                },
                label: function (tooltipItem) {
                    const valueToShow = tooltipItem.value;
                    return ` ${t(`common.words.${type}`)}: ${formatNumber(
                        valueToShow,
                        'impacts'
                    )}`;
                },
            },
        },
        legend: {
            display: false,
        },
    };

    return <Bar data={chartData} options={chartOptions} />;
};

export default BarChart;

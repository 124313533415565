import React, { useEffect, useState } from 'react';
import { newCampaignActions, screensActions } from '../../../../store';
import { useTranslation } from 'react-i18next';
import {
    isNumber,
    formatNumber,
    numberWithoutCommas,
    getCpmMax,
} from '../../../../utils/functions';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import Hint from '../../../Tools/Hint';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../containers/UseAuth';
import useCurrencySpace from '../../../../hooks/budget/useCurrencySpaces';

const Budget = ({
    selectedCurrency,
    currencies,
    budget,
    campaign,
    dispatch,
    currencyDefault,
}) => {
    const { selectedScreensLocal } = useSelector((state) => state.screens);
    const { t } = useTranslation();
    const auth = useAuth();
    const currencySpace = useCurrencySpace(selectedCurrency?.symbol);
    const wallet = useSelector((state) => state.wallet.budgets);
    const availableCurrencies = wallet
        ? Object.keys(wallet)
        : currencies.map((item) => item.short_name);
    const noMoneyPlaceholder = '0.00';
    let currenciesToUse = currencies.filter((item) =>
        availableCurrencies.includes(item.short_name)
    );
    let hasSpent = campaign.totalSpent > 0;

    const change = (attribute, value) => {
        const newBudget = Object.assign({}, budget);
        if (
            auth.session?.user?.cpm_mode === 0 &&
            attribute === 'cpmOptimizer' &&
            value
        ) {
            newBudget.cpm = getCpmMax(campaign.selectedScreens);
        }
        newBudget[attribute] = value;
        dispatch(newCampaignActions.setBudget(newBudget));
    };

    const toggle = (attribute) => {
        change(attribute, !budget[attribute]);
    };

    const handleChangeBudget = (amount) => {
        if (isNumber(amount)) {
            amount = numberWithoutCommas(amount);
            amount = amount === '0' ? '' : amount;
            change('budget', amount);
        } else {
            change('budget', 0);
        }
    };

    const handleChangeCPM = (CPM) => {
        if (isNumber(CPM)) {
            CPM = numberWithoutCommas(CPM);
            CPM = CPM === '0' ? '' : CPM;
            change('cpm', CPM);
        } else {
            change('cpm', 0);
        }
    };

    const handleChangeCurrency = (currencyId) => {
        dispatch(newCampaignActions.setSelectedScreens([]));
        dispatch(screensActions.setSelectedScreensLocal([]));

        const [selectedCurrency] = currenciesToUse.filter(
            (c) => c.id == currencyId
        );
        dispatch(newCampaignActions.setSelectedCurrency(selectedCurrency));
    };

    useEffect(() => {
        if (!selectedCurrency && currenciesToUse.length > 0)
            dispatch(
                newCampaignActions.setSelectedCurrency(currenciesToUse[0])
            );
    }, [currenciesToUse]);

    useEffect(() => {
        if (!selectedCurrency) {
            dispatch(newCampaignActions.setSelectedCurrency(currencyDefault));
        }
    }, []);

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.budget.title'
                    )}
                </div>
                <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                    {budget.budget === '' || budget.budget === 0 ? (
                        t(
                            'sections.campaigns.new-campaign.wizard-basics.budget.info'
                        )
                    ) : (
                        <span className="text-xs">
                            {selectedCurrency.symbol}
                            {formatNumber(budget.budget, 'currency')}
                        </span>
                    )}
                </div>
            </div>
            <div className="body rounded-sm p-1">
                <div className="flex justify-between flex-col md:flex-row mb-2">
                    <div className="md:w-3/6 md:mr-4">
                        <label
                            htmlFor="budget"
                            className="block text-sm font-medium text-gray-700">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.budget.budget-label'
                            )}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">
                                    {selectedCurrency &&
                                        selectedCurrency.symbol}
                                </span>
                            </div>
                            <input
                                type="text"
                                name="budget"
                                id="budget"
                                value={
                                    !parseInt(budget.budget) === 0
                                        ? ''
                                        : formatNumber(
                                              budget.budget,
                                              'withcommas'
                                          )
                                }
                                min="0"
                                autoComplete="off"
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    const positiveValue = newValue.replace(
                                        /^-/,
                                        ''
                                    );
                                    handleChangeBudget(positiveValue);
                                }}
                                className={`focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm rounded-md border-gray-300 ${currencySpace}`}
                                placeholder={noMoneyPlaceholder}
                                data-testid="budget"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center">
                                <label htmlFor="currency" className="sr-only">
                                    {t('common.currency.currency-title')}
                                </label>
                                <select
                                    id="currency"
                                    name="currency"
                                    value={
                                        selectedCurrency
                                            ? selectedCurrency.id
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChangeCurrency(e.target.value)
                                    }
                                    className="focus:ring-0 focus:outline-none h-full py-0 pl-2 pr-7 border-none bg-transparent text-gray-500 sm:text-sm rounded-md">
                                    {currenciesToUse.map((currency, index) => (
                                        <option
                                            value={currency.id}
                                            disabled={hasSpent}
                                            key={index}>
                                            {currency.short_name}
                                        </option>
                                    ))}
                                </select>
                                {hasSpent && (
                                    <Hint
                                        className="absolute -right-5"
                                        title={t(
                                            'sections.campaigns.new-campaign.wizard-screens.screens.currency'
                                        )}
                                        text={t(
                                            'sections.campaigns.new-campaign.wizard-basics.budget.hasSpent'
                                        )}
                                        type={'info'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-3/6 ml-0 mt-4 md:mt-0 md:ml-4">
                        <div className="flex justify-between content-center mb-4">
                            <div
                                className={`${
                                    budget.cpmOptimizer ? 'invisible' : ''
                                } w-3/6 mr-4`}>
                                <label
                                    htmlFor="price"
                                    className="block text-sm font-medium text-gray-700">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.budget.cpm-label'
                                    )}
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">
                                            {selectedCurrency &&
                                                selectedCurrency.symbol}
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        value={
                                            parseInt(budget.cpm) === 0
                                                ? ''
                                                : formatNumber(
                                                      budget.cpm,
                                                      'withcommas'
                                                  )
                                        }
                                        onChange={(e) =>
                                            handleChangeCPM(e.target.value)
                                        }
                                        min="0"
                                        autoComplete="off"
                                        name="price"
                                        id="price"
                                        className={`focus:ring-0 focus:outline-none block w-full sm:text-sm border-gray-300 rounded-md ${currencySpace} ${
                                            budget.cpmOptimizer == 1
                                                ? 'debug'
                                                : ''
                                        }`}
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>
                            {auth.session?.user?.cpm_mode === 0 && (
                                <div className="w-3/6 ml-4">
                                    <label className="block mb-4">
                                        <span className="text-gray-700">
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-basics.budget.cpmOptimizer-label'
                                            )}
                                        </span>
                                        <div className="mt-2">
                                            <SwitcherButton
                                                status={budget.cpmOptimizer}
                                                onToggle={() =>
                                                    toggle('cpmOptimizer')
                                                }
                                                labelOn={t(
                                                    'sections.campaigns.new-campaign.wizard-basics.budget.cpmOptimizer-labelOn'
                                                )}
                                                labelOff={t(
                                                    'sections.campaigns.new-campaign.wizard-basics.budget.cpmOptimizer-labelOff'
                                                )}
                                            />
                                        </div>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Budget;

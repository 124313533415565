import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { campaignStatus, formatNumber } from '../../../utils/functions';
import moment from 'moment';
import PredictionDetails from './PredictionDetails/PredictionDetails';
// import { PredictionDetails } from '../PredictionDetails/PredictionDetails';

const Predictions = ({ isCampaignPrediction, campaignScreensStatus }) => {
    const { t } = useTranslation();
    const dashboard = useSelector((state) => state.dashboard);
    const campaign = dashboard.campaign;
    const campaignPrediction = dashboard.campaignPrediction;
    const [handleDataSchedule, setHandleDataSchedule] = useState(true);
    const isValidPrediction = JSON.stringify(campaignPrediction) !== '{}';
    const isActive =
        campaign.status === campaignStatus('ACTIVE') && isValidPrediction;
    const isDelayed =
        campaignPrediction.endDate > 1 || campaignPrediction.endDate < -1;
    let hours = { AM: [], PM: [] };
    campaign.hours_enabled.forEach((hour) => {
        let hourAMPM = moment(hour, 'HH').format('A-h');
        hourAMPM = hourAMPM.split('-');
        hours[hourAMPM[0]].push(hourAMPM[1]);
    });
    hours.AM.sort((a, b) => a - b);
    hours.PM.sort((a, b) => a - b);

    return (
        <>
            {isActive && (
                <div className={`flex flex-col h-full`}>
                    <div className="font-light text-xs uppercase">
                        <span className="mr-1">
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.screens.screen-currentStatus'
                            )}
                        </span>
                    </div>
                    <div className="flex divide-x divide-dotted">
                        <div className="pr-2 w-2/6">
                            <span className="text-gray-600 text-xs font-light">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-tab.screens'
                                )}
                            </span>
                            <br />
                            <div className="font-extralight text-2xl">
                                <span className="text-black font-semibold">
                                    {campaignPrediction.buyingScreen
                                        ? campaignPrediction.buyingScreen
                                        : 0}
                                </span>
                                /{campaign.screens.length}
                            </div>
                        </div>
                        {/** TODO: Remove the hidden property when the scheduler works */}
                        <div className="pl-2 w-4/6 hidden">
                            <span className="text-gray-600 text-xs font-light">
                                {t(
                                    'sections.dashboard-individual.predictions.estimated-end'
                                )}
                            </span>
                            <br />
                            <div
                                className={`${
                                    isDelayed
                                        ? 'text-red-600 bg-red-100'
                                        : 'text-green-500  bg-gray-50'
                                } mt-2 text-xs rounded-full text-center font-bold flex-none`}>
                                {t(
                                    `sections.dashboard-individual.predictions.${
                                        isDelayed ? 'delayed' : 'on-time'
                                    }`
                                )}
                            </div>
                            {isDelayed ? (
                                <>
                                    <ReactTooltip
                                        effect="solid"
                                        className="w-48 p-2"
                                        id="etaTooltip"
                                        aria-haspopup="true">
                                        {campaignPrediction.endDate > 0
                                            ? t(
                                                  'sections.dashboard-individual.predictions.increase-spending'
                                              )
                                            : t(
                                                  'sections.dashboard-individual.predictions.decrease-spending'
                                              )}
                                    </ReactTooltip>
                                    <div className="text-center mt-2 text-sm">
                                        <a data-tip data-for="etaTooltip">
                                            <FontAwesomeIcon
                                                icon={[
                                                    'fad',
                                                    'exclamation-triangle',
                                                ]}
                                                className="text-yellow-400 mr-1"
                                            />
                                            {campaignPrediction.endDate > 1
                                                ? campaignPrediction.endDate +
                                                  ' ' +
                                                  t(
                                                      'sections.dashboard-individual.predictions.after-schedule'
                                                  )
                                                : campaignPrediction.endDate <
                                                  -1
                                                ? -1 *
                                                      campaignPrediction.endDate +
                                                  ' ' +
                                                  t(
                                                      'sections.dashboard-individual.predictions.before-schedule'
                                                  )
                                                : ''}
                                        </a>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Predictions;

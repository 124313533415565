import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import useClickOuside from '../../../hooks/utils/useClickOuside';
import useKeyEsc from '../../../hooks/utils/useKeyEsc';

const CustomSelect = ({ label = '', options = [], handleClick, firstIcon }) => {
    const [isShowOptions, setIsShowOptions] = useState(false);
    const [containerRef] = useClickOuside((e) => {
        setIsShowOptions(false);
    });

    useKeyEsc(() => {
        setIsShowOptions(false);
    });

    return (
        <div className="relative my-auto" ref={containerRef}>
            <div
                className=" flex justify-between gap-2 cursor-pointer "
                onClick={() => setIsShowOptions(!isShowOptions)}>
                {firstIcon && (
                    <FontAwesomeIcon
                        className="my-auto text-blue-400"
                        icon={['fas', firstIcon]}
                    />
                )}
                <div className="relative p-2 flex justify-between gap-10   focus:ring-0 focus:outline-none border-none bg-transparent text-gray-500 sm:text-sm border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer">
                    <button>{label}</button>
                    <div className="w-3">
                        <FontAwesomeIcon
                            className={`my-auto duration-300 ${
                                isShowOptions ? 'transform rotate-90' : ''
                            } `}
                            icon={['far', 'angle-right']}
                        />
                    </div>
                    <div
                        className="z-20 absolute transform transition-all ease-in-out duration-300 overflow-y-auto rounded-sm  left-0 top-10"
                        style={
                            isShowOptions
                                ? { height: '50vh' }
                                : {
                                      height: '0',
                                  }
                        }>
                        <div className="bg-white shadow  cursor-pointer flex flex-col border border-gray-200 py-2 rounded-sm">
                            {options.map((option, ind) => {
                                return (
                                    <option
                                        key={ind}
                                        className="bg-white pl-2 pr-10 py-1 hover:bg-blue-500 hover:text-white"
                                        onClick={(e) =>
                                            handleClick(option, e.target)
                                        }>
                                        {option.label}
                                    </option>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomSelect;

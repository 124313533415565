import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { screensActions } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';

const ScreenTier = ({
    tier,
    onCheck,
    onUnCheck,
    index,
    isChecked,
    filteredScreensLocal,
}) => {
    const handleChange = (input) => {
        if (input.checked) {
            onCheck(tier);
        } else {
            onUnCheck(tier);
        }
    };

    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        const currentQuantity =
            filteredScreensLocal.length &&
            filteredScreensLocal.filter(
                (s) => s.tierId && tier.ids.includes(s.tierId)
            ).length;
        setQuantity(currentQuantity);
    }, [filteredScreensLocal]);

    return quantity ? (
        <div className="flex items-center justify-between my-2">
            <div className="flex items-center">
                <input
                    defaultChecked={isChecked}
                    onChange={(e) => handleChange(e.target)}
                    name={`screen-tier-${index}`}
                    id={`screen-tier-${index}`}
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label
                    htmlFor={`screen-tier-${index}`}
                    className="ml-2 block text-sm text-gray-600">
                    {tier.screenType}
                </label>
            </div>
            <div className="text-xs text-gray-400 mr-2">
                {filteredScreensLocal.length &&
                    filteredScreensLocal.filter(
                        (s) => s.tierId && tier.ids.includes(s.tierId)
                    ).length}
            </div>
        </div>
    ) : (
        <div></div>
    );
};

const ScreenTierList = ({
    filteredScreensLocal,
    screensFilteredFromScreensWizard,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { tiers, selectedTiers } = useSelector((state) => state.screens);

    return (
        <div className="font-light py-2 w-40">
            <div className="title font-light py-2 shadow-sm">
                {t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.screenTypes-label'
                )}
            </div>
            <div className='max-h-60 overflow-hidden overflow-y-scroll'>
                {tiers.map((tier, index) => (
                    <ScreenTier
                        filteredScreensLocal={filteredScreensLocal}
                        tier={tier}
                        onCheck={() => dispatch(screensActions.addTier(tier))}
                        onUnCheck={() => dispatch(screensActions.removeTier(tier))}
                        key={tier.screenType}
                        isChecked={selectedTiers.some(
                            (selectedTier) =>
                                selectedTier.screenType === tier.screenType
                        )}
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default ScreenTierList;

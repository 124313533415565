import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bar from '../Bar/Bar';
import Item from '../Bar/Item/Item';

const Audiences = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const metrics = newCampaign.forecast;

    return (
        <Bar
            isActive={isActive}
            title={t(
                'sections.campaigns.new-campaign.wizard-briefBar.audiences.title'
            )}>
            <Item
                first
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.targeted-universe'
                )}>
                {metrics.targetUniverse}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.targeted-impacts'
                )}>
                {metrics.totalImpacts}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.coverage'
                )}>
                {metrics.coverage}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.GRP'
                )}>
                {metrics.grp}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.frequency'
                )}>
                {metrics.frequency}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.ad-impressions'
                )}>
                {metrics.adImpressions}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.cost-per-impact'
                )}>
                {metrics.cpi}
            </Item>
        </Bar>
    );
};

export default Audiences;

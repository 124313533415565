import React, { useEffect, useState } from 'react';
import Button from '../../components/Buttons/CommonButton';
import { useAuth } from '../UseAuth';
import { useTranslation } from 'react-i18next';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { useService } from '../UseService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ForgotPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [valid, setValidation] = useState(false);
    const service = useService();
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        await service.forgotPassword(email);
        setIsLoading(false);
        setIsSent(true);
    };

    useEffect(() => {
        setValidation(email.length > 8);
    }, [email]);

    return (
        <LoginLayout>
            <div className="text-gray-500">
                {t('sections.login-page.forgot-title')}
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="my-4">
                    <input
                        type="text"
                        value={email}
                        autoComplete="email"
                        className="mt-2 rounded-sm w-full bg-gray-200 border-none focus:ring-0"
                        placeholder={t('sections.login-page.your-email')}
                        onChange={(e) => setEmail(e.target.value)}></input>
                </div>

                <div className="my-4">
                    <Button
                        label={t('sections.login-page.send-email')}
                        styleType={'primary'}
                        disabled={!valid}
                    />
                    <Button
                        className="ml-2"
                        label={t('sections.login-page.back-login')}
                        styleType={'secondary'}
                        action="/"
                    />
                    <FontAwesomeIcon
                        icon={['fas', 'circle-notch']}
                        className={`text-blue-400 animate-spin mx-2 ${
                            !isLoading && 'hidden'
                        }`}
                    />
                </div>
                <div className={isSent ? 'block' : 'hidden'}>
                    <span className="font-light">
                        {t('sections.login-page.email-sent')}
                    </span>
                </div>
            </form>
        </LoginLayout>
    );
}

export default ForgotPassword;

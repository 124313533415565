import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bar from '../Bar/Bar';
import Item from '../Bar/Item/Item';

const Creatives = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const creatives = useSelector((state) => state.newCampaign.creatives);

    return (
        <Bar
            isActive={isActive}
            title={t(
                'sections.campaigns.new-campaign.wizard-briefBar.creatives.title'
            )}>
            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.creatives.amount-creatives'
                )}>
                {creatives.length}
            </Item>
        </Bar>
    );
};

export default Creatives;

const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const configAxios = (axiosInstance) => {
    let session = localStorage.getItem('session');
    if (session) {
        session = JSON.parse(session);
        const token = session.token;

        axiosInstance.interceptors.request.use((config) => {
            if (token) {
                const headers = {
                    Authorization: 'Bearer ' + token,
                    ...config.headers,
                };
                config.headers = headers;
            } else {
                let { Authorization, ...rest } = config.headers;
                config.headers = rest;
            }

            return config;
        });

        axiosInstance.interceptors.response.use((response) => {
            if (response.data !== undefined) {
                let error = response.data.error;
                let message = response.data.message;

                if (error && message === 'Unauthenticated') {
                    localStorage.clear();
                    window.location = '/';
                }
            }
            return response;
        });
    }
};

export default configAxios;

import React from 'react';
import CommonButton from '../Buttons/CommonButton';
import Modal from './Modal';
import ModalBody from './ModalBody/ModalBody';
import ModalFooter from './ModalFooter/ModalFooter';
import ModalHeader from './ModalHeader/ModalHeader';
import { useTranslation } from 'react-i18next';

const ModalOnDelete = (props) => {
    const { t } = useTranslation();
    const {
        handleModal,
        isActive,
        handleConfirm,
        handleCancel,
        title,
        body,
        cancelButtonLabel = t('common.buttons.cancel'),
        confirmButtonLabel = t('common.buttons.delete'),
        // sizes in styles from "./Modal.module.css";
        size = 'sm',
        className = {
            header: '',
            body: '',
            footer: { container: '', cancel: '', confirm: '' },
        },
    } = props;
    return (
        <Modal size={size} active={isActive}>
            <ModalHeader toggler={handleModal} className={className.header}>
                {title}
            </ModalHeader>
            <ModalBody className={`pl-3 pr-12 ${className.body}`}>
                {body}
            </ModalBody>
            <ModalFooter className={`pr-5 pb-5 ${className.footer.container}`}>
                <CommonButton
                    onClick={handleCancel}
                    label={cancelButtonLabel}
                    styleType={'cancel'}
                    className={`w-28 ${className.footer.cancel}`}
                />
                <CommonButton
                    onClick={handleConfirm}
                    label={confirmButtonLabel}
                    styleType={'danger'}
                    className={`ml-2 w-28 ${className.footer.confirm}`}
                />
            </ModalFooter>
        </Modal>
    );
};

export default ModalOnDelete;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { newCampaignActions } from '../../store';
import PropTypes from 'prop-types';

const CategorySelect = ({ selectedCreativeCategories, service, dispatch }) => {
    const { t } = useTranslation();
    const [categOptions, setCategOptions] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const putValueAndLabel = (category) => ({
        value: category.UniqueID || category.value,
        label: category.Name || category.label,
    });

    const parseData = (data) => {
        JSON.parse(JSON.stringify(data));
        return data.map(putValueAndLabel);
    };

    selectedCreativeCategories = parseData(selectedCreativeCategories);

    const fetchData = async () => {
        let categories = await service.getCreativeCategories();
        categories = categories.map(putValueAndLabel);
        let newSelectedCreativeCategories = selectedCreativeCategories;
        setCategOptions(categories);
        handleOnChange(newSelectedCreativeCategories);
    };

    const handleOnChange = (newCategoriesSelected) => {
        dispatch(
            newCampaignActions.setSelectedCreativeCategories(
                newCategoriesSelected
            )
        );
    };

    return (
        <div className="module rounded ml-1 mb-4">
            <label className="mb-1 block text-sm font-medium text-gray-700">
                {t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.select-categories'
                )}
            </label>
            <div className="w-6/12">
                <Select
                    defaultValue={selectedCreativeCategories}
                    options={categOptions}
                    onChange={handleOnChange}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
            </div>
        </div>
    );
};

CategorySelect.proptyTypes = {
    selectedCreativeCategories: PropTypes.string,
    service: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
};

export default CategorySelect;

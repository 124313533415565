import React from 'react';

const Tab = (props) => {
    const { isAvaible, isActive, title, onClick, hidden } = props;

    return (
        isAvaible && (
            <button
                onClick={onClick}
                className={`${
                    isActive
                        ? 'active text-blue-500 border-b-2 font-medium border-blue-500'
                        : ''
                } text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none ${
                    hidden ? 'hidden' : ''
                }`}>
                {title}
            </button>
        )
    );
};

export default Tab;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
    arrayToList,
    getPeriods,
    isNowPMAbsolute,
    checkHourRange,
} from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import HoursList from '../../HoursList/HoursList';

const CampaignBrief = (props) => {
    const { t } = useTranslation();
    const { timezone } = props;
    const buyingTime = isNowPMAbsolute() ? 'pm' : 'am';
    const campaign = useSelector((state) => state.dashboard.campaign);
    const startCampaign = moment(new Date(campaign.start_period)).format(
        'DD/MM'
    );
    const endCampaign = moment(new Date(campaign.end_period)).format('DD/MM');
    const hoursFormatted = campaign.hours_enabled
        ? getPeriods([...campaign.hours_enabled])
        : {};

    const formatDays = (days) => {
        const weekdays = [
            t('sections.campaigns.new-campaign.wizard-basics.timing.sunday'),
            t('sections.campaigns.new-campaign.wizard-basics.timing.monday'),
            t('sections.campaigns.new-campaign.wizard-basics.timing.tuesday'),
            t('sections.campaigns.new-campaign.wizard-basics.timing.wednesday'),
            t('sections.campaigns.new-campaign.wizard-basics.timing.thursday'),
            t('sections.campaigns.new-campaign.wizard-basics.timing.friday'),
            t('sections.campaigns.new-campaign.wizard-basics.timing.saturday'),
        ];
        const weekdayType = [
            t('sections.campaigns.new-campaign.wizard-basics.timing.every-day'),
            t(
                'sections.campaigns.new-campaign.wizard-basics.timing.bussiness-days'
            ),
            t('sections.campaigns.new-campaign.wizard-basics.timing.weekend'),
        ];
        const isWeekend =
            days.includes(0) && days.includes(6) && days.length === 2;
        const isBusinessDays =
            !isWeekend &&
            !(days.includes(0) || days.includes(6)) &&
            days.length === 5;
        const readableDays = days.map((day) => weekdays[day]);
        let result = weekdayType[isWeekend * 2 + isBusinessDays * 1];
        if (days.length < 7 && result === weekdayType[0]) {
            result = readableDays.join(', ');
        }
        return result;
    };

    const formatGenders = (genders) => {
        if (!genders) return '';
        const translatedGenders = genders.map((genderLetter) =>
            t(
                `sections.campaigns.new-campaign.wizard-audiences.demographics.gender.${genderLetter}`
            )
        );
        return arrayToList(
            translatedGenders.map((gender) => ({ label: gender }))
        );
    };

    const formatAge = (ages) => {
        if (!ages) return '';
        const agesScopesi =
            ages.length > 0
                ? `${campaign.scopesi_ages[0].split('-')[0]} ${t(
                      'common.words.to'
                  )} ${
                      campaign.scopesi_ages[
                          campaign.scopesi_ages.length - 1
                      ].split('-')[1]
                  }`
                : '';
        return agesScopesi;
    };

    const formatIncome = (incomes) => {
        if (!incomes) return '';
        const translatedIncomeLevels = incomes.map((letter) =>
            t(
                `sections.campaigns.new-campaign.wizard-audiences.demographics.income.${letter}`
            )
        );
        return arrayToList(
            translatedIncomeLevels.map((income) => ({ label: income }))
        );
    };

    /**
     * "If the current hour is in the array of hours enabled, return true, otherwise return false."
     *
     * @returns A boolean value
     */
    let isCampaignPlaying = () => {
        let today = new Date();
        let currentHour = today.getHours();
        let bool = campaign.hours_enabled.indexOf(currentHour) !== -1;

        return bool;
    };

    const formatAccuracy = (coverage) => {
        if (!coverage) return '';
        let accuracy = '';
        switch (coverage) {
            case '2.00':
                accuracy = 'high';
                break;
            case '1.00':
                accuracy = 'mid';
                break;
            case '0.00':
                accuracy = 'low';
                break;
            default:
                break;
        }

        return t(
            `sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.${accuracy}`
        );
    };

    const providerSelected = (idProvider) => {
        switch (idProvider) {
            case '1':
                return 'Scopesi';
            case '2':
                return 'Cinnecta';
            case '3':
                return 'Onemata';
            default:
                return '';
        }
    };

    return (
        <div className="block px-2 w-full">
            <div className="font-light text-xs uppercase">
                {t('sections.dashboard-individual.campaign-brief')}
            </div>
            <div className="flex flex-nowrap md:flex-wrap lg:flex-nowrap justify-between divide-dotted divide-x-2 lg:space-x-4">
                <ul className="w-1/4 md:w-3/6 lg:w-1/4 text-gray-400 text-xs font-light space-y-1">
                    <li className="mt-4 text-xs uppercase font-medium text-black">
                        <FontAwesomeIcon
                            icon={['fal', `user`]}
                            fixedWidth
                            className="text-blue-300"
                        />{' '}
                        {t(
                            'sections.campaigns.new-campaign.wizard-tab.audiences'
                        )}
                    </li>
                    {[
                        {
                            property: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.provider.provider-title'
                            ),
                            value: providerSelected(campaign.provider_audience),
                        },
                        {
                            property: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.gender.gender-title'
                            ),
                            value: formatGenders(campaign.scopesi_gender),
                        },
                        {
                            property: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.age.age-title'
                            ),
                            value: formatAge(campaign.scopesi_ages),
                        },
                        {
                            property: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.income.income-title'
                            ),
                            value: formatIncome(campaign.scopesi_nse),
                        },
                        {
                            property: t(
                                'sections.campaigns.new-campaign.wizard-audiences.demographics.accuracy.accuracy-title'
                            ),
                            value: formatAccuracy(campaign.scopesi_coverage),
                        },
                    ].map((audience, index) => (
                        <li key={index}>
                            <span
                                className={`font-semibold ${
                                    audience.value.length === 0 && 'hidden'
                                }`}>
                                {audience.property}:
                            </span>{' '}
                            {audience.value}
                        </li>
                    ))}
                </ul>
                {/* TO DO
                <ul className="mx-4 text-gray-400 text-xs font-light space-y-1">
                    <li className="mt-4 text-xs uppercase font-medium text-black"><FontAwesomeIcon icon={["fal", `sliders-v`]} fixedWidth className="text-blue-300" /> {t('sections.campaigns.new-campaign.wizard-basics.buyingRules.title')}</li>
                    {[].map((rule, index) =>
                        <li key={index}><span className="font-semibold">{rule.context}:</span> {rule.case}</li>
                    )}
                </ul> */}
                <ul className="w-1/4 md:w-3/6 lg:w-1/4 text-gray-400 text-xs font-light pl-2 space-y-1">
                    <li className="mt-4 text-xs uppercase font-medium text-black">
                        <FontAwesomeIcon
                            icon={['fal', `calendar-check`]}
                            fixedWidth
                            className="text-blue-300"
                        />{' '}
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.title'
                        )}
                    </li>
                    <li>
                        <span className="font-semibold">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.days'
                            )}
                            :
                        </span>{' '}
                        {campaign.days_enabled &&
                            formatDays(campaign.days_enabled)}
                    </li>
                    <li>
                        <span className="font-semibold">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.timezone'
                            )}
                            :
                        </span>{' '}
                        {timezone !== '' && `GMT${timezone.gmt}`}
                    </li>
                    <li>
                        <span className="font-semibold whitespace-nowrap">
                            {t(
                                'sections.campaigns.new-campaign.wizard-review.blocks.basics.period'
                            )}
                            :
                        </span>
                        <br /> {startCampaign} - {endCampaign}{' '}
                    </li>
                </ul>
                <HoursList
                    hoursFormatted={hoursFormatted}
                    hoursEnabled={campaign.hours_enabled}
                    isCampaignCard={false}
                    campaignDays={campaign.days_enabled}
                />
                <ul className="w-1/4 md:w-3/6 lg:w-1/4 text-gray-400 text-xs font-light pl-2 space-y-1">
                    <li className="mt-4 text-xs uppercase font-medium text-black">
                        <FontAwesomeIcon
                            icon={['fal', `shipping-fast`]}
                            fixedWidth
                            className="text-blue-300"
                        />{' '}
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.pacing'
                        )}
                    </li>
                    <li>
                        {
                            <span>
                                {campaign.asap
                                    ? t(
                                          'sections.campaigns.new-campaign.wizard-basics.timing.ASAP'
                                      )
                                    : t(
                                          'sections.campaigns.new-campaign.wizard-basics.timing.evenly'
                                      )}
                            </span>
                        }{' '}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CampaignBrief;

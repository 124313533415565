import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bar from '../Bar/Bar';
import Item from '../Bar/Item/Item';

const Screens = (props) => {
    const { isActive, selectedScreensLocal } = props;
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);

    return (
        <Bar
            isActive={isActive}
            title={t(
                'sections.campaigns.new-campaign.wizard-briefBar.screens.title'
            )}>
            <Item
                first
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.screens.selected-screens'
                )}>
                {selectedScreensLocal.length}
            </Item>
        </Bar>
    );
};

export default Screens;

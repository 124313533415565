import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getTotalCampaignsByStatuses,
    formatNumber,
    getProperCurrency,
} from '../../../utils/functions';
import ProgressBar from '../../Progress/ProgressBar';
import CurrencySelector from '../CurrencySelector/CurrencySelector';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../../containers/UseAuth';
//TO DO
//const accumulatedImpressions = "2,590";
const amountCampaignsNotBuying = 1;
//const dailyCapAmount = "38,000";

const DashboardStats = (props) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const currentPeriodName = useSelector(
        (state) => state.extra.currentPeriodName
    );
    const dashboard = useSelector((state) => state.dashboard);
    const campaignStats = dashboard.campaignStats; // campaign stats no tiene el CPI
    const currenciesOfStats = dashboard.campaignStats.currenciesOfStats;
    const [selectedCurrency, setSelectedCurrency] = useState({
        symbol: '',
        short_name: '',
    });
    const campaignsSelector = useSelector((state) => state.campaigns);
    const statuses = campaignsSelector.statuses
        ? campaignsSelector.statuses
        : [];
    const totalCampaigns = getTotalCampaignsByStatuses(statuses);
    const [currentData, setCurrentData] = useState({
        impressions: '-',
        impacts: '-',
        amountActiveCampaigns: '-',
        spent: '-',
        overBudget: 0,
    });

    useEffect(() => {
        if (
            selectedCurrency.symbol !== '' &&
            selectedCurrency.short_name !== ''
        ) {
            const currencyData = currenciesOfStats.find(
                (currencyData) =>
                    currencyData.currency.id === selectedCurrency.id
            );
            if (currencyData) {
                setCurrentData(currencyData.stats);
            } else {
                setCurrentData({ symbol: '', short_name: '' });
            }
        }
    }, [selectedCurrency, currenciesOfStats]);

    useEffect(() => {
        if (dashboard.selectedCurrency)
            setSelectedCurrency(dashboard.selectedCurrency);
    }, [dashboard.selectedCurrency]);

    return (
        <div className="flex col-span-2 w-full gap-1">
            <div className="module w-full">
                <div className="font-light text-xs">
                    <span className="uppercase">
                        {currentPeriodName} {t('sections.dashboard.spent')}{' '}
                    </span>
                    <span>
                        <ReactTooltip
                            effect="solid"
                            className="w-48 p-2"
                            id="currentPeriodName"
                            aria-haspopup="true">
                            {t('sections.dashboard.spent-info')}
                        </ReactTooltip>
                        <a data-tip data-for="currentPeriodName">
                            <FontAwesomeIcon
                                icon={['fad', 'info-circle']}
                                className="text-gray-700 text-sm"
                            />
                        </a>{' '}
                        <br />
                    </span>
                </div>
                <div className="font-semibold text-xl">
                    <span className="text-xs font-thin text-gray-500">
                        <CurrencySelector />
                    </span>
                    {formatNumber(
                        props.totals?.spent,
                        'rounded',
                        props.totals?.currency
                    )}
                    <FontAwesomeIcon
                        icon={['fal', 'circle-notch']}
                        size="2x"
                        className={`${
                            dashboard.isSilentLoading
                                ? 'absolute -right-2 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                : 'hidden'
                        }`}
                    />
                </div>
                <div className="currentStatus flex justify-between">
                    <div
                        className="mt-2 text-gray-400"
                        style={{ fontSize: '50%' }}>
                        {formatNumber(currentData.overBudget, 'rounded')}%{' '}
                        {t('common.modules.todaySpentDailyCapBudgetPercent')}
                    </div>
                    <div
                        className="mt-2 text-gray-400"
                        style={{ fontSize: '50%' }}>
                        <span
                            style={{ fontSize: '40%' }}
                            className="text-xs font-thin text-gray-500"></span>
                    </div>
                </div>
                <ProgressBar
                    progress={currentData.overBudget}
                    isTopModule={true}
                    isActive={true}
                />
            </div>
            <div className="module w-full">
                <div className="font-light text-xs">
                    <span className="uppercase">
                        {t('common.menu.activeCampaigns')}{' '}
                    </span>
                    <span>
                        <ReactTooltip
                            effect="solid"
                            className="w-48 p-2"
                            id="amountActiveCampaigns"
                            aria-haspopup="true">
                            {t('sections.dashboard.active-campaign-info')}
                        </ReactTooltip>
                        <a data-tip data-for="amountActiveCampaigns">
                            <FontAwesomeIcon
                                icon={['fad', 'info-circle']}
                                className="text-gray-700 text-sm"
                            />
                        </a>{' '}
                        <br />
                    </span>
                </div>
                <span className="font-semibold text-2xl">
                    {currentData.amountActiveCampaigns}
                </span>
                <FontAwesomeIcon
                    icon={['fal', 'circle-notch']}
                    size="2x"
                    className={`${
                        dashboard.isSilentLoading
                            ? 'absolute -right-1 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                            : 'hidden'
                    }`}
                />
                {/* <div className="font-semibold text-2xl">{currentActiveCampaigns}<span className="font-thin text-gray-500">/{campaignStats.campaigns.length}</span></div> */}
                <div
                    className={`currentStatus flex justify-between ${
                        amountCampaignsNotBuying === 0 && 'hidden'
                    }`}>
                    <div
                        className="mt-2 text-gray-400"
                        style={{ fontSize: '50%' }}>
                        {amountCampaignsNotBuying}{' '}
                        {t('common.modules.activeCampaignNotBuying')}. <br />
                        {t('common.modules.activeCampaignsHistoric-1')}{' '}
                        {totalCampaigns}{' '}
                        {t('common.modules.activeCampaignsHistoric-2')}.
                    </div>
                </div>
            </div>
            {props.seeImpressions && (
                <div className="module w-full">
                    <div className="font-light text-xs">
                        <span className="uppercase">
                            {currentPeriodName}{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.dailyCap.impressions-label'
                            )}{' '}
                        </span>
                        <span>
                            <ReactTooltip
                                effect="solid"
                                className="w-48 p-2"
                                id="impressions"
                                aria-haspopup="true">
                                {t('sections.dashboard.impressions-info')}
                            </ReactTooltip>
                            <a data-tip data-for="impressions">
                                <FontAwesomeIcon
                                    icon={['fad', 'info-circle']}
                                    className="text-gray-700 text-sm"
                                />
                            </a>{' '}
                            <br />
                        </span>
                        <FontAwesomeIcon
                            icon={['fal', 'circle-notch']}
                            size="2x"
                            className={`${
                                dashboard.isSilentLoading
                                    ? 'absolute -right-0 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                    : 'hidden'
                            } `}
                        />
                    </div>
                    <div className="font-semibold text-2xl">
                        {currentData.impressions}
                    </div>
                    <div className="currentStatus flex justify-between">
                        <div
                            className="mt-2 text-gray-400"
                            style={{ fontSize: '50%' }}>
                            {t('sections.dashboard.accumulated-impressions')}.
                        </div>
                        <div className="mt-2" style={{ fontSize: '50%' }}></div>
                    </div>
                </div>
            )}
            {props.totals?.impacts > 0 ? (
                <div className="module w-full">
                    <div className="font-light text-xs">
                        <span className="uppercase">
                            {currentPeriodName}{' '}
                            {t('sections.dashboard.impacts')}
                        </span>{' '}
                        <span>
                            <ReactTooltip
                                effect="solid"
                                className="w-48 p-2"
                                id="impacts"
                                aria-haspopup="true">
                                {t('sections.dashboard.impacts-info')}
                            </ReactTooltip>
                            <a data-tip data-for="impacts">
                                <FontAwesomeIcon
                                    icon={['fad', 'info-circle']}
                                    className="text-gray-700 text-sm"
                                />
                            </a>{' '}
                            <br />
                        </span>
                        <FontAwesomeIcon
                            icon={['fal', 'circle-notch']}
                            size="2x"
                            className={`${
                                dashboard.isSilentLoading
                                    ? 'absolute -right-0 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                    : 'hidden'
                            } `}
                        />
                    </div>
                    <div className="font-semibold text-2xl">
                        {formatNumber(props.totals?.impacts, 'rounded')}
                    </div>
                    <div className="currentStatus flex justify-between">
                        <div
                            className="mt-2 text-gray-400"
                            style={{ fontSize: '50%' }}>
                            {t(
                                'sections.campaigns.new-campaign.wizard-audiences.forecast.cost-per-impact'
                            )}
                            .
                        </div>
                        <div
                            className="mt-2 text-blue-400"
                            style={{ fontSize: '50%' }}>
                            <span
                                style={{ fontSize: '40%' }}
                                className="text-xs font-thin text-gray-500">
                                {selectedCurrency.short_name +
                                    ' ' +
                                    selectedCurrency.symbol +
                                    ' '}
                            </span>
                            {/* {parseInt(currentData.spent) >= 0 && parseInt(currentData.impacts) > 0 ? currentData.costPerImpacts : "-"} */}

                            {parseInt(currentData.spent) >= 0 &&
                            parseInt(currentData.impacts) > 0
                                ? formatNumber(
                                      props.totals?.spent /
                                          props.totals?.impacts,
                                      'cpi'
                                  )
                                : '-'}
                        </div>{' '}
                        {/* CampaignStats don't have CPI so we calculate it*/}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DashboardStats;

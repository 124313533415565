import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
    formatNumber,
    formatScreenLocation,
    screenLat,
    screenLon,
} from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { useService } from '../../containers/UseService';
import { useAuth } from '../../containers/UseAuth';
import { useTranslation } from 'react-i18next';
import Hint from '../Tools/Hint';
import BarChart from '../Campaign/WizardScreens/BarChart/BarChart';
import LinkButton from '../Buttons/LinkButton';
import { newCampaignActions } from '../../store';
import PercentageGraph from '../Campaign/WizardScreens/PercentageGraph/PercentageGraph';

const ScreenAudiences = ({ data, screensWithCpm }) => {
    const { t } = useTranslation();
    const [daySelected, setDaySelected] = useState({});
    const service = useService();
    const newCampaign = useSelector((state) => state.newCampaign);
    const campaignTimezone = newCampaign.timing.selectedTimezone.gmt || 0;
    const selectedCurrencyShortName = newCampaign?.selectedCurrency?.short_name;
    const audienceProvider = newCampaign?.demographics?.id;
    const auth = useAuth();
    const userId = auth.session.user.id;
    const isAdmin = auth.isAdmin();
    const isSupervising = auth.isSupervising();
    const userMargin = auth.session.user.rtb_margin;
    const dispatch = useDispatch();
    const showInMap = (screen) => {
        dispatch(
            newCampaignActions.setCurrentPositionMap({
                lat: screen.geo.lat,
                lng: screen.geo.lon,
                zoom: 19,
            })
        );
    };

    const [screenReports, setScreenReports] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await service.getScreensReports(
                    newCampaign?.demographics?.id || 'onemata',
                    userId,
                    campaignTimezone,
                    selectedCurrencyShortName,
                    data?.id
                );
                setScreenReports(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [data.id, audienceProvider]);
    const daysOfScreen =
        screenReports &&
        Object.keys(screenReports).length > 0 &&
        data &&
        screenReports[data?.id] &&
        Object.keys(data).length
            ? Object.keys(screenReports[data?.id]['USD']?.days)
            : [];

    let days = daysOfScreen?.map((day) => {
        return {
            value: daysOfScreen.indexOf(day),
            label: t(`common.days.abbreviated-days.${day}`),
        };
    });

    useEffect(() => {
        if (!daySelected.value && daysOfScreen?.length > 0) {
            setDaySelected({
                value: '0',
                day: daysOfScreen[0],
            });
        }
    }, [daysOfScreen]);

    const handleSelect = (e) => {
        setDaySelected({
            value: e.target.value,
            day: daysOfScreen[e.target.value],
        });
    };

    return Object.keys(data).length && screensWithCpm ? (
        <div className="flex flex-col gap-10 relative mt-6">
            {isLoading && (
                <div className="w-80 mx-auto  right-5 top-32 flex items-center">
                    <FontAwesomeIcon
                        icon={['fal', 'circle-notch']}
                        size="2x"
                        className="h-4 animate-spin text-blue-400"
                    />
                    <span className="text-sm text-gray-400">
                        {t('common.words.loading')}...
                    </span>
                </div>
            )}
            {screenReports && screenReports[data.id] && !isLoading && (
                <div>
                    <div className="w-ful mx-auto  bottom-12">
                        <Hint
                            title={t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.average-impacts-title'
                            )}
                            type="info"
                            text={
                                daySelected.day
                                    ? `${t(
                                          'sections.campaigns.new-campaign.wizard-basics.timing.average-impacts-helper'
                                      )}: ${data.name}`
                                    : t(
                                          'sections.campaigns.new-campaign.wizard-basics.timing.average-days-helper'
                                      )
                            }
                            className="absolute right-3 top-4"
                            dataFor="expanded-screen"
                        />
                        {screenReports[data.id]['USD']?.days[
                            daySelected.day
                        ] && (
                            <div className="block">
                                <span className="text-sm font-bold text-gray-700">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.timing.average-impacts'
                                    )}
                                    :
                                </span>{' '}
                                <span>
                                    {t(
                                        `sections.campaigns.new-campaign.wizard-basics.timing.${daySelected?.day?.toLowerCase()}`
                                    )}
                                </span>
                                <h5 className="text-gray-600">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.timing.timezone'
                                    )}
                                    :{' '}
                                    <span className="text-gray-400 text-xs">
                                        GMT {campaignTimezone}
                                    </span>
                                </h5>
                                <h5 className="text-gray-600">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.audience-provider.title'
                                    )}
                                    :{' '}
                                    <span className="text-gray-400 text-sm">
                                        {audienceProvider}
                                    </span>
                                </h5>
                                {screenReports && (
                                    <>
                                        <div className="mt-5">
                                            <BarChart
                                                data={
                                                    screenReports[data.id][
                                                        'USD'
                                                    ]?.days[daySelected.day]
                                                }
                                                type="impacts"
                                            />
                                        </div>
                                        {(isAdmin || isSupervising) && (
                                            <div className="mt-5">
                                                <BarChart
                                                    data={
                                                        screenReports[data.id][
                                                            'USD'
                                                        ]?.days[daySelected.day]
                                                    }
                                                    type="requests"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        <input
                            type="range"
                            min={days[0]?.value}
                            max={days[days.length - 1]?.value}
                            value={daySelected.value}
                            onChange={handleSelect}
                            step="1"
                            className="slider w-full h-2 bg-gray-100 appearance-none rounded-full"
                        />
                        <div className="grid grid-cols-7 mt-4 text-center w-full absolute">
                            {daysOfScreen &&
                                days.map(({ value, label }) => {
                                    return (
                                        <div
                                            key={value}
                                            className="slider-step">
                                            {label}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    {screenReports?.[data.id]?.USD?.reqSaturationPercentage !==
                        undefined &&
                    (isAdmin || isSupervising) ? (
                        <div
                            style={{
                                marginTop: '50px',
                                marginBottom: '-50px',
                            }}>
                            {' '}
                            <PercentageGraph
                                percentaje={
                                    screenReports[data.id]['USD']
                                        .reqSaturationPercentage
                                }></PercentageGraph>
                            <span className="text-sm font-bold text-gray-700">
                                {`${t(
                                    'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.campaigns-using-screen'
                                )} 
                                    `}
                            </span>
                            <span>
                                {
                                    screenReports[data.id]['USD']
                                        .campaignsUsingScreen
                                }
                            </span>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            )}
            <div className="relative flex flex-col gap-2 mt-8">
                {screensWithCpm.find((screen) => screen.screen_id === data.id)
                    ?.screenType && (
                    <div className="relative flex flex-col gap-2">
                        <div>
                            <span className="text-sm font-bold text-gray-700">
                                {t(
                                    'sections.management.section.pricing.tierList.columns.screenType'
                                )}
                                :{' '}
                            </span>
                            <span>
                                {
                                    screensWithCpm.find(
                                        (screen) => screen.screen_id === data.id
                                    )?.screenType
                                }
                            </span>
                        </div>
                        <div>
                            <span className="text-sm font-bold text-gray-700">
                                {`${t(
                                    'sections.management.section.pricing.tierList.columns.tierName'
                                )} id`}
                                :{' '}
                            </span>
                            <span>
                                {
                                    screensWithCpm.find(
                                        (screen) => screen.screen_id === data.id
                                    )?.tierId
                                }
                            </span>
                        </div>
                    </div>
                )}
                <div>
                    <span className="text-sm font-bold text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-screens.screens.table-screenProvider'
                        )}
                        :{' '}
                    </span>
                    <span>{data.publisher.name}</span>
                </div>
                <div>
                    <span className="text-sm font-bold text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.location'
                        )}
                        :{' '}
                    </span>
                    <span>{formatScreenLocation(data)}</span>
                    <br />
                    <LinkButton onClick={() => showInMap(data)}>
                        {' '}
                        {screenLat(data)}, {screenLon(data)}{' '}
                    </LinkButton>
                </div>
                {/* <div>
                 <span className="text-sm font-bold text-gray-700">
                     {t(
                         'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.lat'
                     )}
                     :{' '}
                 </span>
                 <span>{screenLat(data)}</span>
             </div>
             <div>
                 <span className="text-sm font-bold text-gray-700">
                     {t(
                         'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.lon'
                     )}
                     :{' '}
                 </span>
                 <span>{screenLon(data)}</span>
             </div> */}
                {data.deals.prices[0].price && isAdmin && (
                    <div>
                        <span className="text-sm font-bold text-gray-700">
                            {t('common.components.ReportsTable.spot-cost')}:{' '}
                        </span>
                        <span>
                            {formatNumber(
                                data.deals.prices[0].price /
                                    1000 /
                                    (1 - userMargin),
                                'properCurrency',
                                null,
                                {
                                    currency: selectedCurrencyShortName,
                                }
                            )}
                        </span>
                    </div>
                )}
                <div>
                    <span className="text-sm font-bold text-gray-700">
                        {t(
                            t(
                                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenStatus'
                            )
                        )}
                        :{' '}
                    </span>
                    <span>{data.status}</span>
                </div>
                <div>
                    <span className="text-sm font-bold text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-screens.screens.table-screenSize'
                        )}
                        :{' '}
                    </span>
                    <span>{data?.size}</span>
                </div>
                {screenReports && screenReports[data.id] && (
                    <div>
                        <span className="text-sm font-bold text-gray-700">
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.screens.weekly_impacts'
                            )}
                            :{' '}
                        </span>
                        <span>
                            {formatNumber(
                                screenReports[data.id]['USD']?.weekly_impacts,
                                'rounded'
                            )}
                        </span>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <div></div>
    );
};

export default ScreenAudiences;

import React from 'react';
import { useTranslation } from 'react-i18next';

const InputRadio = (props) => {
    const { label, value, onChange, name } = props;
    const { t } = useTranslation();

    return (
        <div className="max-w-sm mx-auto sm:mx-0">
            <label htmlFor={name} className="text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="h-12">
                <div className="flex">
                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            className="form-radio"
                            name={name}
                            id={name}
                            value="1"
                            checked={!value}
                            onChange={onChange}
                        />
                        <span className="ml-2">{t('common.words.no')}</span>
                    </label>
                    <div className="ml-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                className="form-radio"
                                name={name}
                                value="2"
                                checked={value}
                                onChange={onChange}
                            />
                            <span className="ml-2">
                                {t('common.words.yes')}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputRadio;

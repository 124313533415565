import { useEffect, useState } from 'react';

/**
 * Return amount of spaces based on currencySymbol for tailwind.
 * @param {string} symbolCurrency - El símbolo de la moneda para el cual se desea obtener el valor de espacios.
 * @returns {string} El valor de espacios basado en la longitud del símbolo de moneda.
 */
function getSpacesPerCurrencySymbol(symbolCurrency) {
    const amountCharacters = symbolCurrency.length;
    let spaces; // Variable para almacenar el valor de espacios

    switch (amountCharacters) {
        case 1:
            spaces = 'pl-7';
            break;
        case 2:
            spaces = 'pl-8';
            break;
        case 3:
            spaces = 'pl-11';
            break;
        case 4:
            spaces = 'pl-14';
            break;
        default:
            spaces = 'pl-7';
            break;
    }

    return spaces;
}

/**
 * Custom hook to manage the space of the selected currency.
 * @param {object} selectedCurrency - The selected currency for which the space will be determined.
 * @returns {string} The space corresponding to the selected currency.
 */
function useCurrencySpace(selectedCurrencySymbol) {
    const [currencySpace, setCurrencySpace] = useState('pl-7');

    useEffect(() => {
        if (selectedCurrencySymbol) {
            const space = getSpacesPerCurrencySymbol(selectedCurrencySymbol);
            setCurrencySpace(space);
        }
    }, [selectedCurrencySymbol]);

    return currencySpace;
}

export default useCurrencySpace;

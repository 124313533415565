import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const Input = (props) => {
    const {
        children,
        label,
        id = '',
        value,
        onChange,
        onValidate,
        placeholder,
        errorMessage,
        submitted,
        type = 'text',
        step = '',
        min = '',
        containerClassName = '',
        autoComplete = '',
    } = props;
    const [isWriting, setIsWriting] = useState(true);
    const [isValid, setIsValid] = useState(true);

    const onBlur = async () => {
        setIsValid(await onValidate(value));
        setIsWriting(false);
    };

    const onFocus = () => {
        setIsWriting(true);
    };

    useEffect(() => {
        setIsWriting(true);
    }, [submitted]);

    return (
        <div className={` max-w-sm mx-auto sm:mx-0 ${containerClassName}`}>
            <label htmlFor={id} className="text-sm font-medium text-gray-700">
                {label} {children}
            </label>
            <div className="grid grid-cols-7">
                <input
                    autoComplete={autoComplete}
                    type={type}
                    step={step}
                    min={min}
                    value={value}
                    id={id}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className="focus:ring-0 focus:outline-none sm:block col-span-6  sm:w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder={placeholder}
                />
                <div
                    className={`flex justify-end sm:justify-center items-center pl-2 ${
                        isWriting ? 'invisible' : ''
                    }`}>
                    {isValid ? (
                        <FontAwesomeIcon
                            icon={['fas', 'check']}
                            fixedWidth
                            className="mr-1 text-green-500"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={['fas', 'times']}
                            fixedWidth
                            className="mr-1 text-red-500"
                        />
                    )}
                </div>
            </div>
            {!isValid && value !== '' && !isWriting && errorMessage && (
                <span className="text-red-500 text-xs italic">
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

export default Input;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Loading.module.css';

function Loading(params) {
    const isLoading = params.isLoading;
    return (
        <div
            className={`${
                isLoading === true ? 'flex' : 'hidden'
            } fixed z-50 justify-center items-center ${styles.container}`}>
            <FontAwesomeIcon
                icon={['fal', 'circle-notch']}
                size="3x"
                fixedWidth
                className={`p-2 mx-1 animate-spin text-blue-400`}
            />
        </div>
    );
}

export default Loading;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
    formatNumber,
    getCampaignDurationByPeriodValue,
    formatDateAccordingToBrowser,
} from '../../../../utils/functions';
import { useTranslation } from 'react-i18next';
import Block from './Block';
import Item from './Item';

const Basics = () => {
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const dailyCap = newCampaign.dailyCap;
    const selectedCurrency = newCampaign.selectedCurrency;
    const budget = newCampaign.budget.budget;
    const periodValue = newCampaign.timing.periodValue;
    const campaignDuration = getCampaignDurationByPeriodValue(periodValue);
    const validCampaignDuration =
        campaignDuration !== -1 && campaignDuration !== 0;
    const [dailyCapInfo, setDailyCapInfo] = useState({
        symbol: '',
        typeBudget: '',
        amoungBuget: '',
    });

    useEffect(() => {
        if (dailyCap.isActive)
            if (!dailyCap.isDistribution)
                if (dailyCap.isBudget) {
                    const validBudget =
                        dailyCap.budget !== 0 && dailyCap.budget !== '';
                    setDailyCapInfo({
                        symbol: validBudget ? selectedCurrency.symbol : '',
                        typeBudget: t(
                            'sections.campaigns.new-campaign.wizard-review.blocks.basics.budget'
                        ),
                        amountBudget: validBudget
                            ? formatNumber(dailyCap.budget, 'currency')
                            : '-',
                    });
                } else {
                    const validImpressions =
                        dailyCap.impressions !== 0 &&
                        dailyCap.impressions !== '';
                    setDailyCapInfo({
                        symbol: validImpressions
                            ? t(
                                  'sections.campaigns.new-campaign.wizard-briefBar.basics.abbreviated-impressions'
                              )
                            : '',
                        typeBudget: t(
                            'sections.campaigns.new-campaign.wizard-review.blocks.basics.impressions'
                        ),
                        amountBudget: validImpressions
                            ? formatNumber(dailyCap.impressions, 'currency')
                            : '-',
                    });
                }
            else
                setDailyCapInfo({
                    symbol: '',
                    typeBudget: '',
                    amountBudget: t(
                        'sections.campaigns.new-campaign.wizard-briefBar.basics.automatic'
                    ),
                });
        else
            setDailyCapInfo({
                symbol: '',
                typeBudget: '',
                amountBudget: t(
                    'sections.campaigns.new-campaign.wizard-briefBar.basics.unlimited'
                ),
            });
    }, [dailyCap]);

    return (
        <Block
            icon={
                <FontAwesomeIcon
                    icon={['fal', 'pen']}
                    fixedWidth
                    size="lg"
                    className="m-1 text-blue-300"
                />
            }
            title={t('sections.campaigns.new-campaign.wizard-tab.basics')}>
            <Item
                title={`${t(
                    'sections.campaigns.new-campaign.wizard-review.blocks.basics.daily-cap'
                )} ${dailyCapInfo.typeBudget}`}>
                <span>
                    {dailyCapInfo.symbol !== '' ? (
                        <span className="text-xs font-light">
                            {dailyCapInfo.symbol}
                        </span>
                    ) : (
                        ''
                    )}{' '}
                    {dailyCapInfo.amountBudget}
                </span>
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-review.blocks.basics.goal-budget'
                )}>
                <span>
                    {
                        <span className="text-xs font-light">
                            {budget !== '' && budget !== 0
                                ? selectedCurrency.symbol
                                : ''}
                        </span>
                    }{' '}
                    {budget !== '' && budget !== 0
                        ? formatNumber(budget, 'currency')
                        : '-'}
                </span>
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-review.blocks.basics.campaign-duration'
                )}>
                {`${validCampaignDuration ? campaignDuration : '-'} ${
                    validCampaignDuration ? t('common.words.days') : ''
                }`}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-review.blocks.basics.period'
                )}>
                {`${
                    validCampaignDuration
                        ? `${formatDateAccordingToBrowser(
                              periodValue[0]
                          )} ~ ${formatDateAccordingToBrowser(periodValue[1])}`
                        : '-'
                }`}
            </Item>
        </Block>
    );
};

export default Basics;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../containers/UseAuth';
import { useService } from '../../../../../containers/UseService';

const CompanyInformation = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const auth = useAuth();
    const { parseRolToString } = useService();
    const translations =
        'sections.management.section.company.companyInformation.';
    const user = auth.session.user;

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12}`}>
            <span className="text-gray-600 text-xl font-light">
                {t(translations + 'title')}
            </span>
            <h1 className="text-xl font-light mt-2">
                {t(translations + 'personInCharge')}
            </h1>
            <ul className="text-sm font-light pl-2">
                <li>
                    <span className="font-semibold">
                        {t(translations + 'name')}:
                    </span>{' '}
                    {user.name}
                </li>
                <li>
                    <span className="font-semibold">
                        {t(translations + 'email')}:
                    </span>{' '}
                    {user.email}
                </li>
                <li>
                    <span className="font-semibold">
                        {t(translations + 'phoneNumber')}:
                    </span>{' '}
                    {user.phone_number}
                </li>
                <li>
                    <span className="font-semibold">
                        {t(translations + 'role')}:
                    </span>{' '}
                    {parseRolToString(user.role)}
                </li>
            </ul>

            <h3 className="text-xl font-light mt-2">
                {t(translations + 'company')}
            </h3>
            <ul className="text-sm font-light pl-2">
                <li>
                    <span className="font-semibold">
                        {t(translations + 'businessName')}:
                    </span>{' '}
                    {user.business_name}
                </li>
                <li>
                    <span className="font-semibold">
                        {t(translations + 'taxId')}:
                    </span>{' '}
                    {user.legal_id}
                </li>
                <li>
                    <span className="font-semibold">
                        {t(translations + 'address')}:
                    </span>{' '}
                    {user.address}
                </li>
            </ul>
        </div>
    );
};

export default CompanyInformation;

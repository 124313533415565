import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { newCampaignActions } from '../../../../store';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber, lastTwoDecimal } from '../../../../utils/functions';
import DownloadForecastToCsv from '../../WizardReview/Blocks/DownloadForecastToCsv';

const TableComp = ({ adminOrSupervising }) => {
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const metrics = newCampaign.forecast;
    const schedules = metrics.schedules;
    const [sched, setSched] = useState([]);
    const [hideColumn, setHideColumn] = useState(false);
    const { tierList, selectedScreensLocal } = useSelector(
        (state) => state.screens
    );

    useEffect(() => {
        setSched(schedules);
    }, [schedules]);

    const getScreenName = (row) => {
        const screenUsed = metrics.screensUsed.find(
            (screen) => screen.id === row.poiId
        );
        return screenUsed ? screenUsed.name : '';
    };

    const columns = useMemo(
        () => [
            {
                name: 'ID',
                selector: (row) => row.poiId,
                sortable: true,
                omit: hideColumn,
                maxWidth: '8%',
                textAlign: 'left',
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.screen-name'
                ),
                selector: (row) => getScreenName(row),
                cell: (row) => {
                    const screenName = getScreenName(row);
                    return <div>{screenName}</div>;
                },
                sortable: true,
                minWidth: '260px',
                omit: hideColumn,
                textAlign: 'left',
            },
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.screenType'
                ),
                selector: (row) => {
                    let screenForRow = selectedScreensLocal.find(
                        (screen) => screen.id == row.poiId
                    );
                    return (
                        <div className="truncate">
                            {row.poiId
                                ? tierList[
                                      screenForRow?.tierId &&
                                          screenForRow.tierId
                                  ]
                                : ''}
                        </div>
                    );
                },
                sortable: true,
                omit: hideColumn,
                minWidth: '142px',
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.date'
                ),
                selector: (row) => row.date,
                sortable: true,
                omit: hideColumn,
                maxWidth: '8%',
                textAlign: 'left',
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.day'
                ),
                selector: (row) => row.weekDay,
                sortable: true,
                omit: hideColumn,
                maxWidth: '10px',
                textAlign: 'left',
                sortFunction: (a, b) => {
                    const daysOfWeek = [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                        'Sunday',
                    ];
                    return (
                        daysOfWeek.indexOf(a.weekDay) -
                        daysOfWeek.indexOf(b.weekDay)
                    );
                },
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.hour'
                ),
                selector: (row) => row.hour,
                sortable: true,
                omit: hideColumn,
                maxWidth: '4px ',
                textAlign: 'left',
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.impressions'
                ),
                selector: (row) => row.impressions,
                cell: (row) => formatNumber(lastTwoDecimal(row.impressions)),
                sortable: true,
                omit: hideColumn,
                minWidth: '122px',
                right: true,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.impacts'
                ),
                selector: (row) =>
                    'totalImpacts' in row ? row.totalImpacts : 0,
                cell: (row) =>
                    'totalImpacts' in row ? formatNumber(row.totalImpacts) : 0,
                sortable: true,
                textAlign: 'left',
                minWidth: '122px',
                omit: hideColumn,
                right: true,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.targeted-universe'
                ),
                selector: (row) =>
                    'targetUniverse' in row
                        ? formatNumber(row.targetUniverse, 'withcommas')
                        : 0,
                sortable: true,
                minWidth: '122px',
                omit: hideColumn,
                right: true,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.coverage-abs'
                ),
                selector: (row) =>
                    'totalCoverageAbs' in row ? row.totalCoverageAbs : 0,
                cell: (row) =>
                    'totalCoverageAbs' in row
                        ? formatNumber(lastTwoDecimal(row.totalCoverageAbs))
                        : 0,
                sortable: true,
                minWidth: '122px',
                omit: hideColumn,
                right: true,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.coverage-rel'
                ),
                selector: (row) =>
                    'totalCoverageRel' in row ? row.totalCoverageRel : 0,
                cell: (row) =>
                    'totalCoverageRel' in row ? row.totalCoverageRel : 0,
                sortable: true,
                minWidth: '122px',
                omit: hideColumn,
                right: true,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.costs'
                ),
                selector: (row) => row.costs,
                cell: (row) =>
                    `${newCampaign.selectedCurrency.symbol}${formatNumber(
                        row.costs,
                        'currency'
                    )}`,
                sortable: true,
                right: true,
                maxWidth: '8%',
                omit: hideColumn,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.cpm'
                ),
                selector: (row) =>
                    row.cpm
                        ? `${newCampaign.selectedCurrency.symbol}${formatNumber(
                              Math.round(row.cpm),
                              'withcommas'
                          )}`
                        : `${newCampaign.selectedCurrency.symbol}0`,
                sortable: true,
                right: true,
                maxWidth: '8%',
                omit: hideColumn,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.cpi'
                ),
                selector: (row) =>
                    row.cpi
                        ? `${newCampaign.selectedCurrency.symbol}${formatNumber(
                              lastTwoDecimal(row.cpi)
                          )}`
                        : `${newCampaign.selectedCurrency.symbol}0`,
                sortable: true,
                right: true,
                maxWidth: '10px ',
                omit: hideColumn,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.frecuency'
                ),
                selector: (row) =>
                    row.totalFrequency
                        ? formatNumber(row.totalFrequency, 'currency')
                        : 0,
                sortable: true,
                right: true,
                minWidth: '122px',
                omit: hideColumn,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.data-table.grp'
                ),
                selector: (row) =>
                    'grp' in row ? formatNumber(lastTwoDecimal(row.grp)) : 0,
                sortable: true,
                omit: hideColumn,
            },
            {
                name: 'cps',
                selector: (row) =>
                    'cps' in row ? formatNumber(lastTwoDecimal(row.cps)) : 0,
                sortable: true,
                omit: hideColumn,
            },
            {
                name: 'spm',
                selector: (row) =>
                    'spm' in row ? formatNumber(lastTwoDecimal(row.spm)) : 0,
                sortable: true,
                omit: hideColumn,
            },
        ],
        [hideColumn, tierList, metrics.screensUsed]
    );

    return (
        <>
            {adminOrSupervising && (
                <div className="body rounded-sm mt-14">
                    {sched?.length ? (
                        <div class="w-full ml-auto flex justify-end mb-2">
                            <DownloadForecastToCsv
                                selectedScreensLocal={selectedScreensLocal}
                                forecastData={sched}
                                tierList={tierList}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {/* Documentación: https://www.npmjs.com/package/react-data-table-component */}
                    <DataTable
                        columns={columns}
                        fixedHeaderScrollHeight={'70vh'}
                        subHeader={false}
                        pagination
                        paginationRowsPerPageOptions={[10, 30, 60, 120, 300]}
                        paginationPerPage={60}
                        selectableRowsHighlight={true}
                        //sortFunction={customSort}
                        data={sched}
                        noDataComponent={t(
                            'common.components.react-data-table.no-data'
                        )}
                        keyField={'id'}
                        //paginationComponentOptions={paginationComponentOptions}
                        progressComponent={
                            <>
                                <FontAwesomeIcon
                                    icon={['fas', 'circle-notch']}
                                    className="text-blue-400 animate-spin mx-2"
                                />
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.loading-screens'
                                )}
                            </>
                        }
                    />
                </div>
            )}
        </>
    );
};

export default TableComp;

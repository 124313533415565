import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../containers/UseAuth';
import { screensActions } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';

const ScreenPublisher = ({
    publisher,
    onCheck,
    onUnCheck,
    index,
    isChecked,
}) => {
    const handleChange = (input) => {
        if (input.checked) {
            onCheck(publisher);
        } else {
            onUnCheck(publisher);
        }
    };

    return (
        <div className="flex items-center justify-between my-2">
            <div className="flex items-center">
                <input
                    defaultChecked={isChecked}
                    onChange={(e) => handleChange(e.target)}
                    name={`screen-publisher-${index}`}
                    id={`screen-publisher-${index}`}
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label
                    htmlFor={`screen-publisher-${index}`}
                    className="ml-2 block text-sm text-gray-600">
                    {publisher.name}
                </label>
            </div>
            <div className="text-xs text-gray-400 mr-2">
                {publisher.screens_count}
            </div>
        </div>
    );
};

const ScreenPublisherList = ({
    filteredScreensLocal,
    screensFilteredFromScreensWizard,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const auth = useAuth();
    const { selectedPublishers, selectedScreenTypes, isFilterByAudiences } =
        useSelector((state) => state.screens);
    const [publishers, setPublishers] = useState([]);

    const getQuantityPublisher = (screenToCount) => {
        const publishers = [];
        screenToCount.forEach((screen) => {
            const isTestScreen = screen.name.toLowerCase().includes('test');

            const index = publishers.findIndex(
                (publisher) => publisher?.name === screen.publisher?.name
            );

            if (auth.isAdmin()) {
                if (index === -1) {
                    publishers.push({ ...screen.publisher, screens_count: 1 });
                } else {
                    publishers[index].screens_count++;
                }
            } else {
                if (!isTestScreen) {
                    if (index === -1) {
                        publishers.push({
                            ...screen.publisher,
                            screens_count: 1,
                        });
                    } else {
                        publishers[index].screens_count++;
                    }
                }
            }
        });

        // sorting publishers by name asc
        publishers.sort((a, b) => {
            let fa = a.name?.toLowerCase(),
                fb = b.name?.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        return publishers;
    };

    const resetPublishersByAudience = () => {
        // if every selectedPublisher has audience it only shows a list of publishers with audiences, else
        // it shows all the publishers
        const filteredByAudience = filteredScreensLocal.filter(
            (screen) => screen.audiences
        );
        const everyPublisherHasAudience = selectedPublishers.every(
            (publisher) =>
                filteredByAudience.some(
                    (screen) => screen.publisher?.name === publisher.name
                )
        );

        const publishers = getQuantityPublisher(
            isFilterByAudiences && everyPublisherHasAudience
                ? filteredByAudience
                : filteredScreensLocal
        );
        setPublishers(publishers);
    };

    useEffect(() => {
        //seting publisher depending on isFilterByAudiences as long as there aren't types and publishers selecteds
        if (
            selectedPublishers.length === 0 &&
            selectedScreenTypes.length === 0
        ) {
            const publishers = getQuantityPublisher(
                isFilterByAudiences
                    ? screensFilteredFromScreensWizard
                    : filteredScreensLocal
            );
            setPublishers(publishers);
        } else {
            resetPublishersByAudience();
        }
    }, [
        filteredScreensLocal,
        screensFilteredFromScreensWizard,
        isFilterByAudiences,
    ]);

    return (
        <div className="font-light pb-2 w-40">
            <div className="title font-light py-2 shadow-sm">
                {t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.screenProviders-label'
                )}
            </div>
            <div className='max-h-60 overflow-y-scroll overflow-hidden'>
                {publishers.map((publisher, index) => (
                    <ScreenPublisher
                        publisher={publisher}
                        onCheck={() =>
                            dispatch(screensActions.addPublisher(publisher))
                        }
                        onUnCheck={() =>
                            dispatch(screensActions.removePublisher(publisher))
                        }
                        key={publisher.id}
                        isChecked={selectedPublishers.some(
                            (selectedPublisher) =>
                                selectedPublisher.id === publisher.id
                        )}
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default ScreenPublisherList;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalViewCreative from './ModalViewCreative/ModalViewCreative';
import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import ModalFooter from '../../../Modal/ModalFooter/ModalFooter';
import CommonButton from '../../../Buttons/CommonButton';
import { notificationsActions } from '../../../../store';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import useDispatchNotification from '../../../../hooks/notifications/useDispatchNotification';

const UploadedCreatives = ({ creatives, sizesNeeded, service, dispatch }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    const [activeCreative, setActiveCreative] = useState(null);
    const [thisIsGoingToBeDeleted, setThisIsGoingToBeDeleted] = useState([]);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const toggleModalWarning = () => setShowModalWarning(!showModalWarning);
    const { successNotification, warningNotification } =
        useDispatchNotification();
    const [creativesLoading, setCreativesLoading] = useState({});
    const handleCancel = () => {
        toggleModalWarning();
    };
    const handleDelet = () => {
        let newCreativesLoading = {};
        thisIsGoingToBeDeleted.forEach((creative) => {
            newCreativesLoading[creative.id] = true;
        });
        setCreativesLoading(newCreativesLoading);
        toggleModalWarning();
        service
            .removeMultipleCreatives(thisIsGoingToBeDeleted)
            .then(({ withError, withoutError }) => {
                setCreativesLoading({});
                // if all are deleted the input is deleted
                if (creatives.length !== withoutError.length) {
                    let input = document.getElementById('all-creatives-input');
                    input.checked = false;
                }
                setThisIsGoingToBeDeleted([]);
                if (withError.length > 0) {
                    warningNotification(
                        t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.title'
                        ),
                        t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.notification-error'
                        )
                    );
                } else {
                    successNotification(
                        t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.title'
                        ),
                        t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.notification-success'
                        )
                    );
                }
            });
    };
    const deleteSelection = () => {
        if (thisIsGoingToBeDeleted.length == 0) return;
        toggleModalWarning();
    };

    const handleAction = (select, creative) => {
        const action = select.value;
        setCreativesLoading({ ...creativesLoading, [creative.id]: true });
        const actions = {
            duplicate: () => {
                service.duplicateCreative(creative).then((res) => {
                    setCreativesLoading({
                        ...creativesLoading,
                        [creative.id]: false,
                    });
                });
            },
            remove: () => {
                service.removeCreative(creative).then((res) => {
                    setCreativesLoading({
                        ...creativesLoading,
                        [creative.id]: false,
                    });
                });
            },
        };
        actions[action]();
        select.value = 'default';
    };

    const onClickCreative = (creative) => {
        setShowModal(true);
        setActiveCreative(creative);
    };

    const isImage = (creative) => {
        if ('extension' in creative) {
            const imageExtensions = ['gif', 'jpg', 'png', 'jpeg'];
            return imageExtensions.some(
                (extension) => extension === creative.extension
            );
        } else {
            return false;
        }
    };

    const handleChangeResolution = (creative, resolution) => {
        if (resolution.split('x').length === 2) {
            service.changeResolution(creative, resolution);
        }
    };

    const getIcon = (creative) => {
        const extension =
            'extension' in creative ? creative.extension : 'script';
        switch (extension) {
            case 'script':
                return ['fal', 'code'];
            case 'mp4':
            case 'webm':
                return ['fal', 'play-circle'];
            case 'png':
            case 'gif':
            case 'jpeg':
            case 'jpg':
                return ['fal', 'image'];
            case 'zip':
                return ['fab', 'html5'];
            case 'vast':
                return ['fad', 'video'];
            default:
                return ['fal', 'code'];
        }
    };

    const handleInputChange = (input, creative) => {
        if (input.checked) {
            setThisIsGoingToBeDeleted([...thisIsGoingToBeDeleted, creative]);
        } else {
            let creativeDeselected = thisIsGoingToBeDeleted.filter(
                (creativeItem) => creativeItem.id != creative.id
            );
            if (creativeDeselected) {
                setThisIsGoingToBeDeleted(creativeDeselected);
            }
        }
    };

    const handleAllInputsChange = (input) => {
        if (input.checked) {
            let toDelete = [];
            creatives.map((creative) => {
                toDelete.push(creative);
            });
            setThisIsGoingToBeDeleted(toDelete);
            // rellenar inputs
            let arr = document.getElementsByName('individual-creative-input');
            for (let i = 0; i < arr.length; i++) {
                arr[i].checked = true;
            }
        } else {
            setThisIsGoingToBeDeleted([]);
            let arr = document.getElementsByName('individual-creative-input');
            for (let i = 0; i < arr.length; i++) {
                arr[i].checked = false;
            }
        }
    };

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between items-center">
                <div className="min-w-2 title text-xl font-extralight">
                    {/* HERE WE HAVE TO PUT THE CHECKBOX AND THE BUTTON */}
                    {creatives.length !== 0 && (
                        <input
                            onChange={(e) => handleAllInputsChange(e.target)}
                            name={'all-creatives-input'}
                            id="all-creatives-input"
                            type="checkbox"
                            className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                        />
                    )}
                    {/* HERE WE HAVE TO PUT THE CHECKBOX AND THE BUTTON */}
                </div>
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.title'
                    )}
                </div>
                <div className="ml-2">
                    {creatives.length !== 0 && (
                        <button
                            onClick={deleteSelection}
                            className="bg-blue-400 hover:bg-blue-600 text-white mt-1 rounded-sm transition-colors duration-300 ease-in-out font-light text-sm p-2 py-1 px-1 lg:px-4 focus:outline-none">
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.remove-selected'
                            )}
                        </button>
                    )}
                </div>
            </div>
            <div className="header flex justify-between content-center">
                <div className="self-center w-24 ml-2">
                    {creatives.length === 0 && (
                        <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.info'
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="body rounded-sm overflow-y-auto">
                <ul className="my-2 py-2 block list-none divide-y divide-dotted">
                    {creatives.map((creative) => (
                        <li
                            key={creative.id}
                            className="flex justify-between py-1 pr-1 hover:bg-gray-50">
                            {/* Creative Info */}
                            <div className="flex justify-start items-center">
                                {/* HERE IS OUR INDIVIDUAL CHECKBOX  */}
                                <div className="self-center min-w-2">
                                    <input
                                        onChange={(e) =>
                                            handleInputChange(
                                                e.target,
                                                creative
                                            )
                                        }
                                        name={'individual-creative-input'}
                                        id={creative.id}
                                        type="checkbox"
                                        className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                                    />
                                </div>
                                {/* HERE IS OUR MEDIA TYPE AND INFO  */}
                                <div className="text-gray-500 flex px-2">
                                    <button
                                        onClick={() =>
                                            onClickCreative(creative)
                                        }
                                        className="w-16 mr-2">
                                        <div
                                            className={`px-4 object-cover w-full h-12 hover:bg-gray-100 rounded border-2 border-white shadow flex justify-center items-center`}>
                                            <FontAwesomeIcon
                                                icon={getIcon(creative)}
                                                size="2x"
                                            />
                                        </div>
                                    </button>
                                    {/* <img className="object-cover w-16 h-12 rounded border-2 border-white shadow" alt="title" src={creative.thumbnail_url} /> */}
                                    <div className="w-full overflow-hidden">
                                        <span
                                            className="rounded-sm px-1 text-xs bg-gray-100 text-gray-600 text-center uppercase"
                                            style={{ fontSize: '70%' }}>
                                            {'extension' in creative
                                                ? creative.extension
                                                : 'script'}
                                        </span>
                                        <br />
                                        <ReactTooltip
                                            effect="solid"
                                            backgroundColor="whitesmoke"
                                            textColor="black"
                                            className="min-w-48 p-2 shadow-lg font-semibold"
                                            id={`title-${creative.id}`}
                                            aria-haspopup="true">
                                            {creative.name} <br />
                                            <span className="font-light text-xs">
                                                {creative.original_filename}
                                            </span>
                                        </ReactTooltip>
                                        <div
                                            className="truncate text-xs w-24"
                                            data-tip
                                            data-for={`title-${creative.id}`}>
                                            {creative.name}
                                            <br />
                                        </div>
                                        <div
                                            className="truncate w-20"
                                            alt={creative.original_filename}
                                            style={{ fontSize: '60%' }}>
                                            {creative.original_filename}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Creative Actions */}
                            <div className="flex justify-end items-center">
                                <div
                                    className={`self-center ml-4  ${
                                        isImage(creative) && 'invisible'
                                    }`}>
                                    <>
                                        {creative.original_filename
                                            ?.toLowerCase()
                                            .includes('responsive') &&
                                        creative.extension?.toLowerCase() ==
                                            'zip' ? (
                                            <div className=" w-32 focus:ring-0 focus:outline-none py-2 pl-2 pr-8 border-none bg-transparent text-gray-500 text-xs border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.assign-size-responsive'
                                                )}
                                            </div>
                                        ) : (
                                            <select
                                                id="sizes"
                                                value={
                                                    creative.width &&
                                                    creative.height
                                                        ? `${creative.width}x${creative.height}`
                                                        : 'placeholder'
                                                }
                                                name="sizes"
                                                onChange={(e) =>
                                                    handleChangeResolution(
                                                        creative,
                                                        e.target.value
                                                    )
                                                }
                                                className="w-32 focus:ring-0 focus:outline-none h-full py-2 pl-2 pr-8 border-none bg-transparent text-gray-500 text-xs border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer">
                                                <option value="placeholder">
                                                    {t(
                                                        'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.assign-size-placeholder'
                                                    )}
                                                </option>

                                                {sizesNeeded.map(
                                                    (size, index) => (
                                                        <option
                                                            key={index}
                                                            value={size}>
                                                            {size}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        )}
                                    </>
                                </div>
                                <div className="ml-2 text-right text-xs">
                                    {creativesLoading[creative.id] ? (
                                        <div className="py-2 pl-9 pr-9  bg-transparent  shadow rounded-sm ">
                                            <FontAwesomeIcon
                                                icon={['fal', 'circle-notch']}
                                                size="2x"
                                                className="h-4 animate-spin text-blue-400"
                                            />
                                        </div>
                                    ) : (
                                        <select
                                            defaultValue="default"
                                            onChange={(e) =>
                                                handleAction(e.target, creative)
                                            }
                                            id="sizes"
                                            name="sizes"
                                            className="focus:ring-0 focus:outline-none py-2 pl-2 pr-8 border-none bg-transparent text-gray-500 text-xs border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer">
                                            <option value="default">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.actions'
                                                )}
                                            </option>
                                            <option value="duplicate">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.duplicate'
                                                )}
                                            </option>
                                            {/*TODO modal to assign Click url <option value="assignUrl">{t('sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.edit-click-url')}</option> */}
                                            <option value="remove">
                                                {t(
                                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.remove'
                                                )}
                                            </option>
                                        </select>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <Modal
                size="sm"
                active={showModalWarning}
                toggler={toggleModalWarning}>
                <ModalHeader toggler={toggleModalWarning}>
                    {t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.title'
                    )}
                </ModalHeader>
                <ModalBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        {t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.delete-confirmation'
                        )}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <CommonButton
                        onClick={handleCancel}
                        label={t('common.buttons.cancel')}
                        styleType={'secondary'}
                        className="mx-2"
                    />
                    <CommonButton
                        onClick={handleDelet}
                        label={t('common.buttons.delete')}
                        styleType={'danger'}
                        className="ml-2"
                    />
                </ModalFooter>
            </Modal>
            {showModal && (
                <ModalViewCreative
                    creative={activeCreative}
                    isActive={showModal}
                    toggler={toggleModal}
                />
            )}
        </div>
    );
};

UploadedCreatives.proptyTypes = {
    creatives: PropTypes.array,
    sizesNeeded: PropTypes.array,
    service: PropTypes.func,
    dispatch: PropTypes.func,
};

export default UploadedCreatives;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions } from '../../../../../store';

const IncomeSegment = (props) => {
    const { isActive, calculatingForecast } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const segments = useSelector((state) => state.newCampaign.segments);
    const incomeLevel = segments.incomeLevel;
    const isIncomeLowChecked = incomeLevel.some((i) => i === 'low');
    const isIncomeMidChecked = incomeLevel.some((i) => i === 'mid');
    const isIncomeHighChecked = incomeLevel.some((i) => i === 'high');

    function handleDemographicIncome(value) {
        if (calculatingForecast === false) {
            const checked = incomeLevel.some((i) => i === value);
            if (checked) {
                changeIncome(incomeLevel.filter((i) => i !== value));
            } else {
                changeIncome([...incomeLevel, value]);
            }
        }
    }

    const changeIncome = (newIncomeLevel) => {
        if (calculatingForecast === false) {
            const newSegments = Object.assign({}, segments);
            newSegments.incomeLevel = newIncomeLevel;
            dispatch(newCampaignActions.setSegments(newSegments));
        }
    };

    return isActive ? (
        <div className="flex justify-evenly my-8 md:my-0">
            <div className="flex text-center flex-col items-center">
                <input
                    checked={isIncomeLowChecked}
                    onChange={() => handleDemographicIncome('low')}
                    type="checkbox"
                    id="demographic-income-low"
                    name="demographic-income"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label htmlFor="demographic-income-low" className="mt-2">
                    <FontAwesomeIcon
                        icon={['fad', 'hand-holding-usd']}
                        size="3x"
                        className={`${
                            isIncomeLowChecked
                                ? 'text-blue-500'
                                : 'text-gray-300'
                        }`}
                    />{' '}
                    <br />
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.demographics.income.low'
                    )}
                </label>
            </div>
            <div className="flex text-center flex-col items-center">
                <input
                    checked={isIncomeMidChecked}
                    onChange={() => handleDemographicIncome('mid')}
                    type="checkbox"
                    id="demographic-income-mid"
                    name="demographic-income"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label htmlFor="demographic-income-mid" className="mt-2">
                    <FontAwesomeIcon
                        icon={['fad', 'usd-circle']}
                        size="3x"
                        className={`${
                            isIncomeMidChecked
                                ? 'text-blue-500'
                                : 'text-gray-300'
                        }`}
                    />{' '}
                    <br />
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.demographics.income.mid'
                    )}
                </label>
            </div>
            <div className="flex text-center flex-col items-center">
                <input
                    checked={isIncomeHighChecked}
                    onChange={() => handleDemographicIncome('high')}
                    type="checkbox"
                    id="demographic-income-high"
                    name="demographic-income"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label htmlFor="demographic-income-high" className="mt-2">
                    <FontAwesomeIcon
                        icon={['fad', 'sack-dollar']}
                        size="3x"
                        className={`${
                            isIncomeHighChecked
                                ? 'text-blue-500'
                                : 'text-gray-300'
                        }`}
                    />{' '}
                    <br />
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.demographics.income.high'
                    )}
                </label>
            </div>
        </div>
    ) : (
        ' '
    );
};

export default IncomeSegment;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useService } from '../../../../../containers/UseService.js';
import { useAuth } from '../../../../../containers/UseAuth';

export default function CampaignAutodelete() {
    const { t } = useTranslation();

    const service = useService();

    const auth = useAuth();
    const user = auth.session.user.id;

    const days = [30, 45, 60, 90, 120];

    const userLanguage = navigator.language || navigator.userLanguage; // Get the language of the current site

    const formatter = new Intl.RelativeTimeFormat(userLanguage, {
        numeric: 'auto',
    });

    const options = days.map((day) => {
        return {
            value: day,
            label: formatter.format(day, 'day'),
        };
    });

    const [autodeleteValue, setAutodeleteValue] = useState(60);

    const [selectValue, setSelectValue] = useState();

    useEffect(async () => {
        await getDraftCampaignsAutodelete();
    }, []);

    useEffect(() => {
        setSelectValue(
            options.filter((item) => item.value === autodeleteValue)[0]
        );
    }, [autodeleteValue]);

    async function getDraftCampaignsAutodelete() {
        const value = await service.getUserSettings(user);
        const data = value?.data?.data[0]?.campaign_autodelete || 60;
        setAutodeleteValue(data);
        return data;
    }

    async function setDraftCampaignsAutodelete(days) {
        await service.setDraftCampaignAutodelete(user, days);
        await getDraftCampaignsAutodelete();
    }

    return (
        <>
            <div className="text-gray-600 text-xs md:text-sm p-2 m-2">
                {t(
                    'sections.management.section.preferences.settings.campaign-autodelete-select.title'
                )}
            </div>
            {selectValue && (
                <Select
                    className={'w-64'}
                    options={options}
                    onChange={(option) =>
                        setDraftCampaignsAutodelete(option.value)
                    }
                    placeholder={t(
                        'sections.management.section.preferences.settings.campaign-autodelete-select.placeholder'
                    )}
                    value={selectValue}
                />
            )}
        </>
    );
}

import axios from '../utils/axios/axios';
import * as endpoints from '../constants/endpoints';

export const getFeaturesForUser = async () => {
    try {
        const response = await axios.get(endpoints.getFeaturesForUserV2);
        localStorage.setItem('features', JSON.stringify(response.data));
        return;
    } catch (error) {
        throw new Error(error);
    }
};

import React, { useEffect, useState } from 'react';
import { Range } from 'rc-slider';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions } from '../../../../../store/newCampaign';

const AgeSegment = (props) => {
    const { isActive, calculatingForecast } = props;
    const dispatch = useDispatch();
    const newCampaign = useSelector((state) => state.newCampaign);
    const demographics = newCampaign.demographics;
    const segments = newCampaign.segments;
    const ageRange = segments.age.sliderValue;
    const ages = demographics.segments.find((segment) => segment.id === 'age');
    const [marks, setMarks] = useState({});
    const [newAgeRange, setNewAgeRange] = useState(ageRange);
    const audienceProvider = demographics?.id || '';
    let min;
    let max;

    useEffect(() => {
        let first = true;
        const marks = {};
        ages.keys.forEach((key) => {
            const [from, to] = key.id.split('-');
            if (first) {
                first = false;
                marks[from] = from;
                marks[to] = to;
            } else {
                marks[to] = to == 99 ? to + '+' : to;
            }
        });
        if (ageRange.length === 2) {
            setNewAgeRange(ageRange);
        }
        setMarks(marks);
    }, [ages]);

    useEffect(() => {
        if (newAgeRange.length === 2) {
            handleChangeRange(newAgeRange);
        }
    }, [newAgeRange, audienceProvider]);

    const handleChangeRange = (range) => {
        if (calculatingForecast === false) {
            const newRange = range[1] === 99 ? [range[0], 199] : range;
            const age = [];
            ages.keys.forEach((key) => {
                if (isOnRange(key.id, newRange))
                    if (
                        key.id.split('-')[1] === '99' &&
                        key.id.split('-')[0] === '80' &&
                        audienceProvider === 'onemata'
                    ) {
                        const keySplited = key.id.split('-');
                        const newKey = keySplited[0] + '-' + 199;
                        age.push(newKey);
                    } else {
                        age.push(key.id);
                    }
            });
            changeAge(age, newRange);
        }
    };

    const changeAge = (newAge, sliderValue) => {
        if (calculatingForecast === false) {
            const newSegments = Object.assign({}, segments);
            newSegments.age = { value: newAge, sliderValue };
            dispatch(newCampaignActions.setSegments(newSegments));
        }
    };

    const isOnRange = (semiRange, completeRange) => {
        if (calculatingForecast === false) {
            const [from, to] = semiRange.split('-');
            return from >= completeRange[0] && to <= completeRange[1];
        }
    };

    return isActive ? (
        <div className="my-8 md:my-0">
            <Range
                min={min}
                max={max}
                value={ageRange}
                step={null}
                marks={marks}
                onChange={handleChangeRange}
            />
        </div>
    ) : (
        ' '
    );
};

export default AgeSegment;

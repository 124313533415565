import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useService } from '../UseService';
import Dropzone from '../../components/Input/Dropzone/Dropzone';

const UploadTesterPage = () => {
    const service = useService();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [currentFileUploading, setCurrentFileUploading] = useState('');

    const uploadFiles = async (files) => {
        const filesUploaded = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            setCurrentFileUploading(file.name);
            const response = await service.uploadTester(file);
            if (response.error) {
                filesUploaded.push({ error: true, name: file.name, link: '' });
            } else {
                filesUploaded.push({
                    error: false,
                    name: file.name,
                    link: response.data,
                });
            }
            setUploadedFiles([...uploadedFiles, ...filesUploaded]);
            setCurrentFileUploading('');
        }
    };

    return (
        <>
            <div>
                Subi un archivo de cada tipo (gif, jpg, jpeg, png, mp4, webm y
                un zip con un index.html dentro), podes encontrar uno de prueba{' '}
                <a
                    href="https://drive.google.com/file/d/1v48HwLoNiLkznVEk1NhV-jO-Usj5lmXM/view?usp=sharing"
                    style={{ color: 'blue' }}>
                    aca
                </a>
            </div>
            <div
                style={{
                    width: '300px',
                    height: '200px',
                    border: '1px solid black',
                }}>
                <Dropzone
                    loading={currentFileUploading !== ''}
                    onFilesAdded={uploadFiles}
                    disabled={false}
                />
            </div>
            {currentFileUploading !== '' && (
                <>
                    <span>Subiendo: {currentFileUploading}</span>
                    <FontAwesomeIcon
                        icon={['fas', 'circle-notch']}
                        className={`text-blue-400 animate-spin ml-2`}
                    />
                </>
            )}

            <div className="mt-4">
                {uploadedFiles.map((file, index) => (
                    <div className="mb-4" key={index}>
                        <div>Archivo: {file.name}</div>
                        {file.error ? (
                            <div>Error, mirarlo en consola</div>
                        ) : (
                            <div>
                                Link:{' '}
                                <a
                                    href={file.link}
                                    rel="noreferrer"
                                    style={{ color: 'blue' }}
                                    target="_blank">
                                    {file.link}
                                </a>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default UploadTesterPage;

import React, { useEffect, useState } from 'react';
import Hint from '../Tools/Hint';

function SwitcherButton({
    onToggle = () => {},
    status,
    labelOn,
    className,
    labelOff,
    revertLabel,
    duration = 150,
    hintMessage,
    hintTitle,
    dataFor = '',
}) {
    const [isOn, setIsOn] = useState(false);

    useEffect(() => {
        setIsOn(status);
    }, [status]);

    return (
        <>
            <div
                onClick={onToggle}
                className={`${className} flex justify-between ${
                    revertLabel === true ? 'flex-row-reverse' : 'flex-row'
                }`}>
                {hintMessage && hintTitle && (
                    <Hint
                        className="my-auto"
                        title={hintTitle}
                        text={hintMessage}
                        type={'info'}
                        dataFor={dataFor}
                    />
                )}
                <div
                    onClick={() => setIsOn(!isOn)}
                    className={`${
                        labelOff ? 'block' : 'hidden'
                    } text-xs font-light mt-1.5 px-2 cursor-pointer capitalize`}>
                    {isOn ? labelOn : labelOff}
                </div>
                <div
                    onClick={() => setIsOn(!isOn)}
                    className="w-8 relative my-1 cursor-pointer">
                    <div
                        className={`${
                            isOn ? 'bg-blue-300' : 'bg-gray-300'
                        } h-5 w-8 rounded-full`}>
                        <div
                            className={`${
                                isOn ? 'left-4' : 'right-4'
                            } mt-1 w-3 h-3 absolute transition-all transform ease-linear duration-${duration} flex items-center justify-center rounded-full bg-white shadow-toggle border-gray-300 top-0`}></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SwitcherButton;

import React, { useRef, useState } from 'react';
import CommonButton from '../../../../Buttons/CommonButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useService } from '../../../../../containers/UseService';

const TabUploadTag = () => {
    const { t } = useTranslation();
    const sizesNeeded = useSelector((state) => state.newCampaign.sizesNeeded);
    const selectSizeRef = useRef();
    const service = useService();
    const [script, setScript] = useState('');
    const [isScreenSizeSelected, setIsScreenSizeSelected] = useState(false); // Handle state of screen size selection
    const [creativesLoading, setCreativesLoading] = useState(false);

    const getWidthAndHeightSelected = () => {
        const size = selectSizeRef.current.value;
        const sizeSplited = size.split('x');
        if (sizeSplited.length === 2) {
            const width = parseInt(sizeSplited[0]);
            const height = parseInt(sizeSplited[1]);
            return [width, height];
        } else {
            return [0, 0];
        }
    };

    const onAddTag = async () => {
        const [width, height] = getWidthAndHeightSelected();
        setCreativesLoading(true);
        if (width && height && script) {
            await service.uploadScript(width, height, script);
            setCreativesLoading(false);
        }
    };

    return (
        <>
            <div className="w-full text-center pt-4 font-extralight">
                {t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.tags-info'
                )}
            </div>
            <div className="py-8 flex justify-start items-center flex-col md:flex-row">
                <div className="px-2 w-3/6">
                    <textarea
                        onChange={(e) => setScript(e.target.value)}
                        className="focus:ring-0 focus:outline-none block w-full pr-12 h-10 sm:text-sm border-gray-300 rounded-sm"
                        placeholder={t(
                            'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.tags-placeholder'
                        )}></textarea>
                </div>
                <div className="mr-2 w-2/6">
                    <select
                        id="sizes"
                        ref={selectSizeRef}
                        name="sizes"
                        className="focus:ring-0 focus:outline-none h-full py-2 pl-2 pr-8 border-none bg-transparent text-gray-500 sm:text-sm border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm"
                        onChange={(val) =>
                            setIsScreenSizeSelected(
                                val.target.value.includes('x')
                            )
                        }>
                        <option>
                            {t(
                                'sections.campaigns.new-campaign.wizard-creatives.creatives.adType.tags-size'
                            )}
                        </option>
                        {sizesNeeded.map((size, index) => (
                            <option key={index} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="w-1/6">
                    <CommonButton
                        className=""
                        width="w-14"
                        styleType="primary"
                        children={
                            creativesLoading ? (
                                <FontAwesomeIcon
                                    icon={['fal', 'circle-notch']}
                                    size="2x"
                                    className="h-4 animate-spin"
                                />
                            ) : (
                                false
                            )
                        }
                        label={t('common.buttons.add')}
                        onClick={onAddTag}
                        disabled={!(script && isScreenSizeSelected)}
                    />
                </div>
            </div>
        </>
    );
};

export default TabUploadTag;

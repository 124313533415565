import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timezones: [],
    currentPeriod: [
        window.localStorage.getItem('from'),
        window.localStorage.getItem('to'),
    ],
    currentPeriodName: '',
    location: {},
};

const { reducer, actions } = createSlice({
    name: 'extra',
    initialState: initialState,
    reducers: {
        setTimezones(state, action) {
            state.timezones = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setCurrentPeriod(state, action) {
            state.currentPeriod = action.payload;
            window.localStorage.setItem('from', action.payload[0]);
            window.localStorage.setItem('to', action.payload[1]);
        },
        resetPeriod(state, action) {
            state.currentPeriod = [
                window.localStorage.getItem('from'),
                window.localStorage.getItem('to'),
            ];
        },
        setCurrentPeriodName(state, action) {
            state.currentPeriodName = action.payload;
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as extraActions };
export { reducer as extraReducer };

import React from 'react';
import i18next from 'i18next';
import { numberWithoutCommas, formatNumber } from '../../utils/functions';

function CurrencyText({ currency, value, textSize, campaignBudget }) {
    const currentLang = i18next.language;
    const campaignBudgetNumber =
        typeof campaignBudget === 'string'
            ? parseFloat(numberWithoutCommas(campaignBudget))
            : campaignBudget;
    const valueNumber =
        typeof value === 'string'
            ? parseFloat(numberWithoutCommas(value))
            : value;
    const budget =
        campaignBudget && value !== '-' ? ' / ' + campaignBudget : '';

    return (
        <>
            <div className={`font-semibold ${textSize ? textSize : 'text-xl'}`}>
                <span className="text-xs font-thin text-gray-500">
                    {currency}{' '}
                </span>{' '}
                {typeof value === 'string' ? (
                    <>
                        {formatNumber(value, 'rounded') + ' '}{' '}
                        <span className="font-light">
                            {' '}
                            {campaignBudget && value !== '-'
                                ? ' / ' +
                                  formatNumber(campaignBudget, 'rounded')
                                : ''}{' '}
                        </span>{' '}
                    </>
                ) : (
                    formatNumber(value, 'ecpm')
                )}
            </div>
        </>
    );
}

export default CurrencyText;

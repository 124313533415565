import styled from 'styled-components';
import { easyMove } from '../style-constants';
import icon from '../../../images/icons/m1.png';

const MarkerStyled = styled.div`
    position: absolute;
    top: -30px;
    left: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    font-size: 14px;
    background-image: url(${icon});
    background-size: cover;
    background-position: center;
    transition: transform 0.3s;
    animation: ${easyMove} 0.3s;
    &:hover {
        transform: scale(1.2);
    }
`;

export default MarkerStyled;

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
    table: {
        width: '100%',
        fontSize: '9pt',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 12,
    },
    header: {
        borderTop: 'none',
    },
    bold: {
        fontWeight: 'bold',
    },
    row1: {
        width: '15%',
        paddingLeft: 5,
    },
    row2: {
        width: '85%',
    },
});

const ScreenTable = ({ data, selectedScreens }) => {
    const { t } = useTranslation();

    return (
        <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.row1}>Id</Text>
                <Text style={styles.row2}>{t('common.words.screen')}</Text>
            </View>
            {data.map((row, i) => (
                <View
                    key={i}
                    style={{
                        backgroundColor: `${
                            selectedScreens.find(
                                (screen) => screen.name === row.name
                            )
                                ? '#60A5FA'
                                : ''
                        }`,
                    }}>
                    <View key={i} style={styles.row} wrap={false}>
                        <Text style={styles.row1}>
                            <Text style={styles.bold}>{row.id}</Text>
                        </Text>
                        <Text style={styles.row2}>{row.name}</Text>
                    </View>
                </View>
            ))}
        </View>
    );
};

ScreenTable.propTypes = {
    data: PropTypes.array.isRequired,
    selectedScreens: PropTypes.array.isRequired,
};

export default ScreenTable;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
    formatScreenLocation,
    getEmptyResponseDensity,
} from '../../utils/functions';
import { getDensity } from '../../constants/endpoints';
import { useDispatch, useSelector } from 'react-redux';

import ScreenAudiences from './ScreenAudiences';

function ScreenDetails(params) {
    const sorter = {
        sun: 0, // << if sunday is first day of week
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
    };
    function sortByDay(a, b) {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sorter[day1] - sorter[day2];
    }
    const {
        screenOpened,
        onCloseScreen,
        selectedCountries,
        timezone,
        screensWithCpm,
    } = params;
    const audienceProvider = useSelector((state) => {
        return state.newCampaign.demographics.id;
    });
    const screenDetailsVariants = {
        closed: { opacity: 0, display: 'hidden', width: '0px' },
        open: {
            opacity: 1,
            display: 'block',
            width: '350px',
            transition: {
                delayChildren: 0.4,
            },
        },
    };
    const info = {
        closed: { opacity: 0 },
        open: { opacity: 1 },
    };

    const [days, setDays] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(async () => {
        const provider = audienceProvider;
        if (screenOpened !== null && provider !== '') {
            setIsLoading(true);
            //! parches por pantallas sin provedor y timezone
            // const provider = getProviderByCountries(selectedCountries)
            fetch(
                getDensity +
                    `${screenOpened.id}/heatmap?provider=${provider}&timezone=${timezone}`
            )
                .then((res) => {
                    return res.status === 200
                        ? res.json()
                        : { data: getEmptyResponseDensity() };
                })
                .then((res) => {
                    let daysSorted = [];
                    Object.keys(res.data)
                        .sort(sortByDay)
                        .forEach((day) => {
                            daysSorted.push({
                                hours: [...res.data[day]],
                                name: day,
                            });
                        });
                    setDays(daysSorted);
                    setIsLoading(false);
                });
        }
    }, [screenOpened, audienceProvider]);

    const HEATMAP_PALETTE = {
        plasma: {
            0: '#f0f921',
            1: '#fdca26',
            2: '#fb9f3a',
            3: '#ed7953',
            4: '#d8576b',
            5: '#b5367a',
            6: '#9c179e',
            7: '#7201a8',
            8: '#46039f',
            9: '#0d0887',
        },
        viridis: {
            0: '#fde725',
            1: '#b5de2b',
            2: '#6ece58',
            3: '#35b779',
            4: '#1f9e89',
            5: '#26828e',
            6: '#31688e',
            7: '#3e4989',
            8: '#482878',
            9: '#440154',
        },
    };

    const HOURS = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
    ];
    const getColorDensity = (hour, palette = 'viridis') => {
        const colorsByDensity = HEATMAP_PALETTE[palette];
        return colorsByDensity[hour.percentage];
    };

    return (
        <div>
            <motion.div
                style={{ overflowY: 'scroll', overflowX: 'hidden' }}
                className="absolute z-20 inset-y-0 left-0 p-4 pt-16 bg-white shadow-lg hover:shadow-xl transition-shadow duration-150"
                animate={screenOpened ? 'open' : 'closed'}
                initial="closed"
                variants={screenDetailsVariants}
                key="screenDetails">
                {/* fix classnames tailwind , it can be done in the tailwind.config*/}
                <div className="bg-green-100 bg-green-200 bg-green-300 bg-yellow-300 bg-yellow-400 bg-yellow-500 bg-yellow-600 bg-red-600 bg-red-700 bg-red-800 hidden"></div>
                <div
                    onClick={() => {
                        return onCloseScreen(null);
                    }}
                    className="absolute right-4 top-16 text-right text-gray-400 hover:text-gray-800 cursor-pointer">
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </div>
                <motion.div variants={info} className="block w-full">
                    <div className="block text-2xl font-light w-11/12">
                        {screenOpened && screenOpened.name}{' '}
                        <span className="bg-gray-100 rounded text-gray-400 px-1 text-sm">
                            {screenOpened && screenOpened.publisher.name}
                        </span>
                    </div>
                    <div className="block text-gray-400 font-light">
                        {screenOpened && formatScreenLocation(screenOpened)}
                    </div>

                    {/* When we can show the screen images and screen density, remove this comment
                <div className="block text-gray-600 font-light py-4">
                    <FontAwesomeIcon className="text-yellow-500" icon={["fad", `users-class`]} /> High density screen.
                </div>*/}
                    {isLoading ? (
                        <span className="ml-36">
                            <FontAwesomeIcon
                                icon={['fas', 'circle-notch']}
                                className="mt-12 text-blue-400 animate-spin mx-2"
                            />
                        </span>
                    ) : (
                        <div className="block text-gray-600 font-light mt-3">
                            <div className="flex align-text-bottom  subpixel-antialiased tracking-tighter">
                                <div
                                    className="w-2 h-2 m-0.5 bg-white text-center text-xs overflow-hidden"
                                    style={{ fontSize: '50%' }}></div>
                                {HOURS.map((hour) => {
                                    return (
                                        <div
                                            className="w-2 h-3 m-0.5 -mb-1 bg-white text-center font-mono overflow-hidden"
                                            style={{ fontSize: '7px' }}
                                            key={`screen_detail_table_hour_${hour}`}>
                                            {hour}
                                        </div>
                                    );
                                })}
                            </div>
                            {days.map((dayData) => {
                                return (
                                    <div
                                        className="flex"
                                        key={`screen_details_${dayData.name}`}>
                                        <div
                                            className="w-2 h-2 m-0.5 rounded-sm bg-white text-right overflow-hidden flex content-center"
                                            style={{ fontSize: '7px' }}>
                                            {dayData.name[0].toUpperCase()}
                                        </div>
                                        {dayData.hours.map((hour) => {
                                            return (
                                                <div
                                                    key={`screen_details_${dayData.name}_${hour.hour}`}
                                                    className={`w-2 h-2 m-0.5 rounded-sm`}
                                                    style={{
                                                        background: `${getColorDensity(
                                                            hour
                                                        )}`,
                                                    }}></div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {screenOpened && Object.keys(screenOpened).length && (
                        <ScreenAudiences
                            screensWithCpm={screensWithCpm}
                            data={screenOpened}
                        />
                    )}
                </motion.div>
            </motion.div>
        </div>
    );
}

export default ScreenDetails;

import React, { useEffect, useState } from 'react';
import ReactStreetview from 'react-streetview';
import styles from './StreetViewContent.module.css';

function StreetView(props) {
    const {
        toggler,
        streetViewPanoramaOptions,
        active,
        className = '',
    } = props;
    const googleMapsApiKey = 'AIzaSyBfLH4V6D19dTDTW31weSPHhKN-LxDQcR4';
    const [resetStreetView, setResetStreetView] = useState(true);

    useEffect(() => {
        setResetStreetView(false);
    }, [streetViewPanoramaOptions]);

    useEffect(() => {
        if (!resetStreetView) setResetStreetView(true);
    }, [resetStreetView]);

    return (
        <>
            {active ? (
                <>
                    <div className="absolute inset-y-0 -inset-x-10">
                        <div className={`${styles.container}`}>
                            <button
                                className={`${styles.buttonClose} p-1 ml-auto bg-transparent rounded-3xl border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none`}
                                onClick={toggler}>
                                <span className="text-gray-500 hover:text-gray-300 text-2xl block">
                                    ×
                                </span>
                            </button>
                            {resetStreetView && (
                                <ReactStreetview
                                    apiKey={googleMapsApiKey}
                                    streetViewPanoramaOptions={
                                        streetViewPanoramaOptions
                                    }
                                    className={`${className}`}
                                />
                            )}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default StreetView;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const Ssps = (props) => {
    const { user, setUser, ssps } = props;
    const { t } = useTranslation();

    const change = (key, value) => {
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy[key] = value;
        setUser(userCopy);
    };

    return (
        <>
            <div className="w-11/12 grid-cols-1 gap-x-14 sm:gap-y-4  sm:grid sm:grid-cols-2 ">
                <div className="max-w-sm mx-auto sm:mx-0">
                    <label className="text-sm font-medium text-gray-700">
                        {t('sections.management.section.users.users.ssps')}
                    </label>
                    <Select
                        options={ssps}
                        value={user.ssps}
                        onChange={(ssps) => change('ssps', ssps)}
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
            </div>
        </>
    );
};

export default Ssps;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Buttons/CommonButton';
import { useTranslation } from 'react-i18next';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { useService } from '../UseService';
import { useHistory } from 'react-router-dom';

function ResetPassword() {
    const { t } = useTranslation();
    const history = useHistory();
    const [newPassword, setNewPassword] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');
    const [valid, setValidation] = useState(false);
    const service = useService();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = query.get('token');
        const username = query.get('username');
        await service.resetPassword(token, reEnterPassword, username);
        history.push('/');
    };

    useEffect(() => {
        setValidation(
            newPassword.length > 3 &&
                reEnterPassword.length > 3 &&
                newPassword === reEnterPassword
        );
    }, [newPassword, reEnterPassword]);

    return (
        <LoginLayout>
            <div className="text-gray-500 mb-4 text-xl">
                {t('sections.login-page.reset-password')}
            </div>
            <hr />
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="mt-4">
                    <label className="text-gray-700 text-base">
                        {t('sections.login-page.new-password')}
                    </label>
                </div>
                <div className="mb-4">
                    <input
                        type="password"
                        value={newPassword}
                        autoComplete="username"
                        className="mt-2 rounded-sm w-full bg-gray-200 border-none focus:ring-0"
                        placeholder={t('sections.login-page.password-new')}
                        onChange={(e) =>
                            setNewPassword(e.target.value)
                        }></input>
                </div>
                <div className="mt-4">
                    <label className="text-gray-700 text-base">
                        {t('sections.login-page.re-enter-password')}
                    </label>
                </div>

                <div className="mb-4">
                    <input
                        type="password"
                        value={reEnterPassword}
                        autoComplete="username"
                        className="mt-2 rounded-sm w-full bg-gray-200 border-none focus:ring-0"
                        placeholder={t('sections.login-page.password-re-enter')}
                        onChange={(e) =>
                            setReEnterPassword(e.target.value)
                        }></input>
                </div>
                <div className="my-4">
                    <Button
                        label={t('sections.login-page.reset')}
                        styleType={'primary'}
                        disabled={!valid}
                    />
                </div>
            </form>
        </LoginLayout>
    );
}

export default ResetPassword;

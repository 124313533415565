import React from 'react';

const LinkButton = (props) => {
    const { children, onClick = () => {}, className = '' } = props;
    return (
        <button onClick={onClick} className={`link ${className}`}>
            {children}
        </button>
    );
};

export default LinkButton;

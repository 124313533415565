import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './DaysHours.module.css';
import { isAMPMlocale } from '../../../../../utils/functions';

const DaysHours = (props) => {
    const { change } = props;
    const { t } = useTranslation();
    const timing = useSelector((state) => state.newCampaign.timing);
    const daysEnabled = timing.daysEnabled;
    const hoursEnabled = timing.hoursEnabled;

    const handleToggleSelectDay = (value) => {
        let position = parseInt(value);

        let daysEnabled = Object.assign([], timing.daysEnabled);
        let isExist = daysEnabled.indexOf(position) > -1;

        if (position === 0) {
            if (daysEnabled.length === 7) {
                daysEnabled = [];
            } else {
                daysEnabled = [1, 2, 3, 4, 5, 6, 7];
            }
        } else {
            if (isExist) {
                daysEnabled = daysEnabled.filter((item) => {
                    return item !== position;
                });
            } else {
                if (!isExist) {
                    daysEnabled = [...daysEnabled, position];
                }
            }
        }

        change(
            'daysEnabled',
            [...daysEnabled].sort((a, b) => a - b)
        );
    };

    const handleToggleSelectHour = (value) => {
        let position = parseInt(value);
        let hoursEnabled = Object.assign([], timing.hoursEnabled);
        let isExist = hoursEnabled.indexOf(position) > -1;

        /* AM */
        if (position === 24) {
            let pm = hoursEnabled.filter(
                (item) => (item >= 12 && item <= 23) || item == 25
            );
            if (!isExist) {
                hoursEnabled = pm.concat([
                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 24,
                ]);
            } else {
                hoursEnabled = pm;
            }
            /* PM */
        } else if (position === 25) {
            let am = hoursEnabled.filter(
                (item) => (item >= 0 && item <= 11) || item == 24
            );
            if (!isExist) {
                hoursEnabled = am.concat([
                    12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25,
                ]);
            } else {
                hoursEnabled = am;
            }
        } else if (position === 26) {
            if (hoursEnabled.length) {
                hoursEnabled = [];
            } else {
                hoursEnabled = Array.from({ length: 24 }, (_, i) => i);
            }
        } else {
            if (isExist) {
                hoursEnabled = hoursEnabled.filter((item) => {
                    return item !== position;
                });
            } else {
                if (!isExist) {
                    hoursEnabled = [...hoursEnabled, position];
                }
            }
        }

        change(
            'hoursEnabled',
            [...hoursEnabled].sort((a, b) => a - b)
        );
    };

    const daysArray = [
        {
            name: 'all',
            value: 0,
        },
        {
            name: 'monday',
            value: 1,
        },
        {
            name: 'tuesday',
            value: 2,
        },
        {
            name: 'wednesday',
            value: 3,
        },
        {
            name: 'thursday',
            value: 4,
        },
        {
            name: 'friday',
            value: 5,
        },
        {
            name: 'saturday',
            value: 6,
        },
        {
            name: 'sunday',
            value: 7,
        },
    ];

    const HourButton = ({ value, label, onClick, active }) => (
        <button
            type="button"
            value={value}
            onClick={(e) => onClick(e.target.value)}
            className={`${styles.item} ${active && styles.active} ${
                value === 26 && hoursEnabled.length >= 24 && styles.active
            }`}>
            {label}
        </button>
    );

    const HoursButtons = ({ hours, onClick, hoursEnabled, styles }) => (
        <div
            className="btn-group btn-group-sm specific-dates sd-md m-t-10"
            role="group"
            aria-label="Basic example">
            {hours.map((hour) => (
                <HourButton
                    key={hour.value}
                    value={hour.value}
                    label={hour.label}
                    onClick={onClick}
                    active={hoursEnabled.indexOf(hour.value) > -1}
                    styles={styles}
                />
            ))}
        </div>
    );

    const NUMBERING_POSITION_CORRECTION = -1;
    const PM_HOURS_DIFFERENCE = 12;

    const WHOLE_AM_RANGE_CODE = 24;
    const WHOLE_PM_RANGE_CODE = 25;
    const WHOLE_24_RANGE_CODE = 26;

    const AMHours = Array.from({ length: 13 }, (_, i) => ({
        value:
            i === 0
                ? WHOLE_AM_RANGE_CODE
                : i === 1
                ? 0
                : i + NUMBERING_POSITION_CORRECTION,
        label:
            i === 0 ? 'AM' : i === 1 ? 12 : i + NUMBERING_POSITION_CORRECTION,
    }));

    const PMHours = Array.from({ length: 13 }, (_, i) => ({
        value:
            i === 0
                ? WHOLE_PM_RANGE_CODE
                : i === 1
                ? 12
                : i + NUMBERING_POSITION_CORRECTION + PM_HOURS_DIFFERENCE,
        label:
            i === 0 ? 'PM' : i === 1 ? 12 : i + NUMBERING_POSITION_CORRECTION,
    }));

    const hours24 = Array.from({ length: 25 }, (_, i) => ({
        value: i === 0 ? WHOLE_24_RANGE_CODE : i - 1,
        label: i === 0 ? '24hs' : i - 1,
    }));

    const isAMPMformat = () => {
        const hourFormat = localStorage.getItem('hourFormat');

        if (hourFormat) {
            return hourFormat === 'ampm' ? true : false;
        } else {
            return isAMPMlocale();
        }
    };

    return (
        <>
            <div
                className="btn-group btn-group-sm  specific-dates sd-md"
                role="group"
                aria-label="Basic example">
                {daysArray.map((day) => (
                    <button
                        key={day.value}
                        type="button"
                        value={day.value}
                        onClick={(e) => handleToggleSelectDay(e.target.value)}
                        className={`${styles.item} ${
                            day.value !== 0 && daysEnabled.includes(day.value)
                                ? styles.active
                                : day.value == 0 && daysEnabled.length === 7
                                ? styles.active
                                : ''
                        }`}>
                        {t(
                            `sections.campaigns.new-campaign.wizard-basics.timing.${
                                day.name !== 'all'
                                    ? day.name + '-letter'
                                    : day.name
                            }`
                        )}
                    </button>
                ))}
            </div>
            <label
                htmlFor="day-parting"
                className="block text-sm font-medium text-gray-700 mt-6">
                {t(
                    'sections.campaigns.new-campaign.wizard-basics.timing.hours'
                )}{' '}
                <span className="font-light">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.timing.of-the-day'
                    )}
                </span>
            </label>
            {isAMPMformat() ? (
                <div>
                    <HoursButtons
                        hours={AMHours}
                        onClick={handleToggleSelectHour}
                        hoursEnabled={hoursEnabled}
                        styles={styles}
                    />
                    <br />
                    <HoursButtons
                        hours={PMHours}
                        onClick={handleToggleSelectHour}
                        hoursEnabled={hoursEnabled}
                        styles={styles}
                    />
                </div>
            ) : (
                <div>
                    <HoursButtons
                        hours={hours24}
                        onClick={handleToggleSelectHour}
                        hoursEnabled={hoursEnabled}
                        styles={styles}
                    />
                </div>
            )}
        </>
    );
};

export default DaysHours;

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    budgets: null,
    currencies: [],
};

const { reducer, actions } = createSlice({
    name: 'wallet',
    initialState: initialState,
    reducers: {
        setBudgets(state, action) {
            state.budgets = action.payload;
        },
        setCurrencies(state, action) {
            state.currencies = action.payload;
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as walletActions };
export { reducer as walletReducer };

import React from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component-footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MyResponsivePie from '../../../Graph/Pie';

const tableCustomStyles = {
    headRow: {
        style: {
            backgroundColor: '#F3F7FC',
        },
    },
    rows: {
        stripedStyle: {
            color: '#174069',
            backgroundColor: '#F3F7FC',
        },
        highlightOnHover: {
            backgroundColor: '#FFFFFF',
        },
    },
};

const ReportsByUserType = ({
    isData,
    columns,
    dataColumns,
    currencies,
    onSearch,
    totals,
    isLoading,
    dataCharts,
    traductionsCharts,
    currencyCurrent,
    setCurrencyCurrent,
    nameCSV,
    keyForTotals,
}) => {
    const INDEX_IMPRESSIONS = 2;
    const { t } = useTranslation();
    return (
        <div>
            {isData ? (
                <>
                    <nav className="flex">
                        {Object.keys(currencies).map((currencyShortName) => {
                            return (
                                <button
                                    key={`${new Date().getTime()}_${currencyShortName}`}
                                    id={`wallet${currencyShortName}`}
                                    onClick={() =>
                                        setCurrencyCurrent(
                                            currencies[currencyShortName]
                                        )
                                    }
                                    className={`${
                                        currencyCurrent.name ==
                                        currencyShortName
                                            ? 'tabActive font-semibold'
                                            : ''
                                    } tabGeneral uppercase text-xs`}>
                                    {currencies[currencyShortName].short_name}
                                </button>
                            );
                        })}
                    </nav>
                    <div className={'h-72 flex flex-row mt-5'}>
                        {dataCharts.map((dataChartByCurrency, index) => {
                            return (
                                dataChartByCurrency[
                                    currencyCurrent?.name
                                ][0] !== 0 && (
                                    <div className={`h-5/6 w-1/3 `} key={index}>
                                        <MyResponsivePie
                                            symbol={
                                                index === INDEX_IMPRESSIONS
                                                    ? ''
                                                    : currencyCurrent.symbol
                                            }
                                            enableArcLinkLabels={false}
                                            data={
                                                dataChartByCurrency[
                                                    currencyCurrent.name
                                                ]
                                            }
                                            isByUserReports={true}
                                        />
                                        <h2 className="m-auto">
                                            {traductionsCharts[index]}
                                        </h2>
                                    </div>
                                )
                            );
                        })}
                    </div>

                    <div className="cursor-pointer mr-4">
                        <div className="w-full relative overflow-y-auto">
                            <div className="w-full flex justify-between items-center">
                                <div className="md:w-2/6 md:mr-4">
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <span className="text-gray-500 sm:text-sm">
                                                <FontAwesomeIcon
                                                    icon={['far', 'search']}
                                                    fixedWidth
                                                    className="mr-1"
                                                />
                                            </span>
                                        </div>
                                        <input
                                            onChange={(e) =>
                                                onSearch(
                                                    e.target.value,
                                                    currencyCurrent.name
                                                )
                                            }
                                            autoComplete="off"
                                            type="search"
                                            name="budget"
                                            id="search-table"
                                            className={`focus:ring-0 focus:outline-none block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md ml-1`}
                                            placeholder={t(
                                                'sections.management.section.users.users.searchPlaceholder'
                                            )}
                                        />
                                    </div>
                                </div>
                                <CSVLink
                                    filename={nameCSV}
                                    headers={columns.filter(
                                        (column) => column.exportCSV
                                    )}
                                    data={
                                        isData
                                            ? dataColumns[currencyCurrent.name]
                                            : []
                                    }
                                    target={'_blank'}>
                                    <FontAwesomeIcon
                                        icon={['fas', 'file-csv']}
                                        className="text-blue-300"
                                    />
                                    <span className="text-blue-300 text-sm pl-2">
                                        {t(
                                            'common.components.ReportsTable.export-to-csv'
                                        )}
                                    </span>
                                </CSVLink>
                            </div>
                        </div>
                        <div className="w-full">
                            <DataTable
                                responsive={true}
                                keyField="id"
                                noDataComponent={false}
                                fixedHeader={true}
                                fixedHeaderScrollHeight={'70vh'}
                                striped
                                columns={columns}
                                customStyles={tableCustomStyles}
                                data={
                                    isData
                                        ? dataColumns[currencyCurrent.name]
                                        : []
                                }
                                pagination
                                footer={
                                    isData
                                        ? {
                                              ...totals[currencyCurrent.name],
                                              // add string "Totals to footer"
                                              [keyForTotals]:
                                                  t('common.words.total'),
                                          }
                                        : []
                                }
                                progressPending={isLoading}
                                paginationRowsPerPageOptions={[80, 150]}
                                progressComponent={
                                    <>
                                        <FontAwesomeIcon
                                            icon={['fas', 'circle-notch']}
                                            className="text-blue-400 animate-spin mx-2"
                                        />
                                    </>
                                }
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="mt-8 mx-auto text-center">
                    <span>
                        {t('common.components.react-data-table.no-reports')}
                    </span>
                </div>
            )}
        </div>
    );
};

ReportsByUserType.proptyTypes = {
    isData: PropTypes.bool.isRequired,
    currencies: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    dataColumns: PropTypes.array.isRequired,
    currencies: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    totals: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dataCharts: PropTypes.array.isRequired,
    traductionsCharts: PropTypes.array.isRequired,
    currencyCurrent: PropTypes.object.isRequired,
    setCurrencyCurrent: PropTypes.func.isRequired,
    nameCSV: PropTypes.string.isRequired,
};

export default ReportsByUserType;

import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../containers/UseAuth';
import CommonButton from '../../../../Buttons/CommonButton';
import UserSelector from '../../../../Input/UserSelector/UserSelector';

const SupervisorControl = (params) => {
    const { isActive } = params;
    const [selectedUser, setSelectedUser] = useState(null);
    const { t } = useTranslation();
    const auth = useAuth();
    const commonButtonRef = useRef(null); // Crear una referencia para el botón

    const onSupervise = async () => {
        try {
            localStorage.clear();
            await auth.superviseAccount(selectedUser.id);
        } catch (error) {
            console.error(error);
        }
    };

    const pressKey = async (key) => {
        if (key.keyCode === 13 && !key.shiftKey) {
            key.preventDefault();
            if (selectedUser) {
                commonButtonRef.current.focus();
                commonButtonRef.current.click();
            }
            key.stopPropagation();
        }
    };

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12}`}>
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t('sections.management.section.users.supervisor.title')}
                    <p className="font-light">
                        {t(
                            'sections.management.section.users.supervisor.description'
                        )}
                    </p>
                </label>
            </div>
            <div onKeyDown={(key) => pressKey(key)}>
                <div className="mt-8 flex">
                    <div className="md:w-3/6">
                        <div className="flex">
                            <div className="relative flex-grow mr-5">
                                <UserSelector
                                    value={selectedUser}
                                    onChange={setSelectedUser}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="md:w-3/6">
                        <CommonButton
                            ref={commonButtonRef}
                            disabled={selectedUser === null}
                            onClick={onSupervise}
                            label={t(
                                'sections.management.section.users.supervisor.supervise-button'
                            )}
                            styleType={'primary'}
                            useLoader={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupervisorControl;

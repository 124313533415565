import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../../../containers/UseService';
import LinkButton from '../../../../Buttons/LinkButton';
import CommercialPersonModal from './Modal/Modal';
import CommonButton from '../../../../Buttons/CommonButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteModal from './DeleteModal/DeleteModal';

const CommercialInformation = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const service = useService();
    const commercialsPersons = useSelector(
        (state) => state.commercialsPersons.commercialsPersons
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [selectedCommercialPerson, setSelectedCommercialPerson] =
        useState(null);
    const toggleModalOpen = () => setIsModalOpen(!isModalOpen);
    const toggleModalDeleteOpen = () =>
        setIsModalDeleteOpen(!isModalDeleteOpen);
    const translations =
        'sections.management.section.company.companyCommercial.';

    useEffect(service.getCommercialsPersons, []);

    const editCommercialPerson = (commercialPerson) => {
        setSelectedCommercialPerson(commercialPerson);
        toggleModalOpen();
    };

    const deleteCommercialPerson = (commercialPerson) => {
        setSelectedCommercialPerson(commercialPerson);
        toggleModalDeleteOpen();
    };

    const createCommercialPerson = async () => {
        setSelectedCommercialPerson(null);
        toggleModalOpen();
    };

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12}`}>
            <div className="text-gray-600 text-xl font-light">
                {t(translations + 'title')}
            </div>
            {commercialsPersons.length === 0 ? (
                <div className="text-gray-700 text-sm font-bold mt-4">
                    {t(translations + 'empty-title')}{' '}
                    <LinkButton onClick={createCommercialPerson}>
                        {t(translations + 'add-one')}
                    </LinkButton>
                </div>
            ) : (
                <div className="mt-4">
                    {commercialsPersons.map((commercialPerson) => (
                        <div
                            className="p-3 shadow-md mb-4 rounded-lg"
                            key={commercialPerson.id}>
                            <div className="flex justify-between">
                                <div>
                                    <span className="text-gray-700 text-sm font-bold">
                                        {t(translations + 'name')}
                                    </span>
                                    :{' '}
                                    <span className="text-gray-700 text-sm font-light">
                                        {commercialPerson.name}
                                    </span>
                                </div>
                                <div className="flex">
                                    <div
                                        onClick={() =>
                                            editCommercialPerson(
                                                commercialPerson
                                            )
                                        }>
                                        <FontAwesomeIcon
                                            className="text-gray-500 cursor-pointer"
                                            icon={['fad', 'pencil']}
                                            fixedWidth
                                        />
                                    </div>
                                    <div
                                        className="ml-2"
                                        onClick={() =>
                                            deleteCommercialPerson(
                                                commercialPerson
                                            )
                                        }>
                                        <FontAwesomeIcon
                                            className="text-red-500 cursor-pointer"
                                            icon={['fad', 'trash']}
                                            fixedWidth
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className="text-gray-700 text-sm font-bold">
                                    {t(translations + 'email')}
                                </span>
                                :{' '}
                                <span className="text-gray-700 text-sm font-light">
                                    {commercialPerson.email}
                                </span>
                            </div>
                            <div>
                                <span className="text-gray-700 text-sm font-bold">
                                    {t(translations + 'role')}
                                </span>
                                :{' '}
                                <span className="text-gray-700 text-sm font-light">
                                    {commercialPerson.role}
                                </span>
                            </div>
                            <div>
                                <span className="text-gray-700 text-sm font-bold">
                                    {t(translations + 'phoneNumber')}
                                </span>
                                :{' '}
                                <span className="text-gray-700 text-sm font-light">
                                    {commercialPerson.phoneNumber}
                                </span>
                            </div>
                        </div>
                    ))}

                    <div className="mt-5">
                        <CommonButton
                            onClick={createCommercialPerson}
                            label={t(translations + 'add-commercial-person')}
                            styleType={'primary'}
                        />
                    </div>
                </div>
            )}
            <CommercialPersonModal
                isActive={isModalOpen}
                toggle={toggleModalOpen}
                commercialPerson={selectedCommercialPerson}
                edit={selectedCommercialPerson !== null}
                onSubmit={() => setSelectedCommercialPerson(null)}
            />
            <DeleteModal
                isActive={isModalDeleteOpen}
                toggle={toggleModalDeleteOpen}
                commercialPerson={selectedCommercialPerson}
                onSubmit={() => setSelectedCommercialPerson(null)}
            />
        </div>
    );
};

export default CommercialInformation;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyText from '../../Formats/CurrencyText';
import { useService } from '../../../containers/UseService';
import ProgressBar from '../../Progress/ProgressBar';
import {
    isByHourCurrentPeriod,
    isPeriodSelectedInRangeOfCampaign,
    numberWithoutCommas,
    campaignStatus,
    formatNumber,
} from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import { dashboardActions } from '../../../store';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardStats = ({ currentPeriod, seeImpressions }) => {
    const currentPeriodName = useSelector(
        (state) => state.extra.currentPeriodName
    );
    const service = useService();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [campaignPercentage, setCampaignPercentage] = useState(0);
    const dashboard = useSelector((state) => state.dashboard);
    const stats = dashboard.individualCampaignStats;
    const campaign = dashboard.campaign;
    const limitTypes = useMemo(() => ['impressions', 'budget']);
    const limits = useMemo(() => ['impressions', 'spent']);
    const [showPercent, setShowPercent] = useState(true);
    const [sizePerModule, setSizePerModule] = useState(12);
    const budgetForPercent = campaign.budget;

    const has = (statType) => stats[statType] !== 0 && stats[statType] !== '-';

    const calcSizePerModules = () => {
        let amountModules = 1;
        amountModules += has('impressions') ? 1 : 0;
        amountModules += has('impacts') ? 1 : 0;
        amountModules += has('ecpmAverage') ? 1 : 0;
        setSizePerModule(12 / amountModules);
    };

    useEffect(() => {
        const isOnRange = isPeriodSelectedInRangeOfCampaign(
            currentPeriod,
            campaign
        );
        if (campaign.id && currentPeriod[0] && currentPeriod[1] && isOnRange) {
            service.getTimezones();
            if (campaign.status === 5 && isOnRange) {
                service.getCampaignPrediction(campaign);
            } else {
                dispatch(dashboardActions.setCampaignPrediction({}));
            }
        }
    }, [campaign, currentPeriod]);

    const percentBySpent = (spent, budget) => {
        const percent = (spent * 100) / budget;
        return percent;
    };

    useEffect(() => {
        if (stats.spent !== '-') {
            let todaySpentOrImpressions = null;
            todaySpentOrImpressions = parseInt(
                numberWithoutCommas(stats.spent)
            );
            let percentage = percentBySpent(
                todaySpentOrImpressions,
                budgetForPercent
            );
            setCampaignPercentage(percentage.toFixed(2) || 0);
            setShowPercent(true);
        }
        calcSizePerModules();
    }, [stats]);

    const translateLimits = (limit) => t('common.words.' + limit);
    return (
        <>
            {stats.spent !== 0 ? (
                <div
                    className={`module md:flex-1 flex justify-between flex-row md:flex-col lg:flex-row md:space-y-2 divide-x md:divide-x-0 md:divide-y lg:divide-y-0 space-x-2 lg:space-x-2 lg:divide-x divide-dotted`}>
                    <div className="w-full relative">
                        <span className="font-light text-xs uppercase">
                            {currentPeriodName}{' '}
                            {translateLimits(limits[campaign.limit_type])}{' '}
                        </span>
                        <ReactTooltip
                            effect="solid"
                            className="w-48 p-2"
                            id="currentPeriodName"
                            aria-haspopup="true">
                            {t('sections.dashboard.spent-info')}
                        </ReactTooltip>
                        <a data-tip data-for="currentPeriodName">
                            <FontAwesomeIcon
                                icon={['fad', 'info-circle']}
                                className="text-gray-700 text-sm"
                            />
                        </a>{' '}
                        <br />
                        <FontAwesomeIcon
                            icon={['fal', 'circle-notch']}
                            size="2x"
                            className={`${
                                dashboard.isSilentLoading
                                    ? 'absolute -right-2 -bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                    : 'hidden'
                            }`}
                        />
                        <>
                            {stats.spent ? (
                                <div className="inline-flex ">
                                    <CurrencyText
                                        currency={campaign.currency.short_name}
                                        currencySymbol={
                                            campaign.currency.symbol
                                        }
                                        value={stats.spent}
                                        campaignBudget={campaign.budget}
                                    />{' '}
                                    <span className="font-semibold text-xl pl-1"></span>{' '}
                                </div>
                            ) : (
                                <div className="font-semibold text-2xl">
                                    {formatNumber(stats.spent, 'currency')}
                                </div>
                            )}
                        </>
                        {showPercent && (
                            <div className={showPercent ? '' : 'invisible'}>
                                <div className="flex flex-row justify-between">
                                    <div className="currentStatus flex flex-col justify-between">
                                        <div
                                            className="mt-2 text-gray-400"
                                            style={{ fontSize: '80%' }}>
                                            {formatNumber(
                                                campaignPercentage,
                                                'cpi'
                                            )}
                                            %{' '}
                                            {
                                                <>
                                                    {t(
                                                        'common.modules.todaySpentDailyCapBudgetPercent'
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="mt-2 text-gray-400 self-end"
                                        style={{ fontSize: '80%' }}>
                                        <span
                                            style={{ fontSize: '70%' }}
                                            className={`text-xs font-thin text-gray-500`}>
                                            {' '}
                                            Total:
                                            {` ${
                                                campaign?.currency?.symbol ||
                                                campaign?.currency?.short_name
                                            }`}
                                        </span>
                                        {formatNumber(
                                            campaign.budget,
                                            'rounded'
                                        )}
                                    </div>
                                </div>

                                {/* Barra de progreso */}
                                <ProgressBar
                                    progress={campaignPercentage}
                                    isTopModule={true}
                                    isActive={true}
                                    campaignPercentage={campaignPercentage}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
            {has('ecpmAverage') && (
                <div className="module relative">
                    <div className="w-full h-full">
                        <div className="font-light text-xs uppercase">
                            {t('common.words.ecpm')}
                        </div>
                        <CurrencyText
                            currency={campaign.currency.short_name}
                            currencySymbol={campaign.currency.symbol}
                            value={stats.ecpmAverage}
                        />
                        <FontAwesomeIcon
                            icon={['fal', 'circle-notch']}
                            size="2x"
                            className={`${
                                dashboard.isSilentLoading
                                    ? 'absolute -right-1 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                    : 'hidden'
                            } `}
                        />
                    </div>
                </div>
            )}
            {has('impressions') && seeImpressions && (
                <div className={`module`}>
                    <div className="font-light text-xs uppercase">
                        {currentPeriodName}{' '}
                        {translateLimits(limits[!campaign.limit_type * 1])}
                    </div>
                    {!campaign.limit_type ? (
                        <>
                            {stats.spent !== '-' ? (
                                <CurrencyText
                                    currency={campaign.currency.short_name}
                                    currencySymbol={campaign.currency.symbol}
                                    value={stats.spent}
                                />
                            ) : (
                                <div className="font-semibold text-2xl">
                                    {formatNumber(stats.spent, 'rounded')}
                                    <FontAwesomeIcon
                                        icon={['fal', 'circle-notch']}
                                        size="2x"
                                        className={`${
                                            dashboard.isSilentLoading
                                                ? 'absolute -right-1 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                                : 'hidden'
                                        }`}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="font-semibold text-2xl">
                            {formatNumber(stats.impressions, 'rounded')}
                            <FontAwesomeIcon
                                icon={['fal', 'circle-notch']}
                                size="2x"
                                className={`${
                                    dashboard.isSilentLoading
                                        ? 'absolute -right-1 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                        : 'hidden'
                                }`}
                            />
                        </div>
                    )}
                    {/* <div className="currentStatus flex justify-between">
                    <div className="mt-2 text-gray-400" style={{fontSize:'60%'}}>{t("sections.dashboard-individual.accumulated")} {translateLimits(limits[!campaign.limit_type * 1])}.</div>
                    <div className="mt-2" style={{fontSize:'60%'}}>-</div>
                </div> */}
                </div>
            )}
            {has('impacts') && (
                <div className={`module flex-1`}>
                    <div className="font-light text-xs uppercase">
                        {t('common.words.impacts')}
                    </div>
                    <div className="font-semibold text-2xl">
                        {formatNumber(stats.impacts, 'rounded')}
                    </div>
                    <div className="currentStatus flex justify-between">
                        {/* LINEA QUE MUESTRA COST PER IMPACT */}
                        <div
                            className="mt-2 text-gray-400 "
                            style={{ fontSize: '80%' }}>
                            {t('common.words.cost-per-impact')}.
                        </div>

                        {/* LINEA QUE MUESTRA LA MONEDA, EL SIMBOLO DE LA MISMA Y EL VALOR DEL COST POR IMPACT */}
                        {/* <div
                            className="mt-2 text-blue-400 border border-green-500 border-solid"
                            style={{ fontSize: '80%' }}>
                            <span
                                style={{ fontSize: '80%' }}
                                className="text-xs font-thin text-gray-500">
                                {campaign.currency
                                    ? campaign.currency.short_name +
                                      ' ' +
                                      campaign.currency.symbol
                                    : ''}
                            </span>{' '}
                            {formatNumber(stats.spot, 'cpi')}
                                </div> */}

                        <CurrencyText
                            currency={campaign.currency.short_name}
                            currencySymbol={campaign.currency.symbol}
                            value={
                                stats.ecpmAverage
                                    ? stats.ecpmAverage / 1000
                                    : undefined
                            }
                        />
                        <FontAwesomeIcon
                            icon={['fal', 'circle-notch']}
                            size="2x"
                            className={`${
                                dashboard.isSilentLoading
                                    ? 'absolute -right-1 bottom-2 h-4 mx-1 animate-spin text-blue-400'
                                    : 'hidden'
                            }`}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
export default DashboardStats;

import React from 'react';
import { useSelector } from 'react-redux';

const Block = (props) => {
    const { children, icon, title, button, useButton } = props;
    const isResizeMapOn = useSelector(
        (state) => state.newCampaign.isResizeMapOn
    );

    return (
        <div className="block py-4">
            <div className="flex mb-2 ">
                {icon}
                {useButton ? (
                    <div className="flex justify-between mb-2 w-full">
                        <span className="text-xl font-light">{title}</span>
                        {button}
                    </div>
                ) : (
                    <span className="text-xl font-light">{title}</span>
                )}
            </div>
            <div
                className={`${
                    isResizeMapOn ? 'flex-col' : 'flex-col md:flex-row'
                } flex justify-between divide-x divide-dotted`}>
                {children}
            </div>
        </div>
    );
};

export default Block;

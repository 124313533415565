import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdOperationsLeaders from './Submenu/AdOperationsLeaders/AdOperationsLeaders';

const Commercial = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const [isActiveCommercialMenu, setIsActiveCommercialMenu] =
        useState('adOperationsLeader');
    const handleCommercialMenu = (section) =>
        setIsActiveCommercialMenu(section);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-20 md:w-44">
                    <nav className="flex flex-col">
                        <button
                            id="commercialSectionAdOperationsLeader"
                            onClick={() =>
                                handleCommercialMenu('adOperationsLeader')
                            }
                            className={`${
                                isActiveCommercialMenu === 'adOperationsLeader'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.commercial.adOperationsLeader.tab'
                                )}
                            </span>{' '}
                            <FontAwesomeIcon
                                icon={['fal', '']}
                                className="text-blue-500"
                                fixedWidth
                            />
                        </button>
                    </nav>
                </div>

                <AdOperationsLeaders
                    isActive={isActiveCommercialMenu === 'adOperationsLeader'}
                />
            </div>
        </div>
    );
};

export default Commercial;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScreensDetails = ({
    name,
    isOnline,
    currencyMatched,
    currency_name,
    sizesMatched,
    size,
    screenTranslations,
    className,
    audienceMached,
    isBuying,
    priceMatched,
    screenId,
}) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <h4>
                <span className="font-bold">{` #${name}`}</span>
            </h4>
            <p className="font-bold">
                - {t(screenTranslations + 'id') + ': '}
                <span className="inline font-medium font-inter items-center p-0.5 mr-2 bg-gray-200 text-gray-600 rounded px-2">
                    {`${screenId}`}
                </span>
            </p>
            <div className="flex justify-between pr-4">
                <p className="font-bold capitalize ">
                    {'- ' + t(screenTranslations + 'status') + ': '}
                    <span className="font-normal">
                        {`${
                            isOnline
                                ? t(screenTranslations + 'online')
                                : t(screenTranslations + 'offline')
                        }`}
                        .
                        {isBuying
                            ? ` ${t(screenTranslations + 'status-buying')}`
                            : ` ${t(screenTranslations + 'not-buying')}`}
                        .
                    </span>
                </p>
                <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    className={isBuying ? 'text-green-500' : 'text-red-500'}
                />
            </div>
            <p className="font-bold capitalize">
                {'- ' + t(screenTranslations + 'currency') + ': '}
                <span className="font-normal">
                    {`${
                        currencyMatched
                            ? currency_name +
                              ', ' +
                              t(screenTranslations + 'match')
                            : t(screenTranslations + 'no-match')
                    }`}
                </span>
            </p>
            <p className="font-bold capitalize">
                {'- ' + t(screenTranslations + 'creative-size') + ': '}
                <span className="font-normal">
                    {`${
                        sizesMatched
                            ? t(screenTranslations + 'match')
                            : t(screenTranslations + 'no-match')
                    }, `}
                    {size}
                </span>
            </p>
            <p className="font-bold capitalize">
                {'- ' + t(screenTranslations + 'audience') + ': '}
                <span className="font-normal">
                    {`${
                        audienceMached
                            ? t(screenTranslations + 'match')
                            : t(screenTranslations + 'no-match')
                    }`}
                </span>
            </p>
            <p className="font-bold capitalize">
                {'- ' + t(screenTranslations + 'price') + ': '}
                <span className="font-normal">
                    {`${
                        priceMatched
                            ? t(screenTranslations + 'match')
                            : t(screenTranslations + 'no-match')
                    }`}
                </span>
            </p>
        </div>
    );
};

ScreensDetails.propTypes = {
    name: PropTypes.string.isRequired,
    isOnline: PropTypes.bool.isRequired,
    currencyMatched: PropTypes.bool.isRequired,
    currency_name: PropTypes.string.isRequired,
    sizesMatched: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    screenTranslations: PropTypes.string.isRequired,
    className: PropTypes.string,
    audienceMached: PropTypes.bool.isRequired,
};

export default ScreensDetails;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    audienceProviders,
    isProviderAvailableInCountriesSelected,
} from '../../../../services/providersService';
import { useSelector, useDispatch } from 'react-redux';
import { useService } from '../../../../containers/UseService';
import Select from 'react-select';
import { newCampaignActions } from '../../../../store';
import { determineDataProvider } from '../../../../utils/functions';

export default function AudienceProvider() {
    const { t } = useTranslation();
    const service = useService();
    const selectedCountries = useSelector(
        (state) => state.newCampaign.location.selectedCountries
    );

    const dispatch = useDispatch();

    const newCampaign = useSelector((state) => state.newCampaign);

    const providers = audienceProviders;

    const handleProviderChange = (e) => {
        dispatch(
            newCampaignActions.setDemographics({
                ...newCampaign.demographics,
                id: e?.value ?? '',
            })
        );
        service.getProviderMetadata(e.value);
        const newSegments = Object.assign({}, newCampaign.segments);
        newSegments.incomeLevel = [];
        newSegments.gender = [];
        dispatch(newCampaignActions.setSegments(newSegments));
    };

    function formattedSelectOptions(providersRaw) {
        let providers = providersRaw;
        let filteredproviders = providers.filter((item) => {
            return isProviderAvailableInCountriesSelected(
                item.availableCountries,
                selectedCountries
            );
        });

        let returnValue = filteredproviders.length
            ? filteredproviders.map((item) => {
                  return {
                      label: item.title,
                      value: item.name,
                  };
              })
            : [
                  {
                      label: t(
                          'sections.campaigns.new-campaign.wizard-basics.audience-provider.no-provider-available'
                      ),
                      value: '',
                  },
              ];

        return returnValue;
    }

    const [
        defaultAudienceProviderByCountries,
        setDefaultAudienceProviderByCountries,
    ] = useState(null);

    const defaultAudienceProviderSelectValue = providers.filter((item) => {
        if (newCampaign.demographics.id) {
            return item.name === newCampaign.demographics.id;
        } else if (
            !newCampaign.demographics.id &&
            formattedSelectOptions(providers).length
        ) {
            return formattedSelectOptions(providers)[0];
        }
    });

    const formattedDefaultAudienceProviderSelectValue = formattedSelectOptions(
        defaultAudienceProviderSelectValue
    );

    const refSelectInput = useRef();

    useEffect(() => {
        const updatedDefaultProvider = formattedSelectOptions(providers)[0];

        if (newCampaign.demographics.id !== '') {
            const selectedProvider = providers.find(
                (item) => item.name === newCampaign.demographics.id
            );
            if (
                !selectedProvider ||
                !isProviderAvailableInCountriesSelected(
                    selectedProvider.availableCountries,
                    selectedCountries
                )
            ) {
                setDefaultAudienceProviderByCountries(updatedDefaultProvider);
                dispatch(
                    newCampaignActions.setDemographics({
                        ...newCampaign.demographics,
                        id: updatedDefaultProvider.value || '',
                    })
                );
            }
        }
    }, [
        selectedCountries,
        providers,
        defaultAudienceProviderByCountries,
        newCampaign.demographics,
    ]);

    return (
        <>
            <div className="rounded m-1 mb-4">
                <div className="header flex justify-between content-center">
                    <div className="title text-xl font-extralight hidden">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.audience-provider.title'
                        )}
                    </div>
                    <div className="brief w-full">
                        {formattedDefaultAudienceProviderSelectValue && (
                            <Select
                                ref={refSelectInput}
                                options={formattedSelectOptions(providers)}
                                onChange={handleProviderChange}
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.audience-provider.select-novalue'
                                )}
                                value={
                                    formattedDefaultAudienceProviderSelectValue[0]
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

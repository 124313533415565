import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import UserList from './Submenu/UserList/UserList';
import SupervisorControl from './Submenu/SupervisorControl/SupervisorControl';
import { useAuth } from '../../../containers/UseAuth';

const Users = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const [isActiveCommercialMenu, setIsActiveCommercialMenu] =
        useState('supervisor');
    const auth = useAuth();

    const handleCommercialMenu = (section) =>
        setIsActiveCommercialMenu(section);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-20 md:w-44">
                    <nav className="flex flex-col items-center">
                        <button
                            id="commercialSectionCustomers"
                            onClick={() => handleCommercialMenu('supervisor')}
                            className={`${
                                isActiveCommercialMenu === 'supervisor'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right flex items-center`}>
                            <span className="hidden md:inline-block ">
                                {t(
                                    'sections.management.section.users.supervisor.tab'
                                )}
                            </span>{' '}
                            <FontAwesomeIcon
                                icon={['fal', 'eye']}
                                className="text-blue-500"
                            />
                        </button>
                        {auth.isAdmin() && (
                            <button
                                id="buttonUsersTable"
                                onClick={() =>
                                    handleCommercialMenu('customers')
                                }
                                className={`${
                                    isActiveCommercialMenu === 'customers'
                                        ? 'tabActiveV font-semibold truncate'
                                        : ''
                                } tabGeneral uppercase text-xs text-right flex items-center`}>
                                <span className="hidden md:inline-block ">
                                    {t(
                                        'sections.management.section.users.users.tab'
                                    )}
                                </span>{' '}
                                <FontAwesomeIcon
                                    icon={['fal', 'user-friends']}
                                    className="text-blue-500"
                                />
                            </button>
                        )}
                    </nav>
                </div>

                {auth.isAdmin() && (
                    <UserList
                        isActive={isActiveCommercialMenu === 'customers'}
                    />
                )}
                <SupervisorControl
                    isActive={isActiveCommercialMenu === 'supervisor'}
                />
            </div>
        </div>
    );
};

export default Users;

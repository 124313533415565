import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { newCampaignActions, notificationsActions } from '../../../store';
import { useAuth } from '../../../containers/UseAuth';
import ReactTooltip from 'react-tooltip';
import Providers from '../WizardAudiences/ProvidersTabs/Providers';
import CommonButton from '../../Buttons/CommonButton';
import ForecastMetrics from '../WizardAudiences/ForecastMetrics/ForecastMetrics';
import ForecastTable from '../WizardAudiences/ForecastTable/ForecastTable';
import MyResponsivePie from '../../Graph/Pie';
import {
    getValidatedPriceFromScreen,
    toFloat,
    translateDaysEnabled,
} from '../../../utils/functions';

export default function CalculateForecast({
    campaign,
    newCampaign,
    dispatch,
    service,
    setSelectedScreensLocal,
    budgets,
    isActive,
    setCalculatingForecast,
}) {
    const { t } = useTranslation();
    const navProvidersRef = useRef();

    const [hasAnyProvider, setHasAnyProvider] = useState(false);
    const [valid, setValidation] = useState(false);
    const [errors, setErrors] = useState([]);

    const auth = useAuth();

    const forecastTable = newCampaign.forecastTable;
    const selectedCurrency = newCampaign.selectedCurrency;
    const endDate = newCampaign.timing.periodValue
        ? new Date(newCampaign.timing.periodValue[1])
        : null;
    const today = new Date();
    const tomorrow = new Date(today);
    const forecastGraph = forecastTable.map((data) => {
        return {
            id: `${data.gender} ${data.age} ${data.incomeLevel}`,
            value: data.totalImpacts,
        };
    });

    const basicWizard = t('sections.campaigns.new-campaign.wizard-tab.basics');

    const addError = (wizard, title, description, errors) => {
        const newError = { wizard, title, description };
        errors.push(newError);
    };

    const minValue = (min, number) => {
        if (number !== '') {
            return number >= min;
        } else {
            return false;
        }
    };

    const selectedAnyDayOrHour = () => {
        return (
            campaign.timing.daysEnabled.length > 0 &&
            campaign.timing.hoursEnabled.length > 0
        );
    };

    const hasSufficientFunds = () => {
        if (budgets) {
            const selectedCurrency = campaign.selectedCurrency.short_name;
            return (
                selectedCurrency in budgets && budgets[selectedCurrency] >= 0
            );
        } else {
            return true;
        }
    };

    const checkErrors = () => {
        const errors = [];
        const i18nErrors =
            'sections.campaigns.new-campaign.wizard-review.validation-issues.errors.';
        const i18nBasics = 'sections.campaigns.new-campaign.wizard-basics.';
        const i18nAudiences =
            'sections.campaigns.new-campaign.wizard-audiences.';
        //This value is the old cost per mil impressions. Now cost per spot
        const budgetCpm = toFloat(campaign.budget.cpm);
        let locale = navigator.languages
            ? navigator.languages[0]
            : navigator.language;

        if (auth.session.user.cpm_mode === 0) {
            // FIXME: CPM refactor this when the calc is done in the backend
            // Hay una diferencia de sincronización que hace que a veces devuelva en
            // número y otras en string
            let minCpm;
            let cpmArr = [];
            if (
                campaign.selectedScreens.length > 0 &&
                getValidatedPriceFromScreen(campaign.selectedScreens[0])
            ) {
                //filter screens withOutPrice (if there's one) before using Math.min to avoid Nan on minCpm
                let screensWithPrice = campaign.selectedScreens.filter(
                    (screen) => {
                        return getValidatedPriceFromScreen(screen);
                    }
                );
                if (screensWithPrice.length) {
                    minCpm = Math.min(
                        ...screensWithPrice.map((screen) => {
                            return parseFloat(
                                getValidatedPriceFromScreen(screen)
                            );
                        })
                    );
                }
            } else {
                if (locale !== 'en-US' && locale !== 'es-419') {
                    campaign.selectedScreens.forEach((screen) => {
                        if (screen.cpm) {
                            //FIXME: when the page render and has no data in local storage the cpm value is undefined
                            let currencySymbol =
                                campaign.selectedCurrency.symbol;
                            let screenStr = String(screen.cpm);
                            let splitedCpm = screenStr
                                .replace(currencySymbol, '')
                                .replace('.', '')
                                .replace(',', '.');
                            cpmArr.push(toFloat(splitedCpm));
                        }
                    });
                } else {
                    campaign.selectedScreens.forEach((screen) => {
                        if (screen.cpm) {
                            //FIXME: when the page render and has no data in local storage the cpm value is undefined
                            let currencySymbol =
                                campaign.selectedCurrency.symbol;
                            let screenStr = String(screen.cpm);
                            let splitedCpm = screenStr.replace(
                                currencySymbol,
                                ''
                            );
                            cpmArr.push(toFloat(splitedCpm));
                        }
                    });
                }

                minCpm = Math.min(...cpmArr);
            }

            if (budgetCpm < minCpm) {
                addError(
                    basicWizard,
                    t(i18nBasics + 'budget.title'),
                    t(i18nErrors + 'lower-CPM'),
                    errors
                );
            }
        }

        if (campaign.selectedScreens.length == 0) {
            addError(
                basicWizard,
                t(i18nAudiences + 'forecast.screens'),
                t(i18nErrors + 'screens'),
                errors
            );
        }
        if (!minValue(1, campaign.budget.budget)) {
            addError(
                basicWizard,
                t(i18nBasics + 'budget.title'),
                t(i18nErrors + 'budget'),
                errors
            );
        } else if (!hasSufficientFunds()) {
            addError(
                basicWizard,
                t(i18nBasics + 'budget.title'),
                t(i18nErrors + 'budget-insufficient'),
                errors
            );
        }
        if (
            !campaign.budget.cpmOptimizer &&
            !minValue(1, campaign.budget.cpm)
        ) {
            addError(
                basicWizard,
                t(i18nBasics + 'budget.title'),
                t(i18nErrors + 'CPM'),
                errors
            );
        }
        if (campaign.dailyCap.isActive && !campaign.dailyCap.isDistribution) {
            if (
                campaign.dailyCap.isBudget &&
                !minValue(1, campaign.dailyCap.budget)
            ) {
                addError(
                    basicWizard,
                    t(i18nBasics + 'dailyCap.title'),
                    t(i18nErrors + 'daily-cap-budget'),
                    errors
                );
            }
            if (
                campaign.dailyCap.isImpressions &&
                !minValue(1, campaign.dailyCap.impressions)
            ) {
                addError(
                    basicWizard,
                    t(i18nBasics + 'dailyCap.title'),
                    t(i18nErrors + 'daily-cap-impressions'),
                    errors
                );
            }
        }
        if (!campaign.timing.periodValue) {
            addError(
                basicWizard,
                t(i18nBasics + 'timing.title'),
                t(i18nErrors + 'timing-campaign-duration'),
                errors
            );
        }
        if (campaign.timing.selectedTimezone === '') {
            addError(
                basicWizard,
                t(i18nBasics + 'timing.title'),
                t(i18nErrors + 'timing-timezone'),
                errors
            );
        }
        if (campaign.timing.isDayParting && !selectedAnyDayOrHour()) {
            addError(
                basicWizard,
                t(i18nBasics + 'timing.title'),
                t(i18nErrors + 'timing-day-parting'),
                errors
            );
        }
        if (
            campaign.buyingRules.isActive &&
            campaign.buyingRules.rules.length === 0
        ) {
            addError(
                basicWizard,
                t(i18nBasics + 'buyingRules.title'),
                t(i18nErrors + 'buying-rules'),
                errors
            );
        }
        setErrors(errors);
    };

    useEffect(() => {
        checkErrors();
    }, [campaign, budgets]);

    useEffect(() => {
        setValidation(errors.length === 0);
    }, [errors]);

    const onClickCalculateForecast = async () => {
        if (endDate <= tomorrow.getTime()) {
            const errorMessage = `${t(
                'sections.dashboard-individual.end-date-out-of-range-description'
            )}`;
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t(
                        'sections.dashboard-individual.period-out-of-range-title'
                    ),
                    message: errorMessage,
                })
            );
            return;
        }
        setCalculatingForecast(true);
        await service.calculateForecast(newCampaign);
        setCalculatingForecast(false);
    };

    useEffect(() => {
        if (isActive) {
            setHasAnyProvider(navProvidersRef.current.children.length > 0);
        }
    }, [isActive]);

    // for when the campaign have a provider audience and selected other
    // country withoud provider
    if (!isActive && newCampaign.demographics.name) {
        dispatch(newCampaignActions.setDemographics({ id: '' }));
    }

    return (
        <div className="body rounded-sm p-1">
            <nav className="flex" ref={navProvidersRef}>
                <Providers />
            </nav>
            {hasAnyProvider ? (
                <>
                    <div className="block mt-4">
                        <div className="text-lg font-light">
                            {t(
                                'sections.campaigns.new-campaign.wizard-audiences.forecast.forecast'
                            )}
                        </div>
                        <div className="text-gray-600 flex justify-between">
                            <div>
                                <span className="font-normal">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-audiences.demographics.currency'
                                    )}
                                    : {selectedCurrency.short_name}
                                </span>
                                <br />
                                <span className="text-xs font-bold">
                                    {t(
                                        `sections.campaigns.new-campaign.wizard-audiences.forecast.${campaign.demographics.id}-info`
                                    )}
                                </span>
                            </div>
                            <div>
                                <CommonButton
                                    disabled={!valid}
                                    className={
                                        newCampaign.demographics.id === '' &&
                                        'invisible'
                                    }
                                    useLoader={true}
                                    type="primaryButton"
                                    onClick={onClickCalculateForecast}
                                    label={t(
                                        'sections.campaigns.new-campaign.wizard-audiences.forecast.calculate-forecast'
                                    )}
                                    styleType={'primary'}
                                />

                                {valid ? (
                                    ''
                                ) : (
                                    <span className="mr-2">
                                        {' '}
                                        <ReactTooltip
                                            effect="solid"
                                            className="w-48 "
                                            id="notValid"
                                            aria-haspopup="true">
                                            <ul className="list-none text-white">
                                                {errors.map((error, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="py-1">
                                                            <span className="text-lg text-white">
                                                                {error.title}:{' '}
                                                            </span>{' '}
                                                            {error.description}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </ReactTooltip>
                                        <span data-tip data-for="notValid">
                                            <FontAwesomeIcon
                                                icon={['fad', 'info-circle']}
                                                className="text-gray-700 text-sm"
                                            />
                                        </span>{' '}
                                        <br />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-start content-center my-2 p-2 rounded bg-gray-100 text-gray-500">
                            <div className="ml-2 mr-4 mt-2">
                                <FontAwesomeIcon
                                    icon={['fad', 'comment-alt-lines']}
                                    className="text-gray-400 text-2xl"
                                />
                            </div>
                            <div>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.according-your-targeting-selection'
                                )}
                            </div>
                        </div>
                        <ForecastMetrics
                            setSelectedScreensLocal={setSelectedScreensLocal}
                        />
                        <div className="flex my-4">
                            <div
                                className={`w-7/12 flex flex-col ${
                                    forecastTable.length === 0 && 'invisible'
                                }`}>
                                <span className="text-lg font-light text-gray-500">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-audiences.forecast.reach-composition'
                                    )}
                                </span>
                                <div className="flex-grow overflow-auto h-16">
                                    <ForecastTable />
                                </div>
                            </div>
                            <div className="w-5/12 p-5 h-60">
                                <MyResponsivePie data={forecastGraph} />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="mt-2">
                        <span>
                            {t(
                                'sections.campaigns.new-campaign.wizard-audiences.no-providers-message'
                            )}
                            .
                        </span>
                    </div>
                </>
            )}
        </div>
    );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    list: [],
    current: 0,
};

const { reducer, actions } = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        setNotification(state, action) {
            let notification = {
                id: state.current + 1,
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
            };

            state.list = [...state.list, notification];
            state.current = state.current + 1;
        },
        setNotifications(state, action) {
            let notifications = action.payload;

            notifications.map((notification) => {
                let notif = {
                    id: state.current + 1,
                    type: notification.type,
                    title: notification.title,
                    message: notification.message,
                };

                state.list = [...state.list, notif];
                state.current = state.current + 1;
            });
        },
        removeNotification(state, action) {
            let notifications = [...state.list];
            notifications = notifications.filter((notification) => {
                return notification.id !== action.payload;
            });
            state.list = [...notifications];
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as notificationsActions };
export { reducer as notificationsReducer };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import Hint from '../../../Tools/Hint';

const PercentageGraph = ({ percentaje }) => {
    const { t } = useTranslation();
    const valorMostrado =
        percentaje !== undefined ? percentaje : 'Espacio libre';

    const data = {
        datasets: [
            {
                data: [
                    100 - percentaje >= 0 ? (100 - percentaje).toFixed(2) : 0,
                    percentaje || 0,
                ],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                ],
                hoverBorderColor: ['transparent', 'transparent'],
                label: 'percentaje',
                hoverOffset: 4,
            },
        ],
        labels: [
            t(
                'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.free-space'
            ),
            t(
                'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.in-use'
            ),
        ],
    };

    const options = {
        cutoutPercentage: 80,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = data.labels[context.dataIndex];
                        label += ': ' + context.parsed.y + '%';
                        return label;
                    },
                },
            },
        },
    };

    const plugins = [
        {
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;

                var fontSize = (height / 140).toFixed(2);
                ctx.font = fontSize + 'em sans-serif';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = 'rgba(0, 0, 0)';

                var text = Math.round(valorMostrado) + '%',
                    textX = Math.round(
                        (width - ctx.measureText(text).width) / 2
                    );

                var textHeight = ctx.measureText('M').width;

                var textY = 69;
                textY += textHeight;

                ctx.fillText(text, textX, textY);

                textY += textHeight;

                ctx.font = fontSize * 0.8 + 'em sans-serif';
                ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';

                var inUseTextWidth = ctx.measureText(
                    t(
                        'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.in-use'
                    ).toLowerCase()
                ).width;

                var inUseTextX = Math.round((width - inUseTextWidth) / 2);

                ctx.fillText(
                    t(
                        'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.in-use'
                    ).toLowerCase(),
                    inUseTextX,
                    textY
                );

                ctx.save();
            },
        },
    ];

    return percentaje || percentaje == 0 ? (
        <div>
            <div className="flex justify-between">
                <h2 className="text-gray-700">
                    {t(
                        'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.capacity-usage'
                    )}
                </h2>
                <Hint
                    className="mr-3"
                    title={t(
                        'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.capacity-usage'
                    )}
                    text={t(
                        'sections.campaigns.new-campaign.wizard-screens.sidebar.doughnut.hintDescription'
                    )}
                />
            </div>
            <Doughnut data={data} options={options} plugins={plugins} />
        </div>
    ) : (
        <div></div>
    );
};

export default PercentageGraph;

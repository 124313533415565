import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../utils/functions';

const ForecastTable = () => {
    const { t } = useTranslation();
    const forecastTable = useSelector(
        (state) => state.newCampaign.forecastTable
    );
    /**
     * Disabled temporally until we can have details about the forecast
     */

    return (
        <table className="relative w-full">
            <thead>
                <tr>
                    <th className="sticky text-left rounded-tl-lg bg-gray-200 top-0 px-6 py-3">
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.table.gender'
                        )}
                    </th>
                    <th className="sticky text-left bg-gray-200 top-0 px-6 py-3">
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.table.age'
                        )}
                    </th>
                    <th className="sticky text-left bg-gray-200 top-0 px-6 py-3">
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.table.income'
                        )}
                    </th>
                    <th className="sticky text-right rounded-tr-lg bg-gray-200 top-0 px-6 py-3">
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.table.reach'
                        )}
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-dotted odd:bg-gray-100">
                {forecastTable.map((row, index) => (
                    <tr
                        key={index}
                        className="hover:bg-gray-50 transition-colors duration-100">
                        <td className="px-6 py-2">
                            {t(
                                `sections.campaigns.new-campaign.wizard-audiences.demographics.gender.${row.gender}`
                            )}
                        </td>
                        <td className="px-6 py-2">{row.age}</td>
                        <td className="px-6 py-2">
                            {t(
                                `sections.campaigns.new-campaign.wizard-audiences.demographics.income.${row.incomeLevel}`
                            )}
                        </td>
                        <td className="px-6 py-2 text-right">
                            {formatNumber(row.totalImpacts, 'rounded')}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ForecastTable;

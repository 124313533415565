import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    formatNumber,
    getCampaignDurationByPeriodValue,
    lastTwoDecimal,
} from '../../../../utils/functions';
import { useTranslation } from 'react-i18next';
import Bar from '../Bar/Bar';
import Item from '../Bar/Item/Item';

const Basics = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const dailyCap = newCampaign.dailyCap;
    const periodValue = newCampaign.timing.periodValue;
    const selectedCurrency = newCampaign.selectedCurrency;
    const budget = newCampaign.budget;
    const [campaignDuration, setCampaignDuration] = useState('');
    const [dailyCapInfo, setDailyCapInfo] = useState(['', '']);

    useEffect(() => {
        setCampaignDuration(getCampaignDurationByPeriodValue(periodValue));
    }, [periodValue]);

    const formatTextForDailyCap = () => {
        if (dailyCap.isActive)
            if (!dailyCap.isDistribution)
                if (dailyCap.isBudget)
                    setDailyCapInfo([
                        dailyCap.budget ? lastTwoDecimal(dailyCap.budget) : '-',
                        lastTwoDecimal(dailyCap.budget)
                            ? t(
                                  'sections.campaigns.new-campaign.wizard-briefBar.basics.abbreviated-budget'
                              )
                            : '',
                    ]);
                else
                    setDailyCapInfo([
                        dailyCap.impressions ? dailyCap.impressions : '-',
                        dailyCap.impressions
                            ? t(
                                  'sections.campaigns.new-campaign.wizard-briefBar.basics.abbreviated-impressions'
                              )
                            : '',
                    ]);
            else
                setDailyCapInfo([
                    t(
                        'sections.campaigns.new-campaign.wizard-briefBar.basics.automatic'
                    ),
                    '',
                ]);
        else
            setDailyCapInfo([
                t(
                    'sections.campaigns.new-campaign.wizard-briefBar.basics.unlimited'
                ),
                '',
            ]);
    };

    useEffect(() => {
        formatTextForDailyCap();
    }, [dailyCap]);

    return (
        <Bar
            isActive={isActive}
            title={t(
                'sections.campaigns.new-campaign.wizard-briefBar.basics.title'
            )}>
            <Item
                first
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.basics.budget'
                )}>
                {selectedCurrency && budget.budget
                    ? selectedCurrency.symbol
                    : ''}{' '}
                {budget.budget ? formatNumber(budget.budget, 'currency') : '-'}
            </Item>

            {/* If the ternary operator inside another ternary operator is causing you troubles, dailyCapInfo[0] can be a -, a number higher than 0 or Unlimited
         We are just making sure in first place that number isn't unlimited and after that we see if number is actually a number or just a -  */}
            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.basics.daily-cap'
                )}>
                {dailyCapInfo[0] !==
                t(
                    'sections.campaigns.new-campaign.wizard-briefBar.basics.unlimited'
                )
                    ? dailyCapInfo[0] !== '-'
                        ? formatNumber(dailyCapInfo[0])
                        : '-'
                    : t(
                          'sections.campaigns.new-campaign.wizard-briefBar.basics.unlimited'
                      )}{' '}
                <span className="text-xs font-light">{dailyCapInfo[1]}</span>
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.basics.campaign-duration'
                )}>
                {campaignDuration !== -1 && campaignDuration !== 0
                    ? campaignDuration
                    : '-'}{' '}
                <span className="text-xs font-light">
                    {campaignDuration !== -1 && campaignDuration !== 0
                        ? t('common.words.days')
                        : ''}
                </span>
            </Item>
        </Bar>
    );
};

export default Basics;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import Header from '../../components/Header/Header';
import SystemNotification from '../../components/Notifications/SystemNotifications';
import Menu from '../../components/Management/Menu/Menu';
import { useAuth } from '../../containers/UseAuth';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { capitalizeFirstLetter } from '../../utils/functions';

const Management = (params) => {
    const { t } = useTranslation();
    const history = useHistory();
    const auth = useAuth();
    const [isActiveSection, setIsActiveSection] = useState('billing'); // subsection 1
    const [subSectionOne, setSubSectionOne] = useState(
        t('sections.management.section.billing.funding.tab')
    );

    useEffect(() => {
        let section = t('sections.management.section.billing.funding.tab');

        switch (isActiveSection) {
            case 'billing':
                section = t('sections.management.section.billing.funding.tab');
                break;
            case 'pricing':
                section = t('sections.management.section.pricing.tab');
                break;
            case 'financial':
                section = t('sections.management.section.financial.tab');
                break;
            case 'users':
                section = t('sections.management.section.users.tab');
                break;
            case 'reports':
                section = t('sections.management.section.reports.tab');
                break;
            case 'invoices':
                section = t('sections.management.section.invoices.tab');
                break;
            default:
                section = t('sections.management.section.billing.funding.tab');
                break;
        }
        setSubSectionOne(section);
    }, [isActiveSection]);

    const translatedSection = t(
        `sections.management.section.${isActiveSection}.tab`
    );

    return (
        <>
            <Helmet>
                <title>{`${t(
                    'common.menu.management'
                )} | ${translatedSection}`}</title>
            </Helmet>
            <SystemNotification
                notificationType={'success'}
                title={t('sections.dashboard.welcome')}
                text={t('sections.dashboard.welcome-message')}
            />
            <div className="fixed h-screen w-full bg-gray-100 overflow-y-auto pb-10">
                <Header
                    currentSection={t('common.menu.dashboard')}
                    sectionPath={'/dashboard'}
                    subSection={t('common.menu.management')}
                    subSectionOne={subSectionOne}
                />

                <Menu
                    isActiveSection={isActiveSection}
                    setIsActiveSection={setIsActiveSection}
                />
            </div>
        </>
    );
};

export default Management;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import Select from 'react-select';

const InputSelect = (props) => {
    const {
        id = '',
        value,
        options,
        onChange,
        placeholder,
        label,
        containerClassName = '',
    } = props;
    const selectRef = useRef();

    return (
        <div className={`max-w-sm mx-auto  sm:mx-0 ${containerClassName}`}>
            <label htmlFor={id} className="text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="grid grid-cols-7">
                <div className="col-span-6 ">
                    <Select
                        ref={selectRef}
                        inputId={id}
                        value={value}
                        options={options}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                </div>
                <div
                    className={`flex justify-center items-center col-span-1 ${
                        !value ? 'invisible' : ''
                    }`}>
                    <FontAwesomeIcon
                        icon={['fas', 'check']}
                        fixedWidth
                        className="ml-1 text-green-500"
                    />
                </div>
            </div>
        </div>
    );
};

export default InputSelect;

import React from 'react';
import MarkerStyled from './MarkerStyled';

const ClusterMarker = ({ points, onClick }) => {
    return (
        <MarkerStyled
            key={points[0].id}
            lat={points[0].lat}
            lng={points[0].lng}
            name={points[0].id}
            onClick={() => onClick(points[0])}>
            <span>{points.length}</span>
        </MarkerStyled>
    );
};

export default ClusterMarker;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Block from './Block';
import Item from './Item';
import { arrayToList } from '../../../../utils/functions';

const Location = () => {
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const selectedCountries = newCampaign.location.selectedCountries;
    const selectedRegions = newCampaign.location.selectedRegions;
    const selectedCities = newCampaign.location.selectedCities;
    const zones = newCampaign.location.zones;
    const all = t(
        'sections.campaigns.new-campaign.wizard-briefBar.location.all'
    );
    return (
        <Block
            icon={
                <FontAwesomeIcon
                    icon={['fal', `map-marker`]}
                    fixedWidth
                    size="lg"
                    className="m-1 text-blue-300"
                />
            }
            title={t('sections.campaigns.new-campaign.wizard-tab.location')}>
            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.location.countries'
                )}>
                {selectedCountries.length === 0
                    ? all
                    : arrayToList(selectedCountries)}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.location.regions'
                )}>
                {selectedRegions.length === 0
                    ? all
                    : arrayToList(selectedRegions)}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.location.cities'
                )}>
                {selectedCities.length === 0
                    ? all
                    : arrayToList(selectedCities)}
            </Item>

            {zones.length > 0 && (
                <Item
                    title={t(
                        'sections.campaigns.new-campaign.wizard-briefBar.location.places'
                    )}>
                    <ul>
                        {zones.map((zone) => (
                            <li>{zone.search}</li>
                        ))}
                    </ul>
                </Item>
            )}
        </Block>
    );
};

export default Location;

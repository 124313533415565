import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

function SearchInput(params) {
    const { placeholder = '', onSearch = () => {}, classStyles = '' } = params;
    const inputRef = useRef();

    const onKeyPress = (key) => {
        if (key === 'Enter') onSearch(inputRef.current.value);
    };

    useEffect(() => {
        inputRef.current.focus({
            preventScroll: true,
        });
    }, []);

    return (
        <div className={'relative w-auto ml-2 flex items-center' + classStyles}>
            <div
                className="absolute flex items-center pl-2"
                onClick={() => onSearch(inputRef.current.value)}>
                <FontAwesomeIcon
                    icon={['fal', 'search']}
                    className="cursor-pointer  text-gray-500 antialiased"
                />
            </div>
            <input
                id="input-search-campaigns"
                ref={inputRef}
                type="search"
                autoComplete="off"
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => onKeyPress(e)}
                className="shadow rounded border-0 py-2 pl-8 text-sm focus:outline-none focus:ring-1"
                placeholder={placeholder}
            />
            {inputRef.current?.value &&
                inputRef.current?.value?.trim() != '' && (
                    <div
                        className="rounded-full w-6 text-center absolute right-0 cursor-pointer text-gray-400 hover:bg-gray-100 antialiased mr-1"
                        onClick={() => {
                            inputRef.current.value = '';
                            onSearch('');
                        }}>
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </div>
                )}
        </div>
    );
}

export default SearchInput;

import React, { useRef } from 'react';
import { useState } from 'react';
import styles from './Dropzone.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const Dropzone = ({ children, inputFileRef, isLoading, onDrop, disabled }) => {
    const [hightlight, setHightlight] = useState(false);
    const { t } = useTranslation();

    const openFileDialog = () => {
        inputFileRef.current.click();
    };

    const onDragOver = (e) => {
        e.preventDefault();
        setHightlight(true);
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setHightlight(false);
    };

    const handleDrop = (e) => {
        onDrop(e);
        e.preventDefault();
        setHightlight(false);
    };

    return (
        <div
            className={`${styles.dropzone} ${
                hightlight ? styles.hightlight : ''
            }`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={handleDrop}
            onClick={openFileDialog}
            style={{ cursor: disabled ? 'default' : 'pointer' }}>
            {children}
            <div className={`${styles.messageDiv}`}>
                {isLoading ? (
                    <>
                        <FontAwesomeIcon
                            icon={['fas', 'circle-notch']}
                            className="text-blue-400 animate-spin mx-2"
                        />
                        <span className={`${styles.message}`}>
                            {t('common.components.dropzone.uploading')}...
                        </span>
                    </>
                ) : (
                    <>
                        <span className="font-extralight">
                            {t('common.components.dropzone.files-info')}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default Dropzone;

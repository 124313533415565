import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonButton from '../../../Buttons/CommonButton';
import Hint from '../../../Tools/Hint';
import { formatNumber } from '../../../../utils/functions.js';
import { createTierPricing } from '../../../../services/pricingService';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../../store';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const CreateTier = ({
    isActive,
    rerender,
    screenPrices,
    currenciesWithLabel,
    selectedCurrency,
    handleChangeCurrency,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [tier, setTier] = useState('');
    const [pricing, setPricing] = useState('');
    const [screenType, setScreenType] = useState('');

    const inputPlaceholder = formatNumber('0.00', 'properCurrency', null, {
        currency: 'USD',
    });

    useEffect(() => {}, [rerender]);

    const handleChangeTier = (e) => {
        const tier = e.target.value;
        const formatedTier = !isNaN(parseInt(formatNumber(tier, 'nomarker')))
            ? parseInt(formatNumber(tier, 'nomarker'))
            : 0;

        formatedTier === 0 ? setTier('') : setTier(formatedTier);
    };

    const handleChangeScreenType = (e) => {
        const screenType = e.target.value;
        setScreenType(screenType);
    };

    const handleCreatePrice = () => {
        const bodyReq = {
            tier: parseInt(tier),
            screenType: screenType,
            baseCpm: pricing,
        };

        createTierPricing(bodyReq).then((res) => {
            if (res.statusCode === 200) {
                setTier('');
                setPricing('');
                setScreenType('');
                dispatch(
                    notificationsActions.setNotification({
                        type: 'success',
                        title: t(
                            'sections.management.section.pricing.createTier.notificationCreated.title'
                        ),
                        message: t(
                            'sections.management.section.pricing.createTier.notificationCreated.message'
                        ),
                    })
                );
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'error',
                        title: t(
                            'sections.management.section.pricing.createTier.notificationCreated.errorTitle'
                        ),
                        message: res.message,
                    })
                );
            }
        });
    };

    const handleChangePricing = (e) => {
        const price = e.target.value;

        setPricing(price);
    };

    return (
        <div className={`${isActive ? 'flex' : 'hidden'} px-4 w-full`}>
            <div
                className={`${
                    isActive ? 'block' : 'hidden'
                } w-full bg-white bg-opacity-90 pl-10 pt-5 pb-5`}>
                <label
                    htmlFor="tierid"
                    className="block text-gray-700 text-sm font-bold mb-2">
                    <FontAwesomeIcon
                        icon={['fal', 'chart-bar']}
                        fixedWidth
                        className="text-blue-500"
                    />{' '}
                    {t('sections.management.section.pricing.createTier.tab')}
                    <p className="font-light">
                        {t(
                            'sections.management.section.pricing.createTier.title'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                            <input
                                type="text"
                                name="price"
                                id="tierid"
                                data-testid="tierid"
                                value={tier}
                                autoComplete="off"
                                onChange={handleChangeTier}
                                className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder={t(
                                    'sections.management.section.pricing.createTier.placeholder'
                                )}
                            />
                        </div>
                    </div>
                </div>
                <label
                    htmlFor="screentype"
                    className="block text-gray-700 text-sm font-bold mb-2">
                    <FontAwesomeIcon
                        icon={['fal', 'sign']}
                        fixedWidth
                        className="text-blue-500"
                    />{' '}
                    {t(
                        'sections.management.section.pricing.createTier.screenType.title'
                    )}
                    <p className="font-light">
                        {t(
                            'sections.management.section.pricing.createTier.screenType.label'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                            <input
                                type="text"
                                name="price"
                                id="screentype"
                                data-testid="screentype"
                                value={screenType}
                                autoComplete="off"
                                onChange={handleChangeScreenType}
                                className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder={'Billboard'}
                            />
                        </div>
                    </div>
                </div>
                <label
                    htmlFor="tierprice"
                    className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                    <span className="flex items-center">
                        <FontAwesomeIcon
                            icon={['fal', 'coin']}
                            fixedWidth
                            className="text-blue-500"
                        />
                        <p className="ml-1">
                            {t(
                                'sections.management.section.pricing.createTier.setPrice.title'
                            )}
                        </p>
                        <Hint
                            className="ml-1 font-normal"
                            title={t(
                                'sections.management.section.pricing.common.hint.title'
                            )}
                            text={t(
                                'sections.management.section.pricing.common.hint.description'
                            )}
                            type={'info'}
                            dataFor="price"
                        />
                    </span>
                    <p className="font-light">
                        {t(
                            'sections.management.section.pricing.createTier.setPrice.label'
                        )}
                    </p>
                    <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                        <div className="flex">
                            <div className="relative flex-grow mr-5">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                                <input
                                    type="number"
                                    steps="0.01"
                                    min="0"
                                    name="price"
                                    id="tierprice"
                                    data-testid="tierprice"
                                    value={pricing}
                                    autoComplete="off"
                                    onChange={handleChangePricing}
                                    className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder={inputPlaceholder}
                                />
                            </div>
                        </div>
                    </div>
                </label>

                <CommonButton
                    className="mt-4"
                    label={t(
                        'sections.management.section.pricing.createTier.tab'
                    )}
                    styleType="primary"
                    disabled={
                        tier === '' || pricing === '' || screenType === ''
                    }
                    onClick={handleCreatePrice}
                    id="create-tier-btn"
                />
            </div>
            <div
                className={`${
                    isActive ? 'block' : 'hidden'
                } w-full bg-white bg-opacity-90 pt-5 pb-5`}>
                <label
                    htmlFor="select-currency"
                    className="block text-gray-700 text-sm font-bold mb-2">
                    <FontAwesomeIcon
                        icon={['fal', 'coin']}
                        fixedWidth
                        className="text-blue-500"
                    />{' '}
                    {t(
                        'sections.management.section.pricing.common.currency.title'
                    )}
                    <p className="font-light">
                        {t(
                            'sections.management.section.pricing.common.currency.label'
                        )}
                    </p>
                </label>
                <div className="my-2 w-full md:w-3/6 xl:w-2/6">
                    <Select
                        inputId="currency"
                        placeholder={t(
                            'sections.management.section.billing.funding.currencySelect'
                        )}
                        options={currenciesWithLabel}
                        value={selectedCurrency}
                        onChange={handleChangeCurrency}
                        id="select-currency"
                    />
                </div>
                {selectedCurrency && screenPrices && pricing !== '' && (
                    <div className="my-2 w-full md:w-3/6 xl:w-2/6 font-light">
                        {t(
                            'sections.management.section.pricing.common.price.title'
                        )}{' '}
                        {selectedCurrency?.value}:{' '}
                        {formatNumber(
                            pricing *
                                (screenPrices[selectedCurrency?.value] || 1),
                            'properCurrency',
                            null,
                            {
                                currency: selectedCurrency?.value,
                            }
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateTier;

import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component-footer';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../utils/functions';
import ModalOnDelete from '../../../Modal/ModalOnDelete.js';
import { deleteTierPricing } from '../../../../services/pricingService';
import { notificationsActions } from '../../../../store';
import UpdatePricing from './UpdatePricing';
import ModalDelete from './ModalDelete';

const TierList = ({
    isActive,
    tierList,
    setTierList,
    isLoading,
    dispatch,
    screenPrices,
    currenciesWithLabel,
    selectedCurrency,
    handleChangeCurrency,
}) => {
    const { t } = useTranslation();
    const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
    const [isUpdateModalActive, setIsUpdateModalActive] = useState(false);

    const [tier, setTier] = useState(null);

    const handleDeleteModal = (tier) => {
        setIsDeleteModalActive(!isDeleteModalActive);
        setTier(tier);
    };

    const handleCancel = () => {
        handleDeleteModal();
    };

    const handleUpdateModal = (tier) => {
        setIsUpdateModalActive(!isUpdateModalActive);
        setTier(tier);
    };

    const handleConfirm = async () => {
        try {
            await deleteTierPricing(tier.id);
            const updatedTierList = tierList.filter((item) => {
                return item.id !== tier.id;
            });

            setTierList(updatedTierList);

            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(
                        'sections.management.section.pricing.tierList.notificationDelete.title'
                    ),
                    message: t(
                        'sections.management.section.pricing.tierList.notificationDelete.message'
                    ),
                })
            );

            handleCancel();
        } catch (error) {
            dispatch(
                notificationsActions.setNotification({
                    type: 'error',
                    title: t(
                        'sections.management.section.pricing.tierList.notificationDeleteError.title'
                    ),
                    message: t(
                        'sections.management.section.pricing.tierList.notificationDeleteError.message'
                    ),
                })
            );
        }
    };

    const currentColumns = useMemo(() => {
        const columns = [
            {
                name: `${t(
                    'sections.management.section.pricing.tierList.columns.tierName'
                )} id`,
                sortable: true,
                selector: (row) => {
                    return row.id;
                },
            },
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.tierName'
                ),
                sortable: true,
                selector: (row) => {
                    return row.tier;
                },
            },
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.screenType'
                ),
                sortable: true,
                selector: (row) => {
                    return row.screenType;
                },
            },
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.cpm'
                ),
                sortable: true,
                selector: (row) => {
                    return row.baseCpm;
                },
                cell: (row) => {
                    return formatNumber(row.baseCpm, 'properCurrency', null, {
                        currency: 'USD',
                    });
                },
            },
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.createdAt'
                ),
                sortable: true,
                selector: (row) => {
                    return moment(row.created_at).format('DD/MM/YYYY');
                },
            },
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.updatedAt.title'
                ),
                sortable: true,
                selector: (row) => {
                    const updatedDate = moment(row.updated_at).format(
                        'DD/MM/YYYY HH:mm'
                    );
                    return updatedDate !== 'Invalid date'
                        ? updatedDate
                        : t(
                              'sections.management.section.pricing.tierList.columns.updatedAt.invalidDate'
                          );
                },
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.uploaded-creatives.actions.actions'
                ),
                sortable: false,
                selector: (row) => {
                    return (
                        <div>
                            <button
                                className="relative"
                                onClick={() => {
                                    return handleDeleteModal(row);
                                }}>
                                <FontAwesomeIcon
                                    icon={['fas', 'trash']}
                                    className="text-red-300 mx-2 text-lg hover:text-red-400 transition-colors"
                                />
                            </button>
                            <button
                                className="relative"
                                onClick={() => {
                                    return handleUpdateModal(row);
                                }}>
                                <FontAwesomeIcon
                                    icon={['fas', 'pencil']}
                                    className="text-blue-300 mx-2 text-lg hover:text-blue-400 transition-colors"
                                />
                            </button>
                        </div>
                    );
                },
            },
        ];
        return columns;
    }, []);

    return (
        <div
            id="tier-list-container"
            data-testid="tier-list-container"
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12 overflow-x-auto relative`}>
            <div className="" id="tier-table" data-testid="tier-table">
                <DataTable
                    columns={currentColumns}
                    fixedHeader={false}
                    subHeader={false}
                    data={tierList}
                    pagination={true}
                    paginationPerPage={10}
                    progressPending={isLoading}
                    noDataComponent={t(
                        'common.components.react-data-table.no-data'
                    )}
                    progressComponent={
                        <>
                            <FontAwesomeIcon
                                icon={['fas', 'circle-notch']}
                                className="text-blue-400 animate-spin mx-2"
                            />
                        </>
                    }
                />
            </div>
            <ModalOnDelete
                isActive={isDeleteModalActive}
                handleModal={handleDeleteModal}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={t(
                    'sections.management.section.pricing.tierList.modalDelete.title'
                )}
                body={<ModalDelete tier={tier} />}
            />

            <UpdatePricing
                isActive={isActive}
                screenPrices={screenPrices}
                currenciesWithLabel={currenciesWithLabel}
                selectedCurrency={selectedCurrency}
                selectedTier={tier}
                handleChangeCurrency={handleChangeCurrency}
                isUpdateModalActive={isUpdateModalActive}
                setIsUpdateModalActive={setIsUpdateModalActive}
                tierList={tierList}
                setTierList={setTierList}
            />
        </div>
    );
};

export default TierList;

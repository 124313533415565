import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ScreenDiagnostics from '../ScreenDiagnostics/ScreenDiagnostics';
import { newCampaignActions } from '../../../../store';
import TableComp from './TableComp';
import { useAuth } from '../../../../containers/UseAuth';
import { faWallet } from '@fortawesome/pro-duotone-svg-icons';

const ForecastMetrics = ({ setSelectedScreensLocal }) => {
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => {
        return state.newCampaign;
    });
    const { selectedScreensLocal } = useSelector((state) => state.screens);
    const metrics = newCampaign.forecast;
    const auth = useAuth();
    const [adminOrSupervising, setAdminOrSupervising] = useState(false);
    useEffect(() => {
        let adminOrSupervising = auth.isAdmin() || auth.isSupervising();
        setAdminOrSupervising(adminOrSupervising);
    }, []);
    return (
        <>
            {' '}
            {metrics.targetUniverse !== 0 &&
            metrics.targetUniverse !== '-' &&
            metrics.totalImpacts !== 0 &&
            metrics.totalImpacts !== '-' ? (
                <>
                    <div className="text-gray-600 flex flex-wrap justify-between mt-4">
                        <div className="mt-4 md:mt-0 w-3/6 md:w-auto flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'wallet']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                {/* Tooltip para totalCosts */}
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="totalCosts"
                                    aria-haspopup="true">
                                    {t('common.tooltips.audiences.total-costs')}
                                </ReactTooltip>
                                {/* Sección del componente */}
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.budget'
                                )}{' '}
                                <a data-tip data-for="totalCosts">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>{' '}
                                <br />
                                <span className="text-xl text-black font-medium">
                                    {/* Tooltip nuevo para isUnderspend */}
                                    <ReactTooltip
                                        effect="solid"
                                        className="w-48 p-2"
                                        id="isUnderspendTooltip"
                                        aria-haspopup="true">
                                        {t(
                                            'common.tooltips.audiences.isUnderspend-explain'
                                        )}{' '}
                                    </ReactTooltip>
                                    {metrics.isUnderspend && (
                                        <FontAwesomeIcon
                                            icon={[
                                                'fad',
                                                'exclamation-triangle',
                                            ]}
                                            className={`mr-1 text-yellow-300`}
                                            data-tip
                                            data-for="isUnderspendTooltip" // Esto conecta el tooltip con este icono
                                        />
                                    )}
                                    {metrics.costs}
                                </span>
                                {/* Icono con tooltip para isUnderspend */}
                            </div>
                        </div>
                        <div className="mt-4 md:mt-0 w-3/6 md:w-auto flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'users']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceTargetedUniverse"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.targetedUniverse-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.targeted-universe'
                                )}{' '}
                                <a data-tip data-for="audienceTargetedUniverse">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>{' '}
                                <br />
                                <span className="text-xl text-black font-medium">
                                    {metrics.targetUniverse}
                                </span>
                            </div>
                        </div>
                        {/*FIXME: I comment this to remove reach and add
                        impressions, remove impressions and uncomment this when
                                the CPM is operational*/}
                        {/* <div className="mt-4 md:mt-0 w-3/6 md:w-auto flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'eye']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceImpacts"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.impacts-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.reach-ots'
                                )}{' '}
                                <a data-tip data-for="audienceImpacts">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-2xl text-black font-medium">
                                    {metrics.totalImpacts}
                                </span>
                            </div>
                        </div> */}
                        <div className="mt-4 md:mt-0 w-3/6 md:w-auto flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'eye']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceImpacts"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.impacts-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.impacts'
                                )}{' '}
                                <a data-tip data-for="audienceImpacts">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-xl text-black font-medium">
                                    {metrics?.totalImpacts || 0}
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 md:mt-0 w-2/6 md:w-auto flex flex-wrap content-center md:border-l md:pl-16">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'chart-network']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceCoverage"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.coverage-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.coverage'
                                )}{' '}
                                <a data-tip data-for="audienceCoverage">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <div className="flex flex-col">
                                    <span className="text-lg text-black font-medium">
                                        {metrics.coverage}
                                    </span>
                                    <span className="text-grey text-xs">
                                        {metrics.coverageAbs}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 md:mt-0 w-2/6 md:w-auto flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'analytics']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceGRP"
                                    aria-haspopup="true">
                                    {t('common.tooltips.audiences.GRP-explain')}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.GRP'
                                )}{' '}
                                <a data-tip data-for="audienceGRP">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-lg text-black font-medium">
                                    {metrics.grp}
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 md:mt-0 w-2/6 md:w-auto flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'repeat']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceFrequency"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.frequency-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.frequency'
                                )}{' '}
                                <a data-tip data-for="audienceFrequency">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-lg text-black font-medium">
                                    {metrics.frequency}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="text-gray-600 flex justify-between mt-8">
                        {adminOrSupervising && (
                            <div className="mr-8 flex flex-wrap content-center">
                                <div className="mt-3 mr-2">
                                    <FontAwesomeIcon
                                        icon={['fal', 'play-circle']}
                                        className="text-blue-300 text-2xl"
                                    />
                                </div>
                                <div>
                                    <ReactTooltip
                                        effect="solid"
                                        className="w-48 p-2"
                                        id="audienceAdImpressions"
                                        aria-haspopup="true">
                                        {t(
                                            'common.tooltips.audiences.adImpressions-explain'
                                        )}
                                    </ReactTooltip>
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-audiences.forecast.ad-impressions'
                                    )}{' '}
                                    <a
                                        data-tip
                                        data-for="audienceAdImpressions">
                                        <FontAwesomeIcon
                                            icon={['fad', 'info-circle']}
                                            className="text-gray-700 text-sm"
                                        />
                                    </a>
                                    <br />
                                    <span className="text-xl text-black font-medium">
                                        {metrics.adImpressions}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="mr-8 flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'coin']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceCostPerImpact"
                                    aria-haspopup="true">
                                    {t('common.tooltips.audiences.cpm-explain')}
                                </ReactTooltip>
                                {'CPM'}{' '}
                                <a data-tip data-for="audienceCostPerImpact">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-xl text-black font-medium">
                                    {metrics.cpm}
                                </span>
                            </div>
                        </div>
                        <div className="mr-8 flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'calendar-day']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceDuration"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.duration-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.duration'
                                )}{' '}
                                <a data-tip data-for="audienceDuration">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-xl text-black font-medium">
                                    {metrics.duration}
                                </span>
                            </div>
                        </div>
                        <div className="mr-8 flex flex-wrap content-center">
                            <div className="mt-3 mr-2">
                                <FontAwesomeIcon
                                    icon={['fal', 'sign']}
                                    className="text-blue-300 text-2xl"
                                />
                            </div>
                            <div>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2"
                                    id="audienceScreens"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.screens-explain'
                                    )}
                                </ReactTooltip>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.forecast.screens'
                                )}{' '}
                                <a data-tip data-for="audienceScreens">
                                    <FontAwesomeIcon
                                        icon={['fad', 'info-circle']}
                                        className="text-gray-700 text-sm"
                                    />
                                </a>
                                <br />
                                <span className="text-xl text-black font-medium">
                                    {`${metrics.screensUsed.length}`}
                                    <p className="inline text-sm">
                                        <span className="font-light">
                                            {' / '}
                                            {metrics.screensUsed.length +
                                                metrics.screensNotUsed.length}
                                        </span>
                                    </p>
                                </span>{' '}
                                <a
                                    data-tip
                                    data-for="audienceScreensWarning"
                                    className={
                                        metrics.screensNotUsed.length > 0 ||
                                        metrics.notMatchingScreens.length > 0
                                            ? ''
                                            : 'invisible'
                                    }>
                                    <FontAwesomeIcon
                                        icon={['fad', 'exclamation-triangle']}
                                        className="text-yellow-300 text-sm"
                                    />
                                </a>
                                <ReactTooltip
                                    effect="solid"
                                    className="w-48 p-2 shadow"
                                    id="audienceScreensWarning"
                                    aria-haspopup="true">
                                    {t(
                                        'common.tooltips.audiences.screensWarning-explain'
                                    )}
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
            <ScreenDiagnostics
                setSelectedScreensLocal={setSelectedScreensLocal}
            />
            <TableComp adminOrSupervising={adminOrSupervising} />
        </>
    );
};

export default ForecastMetrics;

import React from 'react';
import styles from './Modal.module.css';

const Modal = (props) => {
    const { children, size = 'sm', active, className = '', toggler } = props;

    return (
        <>
            {active ? (
                <>
                    <div
                        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}>
                        <div
                            className={`relative w-auto my-6  mx-auto ${
                                size !== 'regular' ? styles[size] : ''
                            } ${className}`}>
                            <div
                                id="modal"
                                className="border-0 shadow-lg  py-4 relative rounded-xl flex flex-col w-full bg-white outline-none focus:outline-none">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div
                        className="opacity-80 fixed inset-0 z-40 bg-gray-300"
                        onClick={toggler}></div>
                </>
            ) : null}
        </>
    );
};

export default Modal;

import React from 'react';
import SwitcherButton from '../../../../Buttons/CommonSwitcher';
import { useTranslation } from 'react-i18next';

const ShowSelectedScreens = ({
    setShowSelectedScreens,
    showSelectedScreens,
}) => {
    const { t } = useTranslation();

    return (
        <div className="py-2">
            <div className="title font-light">
                {t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.showSelected-label'
                )}
            </div>
            <SwitcherButton
                onToggle={setShowSelectedScreens}
                labelOn={t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.showSelected-labelOn'
                )}
                labelOff={t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.showSelected-labelOff'
                )}
                status={showSelectedScreens}
                revertLabel={true}
            />
        </div>
    );
};

export default ShowSelectedScreens;

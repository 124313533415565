const TEST_MODE = process.env.NODE_ENV !== 'production' ? true : false;

export default class Perf {
    constructor(functionName) {
        this._functionName = functionName || '';
        this._start = performance.now();
    }

    end() {
        this._end = performance.now();
    }

    toString() {
        if (!TEST_MODE) {
            return;
        }
        if (!this._end) {
            this.end();
        }
        return `${this.#formatTime(this._end - this._start)}`;
    }

    out() {
        if (!TEST_MODE) {
            return;
        }
        if (!this._end) {
            this.end();
        }
        console.log(
            `${this._functionName} ${this.#formatTime(this._end - this._start)}`
        );
    }

    /**
     * formatTime
     * @description Format time in ms to human readable format. Convert the time to seconds
     * or minutes if needed.
     * @param {number} time - The time in ms.
     * @returns {string} - The time in human readable format.
     * @throws {Error} - If the time is not a number.
     * @example
     * formatTime(1000) // 1s
     * formatTime(10000) // 10s
     * formatTime(60000) // 1m
     */
    #formatTime(time) {
        if (typeof time !== 'number') {
            throw new Error('Time must be a number');
        }
        if (time < 1000) {
            return `${time.toFixed(2)}ms`;
        }
        if (time < 60000) {
            return `${(time / 1000).toFixed(2)}s`;
        }
        return `${(time / 60000).toFixed(2)}m`;
    }
}

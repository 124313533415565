import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Search/Search';
import MultiSelect from '../MultiSelect/MultiSelect';

const SelectWithSearch = ({
    label = '',
    options = [],
    onClickClose,
    handleClick,
    handleRefresh,
    isLoading,
    isLoadingOptions,
}) => {
    const [search, setSearch] = useState('');
    const { t } = useTranslation();

    return (
        <MultiSelect
            options={options?.filter((option) =>
                option.name
                    .trim()
                    .toLowerCase()
                    .includes(search.trim().toLowerCase())
            )}
            label={label}
            onClickClose={onClickClose}
            handleClick={handleClick}
            setSearch={setSearch}
            isLoadingOptions={isLoadingOptions}>
            {handleRefresh && (
                <button
                    onClick={handleRefresh}
                    className={
                        'text-white rounded-t-md bg-blue-500 cursor-pointer relative w-full flex items-center gap-1 justify-center mb-2 -mt-3 py-1'
                    }>
                    <FontAwesomeIcon
                        icon={['fa', 'sync-alt']}
                        className={`antialiased ${
                            isLoading ? 'animate-spin-fast duration-150' : ''
                        }`}
                    />
                    <span>
                        {t(
                            'sections.campaigns.new-campaign.wizard-screens.filters.refresh'
                        )}
                    </span>
                </button>
            )}
            <SearchInput
                onSearch={(word) => setSearch(word)}
                placeholder={
                    t(
                        'sections.campaigns.new-campaign.wizard-screens.filters.search'
                    ) + ' ...'
                }
                classStyles=" flex items-center mx-2 mb-2"
            />
        </MultiSelect>
    );
};

export default SelectWithSearch;

import React, {
    useLayoutEffect,
    useMemo,
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CommonButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CommonButton = forwardRef(
    (
        {
            children,
            className = '',
            styleType,
            label = '',
            action = null,
            disabled = false,
            onClick = async () => {},
            dataTest = '',
            width,
            useLoader,
            id,
            testId,
        },
        ref
    ) => {
        let history = useHistory();

        const styleTypes = useMemo(() => {
            return {
                cancel: 'bg-gray-100  hover:bg-gray-300 hover:text-black rounded text-gray-600 transition-colors duration-200 ease-in-out text-sm py-2 lg:px-4 focus:outline-none',
                primary:
                    'capitalize bg-blue-400 hover:bg-blue-600 text-white rounded transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none',
                secondary:
                    'capitalize bg-white hover:bg-gray-200 text-black rounded border border-gray-200 transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none',
                danger: 'capitalize bg-red-700 hover:bg-red-900 text-white  rounded transition-colors duration-300 ease-in-out text-sm py-2 px-2 lg:px-4 focus:outline-none',
                smallprimary:
                    'capitalize bg-blue-400 hover:bg-blue-600 text-white rounded-sm pt-0 pb-0 rtransition-colors duration-300 ease-in-out font-light text-xs pt-1 pb-1 p-1 py-1 px-1 lg:px-1 focus:outline-none',
            };
        }, []);

        const [styleButton, setStyleButton] = useState('');
        /* Acceptable Params:
            - Label (Readable Name in Button)
            - Style (primary - secondary - danger)
            - Action (going to page)
        */

        useLayoutEffect(() => {
            setStyleButton(styleTypes[styleType]);
        }, [styleTypes, styleType]);

        const [loading, setLoading] = useState(false);

        async function handleClick(action) {
            if (useLoader) setLoading(true);
            await onClick();
            setLoading(false);
            if (action) {
                history.push(action);
            }
        }

        return (
            <button
                style={{ width: 'fit-content' }}
                ref={ref}
                data-test={dataTest}
                disabled={disabled || loading}
                onClick={() => handleClick(action)}
                className={`${styleButton} ${className} ${
                    disabled || loading ? styles.disabled : ''
                } ${
                    loading ? 'text-opacity-0 select-none' : ''
                } ${width} relative`}
                id={id}
                data-testid={testId}>
                {children ? children : label}
                {useLoader && (
                    <div
                        style={{
                            opacity: loading ? 1 : 0,
                            transitionProperty: 'opacity',
                            transitionDuration: '0s',
                            transitionDelay: '0.1s',
                        }}>
                        <FontAwesomeIcon
                            icon={['fas', 'circle-notch']}
                            className={`transition ease-in-out text-white animate-spin absolute inset-0 mx-auto my-auto absolute inset-0 mx-auto my-auto ${
                                !loading && 'hidden'
                            }`}
                        />
                    </div>
                )}
            </button>
        );
    }
);
export default CommonButton;

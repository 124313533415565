import { useEffect } from 'react';

const useKeyEsc = (handle) => {
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handle();
            }
        };
        window.addEventListener('keydown', close);
        return () => {
            return window.removeEventListener('keydown', close);
        };
    }, []);
};

export default useKeyEsc;

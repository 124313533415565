import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

const ModalHeader = (props) => {
    const { children, toggler, className = '', isHandle = true } = props;

    // Agrego el evento de cerrar modal con Scape
    useEffect(() => {
        if (isHandle) {
            const close = (e) => {
                if (e.keyCode === 27) {
                    toggler();
                }
            };
            window.addEventListener('keydown', close);
            return () => window.removeEventListener('keydown', close);
        }
    }, []);

    return (
        <div
            className={`flex items-start justify-between px-3 rounded-t ${className}`}>
            <h3 className="w-11/12 text-gray-700 text-lg subpixel-antialiased truncate">
                {children}
            </h3>
            {isHandle && (
                <button
                    className="absolute right-2 top-2 w-8 h-8 bg-transparent border-0 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors duration-100 flex items-center justify-center text-black outline-none focus:outline-none"
                    onClick={toggler}>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        fixedWidth
                        className="text-gray-500 h-5 hover:text-black"
                    />
                </button>
            )}
        </div>
    );
};

export default ModalHeader;

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component-footer';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
    numberWithoutCommas,
    formatNumber,
    toFloat,
    getCurrencySymbol,
    getProperCurrency,
} from '../../../utils/functions';
import MyResponsivePie from '../../Graph/Pie';
import styles from './ReportsTable.module.css';
import { useAuth } from '../../../containers/UseAuth';
import { useLocalStorage } from '../../../hooks/storage/useLocalStorage';
import { useSelector } from 'react-redux';

const ReportsTable = (props) => {
    const {
        withGraph = false,
        data,
        currency,
        campaignName = '',
        rowTotals,
    } = props;
    const { t } = useTranslation();
    const dashboard = useSelector((state) => state.dashboard);
    const stats = dashboard?.campaignStats?.currenciesOfStats[0]?.stats;
    const csvLinkRef = useRef();
    const auth = useAuth();
    const userName = auth.session.user.username;
    const { id } = useParams();
    const isDashboardIndividually = id ? true : false;
    const [currentPeriod] = useLocalStorage('currentPeriod', [null, null]);
    let from = currentPeriod[0];
    let to = currentPeriod[1];
    if (currentPeriod[0] && currentPeriod[1]) {
        from = currentPeriod[0].split(' ')[0];
        to = currentPeriod[1].split(' ')[0];
    }
    const [newCurrentData, setNewCurrentData] = useState([]);
    const [newCurrentColumns, setNewCurrentColumns] = useState([]);

    const nameCsv =
        t('sections.dashboard.reports-title') +
        `${
            isDashboardIndividually
                ? `_${id}_${campaignName}`
                : `_${userName}_from=${from}_to=${to}`
        }`;

    const initialRowTotals = {
        name: t('common.words.total'),
        id: 0,
        impressions: 0,
        ecpm: 0,
        spent: 0,
        impacts: 0,
    };

    const [activeTab, setActiveTab] = useState(0);
    const [currentData, setCurrentData] = useState([]);
    const [graphData, setGraphData] = useState([]);

    const changeTab = (value) => setActiveTab(value);

    const currentTotals = () => {
        if (!rowTotals) {
            return {};
        }

        if (!rowTotals[activeTab]) {
            return initialRowTotals;
        }
        if (isDashboardIndividually) {
            return { ...rowTotals[activeTab], name: initialRowTotals.name };
        } else {
            return {
                ...rowTotals[activeTab][currency.short_name],
                name: initialRowTotals.name,
            };
        }
    };

    const formatColumn = (column, value, currency) => {
        let formattedValue = ''; // Degrade gracefully if no value is provided
        if (!isNaN(parseFloat(value))) {
            switch (column) {
                case 'id':
                    formattedValue = formatNumber(value, 'nomarker');
                    break;
                case 'impressions':
                case 'impacts':
                    formattedValue =
                        value == 0 ? '-' : formatNumber(value, 'rounded');
                    break;
                case 'ecpm':
                case 'spent':
                    formattedValue = formatNumber(
                        value,
                        'properCurrency',
                        null,
                        { currency: currency.short_name }
                    );
                    break;
            }
        } else {
            // FIXME: for some reason sometimes it's a string and other times it's an object
            if (typeof value === 'string') {
                formattedValue = formatNumber(value, 'rounded');
            } else if (typeof value === 'object') {
                return value.props?.children ? (
                    <b>{value.props?.children}</b>
                ) : (
                    value
                );
            }
        }
        return formattedValue;
    };

    const [totals, setTotals] = useState({});
    const customSort = (rowA, rowB, nameRow) => {
        if (rowA[nameRow] > rowB[nameRow]) return 1;
        if (rowB[nameRow] > rowA[nameRow]) return -1;
        return 0;
    };

    const currentColumns = useMemo(() => {
        if (currentData?.length === 0) return [];
        setTotals(currentTotals());
        const tabCampaign = t('common.words.campaign');
        const tabBrand = t('common.words.brand');
        const translations = 'common.components.ReportsTable.';

        let firstColumn = {
            name: data[activeTab].columnName,
            key: data[activeTab].columnName === tabBrand ? 'brand' : 'name',
            label: data[activeTab].columnName,
            selector: (row) =>
                data[activeTab].columnName === tabBrand ? row.brand : <div title={activeTab === 0? row.name: ""}>{row.name}</div>,
            sortable: true,
            minWidth: '25%',
        };

        if (data[activeTab].columnName === tabCampaign) {
            firstColumn.cell = (row) =>
                row.campaignid ? (
                    <Link to={row.link}>{row.name}</Link>
                ) : (
                    <div
                        title={
                            data[activeTab].columnName === tabBrand
                                ? row.brand
                                : row.name
                        }>
                        {data[activeTab].columnName === tabBrand
                            ? row.brand
                            : row.name}
                    </div>
                );
        }

        const styleLabel = {
            color: '#bdbdbd',
            fontSize: 10,
        };
        let defaultColumns =
            auth.isAdmin() || auth.isSupervising()
                ? [
                      {
                          name: t(translations + 'id'),
                          key: 'id',
                          label: t(translations + 'id'),
                          selector: (row) => {
                              return formatColumn(
                                  'id',
                                  { ...row }['id'],
                                  currency
                              );
                          },
                          right: true,
                          sortFunction: (row1, row2) =>
                              customSort(row1, row2, 'id'),
                      },
                      {
                          name: t(translations + 'impressions'),
                          key: 'impressions',
                          label: t(translations + 'impressions'),
                          selector: (row) => {
                              return formatColumn(
                                  'impressions',
                                  { ...row }['impressions'],
                                  currency
                              );
                          },
                          right: true,
                          sortFunction: (row1, row2) =>
                              customSort(row1, row2, 'impressions'),
                      },
                  ]
                : [
                      {
                          name: t(translations + 'id'),
                          key: 'id',
                          label: t(translations + 'id'),
                          selector: (row) => {
                              return formatColumn(
                                  'id',
                                  { ...row }['id'],
                                  currency
                              );
                          },
                          right: true,
                          sortFunction: (row1, row2) =>
                              customSort(row1, row2, 'id'),
                      },
                  ];

        let columnsToCSV = defaultColumns;
        let columnsToReports = defaultColumns;

        let columnOfImpacts = [
            {
                name: t(translations + 'impacts'),
                key: 'impacts',
                label: t(translations + 'impacts'),
                selector: (row) => {
                    return formatColumn(
                        'impacts',
                        { ...row }['impacts'],
                        currency
                    );
                },
                right: true,
                sortFunction: (row1, row2) => customSort(row1, row2, 'impacts'),
            },
        ];

        if (formatNumber(currentTotals().impacts) > 0) {
            columnsToReports = columnsToReports.concat(columnOfImpacts);
        }
        columnsToCSV = columnsToCSV.concat(columnOfImpacts);

        let columnOfEcpm = [
            {
                name: (
                    <span>
                        <b>{t(translations + 'ecpm')}</b>{' '}
                        <b style={styleLabel}>({currency.short_name})</b>
                    </span>
                ),
                key: 'ecpm',
                label: `${t(translations + 'ecpm')} (${currency.short_name})`,
                selector: (row) =>
                    formatColumn(
                        'ecpm',
                        row.impacts !== 0
                            ? (1000 * row.spent) / row.impacts
                            : row.ecpm,
                        currency
                    ),
                sortable: true,
                right: true,
                sortFunction: (row1, row2) => customSort(row1, row2, 'ecpm'),
            },
        ];

        columnsToReports = columnsToReports.concat(columnOfEcpm);
        columnsToCSV = columnsToCSV.concat(columnOfEcpm);

        let columnOfSpent = [
            {
                name: (
                    <span>
                        <b>{t(translations + 'spent')}</b>{' '}
                        <b style={styleLabel}>({currency.short_name})</b>
                    </span>
                ),
                key: 'spent',
                label: `${t(translations + 'spent')} (${currency.short_name})`,
                selector: (row) => formatColumn('spent', row.spent, currency),
                sortable: true,
                right: true,
                sortFunction: (row1, row2) => customSort(row1, row2, 'spent'),
            },
        ];

        columnsToReports = columnsToReports.concat(columnOfSpent);
        columnsToCSV = columnsToCSV.concat(columnOfSpent);

        columnsToReports =
            firstColumn.name === tabCampaign
                ? columnsToReports
                : columnsToReports.filter((column) => column.key != 'id');
        columnsToCSV =
            firstColumn.name === tabCampaign
                ? columnsToCSV
                : columnsToCSV.filter((column) => column.key != 'id');

        setNewCurrentColumns([firstColumn, ...columnsToCSV]);
        return [firstColumn, ...columnsToReports];
    }, [currentData, currency]);

    useEffect(() => {
        const arr = [];
        currentData.forEach((field) => {
            const object = {
                budget: field.budget,
                campaignid: field.campaignid,
                campaignname: field.campaignname,
                ecpm: field.ecpm,
                currency: field.currency,
                id: field.id,
                impacts: formatNumber(
                    formatNumber(field.impacts, 'rounded'),
                    'nomarker'
                ),
                impressions: field.impressions,
                link: field.link,
                name: field.name,
                spent: field.spent,
            };

            arr.push(object);
        });

        setNewCurrentData(arr);
    }, [currentData]);

    useEffect(() => {
        if (data && data?.length > 0) {
            const currentData = data[activeTab].data;
            setCurrentData(currentData);
            let keyForGraphic = props.seeImpressions
                ? 'impressions'
                : 'impacts';
            const graphData = currentData.map((row) => ({
                id: row.name || row.brand,
                value:
                    row[keyForGraphic] === 0
                        ? 0
                        : numberWithoutCommas(row[keyForGraphic]),
            }));
            setGraphData(graphData);
        }
    }, [data, activeTab]);

    const noDataComponent = (
        <div className={styles.noContentContainer}>
            <span>{t('common.components.react-data-table.no-reports')}</span>
        </div>
    );

    const downloadToCSV = () => csvLinkRef.current.link.click();

    const actionsMemo = useMemo(() => {
        return currentData?.length > 0 ? (
            <div className="cursor-pointer mr-4" onClick={downloadToCSV}>
                <FontAwesomeIcon
                    icon={['fas', 'file-csv']}
                    className="text-blue-300"
                />
                <span className="text-blue-300 text-sm pl-2">
                    {t('common.components.ReportsTable.export-to-csv')}
                </span>
            </div>
        ) : (
            <></>
        );
    }, [currentData]);

    return (
        <>
            <div className="flex justify-between">
                <div className="font-extralight text-xl p-2">
                    {t('sections.dashboard.reports')}
                </div>
            </div>
            <div className="module">
                <div className="flex">
                    <div className="w-full">
                        <nav className="flex">
                            {data.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => changeTab(index)}
                                    className={`${
                                        activeTab === index
                                            ? 'active text-blue-500 border-b-2 font-medium border-blue-500'
                                            : ''
                                    } ${
                                        item.data?.length === 0 && 'hidden'
                                    } text-gray-600 py-2 font-light px-6 block hover:text-blue-500 focus:outline-none`}>
                                    {item.tabName}
                                </button>
                            ))}
                        </nav>
                        <div className="flex flex-col lg:flex-row">
                            <div className="w-full lg:w-9/12">
                                {/* HACK: Footer props should be better handled. It's different how we get data
                                automatically and reloading*/}
                                <DataTable
                                    columns={currentColumns}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={'70vh'}
                                    subHeader={false}
                                    noDataComponent={noDataComponent}
                                    scrollCollapse
                                    data={currentData}
                                    actions={actionsMemo}
                                    footer={currentTotals() || totals}
                                />
                            </div>
                            {withGraph && (
                                <div
                                    className={`w-full lg:w-3/12 ${styles.graphContainer}`}>
                                    <MyResponsivePie
                                        enableArcLinkLabels={false}
                                        data={graphData}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CSVLink
                    filename={`${nameCsv}.csv`}
                    headers={newCurrentColumns}
                    data={newCurrentData}
                    className={'hidden'}
                    ref={csvLinkRef}
                    target={'_blank'}
                />
            </div>
        </>
    );
};

export default ReportsTable;

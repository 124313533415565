import React, { useEffect, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { setLocalZone, setOtherZone } from '../../utils/functions';

const DateRangerPickerWithCustomTimezone = (props) => {
    const { onChange = () => {}, value, timezone, clearIcon } = props;
    const { t } = useTranslation();
    const [timezoneName, setTimezoneName] = useState('UTC');

    useEffect(() => {
        const timezoneName = timezone.name || moment.tz.guess();
        setTimezoneName(timezoneName);
    }, [timezone]);

    const handleOnChange = (periodValue) => {
        if (periodValue !== null) {
            const periodChanged = [
                setOtherZone(periodValue[0], timezoneName),
                setOtherZone(periodValue[1], timezoneName),
            ];
            onChange(periodChanged);
        } else {
            onChange(null);
        }
    };

    const setLocalZoneByPeriod = (period) => {
        return [
            setLocalZone(period[0], timezoneName),
            setLocalZone(period[1], timezoneName),
        ];
    };

    return (
        <DateRangePicker
            clearIcon={clearIcon}
            rangeDivider={t('common.components.DateRangePicker.until')}
            onChange={(periodValue) => handleOnChange(periodValue)}
            value={value ? setLocalZoneByPeriod(value) : null}
            className={props.className}
        />
    );
};

export default DateRangerPickerWithCustomTimezone;

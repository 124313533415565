import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Buttons/CommonButton';
import { useAuth } from '../UseAuth';
import { useTranslation } from 'react-i18next';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { getFeaturesForUser } from '../../services/featuresService';

import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../store';

function LoginPage() {
    const auth = useAuth();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [valid, setValidation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (valid) {
            setIsLoading(true);
            setValidation(false);
            await auth.signin(username, password);
            await getFeaturesForUser().catch((error) => {
                dispatch(
                    notificationsActions.setNotification({
                        title: t('sections.login-page.title'),
                        message: t('sections.login-page.error-features'),
                        type: 'error',
                    })
                );
            });
            setIsLoading(false);
            setValidation(true);
        }
    };

    const handleClearFields = () => {
        setUsername('');
        setPassword('');
    };

    useEffect(() => {
        setValidation(username.length > 3 && password.length > 3);
    }, [username, password]);

    return (
        <>
            <Helmet>
                <title>{t('sections.login-page.title')}</title>
            </Helmet>
            <LoginLayout>
                <div className="text-gray-500">
                    {t('sections.login-page.sign-in')}
                </div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="my-4">
                        <input
                            type="text"
                            value={username}
                            autoComplete="username"
                            data-testid="username"
                            className="mt-2 rounded-sm w-full bg-gray-200 border-none focus:ring-0"
                            placeholder={t(
                                'sections.login-page.email-username'
                            )}
                            onChange={(e) =>
                                setUsername(e.target.value)
                            }
                        />
                    </div>
                    <div className="my-4">
                        <input
                            type="password"
                            value={password}
                            autoComplete="current-password"
                            className="mt-2 rounded-sm w-full bg-gray-200 border-none focus:ring-0"
                            placeholder={t('sections.login-page.password')}
                            data-testid="password"
                            onChange={(e) =>
                                setPassword(e.target.value)
                            }
                        />
                    </div>
                    <div className="my-4 flex justify-between content-center">
                        {/* TODO */}
                        {/* <label className="text-sm">
                        <input name="remember" className="focus:outline-none focus:ring-0 focus:border-none rounded-sm text-blue-400" type="checkbox" /> {t("sections.login-page.remember-password")}
                    </label> */}
                        <Link to="/forgot" className="link text-right text-xs">
                            {t('sections.login-page.forgot-password')}
                        </Link>
                    </div>
                    <div className="my-4">
                        <Button
                            label={t('sections.login-page.sign-in')}
                            styleType={'primary'}
                            disabled={!valid}
                            testId={"commonButton-login-signIn"}
                        />
                        <Button
                            className="ml-2"
                            label={t('sections.login-page.cancel')}
                            styleType={'secondary'}
                            onClick={handleClearFields}
                            testId={"commonButton-login-cancel"}
                        />
                        <FontAwesomeIcon
                            icon={['fas', 'circle-notch']}
                            className={`text-blue-400 animate-spin ml-2  ${
                                !isLoading ? 'invisible' : ''
                            }`}
                        />
                    </div>
                </form>
            </LoginLayout>
        </>
    );
};

export default LoginPage;

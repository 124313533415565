import React, { useEffect, useState } from 'react';
import { useService } from '../../../../../containers/UseService';
import DataTable from 'react-data-table-component-footer';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownPeriods from '../../../../Select/DropdownPeriods';
import moment from 'moment';
import { formatNumber } from '../../../../../utils/functions';

const Movements = (props) => {
    const { t } = useTranslation();
    const translate = (key) =>
        t(`sections.management.section.movements.${key}`);
    const service = useService();
    const [movements, setMovements] = useState([]);
    const [filteredMovements, setFilteredMovements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('placeholder');
    const [period, setPeriod] = useState([]);

    //fetch and fill movements states
    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const userMovements = await service.getUserMovements();
                setMovements(userMovements);
                setFilteredMovements(userMovements);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        })();
    }, []);

    const columns = [
        {
            name: translate('data-table.date'),
            selector: 'date',
            sortable: true,
            cell: (row) => {
                const date = new Date(row.date * 1000); // Convertir la fecha en segundos a milisegundos
                return (
                    <div style={{ width: '100%' }} className="w-8 truncate">
                        {date.toLocaleDateString() +
                            ' ' +
                            moment(
                                date.toLocaleTimeString(),
                                'HH:mm:ss'
                            ).format('hh:mm A')}
                    </div>
                );
            },
        },
        {
            name: translate('data-table.amount'),
            selector: 'amount',
            sortable: true,
            cell: (row) => {
                const formattedAmount = `$${formatNumber(
                    row.amount,
                    'currency'
                )}`;
                return (
                    <div
                        style={{
                            textAlign: 'right',
                            width: '60%',
                            minWidth: '67px',
                        }}
                        className="w-8 truncate">
                        {formattedAmount}
                    </div>
                );
            },
        },
        {
            name: translate('data-table.currency'),
            selector: 'short_name',
            sortable: true,
        },
        {
            name: translate('data-table.transaction-type'),
            selector: 'transaction_type',
            sortable: true,
            //usign transaction_type as key for get its translation
            cell: (row) => (
                <div style={{ width: '100%' }} className="w-8 truncate">
                    {row.transaction_type &&
                        `${translate(
                            'data-table.transactions.' + row.transaction_type
                        )}`}
                </div>
            ),
        },
    ];

    //when period changes it filters over movements state by dates and reset filteredMovements
    useEffect(() => {
        if (period?.length === 2) {
            const startDateInSeconds = new Date(period[0]).getTime() / 1000;
            const endDateInSeconds = new Date(period[1]).getTime() / 1000;
            setFilteredMovements(
                movements.filter(
                    (m) =>
                        m.date <= endDateInSeconds &&
                        m.date >= startDateInSeconds
                )
            );
        }
    }, [period]);

    return (
        <div className={`px-4 w-full grid`} style={{ minHeight: '156px' }}>
            <span className="text-gray-600 text-xl font-light block">
                {translate('tab')}
            </span>
            <DropdownPeriods
                handleDropdownPeriods={'invoicesReports'}
                setCurrentPeriod={setPeriod}
                currentPeriod={period}
                value={value}
                setValue={setValue}
            />
            {filteredMovements.length ? (
                <div className={`w-full`}>
                    <DataTable
                        columns={columns}
                        data={filteredMovements}
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                    />
                </div>
            ) : loading ? (
                <div className="flex justify-center items-center h-full">
                    <FontAwesomeIcon
                        icon={['fas', 'circle-notch']}
                        className={`text-blue-400 animate-spin mx-2 ${
                            !true ? 'invisible' : ''
                        }`}
                    />
                </div>
            ) : (
                <div className="flex justify-center items-end h-full">
                    <span>
                        {t('common.components.react-data-table.no-data')}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Movements;

/**
 * Auxiliary functions to use in UseService.js.
 *
 * Auxiliary functions to use in UseService.js.
 *
 * @file    This files defines UseService.
 * @author  Alejandro Amaral.
 * @since   2022.05.01
 */

/**
 * Calculate CPM of a screen.
 *
 * Calculate and inject CPM property in screen object. Aditionally, add
 * properties for old localStorage.
 *
 * @since   2022.05.01
 *
 * @param {Object} screen        - Screen object
 * @param {Number} margin        - User revenue margin
 * @param {String} currencyName  - Campaign currency name
 *
 * @returns {Object}             - Screen object with cpm. If cpm is not available, it is set to null.
 */
export const injectCpmOnScreen = (screen, margin, currencyName) => {
    if (!screen || typeof screen !== 'object') {
        return screen;
    }
    let cpm = null;

    // Calculate only if there are prices
    if (
        screen?.deals?.prices?.length > 0 &&
        margin &&
        (margin >= 0 || margin < 1)
    ) {
        if (currencyName) {
            const [priceByCurrency] = screen.deals.prices.filter((price) => {
                return price.currency === currencyName;
            });
            if (priceByCurrency?.price) {
                cpm = priceByCurrency.price / (1 - margin);
            }
        }
    }

    screen.cpm = cpm;

    // Support for old localStorage
    screen.size = `${screen?.width}x${screen?.height}`;
    screen.lat = screen?.geo?.lat;
    screen.lng = screen?.geo?.lon;
    screen.isSelected = false;
    screen.label = screen?.name;
    // end support

    return screen;
};

/**
 * Check if there are updates.
 * Compare local and remote version to determine if there is a new version
 * available. The check is very basic and only compares 1 digit between major and minor versions.
 *
 * @serverVersion {String} - Remote version ej: "1.5.1"
 * @currentVersion {String} - Local version ej: "1.5.1"
 *
 * @since   2020.05.01
 * @returns {Boolean} - True if remote versions are superior than local.
 *
 */
export const thereIsNewVersion = (serverVersion, currentVersion) => {
    if (!currentVersion || !serverVersion) {
        return false;
    } // If there is no local version, there is no new version

    // TODO: Improve version comparison in major and minor digits
    if (serverVersion !== currentVersion) {
        let intServerData = parseInt(serverVersion.ver.replace(/\./g, ''));
        let intCurrentVersion = parseInt(currentVersion.replace(/\./g, ''));
        if (intServerData > intCurrentVersion) {
            return true;
        }
    }
    return false;
};

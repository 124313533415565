import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber } from '../../../../utils/functions';
import ScreensDetails from '../ScreensDetails/ScreensDetails';
import { PropTypes } from 'prop-types';
import ProgressBar from '../../../Progress/ProgressBar';

const PredictionDetails = ({
    setHandleDataSchedule,
    handleDataSchedule,
    campaign,
    campaignPrediction,
    isDelayed,
    campaignScreensStatus,
}) => {
    const [showScreensNotBuying, setShowScreensNotBuying] = useState(false);
    const { t } = useTranslation();
    let allScreens = [];
    try {
        allScreens = campaignPrediction?.screensDetails
            ? [
                  ...campaignPrediction?.screensDetails['offline']?.screen,
                  ...campaignPrediction?.screensDetails['online']?.screen,
              ]
            : [];
    } catch (error) {}

    const screenTranslations =
        'sections.campaigns.new-campaign.wizard-screens.screens.';
    let hours = { AM: [], PM: [] };
    let hoursSchedule = { AM: {}, PM: {} };
    const screenTitle = t(
        'sections.campaigns.new-campaign.wizard-screens.screens.screens-title'
    );

    campaign?.hours_enabled?.forEach((hour) => {
        let hourAMPM = moment(hour, 'HH').format('A-h');
        hourAMPM = hourAMPM.split('-');
        hours[hourAMPM[0]].push(hourAMPM[1]);
    });
    hours.AM.sort((a, b) => a - b);
    hours.PM.sort((a, b) => a - b);

    campaignPrediction?.scheduleCurrentDay?.forEach((hour) => {
        let hourAMPM = moment(hour.hour, 'HH').format('A-h');
        hourAMPM = hourAMPM.split('-');

        hoursSchedule[hourAMPM[0]][hourAMPM[1]] = {
            hour: hourAMPM[1] + [hourAMPM[0].toLocaleLowerCase()],
            impressions: hour.impressions,
        };
    });

    const concatDailyCap = campaignPrediction
        ? `${campaign?.currency_name} ${
              campaign?.currency?.symbol
          } ${formatNumber(campaignPrediction?.dailySpent, 'currency')}
        /
        ${campaignPrediction?.dailyImpressions}
        ${t(
            'sections.campaigns.new-campaign.wizard-review.blocks.basics.impressions'
        )}`
        : '';

    const currentHour = moment(moment().hour(), 'H').format('hA');

    let publishersWithStatus = {};

    campaignScreensStatus?.status?.approvedScreens?.publishers.forEach(
        (publisher) => {
            publishersWithStatus[publisher] = t(
                screenTranslations + 'approved'
            );
        }
    );
    campaignScreensStatus?.status?.disapprovedScreens?.publishers.forEach(
        (publisher) => {
            publishersWithStatus[publisher] = t(
                screenTranslations + 'rejected'
            );
        }
    );
    campaignScreensStatus?.status?.pendingScreens?.publishers.forEach(
        (publisher) => {
            publishersWithStatus[publisher] = t(screenTranslations + 'pending');
        }
    );

    return (
        <div
            className={`mt-2 md:mt-4 xl:mt-2 bg-white fixed right-0 w-96 transform h-screen top-0 pt-14 flex justify-center transition-all ease-in-out  duration-500 overflow-y-auto ${
                handleDataSchedule ? 'translate-x-full' : ''
            }`}
            style={{ zIndex: '1' }}
        >   
            <FontAwesomeIcon
                className="cursor-pointer "
                icon={['fal', 'times']}
                onClick={() => setHandleDataSchedule(!handleDataSchedule)}
            />
            <div className="w-11/12 pt-4 px-1">
                <h3 className="font-normal py-1">
                    {t(
                        'sections.campaigns.new-campaign.wizard-screens.screens.diagnostics-title'
                    )}
                    <span className="font-bold">{` #${campaign?.id}`}</span>
                </h3>
                <span className="font-light">{campaign?.name}</span>
                <h4 className="capitalize font-semibold py-2">{` ${t(
                    screenTranslations + 'publishers'
                )}:`}</h4>
                <ul>
                    {Object.entries(publishersWithStatus).map(
                        ([publisher, status]) => {
                            return (
                                <li key={publisher}>
                                    -{publisher}: <span>{status}</span>
                                </li>
                            );
                        }
                    )}
                </ul>
                <h4 className="capitalize font-semibold py-2">{screenTitle}</h4>

                <p className="font-normal lowercase">
                    {campaignScreensStatus?.status?.approvedScreens?.total}
                    <span className="font-light">
                        {` ${t(screenTranslations + 'screens-approved')}.`}
                    </span>
                </p>
                <p className="font-normal lowercase">
                    {campaignScreensStatus?.status?.disapprovedScreens?.total}
                    <span className="font-light">
                        {` ${t(screenTranslations + 'screens-rejected')}.`}
                    </span>
                </p>
                <p className="font-normal lowercase">
                    {campaignScreensStatus?.status?.pendingScreens?.total}
                    <span className="font-light">
                        {` ${t(screenTranslations + 'screens-pending')}.`}
                    </span>
                </p>

                {campaignPrediction && (
                    <div>
                        {campaignPrediction.matchByAudience && (
                            <p className="font-normal lowercase mt-2">
                                {
                                    Object.keys(
                                        campaignPrediction.matchByAudience
                                    )?.length
                                }{' '}
                                {screenTitle}
                                <span className="font-light">
                                    {` ${t(
                                        screenTranslations + 'mached-audience'
                                    )}.`}
                                </span>
                            </p>
                        )}
                        {/* <p className="font-normal lowercase">
                            {campaignPrediction.buyingScreen} {screenTitle}
                            <span className="font-light">
                                {` ${t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.buying',
                                    {
                                        impressions:
                                            campaignPrediction.impressionLastHour,
                                    }
                                )}.`}
                            </span>
                        </p> */}
                        <p className="font-normal lowercase mb-4">
                            {campaignPrediction?.notBuyingScreen} {screenTitle}
                            <span className="font-light">
                                {` ${t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.no-buying'
                                )}.`}
                            </span>
                        </p>
                    </div>
                )}
                {/* it only shows the detail of the screens that do not buy,
                    so if there is not, it is not shown */}
                {allScreens.length > 0 && (
                    <>
                        <h4
                            className="capitalize mb-4 font-bold cursor-pointer "
                            onClick={() =>
                                setShowScreensNotBuying(!showScreensNotBuying)
                            }
                        >
                            <FontAwesomeIcon
                                className="-ml-3 mr-3"
                                icon={[
                                    'far',
                                    showScreensNotBuying
                                        ? 'angle-down'
                                        : 'angle-right',
                                ]}
                            />
                            {t(screenTranslations + 'here-details')}
                        </h4>
                        <div
                            className="h-screen  transform transition-all ease-in-out duration-500 overflow-y-auto"
                            style={{
                                maxHeight: `${
                                    showScreensNotBuying
                                        ? 'calc(100vh - 25em)'
                                        : '0'
                                }`,
                            }}
                        >
                            {/* Sort screens by isBuying first */}
                            {[...allScreens]
                                .sort(
                                    (a, b) =>
                                        Number(b.isBuying) - Number(a.isBuying)
                                )
                                .map((screen, index) => (
                                    <ScreensDetails
                                        className="mb-4"
                                        key={index}
                                        name={screen.name}
                                        isOnline={screen.isOnline}
                                        currencyMatched={screen.currencyMatched}
                                        currency_name={campaign.currency_name}
                                        sizesMatched={screen.sizesMatched}
                                        audienceMached={
                                            screen.audienceMached || false
                                        }
                                        size={screen.size}
                                        isBuying={screen.isBuying}
                                        screenTranslations={screenTranslations}
                                        priceMatched={screen.priceMatched}
                                        screen={screen}
                                        screenId={screen.id}
                                    />
                                ))}
                        </div>
                    </>
                )}
                {campaignPrediction && (
                    <div>
                        <h4 className="capitalize font-bold mt-2">
                            {t(screenTranslations + 'schedule')}:{' '}
                        </h4>
                        <h4 className="capitalize font-bold mt-4">
                            - {t(screenTranslations + 'defined-hours')}{' '}
                        </h4>
                        <p className=" capitalize font-bold ml-2">
                            {'AM: '}
                            <span className="font-normal lowercase">
                                {hours.AM.join(', ')}
                            </span>
                        </p>
                        <p className=" capitalize font-bold ml-2 ">
                            {'PM: '}
                            <span className="font-normal lowercase">
                                {hours.PM.join(', ')}
                            </span>
                        </p>
                        <p className=" capitalize font-bold ml-2">
                            {t(screenTranslations + 'current-hour') + ': '}
                            <span className="font-normal lowercase">
                                {currentHour}.
                            </span>
                        </p>
                        {/* <h4 className="capitalize font-bold mt-4">
                            {t(screenTranslations + 'today-scheduled')}{' '}
                        </h4> */}
                        {/* <h4 className="capitalize font-bold mt-4">
                            {t(screenTranslations + 'earlie-today')}:
                            <span className="font-normal">
                                {' '}
                                {campaignPrediction.dailyImpressions}{' '}
                                {t(screenTranslations + 'impressions-made') +
                                    '.'}
                            </span>
                        </h4> */}
                    </div>
                )}
                {
                    // Object.values(hoursSchedule).map((AmOrPm) => {
                    //     return Object.values(AmOrPm).map((hour, index) => (
                    //         <p className="capitalize font-bold">
                    //             {hour.hour}
                    //             {': '}
                    //             <span className="font-normal lowercase">
                    //                 {/* last hour number has label diferrent */}
                    //                 {currentHour.toLocaleLowerCase() == hour.hour &&
                    //                     `${t(screenTranslations + 'made')} ${
                    //                         campaignPrediction.impressionsLastHourNumber
                    //                     }, `}
                    //                 {hour.impressions}{' '}
                    //                 {t(screenTranslations + 'impressions')}
                    //                 {` ${t(screenTranslations + 'remaining')}`}
                    //             </span>
                    //         </p>
                    //     ));
                    // })
                }
                {campaignPrediction && (
                    <div>
                        <h4 className="capitalize font-bold mt-4">
                            {`${t(
                                'sections.campaigns.new-campaign.wizard-review.blocks.basics.daily-cap'
                            )}`}
                            :
                        </h4>
                        <p className="font-normal capitalize">
                            {concatDailyCap}
                        </p>
                        {/* <h4 className="capitalize font-bold mt-4">
                            {`${t(
                                screenTranslations + 'current-impressions'
                            )}: `}
                            <p className="inline font-normal">
                                {campaignPrediction.totalImpressions}
                            </p>
                        </h4> */}
                        <h4 className="capitalize font-bold">
                            {`${t(screenTranslations + 'current-spent')}: `}
                            <p className="inline font-normal">
                                {formatNumber(
                                    campaignPrediction.totalSpent,
                                    'currency'
                                )}
                            </p>
                        </h4>
                    </div>
                )}
                <div className={`mt-4 text-xs h-20 capitalize`}>
                    {isDelayed ? (
                        <>
                            <FontAwesomeIcon
                                icon={['far', 'exclamation-triangle']}
                                className={'ml-1 mr-4 text-red-600'}
                            />
                            <p className=" font-bold inline">
                                {t(screenTranslations + 'no-according')}.
                            </p>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={['far', 'check']}
                                className={`ml-1 mr-4 text-green-500`}
                            />
                            <p className="inline font-normal">
                                {t(screenTranslations + 'everything-according')}
                                .
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

PredictionDetails.propTypes = {
    setHandleDataSchedule: PropTypes.func.isRequired,
    handleDataSchedule: PropTypes.bool.isRequired,
    campaign: PropTypes.object.isRequired,
    isDelayed: PropTypes.bool.isRequired,
};

export default PredictionDetails;

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../containers/UseAuth';
import Billing from '../Billing/Billing';
import Commercial from '../Commercial/Commercial';
import Company from '../Company/Company';
import Invoices from '../Invoices/Invoices';
import Reports from '../Reports/Reports';
import Users from '../Users/Users';
import Preferences from '../Preferences/Preferences';
import Pricing from '../Pricing/Pricing';
import PricingUser from '../Pricing/PricingUser';

const Menu = (params) => {
    const features = JSON.parse(localStorage.getItem('features'));
    const { t } = useTranslation();
    const [isActiveSection, setIsActiveSection] = [
        params.isActiveSection,
        params.setIsActiveSection,
    ];
    const auth = useAuth();

    const handleSections = (section) => {
        setIsActiveSection(section);
    };

    return (
        <div className="workingArea w-full mt-10 md:mt-16">
            <div className="flex justify-between">
                <div>
                    <nav className="flex">
                        <button
                            id="sectionFinancial"
                            onClick={() => {
                                handleSections('company');
                            }}
                            className={`${
                                isActiveSection === 'company'
                                    ? 'tabActive font-semibold'
                                    : ''
                            } tabGeneral uppercase text-xs`}>
                            {t('sections.management.section.company.tab')}
                        </button>
                        {/* <button id="sectionCommercial"  onClick={() => handleSections("commercial")}  className={`${isActiveSection === "commercial" ? 'tabActive font-semibold' : ''} tabGeneral uppercase text-xs`}>
                                {t('sections.management.section.commercial.tab')}
                            </button>
                        */}
                        <button
                            id="sectionBilling"
                            onClick={() => {
                                handleSections('billing');
                            }}
                            className={`${
                                isActiveSection === 'billing'
                                    ? 'tabActive font-semibold'
                                    : ''
                            } tabGeneral uppercase text-xs`}>
                            {t(
                                'sections.management.section.billing.funding.tab'
                            )}
                        </button>
                        {auth.isAdminOrSupervisor() && (
                            <button
                                id="sectionBilling"
                                onClick={() => {
                                    handleSections('tierList');
                                }}
                                className={`${
                                    isActiveSection === 'tierList'
                                        ? 'tabActive font-semibold'
                                        : ''
                                } tabGeneral uppercase text-xs`}>
                                {t(
                                    'sections.management.section.pricing.tierTab'
                                )}
                            </button>
                        )}
                        <button
                            id="sectionBilling"
                            onClick={() => {
                                handleSections('pricing');
                            }}
                            className={`${
                                isActiveSection === 'pricing'
                                    ? 'tabActive font-semibold'
                                    : ''
                            } tabGeneral uppercase text-xs`}>
                            {t('sections.management.section.pricing.tab')}
                        </button>
                        {auth.isAdminOrSupervisor() && (
                            <button
                                id="sectionUsers"
                                onClick={() => {
                                    handleSections('users');
                                }}
                                className={`${
                                    isActiveSection === 'users'
                                        ? 'tabActive font-semibold'
                                        : ''
                                } tabGeneral uppercase text-xs`}>
                                {t('sections.management.section.users.tab')}
                            </button>
                        )}
                        {auth.isAdmin() && (
                            <button
                                id="sectionReports"
                                onClick={() => {
                                    handleSections('reports');
                                }}
                                className={`${
                                    isActiveSection === 'reports'
                                        ? 'tabActive font-semibold'
                                        : ''
                                } tabGeneral uppercase text-xs`}>
                                {t('sections.management.section.reports.tab')}
                            </button>
                        )}
                        {auth.isAdmin() && (
                            <button
                                id="sectionReports"
                                onClick={() => {
                                    handleSections('invoices');
                                }}
                                className={`${
                                    isActiveSection === 'invoices'
                                        ? 'tabActive font-semibold'
                                        : ''
                                } tabGeneral uppercase text-xs`}>
                                {t('sections.management.section.invoices.tab')}
                            </button>
                        )}
                        <button
                            id="sectionpreferences"
                            onClick={() => {
                                handleSections('preferences');
                            }}
                            className={`${
                                isActiveSection === 'preferences'
                                    ? 'tabActive font-semibold'
                                    : ''
                            } tabGeneral uppercase text-xs`}>
                            {t('sections.management.section.preferences.tab')}
                        </button>
                    </nav>
                </div>
            </div>
            {auth.isAdminOrSupervisor() && (
                <>
                    {isActiveSection === 'commercial' && (
                        <Commercial isActive={true} />
                    )}
                </>
            )}
            {auth.isAdmin() && (
                <Reports isActive={isActiveSection === 'reports'} />
            )}
            <Billing isActive={isActiveSection === 'billing'} />
            <Company isActive={isActiveSection === 'company'} />
            {isActiveSection === 'tierList' && auth.isAdmin() && (
                <Pricing isActive={isActiveSection === 'tierList'} />
            )}
            {isActiveSection === 'pricing' && (
                <PricingUser
                    isActive={isActiveSection === 'pricing'}
                    userId={auth.session.user.id}
                />
            )}
            {isActiveSection === 'users' && auth.isAdminOrSupervisor() && (
                <Users isActive={true} />
            )}
            {auth.isAdmin() && (
                <Invoices isActive={isActiveSection === 'invoices'} />
            )}
            <Preferences isActive={isActiveSection === 'preferences'} />
        </div>
    );
};

export default Menu;

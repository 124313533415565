import React, { useEffect } from 'react';
import { newCampaignActions } from '../../../../store';
import { useTranslation } from 'react-i18next';

const ScreenSizes = ({ sizesNeeded, creatives, selectedScreens, dispatch }) => {
    const { t } = useTranslation();

    useEffect(() => {
        let sizesNeeded = [];
        selectedScreens.forEach((screen) => {
            const screenSize = `${screen.width}x${screen.height}`;
            if (!sizesNeeded.some((size) => size === screenSize))
                sizesNeeded.push(screenSize);
        });
        dispatch(newCampaignActions.setSizesNeeded(sizesNeeded));
    }, [selectedScreens, creatives]);

    const amountCreativesOf = (size) => {
        let amount = 0;

        creatives.forEach((creative) => {
            const creativeSize = `${creative.width}x${creative.height}`;

            if (
                (creative.original_filename
                    ?.toLowerCase()
                    .includes('responsive') &&
                    creative.extension?.toLowerCase() == 'zip') ||
                creativeSize === size
            ) {
                amount++;
            }
        });

        return amount;
    };

    return (
        <div className="w-2/6 h-full pl-2">
            <span className="font-normal text-gray-700">
                {t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.screenSizes.sizes-needed'
                )}
            </span>
            <span
                className={`${
                    selectedScreens.length === 0 ? '' : 'hidden'
                } text-gray-500 block font-light`}>
                {t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.screenSizes.no-screens-selected'
                )}
            </span>
            <ul
                className={`list-none divide-y px-2 ${
                    sizesNeeded.length >= 7 ? 'max-h-60 ' : ''
                }`}>
                {sizesNeeded.map((size) => {
                    const amountCreativesOfScreenSize = amountCreativesOf(size);
                    const isChecked = amountCreativesOfScreenSize > 0;
                    return (
                        <li
                            key={size}
                            className="flex justify-between items-center content-center py-2 hover:bg-gray-50 transition-colors duration-100">
                            <input
                                type="checkbox"
                                className="rounded-full"
                                checked={isChecked}
                                disabled
                                id="size-1"
                            />
                            <label
                                htmlFor="size-1"
                                className={
                                    isChecked
                                        ? 'text-gray-800'
                                        : 'text-gray-400'
                                }>
                                {size}
                            </label>
                            <div className="rounded-full p-1 bg-gray-100 text-gray-400 text-center text-xs font-semibold">
                                {amountCreativesOfScreenSize}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ScreenSizes;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import { useDispatch, useSelector } from 'react-redux';
import AgeSegment from './AgeSegment/AgeSegment';
import GenderSegment from './GenderSegment/GenderSegment';
import IncomeSegment from './IncomeSegment/IncomeSegment';
import InterestSegment from './InterestSegment/InterestSegment';
import { newCampaignActions } from '../../../../store';
import AccuracySegment from './AccuracySegment/AccuracySegment';

function AudienceSegments({ calculatingForecast }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const newCampaign = useSelector((state) => {
        return state.newCampaign;
    });
    const demographics = newCampaign.demographics;
    const segments = newCampaign.segments;

    const location = useSelector((state) => {
        return state.newCampaign.location;
    });
    const selectedCountries = location.selectedCountries;

    // NOTE: This do not work because screens API is not informing correctly the
    // audience provider. We must infer from location
    let activeSegment = '';
    let isArgentina = selectedCountries.some((country) => {
        return country.value === 'AR';
    });
    if (isArgentina) {
        activeSegment = segments.activeSegment;
    } else {
        activeSegment = 'income';
    }

    // NOTE: If someday the screens informs correctly the provider uncomment
    // this code and it should work fine.
    switch (demographics.id) {
        case 'cinnecta':
            activeSegment = 'income';
            break;
        case 'onemata':
            activeSegment = segments.activeSegment;
            break;
        default:
            activeSegment = segments.activeSegment;
    }

    function handleDemographic(value) {
        if (calculatingForecast === false) {
            const newSegments = Object.assign({}, segments);
            newSegments.activeSegment = value;
            dispatch(newCampaignActions.setSegments(newSegments));
        }
    }

    return (
        <>
            {demographics.id !== '' && (
                <div className="w-full flex flex-col md:flex-row justify-start content-center">
                    <div className="border-r mr-2 w-full md:w-1/6">
                        <nav>
                            <ul className="no-list md:space-y-2">
                                {calculatingForecast ? (
                                    <>
                                        <li
                                            className={`cursor-pointer transition-colors duration-150 ${
                                                demographics?.segments?.some(
                                                    (segment) =>
                                                        segment.id === 'age'
                                                )
                                                    ? 'block'
                                                    : 'hidden'
                                            } ${
                                                activeSegment === 'age'
                                                    ? 'font-semibold text-blue-600 border-r-2 border-blue-600'
                                                    : 'font-thin'
                                            }`}>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.demographics.age.age-title'
                                            )}
                                        </li>
                                        <li
                                            className={`cursor-pointer transition-colors duration-150 ${
                                                demographics?.segments?.some(
                                                    (segment) =>
                                                        segment.id === 'gender'
                                                )
                                                    ? 'block'
                                                    : 'hidden'
                                            } ${
                                                activeSegment === 'gender'
                                                    ? 'font-semibold text-blue-600 border-r-2 border-blue-600'
                                                    : 'font-thin'
                                            }`}>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.demographics.gender.gender-title'
                                            )}
                                        </li>
                                        <li
                                            className={`cursor-pointer transition-colors duration-150 ${
                                                demographics?.segments?.some(
                                                    (segment) =>
                                                        segment.id ===
                                                        'incomeLevel'
                                                )
                                                    ? 'block'
                                                    : 'hidden'
                                            } ${
                                                activeSegment === 'income'
                                                    ? 'font-semibold text-blue-600 border-r-2 border-blue-600'
                                                    : 'font-thin'
                                            }`}>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.demographics.income.income-title'
                                            )}
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li
                                            className={`cursor-pointer transition-colors duration-150 ${
                                                demographics?.segments?.some(
                                                    (segment) =>
                                                        segment.id === 'age'
                                                )
                                                    ? 'block'
                                                    : 'hidden'
                                            } ${
                                                activeSegment === 'age'
                                                    ? 'font-semibold text-blue-600 border-r-2 border-blue-600'
                                                    : 'font-thin'
                                            }`}
                                            onClick={() =>
                                                handleDemographic('age')
                                            }>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.demographics.age.age-title'
                                            )}
                                        </li>
                                        <li
                                            className={`cursor-pointer transition-colors duration-150 ${
                                                demographics?.segments?.some(
                                                    (segment) =>
                                                        segment.id === 'gender'
                                                )
                                                    ? 'block'
                                                    : 'hidden'
                                            } ${
                                                activeSegment === 'gender'
                                                    ? 'font-semibold text-blue-600 border-r-2 border-blue-600'
                                                    : 'font-thin'
                                            }`}
                                            onClick={() =>
                                                handleDemographic('gender')
                                            }>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.demographics.gender.gender-title'
                                            )}
                                        </li>
                                        <li
                                            className={`cursor-pointer transition-colors duration-150 ${
                                                demographics?.segments?.some(
                                                    (segment) =>
                                                        segment.id ===
                                                        'incomeLevel'
                                                )
                                                    ? 'block'
                                                    : 'hidden'
                                            } ${
                                                activeSegment === 'income'
                                                    ? 'font-semibold text-blue-600 border-r-2 border-blue-600'
                                                    : 'font-thin'
                                            }`}
                                            onClick={() =>
                                                handleDemographic('income')
                                            }>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.demographics.income.income-title'
                                            )}
                                        </li>
                                    </>
                                )}
                                {/* When the audiences api accept interest we should remove this comment
                           <li className={`cursor-pointer transition-colors duration-150 ${demographics.segments.some(segment => segment.id === "interests") ? 'block' : 'hidden'} ${activeSegment === 'interests' ? 'font-semibold text-blue-600 border-r-2 border-blue-600' : 'font-thin'}`} onClick={() => handleDemographic('interests')}>{t('sections.campaigns.new-campaign.wizard-audiences.demographics.interests.interests-title')}</li> */}
                            </ul>
                        </nav>
                    </div>
                    <div className="px-2 w-full md:w-3/6 md:mt-4 border-r">
                        {demographics?.segments?.map((segment) => {
                            if (segment.name === 'Ages')
                                return (
                                    <AgeSegment
                                        isActive={activeSegment === 'age'}
                                        calculatingForecast={
                                            calculatingForecast
                                        }
                                    />
                                );
                            if (segment.name === 'Gender')
                                return (
                                    <GenderSegment
                                        isActive={activeSegment === 'gender'}
                                        calculatingForecast={
                                            calculatingForecast
                                        }
                                    />
                                );
                            if (segment.name === 'Income Level')
                                return (
                                    <IncomeSegment
                                        isActive={activeSegment === 'income'}
                                        calculatingForecast={
                                            calculatingForecast
                                        }
                                    />
                                );
                        })}
                        {/* When the audiences api accept interest we should remove this comment
                    <InterestSegment isActive={activeSegment === 'interests'} /> */}
                    </div>
                    <div className="px-2 my-8 md:my-0">
                        <AccuracySegment
                            calculatingForecast={calculatingForecast}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default AudienceSegments;

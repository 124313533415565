import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../utils/functions';

const ModalDelete = ({ tier }) => {
    const { t } = useTranslation();
    return (
        <div
            className="p-2"
            id="modal-delete-body"
            data-testid="modal-delete-body">
            <h1 className="text-gray-700 font-normal">
                {t(
                    'sections.management.section.pricing.tierList.modalDelete.warning-message'
                )}
            </h1>
            <div className="block font-light mt-4">
                <p>
                    {t(
                        'sections.management.section.pricing.tierList.columns.tierName'
                    )}
                    : {tier?.tier}
                </p>
                <p>
                    {t(
                        'sections.management.section.pricing.tierList.columns.screenType'
                    )}
                    : {tier?.screenType}
                </p>
                <p>
                    {t(
                        'sections.management.section.pricing.tierList.columns.cpm'
                    )}
                    :{' '}
                    {formatNumber(tier?.baseCpm, 'properCurrency', null, {
                        currency: 'USD',
                    })}
                </p>
            </div>
        </div>
    );
};

export default ModalDelete;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../containers/UseAuth';

export default function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.session ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

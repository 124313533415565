import Input from '../Input/Input';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../../../../../containers/UseService';
import Select from 'react-select';
import CommonButton from '../../../../../../Buttons/CommonButton';
import React, { useEffect, useState } from 'react';
import { setLabelAndValue } from '../../../../../../../utils/functions';
import { useAuth } from '../../../../../../../containers/UseAuth';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../../../../../store';
import { isValidInput } from '../../../../../../../utils/functions';

const CommercialContacts = (props) => {
    const { user, id, setUser, commercialContacts, setCommercialContacts } =
        props;
    const translations =
        'sections.management.section.company.companyCommercial.';
    const { t } = useTranslation();
    const service = useService();
    const [currentCommercialPerson, setCurrentCommercialPerson] =
        useState(null);
    const [cpName, setCpName] = useState('');
    const [cpPhone, setCpPhone] = useState('');
    const [cpEmail, setCpEmail] = useState('');
    const [cpRole, setCpRole] = useState('');
    const userAdmin = useAuth();
    const [value, setValue] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentCommercialPerson == null) {
            setValue(null);
            setSubmitted(true);
        }
    }, [currentCommercialPerson]);

    const updateCommercialContacts = async () => {
        let userCommercialContacts = await service.getCommercialPersonsAudit(
            user.id
        );
        userCommercialContacts = setLabelAndValue(
            userCommercialContacts,
            'name',
            'id'
        );
        setCommercialContacts(userCommercialContacts);
    };

    const validateString = (value) => {
        return typeof value == 'string' && value;
    };

    const validateName = (value) => {
        return validateString(value);
    };

    const validateRole = (value) => {
        return validateString(value);
    };

    const putCommercialPersonsInInputs = (commercialPersons) => {
        setCurrentCommercialPerson(commercialPersons);

        if (commercialPersons) {
            setCpName(commercialPersons.name);
            setCpEmail(commercialPersons.email);
            setCpPhone(commercialPersons.phone_number);
            setCpRole(commercialPersons.role);
            setValue(commercialPersons);
        } else {
            emptyInputs();
        }
    };

    const onDeleteCommercialContact = async () => {
        await service.removeCommercialPersonAudit(
            user.id,
            currentCommercialPerson.id
        );

        emptyInputs();
        updateCommercialContacts();
        setCurrentCommercialPerson(null);
        setValue(null);
        setSubmitted(!submitted);
        dispatch(
            notificationsActions.setNotification({
                type: 'success',
                title: t(translations + 'commercial-person-success'),
                message: t(
                    translations + 'commercial-person-deleted-successfully'
                ),
            })
        );
    };

    const onEditCommercialContact = async () => {
        if (!currentCommercialPerson) {
            onCreateCommercialContact();
            return;
        }
        if (
            cpName &&
            cpPhone.length >= 7 &&
            cpPhone.length <= 18 &&
            cpRole &&
            cpEmail.includes('@')
        ) {
            const editedCommercialContact = {
                email: cpEmail,
                id: currentCommercialPerson.id,
                label: cpName,
                name: cpName,
                phone_number: cpPhone,
                role: cpRole,
                user_id: user.id,
            };

            await service.editCommercialPersonAudit(
                user.id,
                editedCommercialContact
            );

            emptyInputs();
            updateCommercialContacts();
            setCurrentCommercialPerson(null);
            setValue(null);
            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(translations + 'commercial-person-success'),
                    message: t(
                        translations + 'commercial-person-edited-successfully'
                    ),
                })
            );
            setSubmitted(!submitted);
        } else {
            dispatch(
                notificationsActions.setNotification({
                    type: 'error',
                    title: t(translations + 'commercial-person-error'),
                    message: t(translations + 'some-fields-are-empty'),
                })
            );
        }
    };

    const emptyInputs = () => {
        setCpName('');
        setCpEmail('');
        setCpPhone('');
        setCpRole('');
    };

    const onCreateCommercialContact = async () => {
        // DO STUFF
        if (
            cpName &&
            cpPhone.length >= 7 &&
            cpPhone.length <= 18 &&
            cpRole &&
            cpEmail.includes('@')
        ) {
            const newCommercialContact = {
                email: cpEmail,
                id: '',
                label: cpName,
                name: cpName,
                phone_number: cpPhone,
                role: cpRole,
                user_id: user.id,
            };

            await service.addCommercialPersonsAudit(
                user.id,
                newCommercialContact
            );
            emptyInputs();
            updateCommercialContacts();
            setCurrentCommercialPerson(null);
            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(translations + 'commercial-person-success'),
                    message: t(
                        translations + 'commercial-person-created-successfully'
                    ),
                })
            );
            setSubmitted(!submitted);
        } else {
            dispatch(
                notificationsActions.setNotification({
                    type: 'error',
                    title: t(translations + 'commercial-person-error'),
                    message: t(translations + 'some-fields-are-empty'),
                })
            );
        }
    };

    return (
        <>
            {userAdmin.session.user.id === user.id ? (
                <>
                    <br></br>
                    <div className="flex justify-between">
                        <label className="text-sm m-auto font-medium text-gray-700">
                            {t(translations + 'cannot-audit-your-own-user')}
                        </label>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex justify-center">
                        <div className="w-11/12 grid-cols-1 gap-x-14 sm:gap-y-4 sm:grid sm:grid-cols-2">
                            {commercialContacts?.length > 0 && (
                                <div className="grid col-span-2 mx-auto sm:mx-0">
                                    <label
                                        htmlFor="select-comercial-contact"
                                        className="text-sm font-medium text-gray-700">
                                        {t(
                                            translations +
                                                'select-commercial-person'
                                        )}
                                    </label>
                                    <Select
                                        placeholder={t(
                                            translations +
                                                'search-commercial-person'
                                        )}
                                        options={commercialContacts}
                                        onChange={(commercialContacts) =>
                                            putCommercialPersonsInInputs(
                                                commercialContacts
                                            )
                                        }
                                        className="border-gray-300 rounded-md mr-12"
                                        classNamePrefix="select"
                                        value={value}
                                        isClearable
                                        id="select-comercial-contact"
                                    />
                                </div>
                            )}
                            <Input
                                id="commercial-contact-name"
                                type="text"
                                submitted={submitted}
                                setSubmitted={setSubmitted}
                                label={t(translations + 'name')}
                                value={cpName}
                                onChange={(cpName) => setCpName(cpName)}
                                onValidate={validateName}
                            />
                            <Input
                                id="commercial-contact-email"
                                submitted={submitted}
                                setSubmitted={setSubmitted}
                                label={t(translations + 'email')}
                                value={cpEmail}
                                onChange={(cpEmail) => setCpEmail(cpEmail)}
                                onValidate={() =>
                                    isValidInput(cpEmail, 'email')
                                }
                            />
                            <Input
                                type="text"
                                submitted={submitted}
                                setSubmitted={setSubmitted}
                                label={t(translations + 'phoneNumber')}
                                id="commercial-contact-phone-number"
                                value={cpPhone}
                                onChange={(cpPhone) => setCpPhone(cpPhone)}
                                onValidate={() =>
                                    isValidInput(cpPhone, 'phone')
                                }
                            />
                            <Input
                                type="text"
                                submitted={submitted}
                                setSubmitted={setSubmitted}
                                label={t(translations + 'role')}
                                id="commercial-contact-role"
                                value={cpRole}
                                onChange={(cpRole) => setCpRole(cpRole)}
                                onValidate={validateRole}
                            />
                            <div className={` max-w-sm mt-4`}>
                                <div className="grid grid-cols-7">
                                    {currentCommercialPerson && (
                                        <CommonButton
                                            label={t(
                                                translations +
                                                    'delete-commercial-person'
                                            )}
                                            className="col-span-6  text-sm "
                                            styleType={'danger'}
                                            onClick={onDeleteCommercialContact}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className={` max-w-sm mt-4`}>
                                <div className="grid grid-cols-7">
                                    {
                                        <CommonButton
                                            label={t(
                                                translations +
                                                    'edit-commercial-person'
                                            )}
                                            className="col-span-6 text-sm "
                                            styleType={'primary'}
                                            onClick={onEditCommercialContact}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CommercialContacts;

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    authenticated: false,
    token: '',
    refresh_token: '',
    expires_at: 0,
    user: {},
    margins: 0,
};

const { reducer, actions } = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {
        authenticated(state, action) {
            state.authenticated = action.payload;
        },
        setSession(state, action) {
            state.authenticated = true;
            Object.keys(action.payload).map((property) => {
                state[property] = action.payload[property];
            });
        },
        removeToken(state, action) {
            state = { authenticated: false };
        },
        setMargins(state, action) {
            state.margins = action.payload;
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as sessionActions };
export { reducer as sessionReducer };

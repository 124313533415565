import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInvoices } from '../../../services/adminService';
import InvoicesTable from './Submenus/InvoicesTable';
import NewInvoice from './Submenus/NewInvoice';
import useDispatchNotification from '../../../hooks/notifications/useDispatchNotification';

const Invoices = ({ isActive }) => {
    const { t } = useTranslation();
    const [submenu, setSubmenu] = useState('invoicesTable');
    const { warningNotification } = useDispatchNotification();
    const handleSubMenu = (section) => setSubmenu(section);
    const translate = (key) => t(`sections.management.section.invoices.${key}`);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataSaved, setDataSaved] = useState([]);
    const [period, setPeriod] = useState([]);
    const [value, setValue] = useState('thisMonth');

    // TODO: pagination
    const getData = async () => {
        setIsLoading(true);
        let from = period[0] ? period[0] : '';
        let to = period[1] ? period[1] : '';
        getInvoices(from, to)
            .then((res) => {
                setData(res.data);
                setDataSaved(res.data);
            })
            .catch((err) => {
                warningNotification(
                    translate('tab'),
                    translate('invoices.invoice.error-load')
                );
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    useEffect(() => {
        if (period[0] == null && period[1] == null) return;
        getData();
    }, [period]);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-50">
                    <nav className="flex flex-col">
                        <button
                            id="invoicesTable"
                            onClick={() => handleSubMenu('invoicesTable')}
                            className={`${
                                submenu === 'invoicesTable'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {translate('tab')}
                            </span>
                        </button>

                        <button
                            id="newInvoice"
                            onClick={() => handleSubMenu('newInvoice')}
                            className={`${
                                submenu === 'newInvoice'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {translate('invoices.invoice.create')}
                            </span>
                        </button>
                    </nav>
                </div>
                <div className={`px-2 w-full`}>
                    {submenu === 'invoicesTable' ? (
                        <InvoicesTable
                            data={data}
                            setData={setData}
                            period={period}
                            setPeriod={setPeriod}
                            value={value}
                            setValue={setValue}
                            isLoading={isLoading}
                            dataSaved={dataSaved}
                            getData={getData}
                        />
                    ) : (
                        <NewInvoice />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Invoices;

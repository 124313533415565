import { useEffect, useRef } from 'react';

const useClickOuside = (handle) => {
    const containerRef = useRef(null);
    const handleClickOutside = (event) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            handle();
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return [containerRef];
};

export default useClickOuside;

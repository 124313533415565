import { createSlice } from '@reduxjs/toolkit';

const updateCampaignShowing = (
    state,
    dafultCampaignsIfNotFound = { status: '', page: 1, campaigns: [] }
) => {
    let updated = false;
    state.campaigns.forEach((data) => {
        if (data.status === state.campaignsShowing.status) {
            state.campaignsShowing = data;
            updated = true;
        }
    });

    if (!updated) {
        state.campaignsShowing = dafultCampaignsIfNotFound;
    }
};

const initialState = {
    statuses: null,
    //store all status of campaigns
    campaigns: [],
    //current status showing
    campaignsShowing: { status: '', page: 1, campaigns: [] },
    isLoading: false,
};

const { reducer, actions } = createSlice({
    name: 'campaigns',
    initialState: initialState,
    reducers: {
        setStatuses(state, action) {
            state.statuses = action.payload;
        },
        setCampaigns(state, action) {
            state.campaigns = action.payload;
            updateCampaignShowing(state);
        },
        changeCampaigns(state, action) {
            let newCampaigns = JSON.parse(JSON.stringify(state.campaigns));
            let updated = false;

            newCampaigns = newCampaigns.map((data) => {
                if (data.status === action.payload.status) {
                    updated = true;
                    return action.payload;
                } else {
                    return data;
                }
            });

            if (!updated) {
                newCampaigns.push(action.payload);
            }

            state.campaigns = newCampaigns;
            if (state.campaignsShowing.status === action.payload.status) {
                updateCampaignShowing(state, action.payload);
            }
        },
        setCurrentStatus(state, action) {
            const newCampaignsShowing = state.campaigns.find((value) => {
                return value.status === action.payload;
            });
            if (newCampaignsShowing) {
                state.campaignsShowing = newCampaignsShowing;
            } else {
                state.campaignsShowing = {
                    status: action.payload,
                    page: 1,
                    campaigns: [],
                };
            }
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        reset(state) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as campaignsActions };
export { reducer as campaignsReducer };

/**
 * IndexedDb database models.
 *
 * Models of the databases.
 *
 * @file    This files defines UseService.
 * @author  Alejandro Amaral.
 * @since   2022.05.01
 */

import Dexie from 'dexie';

export const db = new Dexie('taggify');

db.version(1).stores({
    currencies: `
        id,
        name,
        short_name,
        symbol
    `,
    screens: `
        id,
        name,
        adType,
        screenType,
        width,
        height,
        geo,
        provider,
        publisher,
        deals,
        cp,
        siz,
        la,
        ln,
        isSelecte,
        labe,
        status,
        exposure,
        connectivity,
        isBuying,
        audiences,
        normalized_size
    `,
    newCampaignData: `
        id,
        name,
        brand,
        channels,
        budget,
        dailyCap,
        timing,
        buyingRules,
        selectedCurrency,
        location,
        selectedScreens,
        showSelectedScreens,
        selectedScreenTypes,
        selectedPublishers,
        demographics,
        segments,
        forecast,
        forecastTable,
        baseName,
        sizesNeeded,
        tracking,
        creatives,
        selectedCreativeCategories,
        errors,
        canLaunch,
        isResizeMapOn,
        stepper,
        currentWizard,
        wasLaunched,
        currentPositionMap,
        status
    `,
    timezones: `
        id,
        code,
        description,
        displacement,
        gmt,
        label,
        long_description,
        name,
        value
    `,
    categories: `
        Name,
        UniqueID,
        ParentID
    `,
});

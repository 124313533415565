import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sessionReducer as session } from './session';
import { walletReducer as wallet } from './wallet';
import { dashboardReducer as dashboard } from './dashboard';
import { extraReducer as extra } from './extra';
import { notificationsReducer as notifications } from './notifications';
import { campaignsReducer as campaigns } from './campaigns';
import { screensReducer as screens } from './screens';
import { newCampaignReducer as newCampaign } from './newCampaign';
import { commercialsPersonsReducer as commercialsPersons } from './commercialPersons';

const reducer = combineReducers({
    session,
    wallet,
    dashboard,
    extra,
    notifications,
    campaigns,
    screens,
    newCampaign,
    commercialsPersons,
});

export { sessionActions } from './session';
export { walletActions } from './wallet';
export { dashboardActions } from './dashboard';
export { extraActions } from './extra';
export { notificationsActions } from './notifications';
export { campaignsActions } from './campaigns';
export { screensActions } from './screens';
export { newCampaignActions } from './newCampaign';
export { commercialsPersonsActions } from './commercialPersons';

//export default configureStore({ reducer });

// Disable default middleware that are by default in development
export default configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        });
    },
});

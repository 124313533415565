import React from 'react';
import { useTranslation } from 'react-i18next';

const AdOperationsLeaders = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const translations =
        'sections.management.section.commercial.adOperationsLeader.';
    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12}`}>
            <span className="text-gray-600 text-xl font-light">
                {t(translations + 'title')}
            </span>
        </div>
    );
};

export default AdOperationsLeaders;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Bar from '../Bar/Bar';
import Item from '../Bar/Item/Item';
import { arrayToList } from '../../../../utils/functions';

const Location = (props) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const newCampaign = useSelector((state) => state.newCampaign);
    const selectedCountries = newCampaign.location.selectedCountries;
    const selectedRegions = newCampaign.location.selectedRegions;
    const selectedCities = newCampaign.location.selectedCities;
    const all = t(
        'sections.campaigns.new-campaign.wizard-briefBar.location.all'
    );
    const zones = newCampaign.location.zones;
    const zonesLength = zones.length;

    return (
        <Bar
            isActive={isActive}
            title={t(
                'sections.campaigns.new-campaign.wizard-briefBar.location.title'
            )}>
            <Item
                first
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.location.countries'
                )}>
                {selectedCountries.length === 0
                    ? all
                    : arrayToList(selectedCountries)}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.location.regions'
                )}>
                {selectedRegions.length === 0
                    ? all
                    : arrayToList(selectedRegions)}
            </Item>

            <Item
                title={t(
                    'sections.campaigns.new-campaign.wizard-briefBar.location.cities'
                )}>
                {selectedCities.length === 0
                    ? all
                    : arrayToList(selectedCities)}
            </Item>

            {zones.length > 0 && (
                <Item
                    title={t(
                        'sections.campaigns.new-campaign.wizard-briefBar.location.places'
                    )}>
                    <ul className={`${zonesLength > 2 ? 'flex' : ''}`}>
                        {zones.map((zone, i) => (
                            <li
                                key={i}
                                className={`ml-2 ${
                                    zonesLength >= 4 ? 'text-xs' : 'text-s'
                                }`}>
                                {zone.search}
                            </li>
                        ))}
                    </ul>
                </Item>
            )}
        </Bar>
    );
};

export default Location;

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../../../containers/UseService';
import {
    formatNumber,
    numberWithoutCommas,
} from '../../../../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonButton from '../../../../Buttons/CommonButton';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../../../store';
import styles from './Budget.module.css';
import useSize from '../../../../../hooks/window/useSize';
import SwitcherButton2 from '../../../../Buttons/SwitcherButton2';
import ReactTooltip from 'react-tooltip';
import Hint from '../../../../Tools/Hint';

const InputChangeBudget = (params) => {
    const { onClick, campaign } = params;
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    const [currencySpace, setCurrencySpace] = useState('pl-7');
    const isNegativeValue =
        typeof value === 'string' ? value.startsWith('-') : false;

    const handleChange = (newValue) => {
        if (newValue === '-') {
            setValue('-');
        } else {
            const withoutCommas = numberWithoutCommas(newValue);
            if (isNaN(withoutCommas)) {
                setValue(0);
            } else {
                setValue(withoutCommas);
            }
        }
    };

    useEffect(() => {
        const amountCharacters = campaign.currency.symbol.length;
        switch (amountCharacters) {
            case 1:
                setCurrencySpace('pl-7');
                break;
            case 2:
                setCurrencySpace('pl-8');
                break;
            case 3:
                setCurrencySpace('pl-11');
                break;
            case 4:
                setCurrencySpace('pl-14');
                break;
            default:
                setCurrencySpace('pl-7');
        }
    }, [campaign]);

    const handleOnClick = async () => {
        await onClick(parseFloat(value));
        setValue('');
    };

    const parseValue = (value) => {
        if (value === '-') return '-';
        value = formatNumber(value, 'withcommas');
        return value === '0' ? '' : value;
    };

    return (
        <div className="w-full flex">
            <div className="w-8/12">
                <div className="absolute inset-y-0 left-0 pl-7 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                        {campaign &&
                            'currency' in campaign &&
                            campaign.currency.symbol}
                    </span>
                </div>
                <input
                    type="text"
                    value={parseValue(value)}
                    name="assign-budget"
                    id="assign-budget"
                    autoComplete="off"
                    onChange={(e) => handleChange(e.target.value)}
                    className={`focus:ring-0 focus:outline-none w-full flex content-center xl:w-10/12 sm:text-sm border-gray-300 rounded-sm ${currencySpace}`}
                    placeholder={'0.00'}
                />
            </div>
            <CommonButton
                disabled={value === '' || value === '-' || value === 0}
                onClick={handleOnClick}
                className="ml-2 whitespace-nowrap"
                label={
                    isNegativeValue
                        ? t('common.buttons.subtract')
                        : t('common.buttons.add')
                }
                styleType={'primary'}
                useLoader={true}
            />
        </div>
    );
};

const Budget = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const service = useService();
    const [isActiveWalletCurrency, setIsActiveWalletCurrency] = useState(null);
    const handleWalletCurrency = (section) => {
        setIsActiveWalletCurrency(section);
        const campaignsActive = campaigns.filter(
            (c) => campaignActive(c) && campaignCurrency(c, section)
        );
        const campaignsInactive = campaigns.filter(
            (c) => !campaignActive(c) && campaignCurrency(c, section)
        );
        setCampaignsActive(campaignsActive);
        setCampaignsInactive(campaignsInactive);
    };
    const [isCampaignActive, setIsCampaignActive] = useState('active');
    const handleBudgetCampaignStatus = (status) => setIsCampaignActive(status);
    const [budgetsDetails, setBudgetsDetails] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [campaignsActive, setCampaignsActive] = useState([]);
    const [campaignsInactive, setCampaignsInactive] = useState([]);
    const translations = 'sections.management.section.billing.budget.';
    const campaignActive = (campaign) => campaign.status === 5;
    const [campaignsFinisheds, setCampaignsFinisheds] = useState(false);
    const campaignCurrency = (campaign, currency) =>
        campaign.currency_name === currency;
    const { width } = useSize();

    const addBudget = async (campaign, newBudget) => {
        const availableBudgetInCurrencyCampaign =
            campaign.currency_name in budgetsDetails
                ? budgetsDetails[campaign.currency_name].unassigned
                : 0;
        if (availableBudgetInCurrencyCampaign >= newBudget) {
            try {
                const newCampaign = await service.assignBudgetToCampaign(
                    campaign.id,
                    campaign.budget + newBudget
                );
                const newBudgetsDetails = JSON.parse(
                    JSON.stringify(budgetsDetails)
                );
                newBudgetsDetails[campaign.currency_name].assigned += newBudget;
                newBudgetsDetails[campaign.currency_name].unassigned -=
                    newBudget;
                setBudgetsDetails(newBudgetsDetails);
                const newCampaigns = campaigns.map((campaign) => {
                    if (campaign.id == newCampaign.id) {
                        newCampaign.spent = campaign.spent;
                        newCampaign.budgetChanged = true;
                        return newCampaign;
                    } else {
                        return campaign;
                    }
                });
                setCampaigns(newCampaigns);
                setCampaignsActive(
                    newCampaigns.filter(
                        (c) =>
                            campaignActive(c) &&
                            campaignCurrency(c, isActiveWalletCurrency)
                    )
                );
                setCampaignsInactive(
                    newCampaigns.filter(
                        (c) =>
                            !campaignActive(c) &&
                            campaignCurrency(c, isActiveWalletCurrency) &&
                            checkFinisheds(c)
                    )
                );
                dispatch(
                    notificationsActions.setNotification({
                        type: 'success',
                        title: t(translations + 'notifications.budget-added'),
                        message: t(
                            translations +
                                'notifications.budget-added-description'
                        ),
                    })
                );
            } catch (error) {
                console.log(error);
            }
        } else {
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t(
                        translations + 'notifications.insufficient-budget'
                    ),
                    message: t(
                        translations +
                            'notifications.insufficient-budget-description'
                    ),
                })
            );
        }
    };

    // DataTables columns and data
    const columns = [
        {
            name: t(translations + 'campaigns-table.id'),
            selector: (row) => row.id,
            sortable: true,
            cell: (row) => <div>{row.id}</div>,
            width: width <= 900 ? '8ch' : '10ch',
        },
        {
            name: t(translations + 'campaigns-table.name'),
            selector: (row) =>
                row.name ? row.name : t('sections.campaign-list.without-name'),
            cell: (row) => <div>{row.name}</div>,
            sortable: true,
        },
        {
            name: t(translations + 'campaigns-table.spent-budget'),
            selector: (row) =>
                row.currency.symbol + formatNumber(row.spent, 'currency'),
            sortable: true,
            right: true,
            width: width >= 1750 ? '13%' : '13%',
        },
        {
            name: t(translations + 'campaigns-table.remaining-budget'),
            selector: (row) =>
                row.currency.symbol +
                formatNumber(row.budget - row.spent, 'currency'),
            sortable: true,
            right: true,
        },
        {
            name: t(translations + 'campaigns-table.assign-budget'),
            selector: (row) => formatNumber(row.budget, 'currency'),
            sortable: false,
            cell: (row) => (
                <InputChangeBudget
                    campaign={row}
                    onClick={(newBudget) => addBudget(row, newBudget)}
                />
            ),
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const budgetsDetails = await service.getBudgetsDetails();
                const budgets = budgetsDetails.budget;
                const campaignsSpent = budgetsDetails.campaigns.sort(
                    (a, b) => b.start_period - a.start_period
                );
                setCampaigns(campaignsSpent);
                const currenciesShortNames = Object.keys(budgets);
                if (currenciesShortNames.length > 0) {
                    setIsActiveWalletCurrency(currenciesShortNames[0]);
                    setCampaignsActive(
                        campaignsSpent.filter(
                            (c) =>
                                campaignActive(c) &&
                                campaignCurrency(c, currenciesShortNames[0])
                        )
                    );
                    setCampaignsInactive(
                        campaignsSpent.filter(
                            (c) =>
                                !campaignActive(c) &&
                                campaignCurrency(c, currenciesShortNames[0]) &&
                                checkFinisheds(c)
                        )
                    );
                }
                setBudgetsDetails(budgets);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    function checkFinisheds(c) {
        if (campaignsFinisheds) {
            return c.spent ? true : false;
        }
        return true;
    }

    useEffect(() => {
        const someCampaignChanged = campaigns.some(
            (campaign) => 'budgetChanged' in campaign
        );
        if (someCampaignChanged) {
            setTimeout(() => {
                let copyCampaigns = JSON.parse(JSON.stringify(campaigns));
                copyCampaigns.forEach(
                    (campaign) => delete campaign.budgetChanged
                );
                setCampaigns(copyCampaigns);
                setCampaignsActive(
                    copyCampaigns.filter(
                        (c) =>
                            campaignActive(c) &&
                            campaignCurrency(c, isActiveWalletCurrency)
                    )
                );
                setCampaignsInactive(
                    copyCampaigns.filter(
                        (c) =>
                            !campaignActive(c) &&
                            campaignCurrency(c, isActiveWalletCurrency)
                    )
                );
            }, 3000);
        }
    }, [campaigns]);

    useEffect(() => {
        if (campaigns.length) {
            setCampaignsInactive(
                campaigns.filter(
                    (c) =>
                        !campaignActive(c) &&
                        campaignCurrency(c, isActiveWalletCurrency) &&
                        checkFinisheds(c)
                )
            );
        }
    }, [campaignsFinisheds]);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12 overflow-x-auto`}>
            <span className="text-gray-600 text-xl font-light">
                {t(translations + 'title')}
            </span>
            {budgetsDetails === null ? ( //Loading
                <FontAwesomeIcon
                    icon={['fas', 'circle-notch']}
                    className={`text-blue-400 animate-spin mx-2 ${
                        !true ? 'invisible' : ''
                    }`}
                />
            ) : Object.keys(budgetsDetails).length === 0 ? ( //No have currencies in account
                <>
                    <span className="block text-gray-600 text-sm font-light mt-4">
                        {t(translations + 'no-currencies-available')}
                    </span>
                </>
            ) : (
                //Currencies loaded!
                <>
                    <div className="block mt-4">
                        <nav className="flex overflow-x-auto">
                            {/*
                            Según la currency a la que se le asigne plata, aparece aca, el numero corresponde al unassigned budget
                        */}
                            {Object.keys(budgetsDetails).map(
                                (currencyShortName) => (
                                    <button
                                        key={currencyShortName}
                                        id={`wallet${currencyShortName}`}
                                        onClick={() => {
                                            return handleWalletCurrency(
                                                currencyShortName
                                            );
                                        }}
                                        className={`${
                                            isActiveWalletCurrency ===
                                            currencyShortName
                                                ? 'tabActive font-semibold'
                                                : ''
                                        } tabGeneral uppercase text-xs`}>
                                        {currencyShortName}{' '}
                                        <span className="font-bold">
                                            {formatNumber(
                                                budgetsDetails[
                                                    currencyShortName
                                                ].unassigned,
                                                'currency'
                                            )}
                                        </span>
                                    </button>
                                )
                            )}
                        </nav>
                    </div>
                    <div className="block mt-4">
                        <div className="block text-gray-700 text-xs">
                            <span className="font-bold">
                                {t('common.words.currency')}:
                            </span>{' '}
                            {isActiveWalletCurrency}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 xl:grid-cols-4">
                            <div className={`module m-1 w-full h-24`}>
                                <div className="font-light text-xs uppercase">
                                    {t(translations + 'deposited-budget')}{' '}
                                    <br />
                                    <span className="text-xs text-gray-600 capitalize">
                                        ({t(translations + 'historical')})
                                    </span>
                                </div>
                                <div className="font-semibold text-2xl">
                                    <span className="text-xs font-thin text-gray-600">
                                        {isActiveWalletCurrency}
                                    </span>{' '}
                                    {formatNumber(
                                        budgetsDetails[isActiveWalletCurrency]
                                            .deposited,
                                        'rounded'
                                    )}
                                </div>
                            </div>

                            <div className={`module m-1 w-full h-24`}>
                                <div className="font-light text-xs uppercase">
                                    {t(translations + 'unassigned-budget')}{' '}
                                    <br />
                                    <span className="text-xs text-gray-600 capitalize">
                                        ({t(translations + 'to-alocate')})
                                    </span>
                                </div>
                                <div className="font-semibold text-2xl">
                                    <span className="text-xs font-thin text-gray-600">
                                        {isActiveWalletCurrency}
                                    </span>{' '}
                                    {formatNumber(
                                        budgetsDetails[isActiveWalletCurrency]
                                            .unassigned,
                                        'rounded'
                                    )}
                                </div>
                            </div>

                            <div className={`module m-1 w-full h-24`}>
                                <div className="font-light text-xs uppercase">
                                    {t(translations + 'assigned-budget')} <br />
                                    <span className="text-xs text-gray-600 capitalize">
                                        ({t(translations + 'to-spent')})
                                    </span>
                                </div>
                                <div className="font-semibold text-2xl">
                                    <span className="text-xs font-thin text-gray-600">
                                        {isActiveWalletCurrency}
                                    </span>{' '}
                                    {formatNumber(
                                        budgetsDetails[isActiveWalletCurrency]
                                            .assigned,
                                        'rounded'
                                    )}
                                </div>
                            </div>

                            <div className={`module m-1 w-full h-24`}>
                                <div className="font-light text-xs uppercase">
                                    {t(translations + 'spent-budget')} <br />
                                    <span className="text-xs text-gray-600 capitalize">
                                        ({t(translations + 'historical')})
                                    </span>
                                </div>
                                <div className="font-semibold text-2xl">
                                    <span className="text-xs font-thin text-gray-600">
                                        {isActiveWalletCurrency}
                                    </span>{' '}
                                    {formatNumber(
                                        budgetsDetails[isActiveWalletCurrency]
                                            .spent,
                                        'rounded'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="block text-gray-700 text-xs my-4">
                            <span className="font-bold">
                                {t(translations + 'campaigns-in')}:
                            </span>{' '}
                            {isActiveWalletCurrency}
                        </div>
                        <div className="block mt-4">
                            <nav className="flex">
                                <button
                                    id="budgetCampaignStatusActive"
                                    onClick={() =>
                                        handleBudgetCampaignStatus('active')
                                    }
                                    className={`${
                                        isCampaignActive === 'active'
                                            ? 'tabActive font-semibold'
                                            : ''
                                    } tabGeneral uppercase text-xs`}>
                                    {t(translations + 'active-campaigns')}
                                </button>
                                <button
                                    id="budgetCampaignStatusOthers"
                                    onClick={() =>
                                        handleBudgetCampaignStatus('others')
                                    }
                                    className={`${
                                        isCampaignActive === 'others'
                                            ? 'tabActive font-semibold'
                                            : ''
                                    } tabGeneral uppercase text-xs`}>
                                    {t(translations + 'other-campaigns-status')}
                                </button>
                            </nav>
                            <div
                                className={`${
                                    isCampaignActive === 'others'
                                        ? 'block'
                                        : 'hidden'
                                } uppercase text-xs text-gray-500 text-center flex mt-2 align-center justify-end`}>
                                <span
                                    className=" mr-5 mt-2 text-center"
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '1.2',
                                    }}>
                                    {t(
                                        translations +
                                            'other-campaigns-status-finisheds'
                                    )}
                                </span>
                                <div className="flex align-center justify-between">
                                    <SwitcherButton2
                                        value={campaignsFinisheds}
                                        handleClick={() =>
                                            setCampaignsFinisheds(
                                                !campaignsFinisheds
                                            )
                                        }
                                    />
                                    <Hint
                                        className="my-auto mx-3"
                                        text={t(
                                            'common.tooltips.campaigns.finisheds'
                                        )}
                                        type={'info'}
                                    />
                                </div>
                            </div>

                            {/* Active */}
                            <div
                                className={`my-4 ${
                                    isCampaignActive === 'active'
                                        ? 'block'
                                        : 'hidden'
                                }`}>
                                <DataTable
                                    columns={columns}
                                    data={campaignsActive}
                                    pagination={true}
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 25, 50]}
                                />
                            </div>
                            {/* Others */}
                            <div
                                className={`my-4 ${
                                    isCampaignActive === 'others'
                                        ? 'block'
                                        : 'hidden'
                                }`}>
                                <DataTable
                                    columns={columns}
                                    data={campaignsInactive}
                                    pagination={true}
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 25, 50]}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Budget;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateButton = ({
    isLoading,
    isSuccess,
    handleClick,
    label,
    isError,
}) => {
    return (
        <button
            className="capitalize bg-blue-400 hover:bg-blue-600 text-white rounded transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none mt-4"
            onClick={handleClick}
            disabled={isError}>
            {!isLoading && !isSuccess && label}
            {isLoading && (
                <div className="w-8 container mx-auto">
                    <FontAwesomeIcon
                        icon={['fas', 'circle-notch']}
                        className="text-blue-800 animate-spin "
                    />
                </div>
            )}
            {isSuccess && (
                <div className="w-8 container mx-auto">
                    <FontAwesomeIcon
                        icon={['fas', 'check']}
                        className="text-blue-800"
                    />
                </div>
            )}
        </button>
    );
};

export default CreateButton;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';
import InputZoneSearch from './InputZoneSearch/InputZoneSearch';
import { newCampaignActions } from '../../../../store';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import { BlockPicker } from 'react-color';
import { getRandomHex, hexaToRgb, rgbToHex } from '../../../../utils/functions';
import PoisPdf from '../../../Pdf/PoisPdf';
import { usePDF } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import ModalPdf from '../../../Modal/ModalPdf/ModalPdf';
import { useAuth } from '../../../../containers/UseAuth';

const randomRgb = getRandomHex(15);

const Zone = ({
    data,
    index,
    onRemove,
    toggleActive,
    dispatch,
    newCampaign,
}) => {
    const [showPicker, setShowPicker] = useState('hidden');
    let selectedColor = data.results;

    const locationZones =
        (newCampaign?.location?.zones ?? 0) !== 0
            ? newCampaign.location.zones
            : [];
    const mapLocationZones =
        (newCampaign?.mapLocations?.zones?.length ?? 0) !== 0
            ? newCampaign.mapLocations.zones
            : [];

    const handleColor = (color) => {
        color = hexaToRgb(color);

        const arr = [];
        selectedColor.forEach((result) => {
            arr.push({ ...result, color });
        });
        selectedColor = arr;

        const newZonesLocation = locationZones.map((z) => {
            if (z.search === data.search) {
                return {
                    ...z,
                    results: arr,
                };
            } else {
                return z;
            }
        });

        const newLocation = Object.assign({}, newCampaign.location);
        newLocation.zones = newZonesLocation;
        dispatch(newCampaignActions.setLocation(newLocation));

        if (mapLocationZones.length > 0) {
            const newLocationZones = mapLocationZones.map((z) => {
                if (z.search === data.search) {
                    return {
                        ...z,
                        results: arr,
                    };
                } else {
                    return z;
                }
            });

            const newLocations = Object.assign({}, newCampaign.location);
            newLocations.zones = newLocationZones;
            // dispatch(newCampaignActions.setLocation(newLocations));
            dispatch(newCampaignActions.setMapLocations(newLocations));
        }
    };

    const handleFilter = () => {
        toggleActive(data);
    };

    const handlePicker = () => {
        let isHidden = showPicker === 'hidden';

        if (!isHidden) {
            setShowPicker('hidden');
        } else {
            setShowPicker('');
        }
    };

    const openLocationOnMap = (zone) => {
        const newLocations = JSON.parse(JSON.stringify(newCampaign.location));

        const index = newLocations.zones.findIndex(
            (z) => JSON.stringify(z) === JSON.stringify(zone)
        );

        const removedZone = newLocations.zones.splice(index, 1)[0];

        if (removedZone !== undefined) {
            newLocations.zones.push(removedZone);
        }

        dispatch(newCampaignActions.setMapLocations(newLocations));
    };

    return (
        <li className="py-3 flex">
            <div className="flex justify-between w-full p-1">
                <span
                    style={{ backgroundColor: selectedColor[0].color }}
                    className={`h-5 w-5 cursor-pointer rounded-full`}
                    onClick={() => handlePicker()}>
                    <BlockPicker
                        styles={{ zIndex: '3000' }}
                        className={`absolute mt-8 ${showPicker} transition-opacity`}
                        color={selectedColor[0].color}
                        colors={randomRgb}
                        onChangeComplete={(e) => {
                            handleColor(e.hex);
                        }}
                    />
                </span>
                <label className="ml-2 inline w-full text-gray-500">
                    <span
                        onClick={() => openLocationOnMap(data)}
                        htmlFor={`placement-${index}`}>
                        {data.search}
                    </span>
                </label>
                <SwitcherButton
                    className={'absolute text-gray-500 right-14 -mt-1 mr-4'}
                    labelOn={'Filtering on'}
                    labelOff={'Filtering off'}
                    status={data.active}
                    onToggle={() => {
                        handleFilter();
                    }}
                    duration={50}
                />
                <FontAwesomeIcon
                    onClick={() => onRemove(data)}
                    icon={['fas', 'minus-circle']}
                    className="text-gray-500 cursor-pointer mr-4 h-5"
                />
            </div>
        </li>
    );
};

const NearPlace = ({ zones, dispatch, newCampaign, filteredScreensLocal }) => {
    const { t } = useTranslation();
    const [circleSize, setCircleSize] = useState('w-8 h-8');
    const [circleMargin, setCircleMargin] = useState('-mt-2');
    const [circleValueString, setCircleValueString] = useState(
        t('sections.campaigns.new-campaign.wizard-basics.location.range-min')
    );
    const selectedScreens = newCampaign.selectedScreens;
    const isResizeMapOn = newCampaign.isResizeMapOn;
    const [radius, setRadius] = useState(0);
    const [isLoadingPDF, setIsLoadingPDF] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        switch (radius) {
            default:
            case 0:
                setCircleSize('w-8 h-8');
                setCircleMargin('-mt-2');
                setCircleValueString(
                    t(
                        'sections.campaigns.new-campaign.wizard-basics.location.range-min'
                    )
                );
                break;
            case 1:
                setCircleSize('w-12 h-12');
                setCircleMargin('-mt-4');
                setCircleValueString(
                    t(
                        'sections.campaigns.new-campaign.wizard-basics.location.range-mid'
                    )
                );
                break;
            case 2:
                setCircleSize('w-16 h-16');
                setCircleMargin('-mt-6');
                setCircleValueString(
                    t(
                        'sections.campaigns.new-campaign.wizard-basics.location.range-max'
                    )
                );
                break;
        }
    }, [radius]);

    const removeZone = (zone) => {
        const newLocation = Object.assign({}, newCampaign.location);
        newLocation.zones = zones.filter((z) => z !== zone);
        dispatch(newCampaignActions.setLocation(newLocation));
        dispatch(newCampaignActions.setMapLocations(newLocation));
    };

    const toggleActive = (zone) => {
        const newZones = zones.map((z) => {
            if (z.search === zone.search) {
                const active = !zone.active;
                return { ...z, active };
            } else {
                return z;
            }
        });

        const newLocation = Object.assign({}, newCampaign.location);
        newLocation.zones = newZones;

        dispatch(newCampaignActions.setLocation(newLocation));
        dispatch(newCampaignActions.setMapLocations(newLocation));
    };

    const [url, setUrl] = useState([]);
    const [colors, setColors] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const pdf = (
        <PoisPdf
            colors={colors}
            markers={url.join('&')}
            screens={
                auth.isAdmin()
                    ? filteredScreensLocal
                    : filteredScreensLocal.filter(
                          (screen) =>
                              !screen.label.toLowerCase().includes('test')
                      )
            }
            selectedScreens={selectedScreens}
        />
    );
    const [instance, setInstance] = usePDF({ document: pdf });

    useEffect(() => {
        let newURL = [];
        let newColors = [];
        let newCSVData = [];
        zones.forEach((zone) => {
            zone.results.forEach((result) => {
                newCSVData.push({
                    name: zone.search,
                    lat: result.lat,
                    lng: result.lng,
                });

                const color = result.color;
                let hexa;
                if (color) {
                    const arr = color?.split('(');
                    const splited = arr[1]?.split(')');
                    const rgb = splited[0]?.split(',');
                    hexa = rgbToHex(rgb[0], rgb[1], rgb[2]);
                }
                const urlPath = `markers=size:tiny%7Ccolor:0x${hexa}%7C${result.lat},${result.lng}`;
                newURL.push(urlPath);
            });

            newColors.push({
                label: zone.search,
                color: zone.results[0].color,
            });
        });
        setUrl(newURL);
        setCsvData(newCSVData);
        setColors(newColors);
    }, [zones]);

    const downloadPDf = (e) => {
        setIsLoadingPDF(true);
        const pdfpois = (
            <PoisPdf
                colors={colors}
                markers={url.join('&')}
                screens={filteredScreensLocal}
                selectedScreens={selectedScreens}
            />
        );
        setInstance({ document: pdfpois });
    };

    if (!instance.loading && instance.url && isLoadingPDF) {
        setIsLoadingPDF(false);
        setShowPdf(true);
    }

    if (instance.error) {
        console.log('instance.error: ', instance.error);
    }

    return (
        <div className="module rounded m-1 mb-4" id="screenshot">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight w-full">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.location.nearPlace'
                    )}
                </div>
            </div>
            <div
                className={`body flex flex-col md:flex-row justify-between rounded-sm p-1 w-full ${
                    isResizeMapOn ? 'hidden' : ''
                }`}>
                <div className="w-full md:w-3/6 mb-4">
                    <label
                        className="text-sm font-medium text-gray-700"
                        htmlFor="zone-search">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.place-label'
                        )}
                    </label>
                    <div className="mt-4">
                        <InputZoneSearch id="zone-search" radius={radius} />
                    </div>
                </div>
                <div className="w-full md:w-3/6 md:ml-4 mb-4 flex justify-start content-center">
                    <div className="w-1/3">
                        <label>
                            <span className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.distance-label'
                                )}
                            </span>
                            <div className="my-2 h-12 flex justify-center">
                                <Slider
                                    vertical={true}
                                    min={0}
                                    max={2}
                                    step={1}
                                    value={radius}
                                    dots={true}
                                    onChange={setRadius}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="w-1/3 mt-4 flex justify-center content-center text-center self-center">
                        <div className="relative w-auto h-auto mx-auto flex justify-center content-center text-center ">
                            <div
                                className={`${circleSize} ${circleMargin} absolute border-2 border-opacity-10 rounded-full border-purple-600 bg-purple-600 bg-opacity-10 transform transition-all duration-300`}>
                                <FontAwesomeIcon
                                    icon={['fad', `sign`]}
                                    className="text-purple-900 opacity-30 absolute text-xs"
                                />
                                <FontAwesomeIcon
                                    icon={['fad', `sign`]}
                                    className={`${
                                        circleSize === 'w-8 h-8' ? 'hidden' : ''
                                    } text-purple-900 opacity-50 absolute left-0 bottom-4 text-xs`}
                                />
                                <FontAwesomeIcon
                                    icon={['fad', `sign`]}
                                    className={`${
                                        circleSize === 'w-8 h-8' ? 'hidden' : ''
                                    } text-purple-900 opacity-40 absolute right-0 top-4 text-xs`}
                                />
                                <FontAwesomeIcon
                                    icon={['fad', `sign`]}
                                    className={`${
                                        circleSize === 'w-16 h-16'
                                            ? ''
                                            : 'hidden'
                                    } text-purple-900 opacity-30 absolute right-3 bottom-2 text-xs`}
                                />
                            </div>
                            <div className="absolute mx-auto">
                                <FontAwesomeIcon
                                    icon={['fas', `map-marker`]}
                                    className="text-purple-900 opacity-100 mt-0.5"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-gray-400 self-center mt-6 font-light">
                        {circleValueString}
                    </div>
                </div>
            </div>
            {zones.length > 0 && (
                <div className={'flex justify-between'}>
                    <span className="block text-sm font-medium text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.history-label'
                        )}
                    </span>
                    <div className="cursor-pointer">
                        <CSVLink
                            filename={`pois.csv`}
                            data={csvData}
                            target={'_blank'}>
                            <FontAwesomeIcon
                                icon={['fas', 'file-csv']}
                                className="text-blue-300"
                            />
                            <span className="text-blue-300 text-sm pl-2 pr-4">
                                {t(
                                    'common.components.ReportsTable.export-to-csv'
                                )}
                            </span>
                        </CSVLink>
                        <span
                            className="text-gray-500 text-sm"
                            onClick={downloadPDf}>
                            <FontAwesomeIcon
                                icon={['fad', 'file-pdf']}
                                className="text-sm font-medium text-gray-500 mr-2"
                            />
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.location.button-placeholder'
                            )}
                            <FontAwesomeIcon
                                icon={['fas', 'circle-notch']}
                                className={`text-blue-800 ml-2 animate-spin ${
                                    isLoadingPDF ? '' : 'invisible'
                                }`}
                            />
                        </span>
                    </div>
                </div>
            )}
            <div className="block">
                <ul className="list-none divide-y py-2">
                    {zones.map((zone, index) => (
                        <Zone
                            data={zone}
                            dispatch={dispatch}
                            newCampaign={newCampaign}
                            toggleActive={toggleActive}
                            onRemove={removeZone}
                            key={index}
                            index={index}
                        />
                    ))}
                </ul>
            </div>
            {showPdf && (
                <ModalPdf
                    isShowPdf={showPdf}
                    pdfUrl={instance.url}
                    titleIframe={`#${newCampaign.id}-maps.pdf`}
                    setIsShowPdf={() => setShowPdf(false)}
                    classNameStyles="h-full pb-6"
                />
            )}
        </div>
    );
};

export default NearPlace;

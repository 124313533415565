import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import { useService } from '../../../../containers/UseService.js';
import { useAuth } from '../../../../containers/UseAuth';
import CampaignAutodelete from './Settings/CampaignAutodelete';

export default function Settings() {
    const { t } = useTranslation();

    const currentFormat = localStorage.getItem('hourFormat');

    const [status, setStatus] = useState(currentFormat === 'ampm');

    function setHourFormatInLocalStorage(value) {
        let format = value ? 'ampm' : '24hs';

        localStorage.setItem('hourFormat', format);
    }

    useEffect(() => {
        setHourFormatInLocalStorage(status);
    }, [status]);

    return (
        <>
            <span className="text-gray-600 text-xl font-light m-3 mb-5">
                {t(
                    'sections.management.section.preferences.settings.section.format'
                )}
            </span>
            <div className="header flex justify-between items-center ">
                <div className="text-gray-600 text-xs md:text-sm p-2 m-2">
                    {t(
                        'sections.management.section.preferences.settings.hour-format-select.title'
                    )}
                </div>

                <SwitcherButton
                    onToggle={() => setStatus(!status)}
                    labelOn={'AM/PM'}
                    labelOff={'24hs'}
                    status={status}
                />
            </div>
            <span className="text-gray-600 text-xl font-light m-3 mb-5">
                {t(
                    'sections.management.section.preferences.settings.section.campaigns'
                )}
            </span>
            <div className="header flex justify-between items-center">
                <CampaignAutodelete />
            </div>
        </>
    );
}

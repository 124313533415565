import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import CommonButton from '../../../Buttons/CommonButton';
import { formatNumber } from '../../../../utils/functions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Hint from '../../../Tools/Hint';
import { updatePricing } from '../../../../services/pricingService';
import { notificationsActions } from '../../../../store';
import Modal from '../../../Modal/Modal';
import ModalHeader from '../../../Modal/ModalHeader/ModalHeader';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import ModalFooter from '../../../Modal/ModalFooter/ModalFooter';

const UpdatePricing = ({
    isActive,
    screenPrices,
    currenciesWithLabel,
    selectedCurrency,
    selectedTier,
    handleChangeCurrency,
    isUpdateModalActive,
    setIsUpdateModalActive,
    tierList,
    setTierList,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [pricing, setPricing] = useState('');
    const [screenType, setScreenType] = useState('');

    useEffect(() => {
        setPricing(selectedTier?.baseCpm);
        setScreenType(selectedTier?.screenType);
    }, [selectedTier, isUpdateModalActive]);

    const inputPlaceholder = formatNumber('0.00', 'properCurrency', null, {
        currency: 'USD',
    });

    const handleChangePricing = (e) => {
        const price = e.target.value;
        setPricing(price);
    };

    const handleChangeScreenType = (e) => {
        const screenType = e.target.value;
        setScreenType(screenType);
    };

    const handleUpdatePrice = () => {
        const bodyReq = {
            tier: parseInt(selectedTier?.tier),
            screenType: screenType,
            baseCpm: parseFloat(pricing),
        };

        updatePricing(selectedTier?.id, bodyReq)
            .then(() => {
                const updatedTierList = tierList.map((item) => {
                    if (item.id === selectedTier.id) {
                        return { ...item, baseCpm: bodyReq.baseCpm };
                    }
                    return item;
                });

                setTierList(updatedTierList);

                dispatch(
                    notificationsActions.setNotification({
                        type: 'success',
                        title: t(
                            'sections.management.section.pricing.updateTier.notificationUpdated.title'
                        ),
                        message: t(
                            'sections.management.section.pricing.updateTier.notificationUpdated.message'
                        ),
                    })
                );

                handleUpdateModal();
            })
            .catch(() => {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'error',
                        title: t(
                            'sections.management.section.pricing.updateTier.error-title'
                        ),
                        message: t(
                            'sections.management.section.pricing.updateTier.error-message'
                        ),
                    })
                );
            });
    };

    const handleUpdateModal = () => {
        setIsUpdateModalActive(!isUpdateModalActive);
    };

    const handleCancelUpdateModal = () => {
        handleUpdateModal();
    };

    return (
        <Modal
            size="sm"
            active={isUpdateModalActive}
            toggler={handleCancelUpdateModal}>
            <ModalHeader toggler={handleCancelUpdateModal}>
                {t('sections.management.section.pricing.updateTier.tab')}:{' '}
                {selectedTier?.tier} - {selectedTier?.screenType}
            </ModalHeader>
            <ModalBody>
                <div
                    id="modal-update-body"
                    data-testid="modal-update-body"
                    className={`block px-4 w-full`}>
                    <div className={`block w-full bg-opacity-90 `}>
                        <label
                            htmlFor="update-price"
                            className="block text-gray-700 text-sm font-bold">
                            <span className="flex items-center">
                                <FontAwesomeIcon
                                    icon={['fal', 'sign']}
                                    fixedWidth
                                    className="text-blue-500"
                                />
                                <p className="ml-1">
                                    {t(
                                        'sections.management.section.pricing.updateTier.screenType.title'
                                    )}
                                </p>
                            </span>
                            <p className="font-light">
                                {t(
                                    'sections.management.section.pricing.updateTier.screenType.updateLabel'
                                )}
                            </p>
                        </label>
                        <div className="my-2 w-full">
                            <div className="flex">
                                <div className="relative flex-grow">
                                    <input
                                        type="text"
                                        name="price"
                                        id="update-price"
                                        data-testid="update-price"
                                        value={screenType}
                                        autoComplete="off"
                                        onChange={handleChangeScreenType}
                                        className="focus:ring-0 focus:outline-none block w-full bottom-0 border-gray-300 rounded-md"
                                        placeholder={t(
                                            'sections.management.section.pricing.updateTier.screenType.title'
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`block w-full bg-opacity-90 `}>
                        <label
                            htmlFor="price-input"
                            className="block text-gray-700 text-sm font-bold">
                            <span className="flex items-center">
                                <FontAwesomeIcon
                                    icon={['fal', 'coin']}
                                    fixedWidth
                                    className="text-blue-500"
                                />
                                <p className="ml-1">
                                    {t(
                                        'sections.management.section.pricing.updateTier.setPrice.title'
                                    )}
                                </p>
                                <Hint
                                    className="ml-1 font-normal"
                                    title={t(
                                        'sections.management.section.pricing.common.hint.title'
                                    )}
                                    text={t(
                                        'sections.management.section.pricing.common.hint.description'
                                    )}
                                    type={'info'}
                                    dataFor="price"
                                />
                            </span>
                            <p className="font-light">
                                {t(
                                    'sections.management.section.pricing.updateTier.setPrice.label'
                                )}
                            </p>
                        </label>
                        <div className="my-2 w-full">
                            <div className="flex">
                                <div className="relative flex-grow">
                                    <input
                                        type="number"
                                        steps="0.01"
                                        min="0"
                                        name="price"
                                        id="price-input"
                                        data-testid="price-input"
                                        value={pricing}
                                        autoComplete="off"
                                        onChange={handleChangePricing}
                                        className="focus:ring-0 focus:outline-none block w-full bottom-0 border-gray-300 rounded-md"
                                        placeholder={inputPlaceholder}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${
                            isActive ? 'block' : 'hidden'
                        } w-full bg-opacity-90`}>
                        <label
                            htmlFor="tier"
                            className="block text-gray-700 text-sm font-bold mb-2">
                            <FontAwesomeIcon
                                icon={['fal', 'coin']}
                                fixedWidth
                                className="text-blue-500"
                            />{' '}
                            {t(
                                'sections.management.section.pricing.common.currency.title'
                            )}
                            <p className="font-light">
                                {t(
                                    'sections.management.section.pricing.common.currency.label'
                                )}
                            </p>
                        </label>
                        <div className="my-2 w-full">
                            <Select
                                inputId="currency"
                                id="select-currency"
                                placeholder={t(
                                    'sections.management.section.billing.funding.currencySelect'
                                )}
                                options={currenciesWithLabel}
                                value={selectedCurrency}
                                onChange={handleChangeCurrency}
                            />
                        </div>
                        {selectedCurrency && pricing && screenPrices && (
                            <div className="my-2 w-full md:w-3/6 xl:w-2/6 font-light">
                                {t(
                                    'sections.management.section.pricing.common.price.title'
                                )}{' '}
                                {selectedCurrency?.value}:{' '}
                                {formatNumber(
                                    pricing *
                                        (screenPrices[
                                            selectedCurrency?.value
                                        ] || 1),
                                    'properCurrency',
                                    null,
                                    {
                                        currency: selectedCurrency?.value,
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <CommonButton
                    onClick={handleCancelUpdateModal}
                    label={t('common.buttons.cancel')}
                    styleType={'danger'}
                    className="mx-2 capitalize "
                    id="modal-update-cancel"
                />
                <CommonButton
                    disabled={!selectedTier || pricing === ''}
                    label={t(
                        'sections.management.section.pricing.updateTier.tab'
                    )}
                    styleType="primary"
                    className="mx-2 capitalize "
                    onClick={handleUpdatePrice}
                    id="modal-update-save"
                />
            </ModalFooter>
        </Modal>
    );
};

export default UpdatePricing;

import React, { useEffect, useState } from 'react';
import CreateTier from './Submenu/CreateTier';
import TierList from './Submenu/TierList';
import {
    getCurrenciesList,
    getTierLists,
    getTierTypesList,
} from '../../../services/pricingService';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Pricing = ({ isActive }) => {
    const dispatch = useDispatch();
    const [isActiveMenu, setIsActiveMenu] = useState('tierList');
    const [isLoading, setIsLoading] = useState(false);
    const [tierList, setTierList] = useState([]);
    const [forceRerender, setForceRerender] = useState(false);
    const [screenPrices, setScreenPrices] = useState(null);
    const [tierTypes, setTierTypes] = useState(null);
    const currencies = useSelector((state) => {
        return state.wallet.currencies;
    });
    const [currenciesWithLabel, setCurrenciesWithLabel] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getTierLists(), getCurrenciesList(), getTierTypesList()])
            .then((res) => {
                setTierList(res[0].message);
                setScreenPrices(res[1].data.conversion_rates);
                setTierTypes(res[2].message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [forceRerender]);

    useEffect(() => {
        setCurrenciesWithLabel(
            currencies.map((currency) => {
                return {
                    ...currency,
                    label: `${currency.symbol} ${currency.short_name} - ${currency.name}`,
                    value: currency.short_name,
                };
            })
        );
    }, [currencies]);

    const handleChangeCurrency = (currency) => {
        if (currency.value === 'SOL') {
            setSelectedCurrency({
                label: currency.label,
                value: 'PEN',
            });
        } else {
            setSelectedCurrency(currency);
        }
    };

    const handleFinancialMenu = (section) => {
        setIsActiveMenu(section);
        setForceRerender(!forceRerender);
    };

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-50">
                    <nav className="flex flex-col">
                        <button
                            id="tierList"
                            onClick={() => {
                                handleFinancialMenu('tierList');
                            }}
                            className={`${
                                isActiveMenu === 'tierList'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.pricing.tierList.tab'
                                )}
                            </span>
                        </button>
                        <button
                            id="createTier"
                            onClick={() => {
                                handleFinancialMenu('createTier');
                            }}
                            className={`${
                                isActiveMenu === 'createTier'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.pricing.createTier.tab'
                                )}
                            </span>
                        </button>
                    </nav>
                </div>

                <CreateTier
                    isActive={isActiveMenu === 'createTier'}
                    rerender={forceRerender}
                    screenPrices={screenPrices}
                    tierTypes={tierTypes}
                    currenciesWithLabel={currenciesWithLabel}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    handleChangeCurrency={handleChangeCurrency}
                />
                <TierList
                    isActive={isActiveMenu === 'tierList'}
                    isLoading={isLoading}
                    tierList={tierList}
                    setTierList={setTierList}
                    dispatch={dispatch}
                    screenPrices={screenPrices}
                    tierTypes={tierTypes}
                    currenciesWithLabel={currenciesWithLabel}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    handleChangeCurrency={handleChangeCurrency}
                />
            </div>
        </div>
    );
};

export default Pricing;

import React from 'react';

const Item = (props) => {
    const { children, first, title } = props;
    return (
        <div className={`${first ? 'pl-0' : ''} px-4 pt-2`}>
            <span className="label font-light">{title}</span>
            <br />
            <span className="value font-extrabold text-gray-700 text-base">
                {children}
            </span>
        </div>
    );
};

export default Item;

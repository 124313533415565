import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommercialInformation from './Submenu/CommercialInformation/CommercialInformation';
import CompanyInformation from './Submenu/CompanyInformation/CompanyInformation';

const Company = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const [isActiveFinancialMenu, setIsActiveFinancialMenu] =
        useState('companyInformation');
    const handleFinancialMenu = (section) => setIsActiveFinancialMenu(section);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div className="flex divide-x divide-dotted">
                <div className="w-50">
                    <nav className="flex flex-col">
                        <button
                            id="financialSectionCompany"
                            onClick={() =>
                                handleFinancialMenu('companyInformation')
                            }
                            className={`${
                                isActiveFinancialMenu === 'companyInformation'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.company.companyInformation.tab'
                                )}
                            </span>
                        </button>

                        <button
                            id="financialSectionCommercial"
                            onClick={() =>
                                handleFinancialMenu('companyCommercial')
                            }
                            className={`${
                                isActiveFinancialMenu === 'companyCommercial'
                                    ? 'tabActiveV font-semibold truncate'
                                    : ''
                            } tabGeneral uppercase text-xs text-right whitespace-nowrap`}>
                            <span className="hidden md:inline-block">
                                {t(
                                    'sections.management.section.company.companyCommercial.tab'
                                )}
                            </span>
                        </button>
                    </nav>
                </div>

                <CompanyInformation
                    isActive={isActiveFinancialMenu === 'companyInformation'}
                />
                <CommercialInformation
                    isActive={isActiveFinancialMenu === 'companyCommercial'}
                />
            </div>
        </div>
    );
};

export default Company;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomPeriodModal from '../Dashboard/CustomPeriodModal/CustomPeriodModal';
import moment from 'moment';
import { extraActions } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateAccordingToBrowser } from '../../utils/functions';

const DropdownPeriods = (props) => {
    const {
        fromWholePeriod,
        toWholePeriod,
        UTC = false,
        handleDropdownPeriods,
        setCurrentPeriod,
        currentPeriod,
        value,
        setValue,
        isDisabled,
        loadingIcon,
    } = props;
    const { t } = useTranslation();
    const translatePeriods = 'common.components.DropdownPeriods.periods.';
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    const isByuserReport =
        handleDropdownPeriods == 'usersReports' ||
        handleDropdownPeriods == 'invoicesReports';
    const toggleModal = () => setShowModal(!showModal);
    const periods = {
        placeholder: t(translatePeriods + 'choose-period'),
        today: t(translatePeriods + 'today'),
        yesterday: t(translatePeriods + 'yesterday'),
        thisMonth: t(translatePeriods + 'this-month'),
        whole: t(translatePeriods + 'whole'),
        custom: t(translatePeriods + 'custom'),
    };

    const onChange = (value) => setValue(value); // this goes to localstorage

    const isSafariBrowser =
        navigator.userAgent.indexOf('Safari') > -1 &&
        navigator.userAgent.indexOf('Chrome') <= -1;

    useEffect(() => {
        isByuserReport ||
            dispatch(
                extraActions.setCurrentPeriodName(
                    value !== 'placeholder' ? periods[value] : ''
                )
            );
        if (value === 'placeholder' && !showModal) {
            isByuserReport || dispatch(extraActions.resetPeriod());
        } else if (value === 'custom') {
            setShowModal(true);
            setValue('placeholder');
        } else {
            let from = null;
            let to = null;

            switch (value) {
                case 'today':
                    from = moment().format('YYYY-MM-DD 00:00:00'); // aca habia un doble espacio entre D y 0
                    to = moment().format('YYYY-MM-DD 23:59:00');
                    break;
                case 'yesterday':
                    from = moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD 00:00:00');
                    to = moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD 23:59:00');
                    break;
                case 'thisMonth':
                    from = moment().format('YYYY-MM-01 00:00:00');
                    to = moment().format('YYYY-MM-DD 23:59:00');
                    break;
                case 'whole':
                    if (fromWholePeriod)
                        from = moment(fromWholePeriod).format(
                            'YYYY-MM-DD 00:00:00'
                        );
                    if (!fromWholePeriod) {
                        from = moment(
                            toWholePeriod ? toWholePeriod : new Date()
                        ).format('YYYY-MM-DD 00:00:00');
                        setValue('today');
                    }
                    to = moment(
                        toWholePeriod ? toWholePeriod : new Date()
                    ).format('YYYY-MM-DD 23:59:00');
                    break;
                default:
                    break;
            }
            isByuserReport && currentPeriod[0] != from && currentPeriod[1] != to
                ? setCurrentPeriod([from, to])
                : setCurrentPeriod([from, to]);
        }
    }, [value]);

    return (
        <>
            <div
                className={`${
                    isByuserReport
                        ? ''
                        : 'workingArea flex justify-between items-center w-full mt-10 md:mt-16'
                }`}>
                {isByuserReport || (
                    <div className="font-extralight text-xl p-2">
                        {t('common.menu.dashboard')}
                    </div>
                )}
                <div className="relative text-right mb-2">
                    {currentPeriod.length === 2 &&
                        currentPeriod[0] &&
                        currentPeriod[1] && (
                            <span className="text-gray-600 text-xs md:text-sm mr-2">
                                {`${formatDateAccordingToBrowser(
                                    currentPeriod[0]
                                )} ~ ${formatDateAccordingToBrowser(
                                    currentPeriod[1]
                                )}`}
                            </span>
                        )}
                    <FontAwesomeIcon
                        icon={['fad', 'calendar']}
                        fixedWidth
                        className="md:mr-2 text-blue-500"
                    />
                    <select
                        disabled={
                            (dashboard.isLoading &&
                                dashboard.isSilentLoading) ||
                            isDisabled
                        }
                        value={value}
                        onChange={(e) => {
                            return onChange(e.target.value);
                        }}
                        id="ifAny"
                        name="ifAny"
                        className="focus:ring-0 focus:outline-none h-full py-2 pl-2 pr-8 border-none bg-transparent text-gray-500 sm:text-sm border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer">
                        <option value="placeholder">
                            {periods.placeholder}
                        </option>
                        <option value="today">{periods.today}</option>
                        <option value="yesterday">{periods.yesterday}</option>
                        <option value="thisMonth">{periods.thisMonth}</option>
                        {fromWholePeriod && toWholePeriod && (
                            <option value="whole">{periods.whole}</option>
                        )}
                        <option value="custom">{periods.custom}</option>
                    </select>
                    <div
                        className={`${
                            loadingIcon
                                ? 'absolute right-2 top-3 bg-white rounded-full flex justify-center w-7'
                                : 'hidden'
                        }`}>
                        <FontAwesomeIcon
                            icon={['fal', 'circle-notch']}
                            size="2x"
                            className={`${
                                dashboard.isLoading || dashboard.isSilentLoading
                                    ? 'h-4 mx-1 animate-spin text-blue-400'
                                    : 'hidden'
                            }`}
                        />
                    </div>
                    <CustomPeriodModal
                        UTC={UTC}
                        active={showModal}
                        toggler={toggleModal}
                        setCurrentPeriod={setCurrentPeriod}
                        minDate={new Date(fromWholePeriod)}
                        maxDate={new Date(toWholePeriod)}
                    />
                </div>
            </div>
            {/* here */}
        </>
    );
};

export default DropdownPeriods;

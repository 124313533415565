import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../containers/UseAuth';

function UserTop(params) {
    const auth = useAuth();
    const user = auth.session.user;
    const { t } = useTranslation();

    const onSignout = () => {
        auth.signout();
    };

    return (
        <>
            <div className="relative mx-4 cursor-pointer group">
                <span className="text-gray-400 hover:text-blue-700">
                    <span className="font-mono">#{user.id}</span> -{' '}
                    {user.username}
                </span>
                <FontAwesomeIcon
                    icon={['fal', 'user-circle']}
                    className="text-black hover:text-blue-700 ml-3 align-middle"
                />
                <div className="absolute hidden group-hover:block right-0 top-0 mt-4 -mr-2 text-gray-400 bg-white border py-2 rounded-lg shadow w-40">
                    {
                        <ul className="list-none">
                            {auth.isSupervising() && (
                                <a
                                    onClick={(a) =>
                                        auth.backToOriginalSupervisorAccount()
                                    }>
                                    <li className="drounded-sm flex justify-between bg-gray-100 text-gray-700 hover:bg-gray-200 py-2 px-2">
                                        {t(
                                            'common.components.header.user.unSupervise'
                                        )}{' '}
                                        <FontAwesomeIcon
                                            icon={['fal', 'eye-slash']}
                                            className="mr-4"
                                        />
                                    </li>
                                </a>
                            )}
                            <a onClick={onSignout}>
                                <li className="rounded-sm flex justify-between bg-gray-100 text-gray-700 hover:bg-gray-200 py-2 px-2">
                                    {t('common.components.header.user.logout')}{' '}
                                    <FontAwesomeIcon
                                        icon={['fal', 'power-off']}
                                        className="text-red-400 mr-4"
                                    />
                                </li>
                            </a>
                        </ul>
                    }
                </div>
            </div>
        </>
    );
}

export default UserTop;

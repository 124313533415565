import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions } from '../../../../store';
import CommonButton from '../../../Buttons/CommonButton';
import ModalOnDelete from '../../../Modal/ModalOnDelete';
import ScreensList from '../ForecastMetrics/ScreensList';

const ScreenDiagnostics = ({ setSelectedScreensLocal }) => {
    const newCampaign = useSelector((state) => state.newCampaign);
    const selectedScreens = newCampaign.selectedScreens;
    const dispatch = useDispatch();
    const metrics = newCampaign.forecast;
    const { t } = useTranslation();
    const [screensToDelete, setScreensToDelete] = useState([]);
    const [isActive, setIsActive] = useState(false);

    const handleInputChange = (isChecked, idScreen) => {
        if (isChecked) {
            setScreensToDelete([...screensToDelete, idScreen]);
        } else {
            let screen = screensToDelete.filter(
                (screenItem) => screenItem != idScreen
            );
            if (screen) {
                setScreensToDelete(screen);
            }
        }
    };
    const handleAllInputChange = (isChecked) => {
        let allScreens = [];
        let allCheckeds = document.getElementsByName(
            'individual-screensWithoutAudience-input'
        );
        if (isChecked) {
            metrics.screensWithoutProvider.forEach((screenData) => {
                allScreens.push(screenData.id);
            });
            setScreensToDelete(allScreens);
            allCheckeds.forEach((itemChecked) => {
                itemChecked.checked = true;
            });
        } else {
            setScreensToDelete([]);
            allCheckeds.forEach((itemChecked) => {
                itemChecked.checked = false;
            });
        }
    };
    const handleClickDelete = () => {
        if (screensToDelete.length == 0) {
            return;
        }
        handleModal();
    };
    const handleModal = () => setIsActive(!isActive);
    const handleCancel = () => {
        handleModal();
    };
    const handleConfirm = () => {
        let screensWithAudience = [...selectedScreens];
        screensToDelete.forEach((iDtgx) => {
            screensWithAudience = screensWithAudience.filter(
                (dataScren) => dataScren.id != iDtgx
            );
        });
        dispatch(newCampaignActions.setScreensWithouProvider([]));
        setSelectedScreensLocal(screensWithAudience);
        dispatch(newCampaignActions.setSelectedScreens(screensWithAudience));
        handleModal();
    };
    return (
        <>
            <div
                className={`mt-8 ${
                    metrics.screensWithoutProvider.length > 0
                        ? 'block'
                        : 'hidden'
                } flex flex-col rounded-lg bg-red-100 p-2`}>
                <div className="my-4">
                    <span className="font-bold text-red-600">
                        <FontAwesomeIcon
                            icon={['fas', 'exclamation-triangle']}
                            fixedWidth
                            className="mr-2 text-red-300"
                        />{' '}
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience'
                        )}
                        :
                    </span>
                    <br />
                    <span className="text-gray-700 font-light">
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience-explain'
                        )}
                    </span>
                </div>
                <div className="flex justify-between">
                    <input
                        onChange={(e) => handleAllInputChange(e.target.checked)}
                        name={'all-screensWithoutAudience-input'}
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 mt-2 rounded"
                    />
                    <button
                        className="bg-red-700 hover:bg-red-900 text-white rounded transition-colors duration-300 ease-in-out font-light text-sm p-1 focus:outline-none w-5/12"
                        onClick={() => handleClickDelete()}>
                        {t(
                            'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience-unselect'
                        )}
                    </button>
                </div>
                <ol className="text-xs mt-4 text-xs ">
                    {metrics.screensWithoutProvider.map((screen, index) => (
                        <div className="flex flex-row mt-1">
                            <input
                                onChange={(e) =>
                                    handleInputChange(
                                        e.target.checked,
                                        screen.id
                                    )
                                }
                                name={'individual-screensWithoutAudience-input'}
                                id={screen.id}
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 mr-2 focus:ring-0 border-gray-300 rounded"
                            />
                            <li key={index} className="text-gray-500 ">
                                <label for={screen.id}>
                                    {screen.name} ({screen.id})
                                </label>
                            </li>
                        </div>
                    ))}
                </ol>
            </div>
            <div
                className={`mt-6 flex flex-col ${
                    metrics.screensUsed?.length > 0 &&
                    metrics.screensNotUsed?.length > 0
                        ? 'block'
                        : 'hidden'
                }`}>
                <ScreensList
                    screens={metrics.screensUsed}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-used'
                    )}
                    icon={{ type: 'fal', name: 'check-circle' }}
                    description={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-used-explain'
                    )}
                    color={'blue-400'}
                />
            </div>
            <div
                className={`mt-6 flex flex-col ${
                    metrics.screensNotUsed?.length > 0 ? 'block' : 'hidden'
                }`}>
                <ScreensList
                    screens={metrics.screensNotUsed}
                    title={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-not-used'
                    )}
                    icon={{ type: 'fad', name: 'exclamation-triangle' }}
                    description={t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-not-used-explain'
                    )}
                    color={'yellow-300'}
                />
            </div>

            <div
                className={`mt-6 flex flex-col ${
                    metrics.notMatchingScreens.length > 0 ? 'block' : 'hidden'
                }`}>
                <span className="font-bold">
                    <FontAwesomeIcon
                        icon={['fad', 'exclamation-triangle']}
                        className="mr-1 text-yellow-300"
                    />{' '}
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience-match'
                    )}
                    :
                </span>
                <span className="font-light mb-4">
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience-match-explain'
                    )}
                    .
                </span>
                <ul>
                    {metrics.notMatchingScreens.map((screen) => (
                        <span className="font-light text-gray-600 block">
                            {screen.name} ({screen.id})
                        </span>
                    ))}
                </ul>
            </div>
            <ModalOnDelete
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                isActive={isActive}
                handleModal={handleModal}
                title={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience'
                )}
                body={t(
                    'sections.campaigns.new-campaign.wizard-audiences.forecast.screens-without-audience-confirm'
                )}
            />
        </>
    );
};

export default ScreenDiagnostics;

//<button className="underline text-gray-500" onClick={handleSelection}>unselect</button>

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { newCampaignActions, screensActions } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';

const ScreenType = ({ screenType, onCheck, onUnCheck, isChecked }) => {
    const handleChange = (input) => {
        if (input.checked) {
            onCheck(screenType);
        } else {
            onUnCheck(screenType);
        }
    };

    const getIconByType = (type) => {
        switch (type) {
            case 'outdoor':
                return (
                    <FontAwesomeIcon
                        className="hidden lg:inline"
                        icon={['fal', 'road']}
                    />
                );
            case 'indoor':
                return (
                    <FontAwesomeIcon
                        className="hidden lg:inline"
                        icon={['fal', 'door-open']}
                    />
                );
            default:
                <></>;
        }
    };

    return (
        <div className="flex items-center justify-between my-2">
            <div className="flex items-center">
                <input
                    defaultChecked={isChecked}
                    onChange={(e) => handleChange(e.target)}
                    name={`screen-${screenType.name}`}
                    id={`screen-${screenType.name}`}
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label
                    htmlFor={`screen-${screenType.name}`}
                    className="ml-2 block text-sm text-gray-600">
                    {getIconByType(screenType.type)} {screenType.name}
                </label>
            </div>
            <div className="text-xs text-gray-400 mr-2">
                {screenType.screens_count}
            </div>
        </div>
    );
};

const ScreenTypeList = ({
    screens,
    screensFilteredFromScreensWizard,
    filteredScreensLocal,
}) => {
    const { selectedScreenTypes, isFilterByAudiences, selectedPublishers } =
        useSelector((state) => state.screens);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const getScreenTypes = (providerCountsScreens) => {
        return [
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.screenLocation-outdoor'
                ),
                type: 'outdoor',
                screens_count: providerCountsScreens.outdoor,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.screenLocation-indoor'
                ),
                type: 'indoor',
                screens_count: providerCountsScreens.indoor,
            },
        ];
    };
    const [screenTypes, setScreenTypes] = useState(
        getScreenTypes({ indoor: 0, outdoor: 0 })
    );

    const getQuantityScreenTypes = (screensToCount) => {
        const providerCountsScreens = { indoor: 0, outdoor: 0 };
        screensToCount.forEach((screen) => {
            switch (screen.screenType) {
                case 'Indoor':
                    providerCountsScreens.indoor++;
                    break;
                case 'Outdoor':
                    providerCountsScreens.outdoor++;
                    break;
                default:
                    break;
            }
        });
        return providerCountsScreens;
    };

    //changing screenTypes count when changing filterByAudience status
    const resetTypesByAudience = () => {
        const providerCountsScreens = getQuantityScreenTypes(
            isFilterByAudiences
                ? filteredScreensLocal.filter((screen) => screen.audiences)
                : filteredScreensLocal
        );
        setScreenTypes(getScreenTypes(providerCountsScreens));
    };

    useEffect(() => {
        //changing screenTypes count
        if (
            selectedScreenTypes.length === 0 &&
            selectedPublishers.length === 0
        ) {
            const providerCountsScreens = getQuantityScreenTypes(
                isFilterByAudiences ? screensFilteredFromScreensWizard : screens
            );

            setScreenTypes(getScreenTypes(providerCountsScreens));
        } else {
            resetTypesByAudience();
        }
    }, [screens, screensFilteredFromScreensWizard, isFilterByAudiences]);

    return (
        <div className="font-light py-2">
            <div className="title font-light">
                {t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.screenLocation-label'
                )}
            </div>
            {screenTypes.map((screenType, index) => (
                <ScreenType
                    screenType={screenType}
                    onCheck={() =>
                        dispatch(
                            screensActions.addSelectedScreenTypes(screenType)
                        )
                    }
                    onUnCheck={() =>
                        dispatch(
                            screensActions.removeFromSelectedScreenTypes(
                                screenType
                            )
                        )
                    }
                    isChecked={selectedScreenTypes.some(
                        (st) => st.type === screenType.type
                    )}
                    key={index}
                />
            ))}
        </div>
    );
};

export default ScreenTypeList;

import axios from '../utils/axios/axios';
import { formatDate } from '../utils/functions';
import * as endpoints from '../constants/endpoints';

// * services for general dashboard
/**
 * It gets the stats of campaigns by user id user between two dates for dashboard general
 * @param from - The start date of the time period you want to get stats for.
 * @param to - The end date of the time period you want to get stats for.
 * @param userId - The id user .
 * @param rawData - This is the object that will be used to store the data.
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsOfCampaignsByUserId = async (from, to, rawData, userId) => {
    from = new Date(from);
    from = formatDate(from);
    to = new Date(to);
    to = formatDate(to);
    try {
        let newUrl = `${endpoints.getStats}/campaigns/user/${userId}?from=${from}&to=${to}`;
        let newResponse = await axios.get(newUrl);
        rawData.data = { ...JSON.parse(JSON.stringify(newResponse.data)) }; // NOTE: Fuck redux
        return newResponse;
    } catch (e) {
        console.error({ e });
    }
};

/**
 * It gets all the stats of campaigns by user id
 * @param userId - The id user .
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsOfCampaignsByUserIdHistory = async (userId) => {
    const from = 22010100;
    const to = 99123123;
    try {
        let newUrl = `${endpoints.getStats}/campaigns/user/${userId}?from=${from}&to=${to}`;
        let newResponse = await axios.get(newUrl);
        return newResponse;
    } catch (e) {
        console.error({ e });
    }
};

/**
 * It gets the stats of the user by hours between two dates
 * @param from - The start date of the period you want to get stats for.
 * @param to - The end date of the range.
 * @param userId - The id user .
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsByHours = async (from, to, userId) => {
    try {
        from = new Date(from);
        from = formatDate(from);
        to = new Date(to);
        to = formatDate(to);
        let url = `${endpoints.getStats}/hours/user/${userId}?from=${from}&to=${to}`;
        const response = await axios.get(url);

        return response;
    } catch (e) {
        console.error(e);
    }
};

/**
 * It gets the stats for a user between two dates
 * @param from - The start date of the range you want to get stats for.
 * @param to - The end date of the range.
 * @param userId - The id user .
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsByDay = async (from, to, userId) => {
    try {
        from = new Date(from);
        from = formatDate(from);
        to = new Date(to);
        to = formatDate(to);
        let url = `${endpoints.getStats}/days/user/${userId}?from=${from}&to=${to}`;
        const response = await axios.get(url);

        return response;
    } catch (e) {
        console.error(e);
    }
};

/**
 * It takes a start date, an end date and user id, formats them, and then makes an API call to get the stats for
 * the user's campaigns
 * @param from - The start date of the range you want to query.
 * @param to - The end date of the date range.
 * @param userId - The id user .
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsBrandsByUserId = async (from, to, userId) => {
    from = new Date(from);
    from = formatDate(from);
    to = new Date(to);
    to = formatDate(to);
    try {
        let newUrl = `${endpoints.getStats}/brands/user/${userId}?from=${from}&to=${to}`;
        let newResponse = await axios.get(newUrl);
        return newResponse;
    } catch (e) {
        console.error({ e });
    }
};

// * services for individual dashboard
/**
 * It takes a campaign ID, a start date, and an end date, and returns the stats for that campaign
 * between those dates
 * @param campaignId - The id of the campaign you want to get stats for.
 * @param from - The start date of the range you want to get stats for.
 * @param to - The end date of the range you want to get stats for.
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsByCampaign = async (campaignId, from, to) => {
    try {
        from = new Date(from);
        from = formatDate(from);
        to = new Date(to);
        to = formatDate(to);
        let url = `${endpoints.getStats}/days/campaign/${campaignId}?from=${from}&to=${to}`;
        const response = await axios.get(url);

        return response;
    } catch (e) {
        console.error(e);
    }
};

/**
 * It gets the stats of a campaign by hours user between two dates
 * @param campaignId - The id of the campaign you want to get stats for
 * @param from - The start date of the range you want to get stats for.
 * @param to - The end date of the range you want to get stats for.
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsByCampaignByHours = async (campaignId, from, to) => {
    try {
        from = new Date(from);
        from = formatDate(from);
        to = new Date(to);
        to = formatDate(to);
        let url = `${endpoints.getStats}/hours/campaign/${campaignId}?from=${from}&to=${to}`;
        const response = await axios.get(url);

        return response;
    } catch (e) {
        console.error(e);
    }
};

/**
 * It gets the stats of a campaign by screens user between two dates
 * @param campaignId - The id of the campaign you want to get stats for
 * @param from - The start date of the time period you want to get stats for.
 * @param to - The end date of the time period you want to get stats for.
 * @returns An object with the following properties:
 *      - currencies: ["ARS"]
 *      - totals: {ARS: {}}
 *      - stats : { ARS : []}
 */
const getStatsByScreens = async (campaignId, from, to) => {
    try {
        from = new Date(from);
        from = formatDate(from);
        to = new Date(to);
        to = formatDate(to);
        let url = `${endpoints.getStats}/screens/campaign/${campaignId}?from=${from}&to=${to}`;
        const response = await axios.get(url);

        return response;
    } catch (e) {
        console.error(e);
    }
};

/**
 * returns status of screens by campaigns
 * @param campaignId - The id of the campaign you want to get the status of.
 */
const getCampaignScreensStatus = async (campaignId) => {
    try {
        let url = `${endpoints.campaigScreensStatus}/${campaignId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (e) {
        console.error(e);
        return {
            error: true,
        };
    }
};

const getCampaignsScreensStatus = async (campaigns) => {
    try {
        let url = `${endpoints.campaignsScreensStatus}?campaigns=${campaigns}`;
        const response = await axios.get(url);
        return response.data;
    } catch (e) {
        console.error(e);
        return {
            error: true,
        };
    }
};

export default {
    // for dashboard general
    getStatsOfCampaignsByUserId,
    getStatsBrandsByUserId,
    getStatsByDay,
    getStatsByHours,
    getCampaignsScreensStatus,
    getStatsOfCampaignsByUserIdHistory,

    // for dashboard individual
    getStatsByCampaign,
    getStatsByCampaignByHours,
    getStatsByScreens,
    getCampaignScreensStatus,
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    screens: [],
    filteredScreens: [],
    filters: [],
    isFilterByAudiences: false,
    showSelectedScreens: false,
    selectedScreenTypes: [],
    selectedPublishers: [],
    selectedScreensLocal: [],
    screensFilteredFromScreensWizard: [],
    tiers: [],
    selectedTiers: [],
    tierList: {},
    maxCpm: null,
};

const { reducer, actions } = createSlice({
    name: 'screens',
    initialState: initialState,
    reducers: {
        setScreens(state, action) {
            state.screens = action.payload;
        },
        setFilteredScreens(state, action) {
            state.filteredScreens = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        setIsFilterByAudiences(state, action) {
            state.isFilterByAudiences = !state.isFilterByAudiences;
        },
        setShowSelectedScreens(state, action) {
            state.showSelectedScreens = !state.showSelectedScreens;
        },
        addPublisher(state, action) {
            state.selectedPublishers = [
                ...state.selectedPublishers,
                action.payload,
            ];
        },
        removePublisher(state, action) {
            let publisher = action.payload;
            state.selectedPublishers = state.selectedPublishers.filter((p) => {
                return p.id !== publisher.id;
            });
        },
        addTier(state, action) {
            state.selectedTiers = [...state.selectedTiers, action.payload];
        },
        removeTier(state, action) {
            let tier = action.payload;
            state.selectedTiers = state.selectedTiers.filter((p) => {
                return p.screenType !== tier.screenType;
            });
        },
        addSelectedScreenTypes(state, action) {
            state.selectedScreenTypes = [
                ...state.selectedScreenTypes,
                action.payload,
            ];
        },
        removeFromSelectedScreenTypes(state, action) {
            state.selectedScreenTypes = state.selectedScreenTypes.filter(
                (st) => {
                    return st.name !== action.payload.name;
                }
            );
        },
        setSelectedScreensLocal(state, action) {
            state.selectedScreensLocal = action.payload;
        },
        setScreensFilteredFromScreensWizard(state, action) {
            state.screensFilteredFromScreensWizard = action.payload;
        },
        resetByKey(state, action) {
            state[action.payload] = initialState[action.payload];
        },
        setTierList(state, action) {
            state.tierList = action.payload;
        },
        setTiers(state, action) {
            state.tiers = action.payload;
        },
        setMaxCpm(state, action) {
            state.maxCpm = action.payload;
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as screensActions };
export { reducer as screensReducer };

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AudienceSegments from './AudienceSegments/AudienceSegments';
import { newCampaignActions } from '../../../store';
import PropTypes from 'prop-types';
import Providers from './ProvidersTabs/Providers';
import AudienceProvider from '../WizardBasics/AudienceProvider/AudienceProvider';
import CalculateForecast from './CalculateForecast';

function WizardAudiences({
    isActive,
    useSelect,
    dispatch,
    service,
    newCampaign,
    budgets,
    campaign,
    setSelectedScreensLocal,
    setCalculatingForecast,
    calculatingForecast,
}) {
    const { t } = useTranslation();
    const [hasAnyProvider, setHasAnyProvider] = useState(false);
    const isResizeMapOn = newCampaign.isResizeMapOn;
    const navProvidersRef = useRef();

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setHours(0, 0, 0, 0);

    const hasForecastValues = () => {
        let hasValues = false;
        Object.keys(newCampaign.forecast).forEach((key) => {
            if (newCampaign.forecast[key] !== '-') hasValues = true;
        });

        return hasValues;
    };

    useEffect(() => {
        if (isActive) {
            setHasAnyProvider(navProvidersRef.current.children.length > 0);
        }
    }, [isActive]);

    // for when the campaign have a provider audience and selected other
    // country withoud provider
    if (!hasAnyProvider && newCampaign.demographics.name) {
        dispatch(newCampaignActions.setDemographics({ id: '' }));
    }

    useEffect(() => {
        if (useSelect) {
            setHasAnyProvider(true);
        }
    }, [newCampaign.stepper]);

    return (
        <>
            {!isActive ? (
                ''
            ) : (
                <div
                    className={`basics mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-299 ${
                        isResizeMapOn
                            ? 'transform scale-75 -translate-y-20'
                            : ''
                    }`}>
                    <div className="module rounded m-1 mb-4">
                        <div className="header flex justify-between content-center">
                            <div className="title text-xl font-extralight">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.demographics.title'
                                )}
                            </div>
                            <div
                                className={`brief font-light text-xs text-gray-400 mt-1.5 text-right ${
                                    hasForecastValues() ? 'invisible' : ''
                                }`}>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-audiences.demographics.info'
                                )}
                            </div>
                        </div>
                        <div className="body rounded-sm p-1">
                            <nav className="flex" ref={navProvidersRef}>
                                <Providers />
                            </nav>
                            {hasAnyProvider ? (
                                <>
                                    <div className="block mt-10">
                                        <AudienceSegments
                                            calculatingForecast={
                                                calculatingForecast
                                            }
                                        />
                                        <CalculateForecast
                                            budgets={budgets}
                                            campaign={newCampaign}
                                            dispatch={dispatch}
                                            isActive={isActive}
                                            newCampaign={newCampaign}
                                            service={service}
                                            setSelectedScreensLocal={
                                                setSelectedScreensLocal
                                            }
                                            setCalculatingForecast={
                                                setCalculatingForecast
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="mt-2">
                                        <span>
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-audiences.no-providers-message'
                                            )}
                                            .
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

WizardAudiences.proptyTypes = {
    isActive: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    service: PropTypes.func,
    newCampaign: PropTypes.object,
    budget: PropTypes.object,
    campaign: PropTypes.object,
};

export default WizardAudiences;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions } from '../../../../../store';

const GenderSegment = (props) => {
    const { isActive, calculatingForecast } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const segments = useSelector((state) => state.newCampaign.segments);
    const isGenderMaleChecked = segments.gender.some(
        (gender) => gender === 'male'
    );
    const isGenderFemaleChecked = segments.gender.some(
        (gender) => gender === 'female'
    );

    function handleDemographicGender(gender) {
        if (calculatingForecast === false) {
            const checked = segments.gender.some((g) => g === gender);
            if (checked) {
                changeGender(segments.gender.filter((g) => g !== gender));
            } else {
                changeGender([...segments.gender, gender]);
            }
        }
    }

    const changeGender = (newGenders) => {
        if (calculatingForecast === false) {
            const newSegments = Object.assign({}, segments);
            newSegments.gender = newGenders;
            dispatch(newCampaignActions.setSegments(newSegments));
        }
    };

    return isActive ? (
        <div className="flex justify-evenly my-8 md:my-0">
            <div className="flex items-center">
                <input
                    checked={isGenderMaleChecked}
                    onChange={() => handleDemographicGender('male')}
                    id="demographic-gender-male"
                    name="demographic-gender"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label
                    htmlFor="demographic-gender-male"
                    className="ml-2 block text-sm text-gray-600">
                    <FontAwesomeIcon
                        icon={['fad', 'male']}
                        size="4x"
                        className={`hidden lg:inline ${
                            isGenderMaleChecked
                                ? 'text-blue-300'
                                : 'text-gray-300'
                        }`}
                    />{' '}
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.demographics.gender.male'
                    )}
                </label>
            </div>
            <div className="flex items-center">
                <input
                    checked={isGenderFemaleChecked}
                    onChange={() => handleDemographicGender('female')}
                    id="demographic-gender-female"
                    name="demographic-gender"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                />
                <label
                    htmlFor="demographic-gender-female"
                    className="ml-2 block text-sm text-gray-600">
                    <FontAwesomeIcon
                        icon={['fad', 'female']}
                        size="4x"
                        className={`hidden lg:inline ${
                            isGenderFemaleChecked
                                ? 'text-red-300'
                                : 'text-gray-300'
                        }`}
                    />{' '}
                    {t(
                        'sections.campaigns.new-campaign.wizard-audiences.demographics.gender.female'
                    )}
                </label>
            </div>
        </div>
    ) : (
        ' '
    );
};

export default GenderSegment;
